import React, { useEffect } from 'react';
import TaskDateTime from '../../../common/components/DatePickers/DateTimePiker';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import { recalculateTaskLoad, setTaskEnd } from '../actions';
import { State } from '../../../rootReducer';

const TaskEnd = ({
  end,
  minDate,
  setTaskEnd,
  delegated,
  recalculateTaskLoad,
}) => {
  useEffect(() => {
    recalculateTaskLoad();
  }, [end]);

  let [date, time] = minDate.split(' ');
  let minDateObj = new Date(`${date.split('-').reverse().join('-')} ${time}`);
  // in particular cases, 'end' date comes here in format dd/mm/yyyy hh:mm:ss
  // expected format is dd-mm-yyyy
  end = end.replace(/\//g, '-');

  return (
    <TaskDateTime
      disabled={delegated}
      acceptHandler={setTaskEnd}
      date={end ? end : ""}
      minDate={minDateObj}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    end: state.newTask.end,
    minDate: state.newTask.begin,
    delegated: state.newTask.delegated,
  };
};

const mapDispatchToProps = {
  setTaskEnd,
  recalculateTaskLoad,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskEnd);
