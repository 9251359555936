import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {State} from '../../../rootReducer';
import {status_list} from "../../../pages/Meetings/components/RightPlate";

type Props = {
    // statusName: string;
    statusName: any;
};

const WrapperStatusCell = styled.div`
  display: flex;
  align-items: center;
 
`;
const MeetingStatusCell: React.FC<Props> = ({statusName}) => {
    switch (statusName) {
        case status_list[0].name:
            return <WrapperStatusCell>
                <div style={{
                    width: '120px',
                    height: '32px',
                    borderRadius: '6px',
                    backgroundColor: '#F3FAFE',
                    border: '1px solid #02405E',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    Предстоит
                </div>
            </WrapperStatusCell>
            break;

        case status_list[1].name:
            return <WrapperStatusCell>
                <div style={{
                    width: '120px',
                    height: '32px',
                    borderRadius: '6px',
                    backgroundColor: '#F2FEFC',
                    border: '1px solid #008C6D',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#008C6D'
                }}>
                    Завершено
                </div>
            </WrapperStatusCell>
            break;

        case status_list[2].name:
            return <WrapperStatusCell>
                <div style={{
                    width: '120px',
                    height: '32px',
                    borderRadius: '6px',
                    backgroundColor: '#F2F2F2',
                    border: '1px solid #A8A8A8',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#707070'
                }}>
                    Отменено
                </div>
            </WrapperStatusCell>
            break;

        default:
            return <WrapperStatusCell>
                <div style={{
                    width: '120px',
                    height: '32px',
                    borderRadius: '6px',
                    backgroundColor: '#F3FAFE',
                    border: '1px solid #02405E',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    Предстоит
                </div>
            </WrapperStatusCell>
            break;
    }
};

export default MeetingStatusCell;
