import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { urlApp } from '../../../App';
import DateMaster from '../../../utils/DateMaster';
import { fetchData } from '../../../utils/fetchData';
import Avatar from '../Avatar/Avatar';
import Icon from '../Icon';

const NotificationItemStyle = styled.div`
  z-index: 1000;
  display: flex;
  flex-direction: column;
  position: relative;
  //cursor: default;
  padding: 16px;
  min-height: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  &:hover {
    background-color: #f2f2f2;
  }
`;
const FlexBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;
const NotificationName = styled.span`
  display: flex;
  position: relative;
  max-width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #212121;
`;
const UnreadMark = styled.div`
  display: flex;
  align-items: center;
  min-width: 8px;
  min-height: 8px;
  background-color: #1baaf0;
  border-radius: 50%;
  margin-right: 8px;
`;
const NotificationDate = styled.div`
  position: absolute;
  right: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #a8a8a8;
`;

const NotificationInforation = styled.div`
  display: flex;
  max-width: ${({ nameTask }) => (!nameTask ? '200px' : '272px')};
  margin-left: 8px;
  flex-direction: ${({ checkStatus }) => (checkStatus ? 'row' : 'column')};
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #02405e;

  .add_comment {
    color: black;
    padding: 0;
    text-align: left;
    text-transform: initial;
    font-size: 14px;
    width: 100%;
  }

  .change_params {
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    .param {
      margin-right: 2px;
    }
    .param_value {
      margin-left: 2px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .status_12,
  .status_13 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    height: 32px;
    border-radius: 6px;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 4px;
    font-size: 14px;
    line-height: 16px;
    color: #008c6d;
    background: #f2fefc;
    border: 1px solid #008c6d;
  }
  .status_10 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    height: 32px;
    border-radius: 6px;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 4px;
    font-size: 14px;
    line-height: 16px;
    color: #02405e;
    background: #f3fafe;
    border: 1px solid #02405e;
  }
  .status_14 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    height: 32px;
    border-radius: 6px;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 4px;
    font-size: 14px;
    line-height: 16px;
    color: #cc0000;
    background: #fff2f2;
    border: 1px solid #cc0000;
  }
  .status_6,
  .status_17 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    height: 32px;
    border-radius: 6px;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 4px;
    font-size: 14px;
    line-height: 16px;
    color: #b9932f;
    background: #fffdf7;
    border: 1px solid #b9932f;
  }
  .status_2,
  .status_3,
  .status_4,
  .status_5 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: 175px;
    align-items: center;
    height: 32px;
    border-radius: 6px;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 4px;
    font-size: 14px;
    line-height: 16px;
    color: #b201f1;
    background: #fbf3fe;
    border: 1px solid #b201f1;
  }
  .status_3 {
    min-width: 180px;
  }
  .status_15,
  .status_11,
  .status_1,
  .status_9 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    height: 32px;
    border-radius: 6px;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 4px;
    font-size: 14px;
    line-height: 16px;
    color: #707070;
    background: #f2f2f2;
    border: 1px solid #707070;
  }

  .task_create {
    font-size: 14px;
    line-height: 16px;
    color: #212121;
  }
  .task_delete {
    font-size: 14px;
    line-height: 16px;
    color: #212121;
  }
  .dedline {
    font-size: 14px;
    line-height: 16px;
    color: #212121;
  }
`;

const BuildNotificationRow = ({
  id_key,
  avatar_url,
  user_name,
  task_name = '',
  item,
  unread_mark = false,
  readClick,
  users,
  textAction,
}) => {
  let avatara, fullname;
  if (item.data && item.data.author_notif) {
    let author_id = parseInt(item.data.author_notif);
    users.forEach((user) => {
      if (user.id === author_id) {
        if (user.image) avatara = user.image.url;
        fullname = user.name + ' ' + user.surname;
      }
    });
  }

  const dedlineString = `App\\\Notifications\\\TaskEndNearSystem`;
  const formatString = `App\\\Notifications\\\ErrorCreateCyclicTaskSystem`;
  const checkStatus = () => {
    if (typeof item?.data?.text === 'string') {
      return item?.data?.text.split(' ').includes('Статус');
    } else {
      return false;
    }
  };

  return (
    <NotificationItemStyle
      key={id_key}
      data-taskid={item?.data?.task_id || null}
      onClick={(event) => {
        if (item.data)
          textAction(item.data.task_id, item.id, item.notifiable_id, event);
      }}
    >
      <NotificationName
        title={task_name}
        unread_mark={unread_mark}
        data-taskid={item?.data?.task_id || null}
      >
        {unread_mark && <UnreadMark />}
        {task_name}
      </NotificationName>

      <NotificationDate data-taskid={item?.data?.task_id || null}>
        {new DateMaster(new Date(item.created_at)).commentDate}
      </NotificationDate>
      <FlexBlock data-taskid={item?.data?.task_id || null}>
        {item.type === dedlineString || item.type === formatString ? (
          <Icon name="errow" data-taskid={item?.data?.task_id || null} />
        ) : (
          <Avatar
            data-taskid={item?.data?.task_id || null}
            style={{
              minWidth: '32px',
              minHeight: '32px',
              width: '32px',
              height: '32px',
            }}
            name={fullname}
            url={avatara ? urlApp + `/files/${avatara}/download` : null}
          />
        )}

        <NotificationInforation
          data-taskid={item?.data?.task_id || null}
          checkStatus={checkStatus()}
          nameTask={task_name}
          // flex-direction={item.data.text.split('"')}
          //   className={'action'}
          dangerouslySetInnerHTML={{
            __html:
              item.data && item.data.text && fullname
                ? item.data.text
                : 'формат уведомления некорректен',
          }}
        >
          {/*{item.data && item.data.text ? item.data.text : 'формат уведомления некорректен'}*/}
        </NotificationInforation>
        {/*<div className={'subject_of_action'}>{/*</div>*/}
        {/*{item.data.comment && <div className={'additional_content'}>*/}
        {/*    <p>{item.data.comment}</p>*/}
        {/*</div>}*/}
        {/*{item.data.after && <TaskStatus id={parseInt(item.data.after)} taskId={item.data.task_id}/>}*/}
      </FlexBlock>
    </NotificationItemStyle>
  );
};

export default BuildNotificationRow;
