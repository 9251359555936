import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

const Name = styled.p`
`;

const Description = styled.p`
  color: #A8A8A8;
`;

type Props = {
	name: string;
	description: string;
};

const TaskDescription: FC<Props> = ({ name, description }) => {

	return (
		<Wrapper>
			<Name>{name}</Name>
			<Description>{description}</Description>
		</Wrapper>
	);
};

export default TaskDescription;
