//   Выбор и оплата тарифа

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Button from '../../common/components/newComponent/Buttons/Button';
import Icon from '../../common/components/Icon';
import { State } from '../../rootReducer';
import {
  changePriceParts,
  changeStep,
  paidSuccess,
  paymentDetails,
} from './actions';
import MultySelect from '../../common/components/Selects/MultySelect';
import Tile from './Components/Tile';
import BreadCrumbsText from './Components/breadCrumbText';
import BreadCrumbsArrow from './Components/breadCrumbArrow';
import { fetchData } from '../../utils/fetchData';
import { setPaid } from '../Payment/actions';
import { nanoid } from 'nanoid';
import CustomInput from '../../common/components/newComponent/Inputs/CustomInput';
import NameWithInput from '../../common/components/newComponent/Inputs/NameWithInput';
import { useHistory } from 'react-router';
import { PayemntInfo } from '../Payment/action_types';
import { cookieMaster } from '../../utils/CookieMaster';
import TarifDescription from './Components/TarifDescription';

type Props = {
  currentTariff: string;
  changeStep: (step: number) => void;
  step: number;
  months: number;
  members: number;
  changePriceParts: (members?: number, months?: number) => void;
  setPaid: (data, pay: PayemntInfo, companyId) => void;
  paid_success: boolean;
  commonInfoCompany: any;
  paymentDetails: (val: object) => void;
  paymentDetailsData: object;
};

const NewRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ styles }) => styles && { ...styles }}
`;

const StepTitle = styled.div`
  font-size: 24px;
  margin-bottom: 24px;
`;

const NameForInputField = styled.div`
  width: 152px;
  padding: 12px 8px;
  margin-right: 8px;
`;

const NameRow3rdScreen = styled.div`
  width: 464px;
  height: 40px;
  display: flex;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Payment: React.FC<Props> = ({
  currentTariff,
  paid_success,
  changeStep,
  step,
  changePriceParts,
  members,
  months,
  setPaid,
  paymentDetails,
  commonInfoCompany,
  paymentDetailsData,
}) => {
  const [monthsState, setMonthsState] = useState<number>(months);
  const [membersState, setMembersState] = useState<number>(members);
  const [companyId, setCompanyId] = useState<number>(0);
  const history = useHistory();
  const dispatch = useDispatch();
  // const [currentCompanyID, setCurrentCompanyID] = useState<number>(1);
  const [clickedTariff, setClickedTariff] = useState<number>(1);
  const [currentCompanyTariffInfo, setCurrentCompanyTariffInfo] =
    useState<object | null>(null);

  const breadCrumbsItems = [
    'Выбор тарифа',
    'Настройка',
    'Платежные реквизиты',
    'Оплата',
  ];

  const tilesSourceData = [
    {
      id: 1,
      iconName: 'base_price_icon',
      color: '1BAAF0',
      tariffName: 'EASY FREE',
      describe1:
        'Легкий и эффективный инструмент управления небольшой командой',
      describe2: 'Совершенно бесплатная работа до 5 пользователей',
      price: 0 + ' ₽',
      buttonTitle: 'Продолжить работу',
      buttonDesign: 'outline',
      active: false,
    },
    {
      id: 2,
      iconName: 'premium_price_icon',
      color: '27CBA6',
      tariffName: 'EASY PAY',
      describe1: 'Необходимый функционал для сплоченной работы над проектами',
      describe2: `Стоимость указана за пользователя в месяц.`,
      price: 99 + ' ₽',
      buttonTitle: 'Оформить подписку',
      buttonDesign: 'green_main_filled',
      active: false,
    },
    {
      id: 3,
      iconName: 'enterprise_price_icon',
      color: 'BB6BD9',
      tariffName: 'EASY BOX',
      describe1: 'Мощный ресурс для выстраивания процессов и аналитики',
      describe2: 'Оставьте контактные данные, и мы с вами свяжемся.',
      price: 'По запросу',
      buttonTitle: 'Напишите нам',
      buttonDesign: 'green_main_filled',
      active: false,
    },
  ];
  
  
  let descriptionText:string = "";     // **************************************************  
  let tarif_plan_title:string = "Расширенный";  //********************* 

  const membersCount_rentDate = [{ text: '1', value: 1 }];
  for (let i = 2; i < 13; i++)
    membersCount_rentDate.push({ text: i.toString(), value: i });

  useEffect(() => {
    (async () => {
      if (step === 2) {
        console.log("step2");
        setClickedTariff(step);
      }

      if (commonInfoCompany && Object.keys(commonInfoCompany).length) {
     //   const tariffInfo = await fetchData.get(
    //      `/api/v1/pricing_plans/${clickedTariff}`
    //    );
    console.log("tariffInfo");
    const tariffInfo = {price_per_month:99}
        setCurrentCompanyTariffInfo(tariffInfo);
      }
    })();
  }, [setClickedTariff, clickedTariff, step, history.location.pathname]);

  let disabled = true;
  if (Object.keys(paymentDetailsData).length === 8) {
    let empty_field = false;

    Object.values(paymentDetailsData).forEach((item) => {
      if (item === '') empty_field = true;
    });

    if (!empty_field) disabled = false;
  }

  useEffect(() => {
    setMonthsState(months);
  }, [months]);

  useEffect(() => {
    setMembersState(members);
  }, [members]);

  useEffect(() => {
    if (companyId === 0) {
      console.log("company_Id_0");
      let LS_company_id = localStorage.getItem('company_id');

      if (LS_company_id) {
        setCompanyId(parseInt(LS_company_id));
      }
    } else {
      let path = history.location.pathname.split('/');
      if (path[path.length - 2] === 'payment') {
        console.log("payment");
        setCompanyId(+path[path.length - 1]);
      }
    }
  }, [
    localStorage.getItem('company_id'),
    commonInfoCompany,
    companyId,
    history.location.pathname,
  ]);

  useEffect(() => {
    let path = history.location.pathname.split('/');

    if (path.includes('transaction')) {
      console.log("transaction");
      fetchData
        .get(
          `/api/v1/account/transactions?transaction_id=${
            path[path.length - 1]
          }`,
          {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            Authorization: `${cookieMaster.getCookie('access_token')}`,
            'company-id': `${path[path.length - 3]}`,
          }
        )
        .then((transactions) => {
          if (transactions[0].status === 'PENDING') {
            console.log('В процессе');
          } else if (transactions[0].status === 'SUCCEDED') {
            console.log('Оплачено');
            dispatch(paidSuccess(true));
            dispatch(changeStep(4));
          } else if (transactions[0].status === 'CANCELLED') {
            console.log('Ошибка');
            dispatch(paidSuccess(false));
            dispatch(changeStep(4));
          }
        });
    } else if (path[path.length - 1] !== 'payment') {
      console.log("else");
      setCompanyId(+path[path.length - 1]);
    }
  }, []);
  tilesSourceData.map((item, i) => {
    if (commonInfoCompany[companyId]) {
      if (commonInfoCompany[companyId].pricing_plan_id === item.id) {
        tilesSourceData[i].active = true;
      } else {
        tilesSourceData[i].active = false;
      }
    }
  });

  return (
    <NewRow styles={{ justifyContent: 'center' }}>
      <div style={{ padding: '24px', width: '920px' }}>
        <NewRow styles={{ marginBottom: '24px' }}>
          {breadCrumbsItems.map((name, number) => {
            return (
              <div style={{ display: 'flex' }} key={nanoid()}>
                <BreadCrumbsText
                  contextStep={number + 1}
                  currentStep={step}
                  name={name}
                  changeStep={changeStep}
                />
                {breadCrumbsItems.length !== number + 1 && <BreadCrumbsArrow />}{' '}
                {/*если это не последний пункт*/}
              </div>
            );
          })}
        </NewRow>

        {step === 1 && (
          <div>
            <StepTitle>Выбор тарифа</StepTitle>
            <NewRow styles={{ justifyContent: 'center' }}>
              {tilesSourceData.map((item) => {
                return (
                  <Tile
                    key={nanoid()}
                    companyId={companyId}
                    iconName={item.iconName}
                    color={item.color}
                    active={item.active}
                    tariffName={item.tariffName}
                    describe1={item.describe1}
                    price={item.price}
                    describe2={item.describe2}
                    // buttonTitle={item.buttonTitle}
                    buttonDesign={item.buttonDesign}
                    changeStep={changeStep}
                    id={item.id}
                    selectedTariffId={(arg) => {
                      setClickedTariff(arg);

                      // let newVal = {...paymentDetails}
                      // newVal['pricing_plan_id'] = arg
                      // setPaymentDetails(newVal)

                      let newPram = {};
                      newPram['pricing_plan_id'] = arg;
                      paymentDetails(newPram);
                    }}
                  />
                );
              })}
            </NewRow>
            <TarifDescription></TarifDescription>
          </div>
        )}

        {step === 2 && (
          <div>
            <StepTitle>Тариф EASY PAY</StepTitle>
            <NewRow>Введите количество сотрудников</NewRow>
            <NewRow styles={{ height: '40px', lineHeight: '40px' }}>
              <div
                style={{
                  width: '24px',
                  height: '24px',
                  textAlign: 'center',
                  marginRight: '8px',
                }}
              >
                <Icon name={'peoples'} />
              </div>
              <div style={{ marginRight: '8px' }}>Сотрудники</div>
              <MultySelect
                itemsList={membersCount_rentDate}
                selectedItems={[]}
                addHandler={(arg) => {
                  changePriceParts(arg);
                }}
                removeHandler={() => {}}
                length_block={90}
                withImage={false}
                current_value={membersState.toString()}
                radio={true}
              />
              <div style={{ marginLeft: '8px' }}>человек</div>
            </NewRow>
            <NewRow styles={{ height: '40px', lineHeight: '40px' }}>
              <div
                style={{
                  width: '24px',
                  height: '24px',
                  textAlign: 'center',
                  marginRight: '8px',
                }}
              >
                <Icon name={'calendar-black'} />
              </div>
              <div style={{ marginRight: '8px' }}>Срок аренды</div>
              <MultySelect
                itemsList={membersCount_rentDate ? membersCount_rentDate : []}
                selectedItems={[]}
                addHandler={(arg) => {
                  changePriceParts(undefined, arg);
                }}
                removeHandler={() => {}}
                length_block={90}
                withImage={false}
                current_value={monthsState.toString()}
                radio={true}
              />
              <div style={{ marginLeft: '8px' }}>месяцев</div>
            </NewRow>

            <div
              style={{
                width: '320px',
                height: '96px',
                backgroundColor: '#F2F2F2',
                borderRadius: '12px',
                border: '1px solid #E0E0E0',
                padding: '16px',
                marginBottom: '16px',
              }}
            >
              <div style={{ marginBottom: '16px', color: '#707070' }}>
                Сумма за {membersState} сотрудников за {monthsState} месяцев
              </div>
              <div style={{ fontSize: '36px', fontWeight: 300 }}>
                {/*membersState *
                  (monthsState >= 12
                    ? currentCompanyTariffInfo?.['price_per_year'] * monthsState
                    : currentCompanyTariffInfo?.['price_per_month'] *
                  monthsState)*/}
                 {membersState *
                  (monthsState >= 12
                    ? currentCompanyTariffInfo?.['price_per_year'] * monthsState
                    : currentCompanyTariffInfo?.['price_per_month'] *
                  monthsState)}  
                ,00 ₽
              </div>
            </div>

            <div style={{ width: '243px' }}>
              <Button
                title={'Далее'}
                style={{ width: '100%', marginBottom: '8px' }}
                onClick={() => changeStep(3)}
              />
              <Button
                title={'Назад к выбору тарифа'}
                design={'outline'}
                style={{ width: '100%' }}
                onClick={() => changeStep(1)}
              />
            </div>
          </div>
        )}

        {step === 3 && (
          <div>
            <StepTitle>Платежные реквизиты</StepTitle>
            <NewRow>
              <div style={{ marginRight: '24px' }}>
                <NameWithInput
                  withoutIcon={true}
                  type={'text'}
                  title={'Имя'}
                  placeholder={'Иван'}
                  widthInput={'400px'}
                  inputChangeHandler={(arg) => {
                    paymentDetails({ payer_name: arg });
                  }}
                  inputValue={paymentDetailsData['payer_name']}
                  style={{ marginLeft: '6px' }}
                />

                <NameWithInput
                  withoutIcon={true}
                  type={'text'}
                  title={'Фамилия'}
                  placeholder={'Иванов'}
                  widthInput={'400px'}
                  inputChangeHandler={(arg) => {
                    paymentDetails({ payer_surname: arg });
                  }}
                  inputValue={paymentDetailsData['payer_surname']}
                  style={{ marginLeft: '6px' }}
                />

                <NameWithInput
                  withoutIcon={true}
                  type={'text'}
                  title={'Email'}
                  placeholder={'ivanov@gmail.com'}
                  widthInput={'400px'}
                  inputChangeHandler={(arg) => {
                    paymentDetails({ payer_email: arg });
                  }}
                  inputValue={paymentDetailsData['payer_email']}
                  style={{ marginLeft: '6px' }}
                />

                <NameWithInput
                  withoutIcon={true}
                  type={'text'}
                  title={'Страна'}
                  placeholder={'Россия'}
                  widthInput={'400px'}
                  inputChangeHandler={(arg) => {
                    paymentDetails({ country: arg });
                  }}
                  inputValue={paymentDetailsData['country']}
                  style={{ marginLeft: '6px' }}
                />

                <NameWithInput
                  withoutIcon={true}
                  type={'text'}
                  title={'Город'}
                  placeholder={'Москва'}
                  widthInput={'400px'}
                  inputChangeHandler={(arg) => {
                    paymentDetails({ city: arg });
                  }}
                  inputValue={paymentDetailsData['city']}
                  style={{ marginLeft: '6px' }}
                />

                <NameWithInput
                  withoutIcon={true}
                  type={'text'}
                  title={'Юридический адрес'}
                  placeholder={'Введите адрес компании'}
                  widthInput={'400px'}
                  inputChangeHandler={(arg) => {
                    paymentDetails({ payer_address: arg });
                  }}
                  inputValue={paymentDetailsData['payer_address']}
                  style={{ marginLeft: '6px' }}
                />

                <NameWithInput
                  withoutIcon={true}
                  type={'text'}
                  title={'Индекс'}
                  placeholder={'123456'}
                  widthInput={'400px'}
                  inputChangeHandler={(arg) => {
                    paymentDetails({ postal_code: arg });
                  }}
                  inputValue={paymentDetailsData['postal_code']}
                  style={{ marginLeft: '6px' }}
                />

                {/*<NameRow3rdScreen>*/}
                {/*    <NameForInputField>Имя</NameForInputField>*/}
                {/*    <div style={{width: '304px'}}>*/}
                {/*        <CustomInput height={'40px'} width={'100%'}*/}
                {/*                     placeholder={''}*/}
                {/*                     value={undefined}*/}
                {/*                     inputChangeHandler={(arg) => {*/}
                {/*                         // let newParam = {}*/}
                {/*                         // newParam[item.name] = arg*/}
                {/*                         // // console.log(newParam)*/}
                {/*                         // paymentDetails(newParam)*/}
                {/*                     }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</NameRow3rdScreen>*/}
                {/*})}*/}
              </div>
              <div>
                <div
                  style={{
                    width: '320px',
                    border: '1px solid #E0E0E0',
                    backgroundColor: '#F2F2F2',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <NewRow
                    styles={{
                      height: '24px',
                      fontSize: '16px',
                      fontWeight: 500,
                    }}
                  >
                    Заказ
                  </NewRow>
                  <NewRow
                    styles={{
                      justifyContent: 'space-between',
                      fontSize: '14px',
                    }}
                  >
                    <div>Подписка</div>
                    <div>EASY PAY</div>
                  </NewRow>
                  <NewRow
                    styles={{
                      justifyContent: 'space-between',
                      fontSize: '14px',
                    }}
                  >
                    <div>Срок аренды</div>
                    <div>{monthsState} мес</div>
                  </NewRow>
                  <NewRow
                    styles={{
                      justifyContent: 'space-between',
                      fontSize: '14px',
                    }}
                  >
                    <div>Пользователей</div>
                    <div>{membersState}</div>
                  </NewRow>
                  <NewRow
                    styles={{
                      justifyContent: 'space-between',
                      fontSize: '14px',
                    }}
                  >
                    <div>Заказ</div>
                    <div>
                      {membersState * currentCompanyTariffInfo?.['price_per_month'] * monthsState}
                      ,00
                    </div>
                  </NewRow>
                  <div
                    style={{
                      borderBottom: '1px solid #E0E0E0',
                      marginBottom: '16px',
                    }}
                  />
                  <NewRow
                    styles={{
                      justifyContent: 'space-between',
                      fontSize: '24px',
                    }}
                  >
                    <div>Итого</div>
                    <div>
                      ₽{' '}
                      {membersState * currentCompanyTariffInfo?.['price_per_month'] * monthsState}
                      ,00
                    </div>
                  </NewRow>
                </div>
                <div style={{ marginTop: '16px' }}>

                  <Button
                    title={'Перейти к оплате'}
                    style={{ width: '100%', marginBottom: '8px' }}
                    big={true}
                    disabled={disabled}
                    onClick={() => {   

                    descriptionText =  "user_count:"+ membersState + "," + "time_period:"  + monthsState + "," + "tarif_plan_title:" + tarif_plan_title;   //*****  
                    
                      //*** description: descriptionText
                      setPaid(
                        paymentDetailsData,
                        {
                          amount:
                            membersState * currentCompanyTariffInfo?.['price_per_month'] *  monthsState,
                          description: descriptionText,
                        },
                        companyId
                      );
                      console.log("Нажата кнопка - перейти к оплате");
                    }}
                  />
                  <Button
                    design={'outline'}
                    title={'К выбору тарифа'}
                    style={{ width: '100%' }}
                    big={true}
                    onClick={() => {
                      changeStep(2);
                    }}
                  />
                </div>
              </div>
            </NewRow>
          </div>
        )}

        {step === 4 && (
          <NewRow styles={{ justifyContent: 'center', marginTop: '115px' }}>
            <div style={{ width: '320px' }}>
              <NewRow styles={{ width: '100%', justifyContent: 'center' }}>
                {paid_success ? (
                  <Icon name={'success_payment'} />
                ) : (
                  <Icon name={'failed_payment'} />
                )}
              </NewRow>

              {paid_success ? (
                <NewRow styles={{ fontSize: '24px', justifyContent: 'center' }}>
                  Оплата прошла успешно
                </NewRow>
              ) : (
                <NewRow styles={{ fontSize: '24px', justifyContent: 'center' }}>
                  Ошибка оплаты
                </NewRow>
              )}
              <NewRow styles={{ padding: '0 32px', color: '#A8A8A8' }}></NewRow>
              <div>
                {!paid_success && (
                  <Button
                    title={'Повторить попытку'}
                    style={{ width: '100%', marginBottom: '8px' }}
                    big={true}
                    onClick={() => {
                      changeStep(2);
                    }}
                  />
                )}

                <Button
                  design={'outline'}
                  title={'Вернуться на главную'}
                  style={{ width: '100%' }}
                  big={true}
                  onClick={() => {
                    history.replace('/');
                  }}
                />
              </div>
            </div>
          </NewRow>
        )}
      </div>
    </NewRow>
  );
};

const mapStateToProps = (state: State) => {
  // console.log(state.commonInfo.company)
  return {
    currentTariff: state.payment.currentTariff,
    step: state.payment.step,
    months: state.payment.months,
    members: state.payment.members,
    paid_success: state.payment.paid_success,
    paymentDetailsData: state.payment.paymentDetails,
    commonInfoCompany: state.commonInfo.company,
  };
};

const mapDispatchToProps = {
  changeStep,
  changePriceParts,
  paymentDetails,
  setPaid,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
