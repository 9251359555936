import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Department, User, Workgroup } from "../../../common/types";
import {
  getFilter,
  getDepList,
  getGroupList,
  getShowingDepGroupPlate,
} from "../selectors";
import { State } from "../../../rootReducer";
import {
  selectDepGroup,
  setDepFilter,
  setDepGroupType,
  showWindowCreateDepGroup,
} from "../actions";
import DepGroupListElem from "./DepGroupListElem";
import styled from "styled-components";
import { fetchData } from "../../../utils/fetchData";
import Icon from "../../../common/components/Icon";

type Props = {
  showDepGroupPlate: boolean;
  deps: Department[];
  filter: string;
  setDepFilter: (filter: string) => void;
  setDepGroupType: (value: string | null) => void;
  plateType: null | string;
  groups: Workgroup[];
  showWindowCreateDepGroup: (val: boolean) => void;
  total_users: number;
  selectDepGroup: (id: number | null, totalUsers: number | null) => void;
  isLeftBlock: boolean;
  setIsLeftBlock: (value: boolean) => void;
};

const WrapperGroupPlate = styled.div`
  position: relative;
  display: flex;
`;

const GroupPlateBlock = styled.div`
  width: ${({ isLeftBlock }) => (isLeftBlock ? "280px" : "0")};
  margin-left: ${({ isLeftBlock }) => (isLeftBlock ? "24px" : "0")};
  min-width: 0;
  transition: width 0.4s ease, min-width 0.4s ease, margin-left 0.4s ease;
  height: calc(100vh - 80px);
  // иначе  длинный список отделов уходит ниже окна и скрывается
  overflow: hidden;
  overflow-y: auto;

  > :first-child {
    font-size: 16px;
    line-height: 24px;
    margin: 0 12px;
    font-weight: 500;
  }
`;
const BLockRollUp = styled.div`
  position: absolute;
  width: 8px;
  right: -8px;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: ${({ isLeftBlock }) =>
    isLeftBlock ? `1px solid rgb(0 0 0 / 10%)` : `1px solid #fff`};

  &:hover {
    div {
      background-color: #1baaf0;
    }
  }
  div {
    position: relative;
    width: 8px;
    height: 48px;
    border-radius: 1px;
    background-color: ${({ isLeftBlock }) =>
      isLeftBlock ? "#e6ecef" : "#1baaf0"};
    cursor: pointer;
    &::before {
      content: "";
      border: 4px solid transparent;
      ${({ isLeftBlock }) =>
        isLeftBlock
          ? `border-right: 5px solid #ffffff; left: -3px;`
          : "border-left: 5px solid #ffffff; left: 1px;"}
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const HeaderBlockStyle = styled.div`
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :first-child {
    display: flex;
    margin: 0 12px;
    height: inherit;
    align-items: center;
    cursor: default;
  }
  span {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
  }
`;

const DepList = styled.div``;
const DepGroupPlate: React.FC<Props> = ({
  showDepGroupPlate,
  selectDepGroup,
  total_users,
  showWindowCreateDepGroup,
  plateType,
  deps,
  groups,
  filter,
  setDepFilter,
  setDepGroupType,
  isLeftBlock,
  setIsLeftBlock,
}) => {
  // const transitionEndHandler = useCallback(
  //   (e) => {
  //     if (!showDepGroupPlate) {
  //       setDepGroupType(null);
  //     }
  //   },
  //   [setDepGroupType, showDepGroupPlate]
  // );
  useEffect(() => {
    setIsLeftBlock(
      localStorage.getItem("leftBlockUser") === "false" ? false : true
    );
  }, []);
  return (
    <WrapperGroupPlate>
      <GroupPlateBlock
        isLeftBlock={isLeftBlock}
        //  onTransitionEnd={transitionEndHandler}
        // className={`dep_group_plate${showDepGroupPlate ? ' visible' : ''}`}>
      >
        {/*один поиск на оба раздела*/}
        {/*<Search*/}
        {/*    value={filter}*/}
        {/*    // placeholder={plateType === 'deps' ? 'Искать отделы' : 'Искать группы'}*/}
        {/*    placeholder={'Искать'}*/}
        {/*    changeHandler={setDepFilter}*/}
        {/*/>*/}

        <div style={{ cursor: "default" }}>Сотрудники</div>

        <DepGroupListElem name={"Все"} number_of_employees={total_users} />

        {/*оглавление и список по отделам и группам*/}
        <HeaderBlockStyle>
          <div>
            <Icon name="trees" style={{ marginRight: "8px" }} />
            <span>Отделы</span>
          </div>
          <Icon
            name="plus_circle_blue"
            style={{ padding: "12px" }}
            onClick={() => {
              selectDepGroup(null, null);
              setDepGroupType("deps");
              showWindowCreateDepGroup(true);
            }}
          />
        </HeaderBlockStyle>

        <DepList>
          {deps.map((elem) => {
            if (!elem.parent_id) {
              return (
                <DepGroupListElem
                  type={"deps"}
                  key={elem.id}
                  id={elem.id}
                  name={elem.name}
                  number_of_employees={elem.number_of_employees}
                />
              );
            }
          })}
        </DepList>

        {/*//////////////////////////////*/}

        {/*оглавление и список по отделам и группам*/}
        <HeaderBlockStyle c>
          <div>
            <Icon name="peoples" style={{ marginRight: "8px" }} />
            <span>Группы</span>
          </div>
          <Icon
            name="plus_circle_blue"
            style={{ padding: "12px" }}
            onClick={() => {
              selectDepGroup(null, null);
              setDepGroupType("groups");
              showWindowCreateDepGroup(true);
            }}
          />
        </HeaderBlockStyle>

        <DepList>
          {groups.map((elem) => {
            return (
              <DepGroupListElem
                type={"groups"}
                key={elem.id}
                id={elem.id}
                name={elem.name}
                number_of_employees={elem.number_of_employees}
              />
            );
          })}
        </DepList>
      </GroupPlateBlock>
      <BLockRollUp isLeftBlock={isLeftBlock}>
        <div
          onClick={() => {
            setIsLeftBlock(!isLeftBlock);
            localStorage.setItem("leftBlockUser", !isLeftBlock + "");
          }}
        />
      </BLockRollUp>
    </WrapperGroupPlate>
  );
};

const mapStateToProps = (state: State) => {
  return {
    total_users: state.commonInfo.users.length,
    showDepGroupPlate: getShowingDepGroupPlate(state),
    deps: getDepList(state),
    filter: getFilter(state),
    groups: getGroupList(state),
    plateType: state.usersPage.depGroupPlateType,
  };
};

const mapDispatchToProps = {
  setDepFilter,
  setDepGroupType,
  showWindowCreateDepGroup,
  selectDepGroup,
  // setAddingDep
};

export default connect(mapStateToProps, mapDispatchToProps)(DepGroupPlate);
