// Тут добавляется в компанию сотрудник

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../common/components/newComponent/Buttons/Button';
import CustomInput from '../../../common/components/newComponent/Inputs/CustomInput';
import { State } from '../../../rootReducer';
import { fetchData } from '../../../utils/fetchData';
import { setUserName, setUserSurname } from '../../NewUser/actions';
import DepartmentSelect from '../../NewUser/components/DepartmentSelect';
import PositionSelect from '../../NewUser/components/PositionSelect';
import RoleSelect from '../../NewUser/components/RoleSelect';
import { setUserDepartment } from '../../../pages/NewUser/actions';  //*********************** */
import { setUserPosition } from '../../../pages/NewUser/actions'; //*************************** */

const WrapperUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  z-index: 1000;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
`;
const WrapperBlock = styled.div`
  width: 352px;
  background: #ffffff;
  padding: 16px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  input {
    border: 1px solid #e0e0e0;
  }
`;
const TitleStyle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  margin-bottom: 16px;
`;
const BlockItem = styled.div`
  p {
    font-size: 14px;
    color: #707070;
    margin: 8px 0;
  }
`;
const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 100%;
    &:first-child {
      margin-right: 8px;
    }
  }
`;

const NewUserInCompany = ({
  setChangeDataUser,
  isAddUserInCompany,
  setIsAddUserInCompany,
  changeDataUser,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();


  const { surname, name, email, roles, positions, departments } = useSelector(
    (state: State) => state.newUser
  );




  // ***********************  Получение информации из state
  const { departments:departmentsAll,positions:positionsAll} = useSelector(
    (state: State) => state.commonInfo
  );
//   console.log("departmentsAll***",departmentsAll)
//   console.log("positionsAll***",positionsAll)
//****************************************************** */

let departments0:number[] = [];
let positions0:number[] = [];


// Добавлена функция для заполнения отдела и должности если пользователь их не заполнил
//const AddDepPos = async () => {
 //if (isAddUserInCompany===true) {
 //  console.log("Добавление пользователя!")
 //  console.log("departments",departments)
 //  console.log("positions",positions)
 //  if (departments.length===0) {console.log("отдел:общий"); await dispatch(setUserPosition([positionsAll[0].id])); } 
 //  if (positions.length===0) {console.log("должность:специалист"); await dispatch(setUserDepartment([departmentsAll[0].id])); }
//   console.log("departments",departments[0])
//   console.log("positions",positions[0])
//   }
//  }
 //***************************************************** */


  useEffect(() => {}, []);
  return (
    <WrapperUser>
      <WrapperBlock>
        <TitleStyle>Добавить в компанию</TitleStyle>
        <BlockItem>
          <p>Фамилия</p>
          <CustomInput
            value={surname}
            inputChangeHandler={(value) => dispatch(setUserSurname(value))}
          />
        </BlockItem>
        <BlockItem>
          <p>Имя</p>
          <CustomInput
            value={name}
            inputChangeHandler={(value) => dispatch(setUserName(value))}
          />
        </BlockItem>
        <BlockItem>
          <p>Email</p>
          <CustomInput
            disabled
            value={email}
            style={{ background: '#F2F2F2' }}
          />
        </BlockItem>
        <BlockItem>
          <p>Роль</p>
          <RoleSelect
            isAddUserInCompany={isAddUserInCompany}
            setChangeDataUser={setChangeDataUser}
          />
        </BlockItem>
        <BlockItem>
          <p>Должность</p>
           {/* Компонент для автозаполнения */}
          <PositionSelect
            isAddUserInCompany={isAddUserInCompany}
            setChangeDataUser={setChangeDataUser}
          />
        </BlockItem>
        <BlockItem>
          <p>Отдел</p>
           {/* Компонент для автозаполнения */}
          <DepartmentSelect
            isAddUserInCompany={isAddUserInCompany}
            setChangeDataUser={setChangeDataUser}
          />
        </BlockItem>
        <WrapperButtons>
          <Button
            design="secondary"
            title="Отмена"
            onClick={() => {
              window.location.replace('/');
            }}
          />
          <Button
            disabled={!roles.length}
            title="Добавить"
            onClick={() => {

            // AddDepPos(); // Если пользователь не выбрал отдел или должность- добавляет их "по умолчанию"
                
             let id = history.location.pathname.split('/')[2];
            
             // Отдел по умолчанию из общего списка отделов
             departments0 = [departmentsAll[0].id];
             // Должность по умолчанию из общего списка должностей
             positions0 = [positionsAll[0].id];

            // console.log("departments0",departments0)
            // console.log("positions0",positions0)  

            // console.log("departments",departments)
            // console.log("positions",positions)
             
                  
             //console.log("departmentsAll",departmentsAll)
             //console.log("positionsAll",positionsAll)        

              // Пользователь выбрал и отдел и должность
              if ((departments.length>0) && (positions.length>0)) {
              console.log("Добавление")  

              fetchData
                .post(
                  `/api/v1${history.location.pathname}`,
                  JSON.stringify({
                    roles,
                    positions,
                    departments,
                  })
                )
                .then(() =>
                  fetchData.patch(
                    `/api/v1/users/${id}`,
                    JSON.stringify({
                      surname,
                      name,
                    })
                  )
                )
                .then(() => window.location.replace(`/users/${id}`))
                .catch((error) => console.warn(error));
                  }

             // Пользователь не выбрал отдел и выбрал должность      
                  if ((departments.length===0) && (positions.length>0)) {
                    console.log("Добавление")
      
                    fetchData
                      .post(
                        `/api/v1${history.location.pathname}`,
                        JSON.stringify({
                          roles,
                          positions,
                          departments0,
                        })
                      )
                      .then(() =>
                        fetchData.patch(
                          `/api/v1/users/${id}`,
                          JSON.stringify({
                            surname,
                            name,
                          })
                        )
                      )
                      .then(() => window.location.replace(`/users/${id}`))
                      .catch((error) => console.warn(error));
                        }

                      // Пользователь выбрал отдел и не выбрал должность
                        if ((departments.length>0) && (positions.length===0)) {
                          console.log("Добавление")
            
                          fetchData
                            .post(
                              `/api/v1${history.location.pathname}`,
                              JSON.stringify({
                                roles,
                                positions0,
                                departments,
                              })
                            )
                            .then(() =>
                              fetchData.patch(
                                `/api/v1/users/${id}`,
                                JSON.stringify({
                                  surname,
                                  name,
                                })
                              )
                            )
                            .then(() => window.location.replace(`/users/${id}`))
                            .catch((error) => console.warn(error));
                              }
                            // Пользователь не выбрал и отдел и должность
                              if ((departments.length===0) && (positions.length===0)) {
                                console.log("Добавление")
                  
                                fetchData
                                  .post(
                                    `/api/v1${history.location.pathname}`,
                                    JSON.stringify({
                                      roles,
                                      positions0,
                                      departments0,
                                    })
                                  )
                                  .then(() =>
                                    fetchData.patch(
                                      `/api/v1/users/${id}`,
                                      JSON.stringify({
                                        surname,
                                        name,
                                      })
                                    )
                                  )
                                  //.then(() => window.location.replace(`/users/${id}`))
                                  .then(() => window.location.replace(`/users`))
                                  .catch((error) => console.warn(error));
                                    }

            }}
          />
        </WrapperButtons>
      </WrapperBlock>
    </WrapperUser>
  );
};

export default NewUserInCompany;
