import {
  types,
  deleteCommentAction,
  uploadCommentFileAction,
  deleteCommentFileAction,
  fetchProjectSectionsAction,
  delegateConfirmAction,
  sendReqToChangeTemplRepTaskAction,
} from "./action_types";
import { call, takeLatest, put, takeEvery } from "redux-saga/effects";
import { fetchData } from "../../utils/fetchData";
import { store } from "../../App";
import {
  setComentList,
  setCommentFiles,
  setIsSendongComment,
  clearCommentField,
  setProjectSections,
  addCommentsList,
  getCommentsList,
  rewriteWholeTaskList,
  changeReducerCheckboxItem,
  selectTask,
  setShownTaskInfoPlate,
  setIsSendingDelegate,
  setTaskLoad,
  setTaskWorkLoad,
  setDefaultValues,
  successfulCreatedNewTasks,
  isSendingNewTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
  setStartCyclick,
  setFinishCyclick,
  setParameterTrigger,
  setLabelsData,
  // successfulDelegatedTo
} from "./actions";
import { CreateNotif } from "../../utils/createNotification";
import { cookieMaster } from "../../utils/CookieMaster";
import {
  clearTask,
  lastChanged,
  setIsSendingTask,
  successful_create_new_task,
} from "../NewTask/actions";
import { updateTask } from "../../common/actions";
import { changeTaskList, fetchTasks, setTaskAddMode } from "../Tasks/actions";
import { createTaskAction } from "../NewTask/action_types";

import { status } from "../TaskInfoPlate/components/InfoTab"; //****************************************************** */

import { useContext } from "react";
import { useStateContext } from "../../useStateContext";
/*
const {
  checkedPersonalTask,
  setCheckedPersonalTask
} = useContext(useStateContext);
*/
function* createTask({
  executor_id,
  isCalendar,
  isUpdateScheduler,
}: createTaskAction) {
  yield put(setIsSendingTask(true));
  const { taskInfoPlate, newTask, tasksPage, commonInfo } = store.getState();

  const taskId = newTask.id;

  if (taskId || isUpdateScheduler) {
    const { selectedTask, name, description, begin, end } = taskInfoPlate;
    // update
    // вот здесь глупость написана. исполнение сюда никогда не приходит.
    let body = {
      name: name || newTask.name,
      description: description || newTask.description,
      begin: begin || newTask.begin.replace(/\//g, "-"),
      end: end || newTask.end.replace(/\//g, "-"),
      task_load: selectedTask?.task_load || newTask.task_load,
      work_load: selectedTask?.work_load || newTask.work_load,
      provide_to: selectedTask?.provide_to || newTask.provide_to,
      project_id: selectedTask?.project_id || newTask.project_id,
      executor_id: selectedTask?.executor_id,
      priority_id: selectedTask?.priority_id || newTask.priority_id,
      workflow_id: selectedTask?.workflow_id || newTask.workflow_id,
      // status_id: newTask.executor_id === newTask.author_id ? 10 : 3,
      project_section_id:
        selectedTask?.project_section_id || newTask.project_section_id,
      parent_id: selectedTask?.parent_id || newTask.parent_id,
      next_id: selectedTask?.next_id || newTask.next_id,
      prev_id: selectedTask?.prev_id || newTask.prev_id,
      id_bp: taskInfoPlate.id_bp,
      private: selectedTask?.private || newTask.private,
    };

    //delete body['status_id']; // при редактировании задачи нельзя отправлять статус, иначе хана
    yield put(
      updateTask(taskId || (taskInfoPlate.selectedTask?.id as number), body)
    );
    yield put(setTaskAddMode(false));
  } else {
    // create

    // 17-03-2022 2:15:00
    // 17-03-2022 05:20:36

    //******************************************************** */
    let body = {};

    if (status === "idea") {
      body = {
        name: taskInfoPlate.name,
        status_id: 19,
        description: taskInfoPlate.description,
        project_id: taskInfoPlate.project_id,
        project_section_id: taskInfoPlate.project_section_id,
        priority_id: taskInfoPlate.priority_id,
        executor_id: executor_id ? executor_id : taskInfoPlate.executor_id, // под вопросом правильность этих данных
        provide_to: taskInfoPlate.provide_to,
        begin: taskInfoPlate.begin.replace(/\//g, "-"),
        end: taskInfoPlate.end.replace(/\//g, "-"),
        // task_load: taskInfoPlate.task_load,
        // work_load: taskInfoPlate.work_load,
        prev_id: taskInfoPlate.prev_id,
        next_id: taskInfoPlate.next_id,
        parent_id: taskInfoPlate.parent_id,
        //child_tasks: taskInfoPlate.child_tasks,
        workflow_id: taskInfoPlate.workflow_id,
        id_bp: taskInfoPlate.id_bp,
        private: taskInfoPlate.private,
      };
    } else {
      body = {
        name: taskInfoPlate.name,
        status_id:
          (executor_id ? executor_id : taskInfoPlate.executor_id) ===
          commonInfo.currentUserInfo?.id
            ? 10
            : 3,
        description: taskInfoPlate.description,
        project_id: taskInfoPlate.project_id,
        project_section_id: taskInfoPlate.project_section_id,
        priority_id: taskInfoPlate.priority_id,
        executor_id: executor_id ? executor_id : taskInfoPlate.executor_id, // под вопросом правильность этих данных
        provide_to: taskInfoPlate.provide_to,
        begin: taskInfoPlate.begin.replace(/\//g, "-"),
        end: taskInfoPlate.end.replace(/\//g, "-"),
        // task_load: taskInfoPlate.task_load,
        // work_load: taskInfoPlate.work_load,
        prev_id: taskInfoPlate.prev_id,
        next_id: taskInfoPlate.next_id,
        parent_id: taskInfoPlate.parent_id,
        //child_tasks: taskInfoPlate.child_tasks,
        workflow_id: taskInfoPlate.workflow_id,
        id_bp: taskInfoPlate.id_bp,
        private: taskInfoPlate.private,
      };
    }

    //*************************************************** */

    if (taskInfoPlate.taskWorkLoadSwitcher === "task_load") {
      body["task_load"] = taskInfoPlate.task_load;
    } else if (taskInfoPlate.taskWorkLoadSwitcher === "work_load") {
      body["work_load"] = taskInfoPlate.work_load;
    }
    if (taskInfoPlate.child_tasks.length !== 0) {
      body["child_tasks"] = taskInfoPlate.child_tasks;
    }

    // if (body.child_tasks.length === 0) delete body['child_tasks'];

    const { end_date } = { ...taskInfoPlate.repeat };
    let bodyCyclicTask = {
      ...body,
      ...taskInfoPlate.repeat,
      end_date: end_date ? end_date?.split(" ")[0] : null,
    };

    if (bodyCyclicTask.period) {
      // TODO В случае повторения каждый день удаляем параметры (переделать и вынести в компонент)
      if (bodyCyclicTask.period === "every day") {
        delete bodyCyclicTask["params"];
      }

      const response = yield call(
        fetchData.post,
        "/api/v1/cyclic-tasks",
        JSON.stringify(bodyCyclicTask)
      );

      if (response?.id) {
        body["cyclic_task_id"] = response.id;

        const task = yield call(
          fetchData.post,
          "/api/v1/tasks",
          JSON.stringify(body)
        );

        if (task) {
          let created_tasks = taskInfoPlate.successfulCreatedTasks;

          if (task.executor_id === created_tasks["executorsIds"][0]) {
            yield put(
              successfulCreatedNewTasks({
                createdTasks: [task.id as number],
                executorsIds: created_tasks["executorsIds"],
                checkItems: created_tasks["checkItems"],
              })
            );
          }

          yield put(changeTaskList([]));
          yield put(fetchTasks(1));
          let checkList = taskInfoPlate.checkList;

          if (checkList.length) {
            // отправить чек-лист
            for (let i = 0; i < checkList.length; i++) {
              yield dispatchCheckboxItem({
                orderNumber: i + 1,
                actionType: "create",
                task_id: task.id,
                checklist_item: checkList[i],
              });
            }
          } else {
            if (task.executor_id === created_tasks["executorsIds"][0]) {
              // если чек-листа нет, то активируем задачу по первому из исполнителей в массиве
              if (!isCalendar) {
                yield put(selectTask(task));
              }
              CreateNotif("Задача успешно создана", "success");
            }

            if (
              task.executor_id ===
              created_tasks["executorsIds"][
                created_tasks["executorsIds"].length - 1
              ]
            ) {
              yield put(isSendingNewTask(false));
            }
          }

          // отправить атачменты
          if (taskInfoPlate.commentFiles.length)
            yield sendComment({ task_Id: task.id });
        } else {
          yield put(isSendingNewTask(false));
        }
      } else {
        yield put(isSendingNewTask(false));
      }
    } else {
      const task = yield call(
        fetchData.post,
        "/api/v1/tasks",
        JSON.stringify(body)
      );

      if (task) {
        let created_tasks = taskInfoPlate.successfulCreatedTasks;

        if (task.executor_id === created_tasks["executorsIds"][0]) {
          yield put(
            successfulCreatedNewTasks({
              createdTasks: [task.id as number],
              executorsIds: created_tasks["executorsIds"],
              checkItems: created_tasks["checkItems"],
            })
          );
        }

        yield put(changeTaskList([]));
        yield put(fetchTasks(1));
        let checkList = taskInfoPlate.checkList;

        if (checkList.length) {
          // отправить чек-лист
          for (let i = 0; i < checkList.length; i++) {
            yield dispatchCheckboxItem({
              orderNumber: i + 1,
              actionType: "create",
              task_id: task.id,
              checklist_item: checkList[i],
            });
          }
        } else {
          if (task.executor_id === created_tasks["executorsIds"][0]) {
            // если чек-листа нет, то активируем задачу по первому из исполнителей в массиве
            if (!isCalendar) {
              yield put(selectTask(task));
              yield put(setShownTaskInfoPlate(false));
            }
            CreateNotif("Задача успешно создана", "success");
          }

          if (
            task.executor_id ===
            created_tasks["executorsIds"][
              created_tasks["executorsIds"].length - 1
            ]
          ) {
            yield put(isSendingNewTask(false));
          }
        }

        // отправить атачменты
        if (taskInfoPlate.commentFiles.length)
          yield sendComment({ task_Id: task.id });
      } else {
        isSendingNewTask(false);
      }
    }
  }

  yield put(isSendingNewTask(false));
}

function* uploadFile({ file }: uploadCommentFileAction) {
  const formData = new FormData();
  formData.append("file", file);

  const data = yield call(fetchData.post, "/api/v1/files", formData, {
    Authorization: cookieMaster.getCookie("access_token"),
  });

  if (data) {
    let files = store.getState().taskInfoPlate.commentFiles.slice();
    yield put(setCommentFiles([...files, data]));
  }
}

function* deleteFile({ id }: deleteCommentFileAction) {
  let data = yield call(fetchData.delete, `/api/v1/files/${id}`);
  if (data) {
    let files = store.getState().taskInfoPlate.commentFiles.slice(),
      index = files.findIndex((file) => file.id === id);

    files.splice(index, 1);
    yield put(setCommentFiles(files));
  }
}

function* sendComment({ task_Id, commentText }: any) {
  yield put(setIsSendongComment(true));
  // const infoPlateData = taskInfoPlateData ? taskInfoPlateData : store.getState().taskInfoPlate;
  const infoPlateData = store.getState().taskInfoPlate;
  const author_id = store.getState().commonInfo.current_user;
  const taskId = task_Id ? task_Id : infoPlateData.selectedTask?.id;

  const body = {
    text: commentText ? commentText : infoPlateData.text,
    author_id,
    task_id: taskId,
    parent_id: infoPlateData.parentComment ? infoPlateData.parentComment.id : 0,
  };

  if (infoPlateData.commentFiles.length > 0) {
    body["files"] = infoPlateData.commentFiles.map((file) => file.id);
  }

  // Добавлены условия для корректной обработки случая
  // если пользователь пытается отправить пустой комментарий

  // Если комментарий пустой и 1 файл прикреплен
  // Выводим в поле "комментарий" текст "Прикреплен файл:"
  if (!body.text && infoPlateData.commentFiles.length === 1) {
    body.text = "Прикреплен файл:";
  }

  // Если комментарий пустой и несколько файлов прикреплены
  // Выводим в поле "комментарий" текст "Прикреплены файлы:"
  if (!body.text && infoPlateData.commentFiles.length > 1) {
    body.text = "Прикреплены файлы:";
  }

  // Если комментарий пустой и файлы не прикреплены
  // Выводим предупреждение, комментарий не записываем
  if (!body.text && infoPlateData.commentFiles.length === 0) {
    CreateNotif(
      "Добавьте, пожалуйста, текст или файлы в комментарий!",
      "error"
    );
  } else {
    const comment_done = yield call(
      fetchData.post,
      `/api/v1/tasks/${taskId}/comments`,
      JSON.stringify(body)
    );

    if (comment_done) {
      yield put(addCommentsList([comment_done]));
      yield put(
        setLabelsData(
          infoPlateData.labelsData.comments_count + 1,
          infoPlateData.labelsData.files_count +
            infoPlateData.commentFiles.length
        )
      );
      yield put(clearCommentField());
    }
    yield put(setIsSendongComment(false));
  }
}

function* getComments(page: number) {
  // yield put(setComentList([]))
  // const taskId = selectedTask?.id
  // if (!taskId) return
  // const commentListResponse = await fetchData.get(`/api/v1/tasks/${taskId}/comments?order=created_at&page=${pageNum}&orderType=desc&limit=${sortedComments.length}`)
  // console.log(commentListResponse)
  // if (commentListResponse) {
  //     addCommentsList(commentListResponse)
  // }
  // }
}

function* deleteComment({ taskId, commentId }: deleteCommentAction) {
  const status = yield call(
    fetchData.delete,
    `/api/v1/tasks/${taskId}/comments/${commentId}`
  );
  if (status.success === true) {
    const commentList = store.getState().taskInfoPlate.commentList.slice();
    const labelsData = store.getState().taskInfoPlate.labelsData;

    let files_len = commentList.find((item) => item.id === commentId)?.files
      .length;
    if (files_len)
      yield put(
        setLabelsData(
          labelsData.comments_count - 1,
          labelsData.files_count - files_len
        )
      );

    let ind = commentList.findIndex((comment) => comment.id === commentId);
    commentList.splice(ind, 1);
    yield put(clearCommentField());
    yield put(setComentList([...commentList]));
    CreateNotif("Комментарий успешно удален", "success");
  }
}

function* fetchProjectSections({ projectId }: fetchProjectSectionsAction) {
  yield put(setProjectSections([]));
  const sections = yield call(
    fetchData.get,
    `/api/v1/projects/${projectId}/sections`
  );
  if (sections) yield put(setProjectSections(sections));
}

function* recalculateLoad({ arg }: any) {
  // arg - хранит в себе объект, в котором ключ task_load или work_load, и значение, которое было введено для него с клавиатуры. Подставляешь одно значение - высчитывается противоположное.
  // если arg не передается - берутся значения из редюсера
  const selectedTask = store.getState().taskInfoPlate.selectedTask;
  // const {executor_id, begin, end, task_load, work_load, lastChangedValue} = store.getState().newTask
  const taskWorkLoadSwitcher =
    store.getState().taskInfoPlate.taskWorkLoadSwitcher;
  const flagForActionCyclycTask =
    store.getState().taskInfoPlate.flagForActionCyclycTask;

  if (selectedTask) {
    const { executor_id, begin, end, task_load, work_load } = selectedTask;

    if (executor_id && begin && end && task_load) {
      let sections;
      if (arg) {
        // исполнение пришло с изменения с клавиатуры
        let request_url = "/api/v1/task-load?";
        const actionType = Object.keys(arg)[0];

        if (actionType === "userId") {
          // пересчет нагрузки вследствии изменения исполнителя
          request_url +=
            actionType +
            "=" +
            Object.values(arg)[0] +
            `&begin=${begin}&end=${end}&task_load=${task_load}`;
        }

        if (["task_load", "work_load"].indexOf(actionType) + 1) {
          // сравниваю одно значение с несколькими
          request_url +=
            `userId=${executor_id}&begin=${begin}&end=${end}&` +
            actionType +
            "=" +
            Object.values(arg)[0];
          if (actionType === "task_load") yield put(lastChanged("task_load"));
          else if (actionType === "work_load")
            yield put(lastChanged("work_load"));
        }
        sections = yield call(fetchData.get, request_url);
      } else {
        sections = yield call(
          fetchData.get,
          `/api/v1/task-load?userId=${executor_id}&begin=${begin}&end=${end}&` +
            (taskWorkLoadSwitcher === "task_load"
              ? "task_load=" + task_load
              : "work_load=" + work_load)
        );
      }

      if (sections) {
        // данные успешно получены
        if (Object.keys(sections).length === 1) {
          if (!parseFloat(Object.keys(sections)[0])) {
            // тут считываются вещественные данные для задач
            if (sections[0] === "Incorrect working hours!")
              CreateNotif("Нерабочие часы исполнителя !");
          }
          let newObj = {};
          newObj[Object.keys(sections)[0]] = Object.values(sections)[0];
          if (arg) newObj[Object.keys(arg)[0]] = Object.values(arg)[0];

          if (selectedTask.cyclic_task_id !== 0) {
            // эта задача вообще циклическая или сама по себе ?
            // значит циклическая
            if (flagForActionCyclycTask === "") {
              yield put(setCyclicTaskToChange(newObj)); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
            } else if (flagForActionCyclycTask === "task") {
              yield put(updateTask(selectedTask.id, newObj));
            } else {
              yield put(updateTask(selectedTask.id, newObj));
              yield put(sendReqToChangeTemplRepTask(newObj));
            }
          } else {
            yield put(updateTask(selectedTask.id, newObj));
          }
        } else CreateNotif("с сервера вернулось больше 2х параметров");
      } else CreateNotif(sections);
    }
  } else {
    const { executor_id, begin, end, task_load, work_load } =
      store.getState().taskInfoPlate;
    if (executor_id && begin && end && task_load && work_load) {
      let response;
      if (arg) {
        // исполнение пришло с изменения с клавиатуры
        let request_url = "/api/v1/task-load?";
        const actionType = Object.keys(arg)[0];

        if (actionType === "userId") {
          // пересчет нагрузки вследствии изменения исполнителя
          request_url +=
            actionType +
            "=" +
            Object.values(arg)[0] +
            `&begin=${begin}&end=${end}&task_load=${task_load}`;
        }

        if (["task_load", "work_load"].indexOf(actionType) + 1) {
          // сравниваю одно значение с несколькими
          request_url +=
            `userId=${executor_id}&begin=${begin}&end=${end}&` +
            actionType +
            "=" +
            Object.values(arg)[0];
          if (actionType === "task_load") yield put(lastChanged("task_load"));
          else if (actionType === "work_load")
            yield put(lastChanged("work_load"));
        }

        response = yield call(fetchData.get, request_url);
      } else {
        response = yield call(
          fetchData.get,
          `/api/v1/task-load?userId=${executor_id}&begin=${begin}&end=${end}&` +
            (taskWorkLoadSwitcher === "task_load"
              ? "task_load=" + task_load
              : "work_load=" + work_load)
        );
      }

      if (!response) {
        CreateNotif(response);
      } else {
        if (Object.keys(response).length === 1) {
          if (!parseFloat(Object.keys(response)[0])) {
            // тут считываются вещественные данные для задач
            if (response[0] === "Incorrect working hours!")
              CreateNotif("Нерабочие часы исполнителя !");
          }

          if (Object.keys(response)[0] === "task_load") {
            yield put(setTaskLoad(Object.values(response)[0] as number)); // результат с сервера
            if (arg)
              yield put(setTaskWorkLoad(Object.values(arg)[0] as number)); //  локально обновляю
          } else if (Object.keys(response)[0] === "work_load") {
            yield put(setTaskWorkLoad(Object.values(response)[0] as number)); // результат с сервера
            if (arg) yield put(setTaskLoad(Object.values(arg)[0] as number)); //  локально обновляю
          }
        } else CreateNotif("с сервера вернулось больше 2х параметров");
      }
    }
  }
}

function* dispatchCheckboxItem({
  orderNumber,
  actionType,
  task_id,
  checklist_item,
}) {
  const { checkList, successfulCreatedTasks }: any =
    store.getState().taskInfoPlate;
  const taskId = task_id
    ? task_id
    : store.getState().taskInfoPlate.selectedTask?.id;
  switch (actionType) {
    case "create":
      const body = {
        name: checklist_item
          ? checklist_item.name
          : checkList[orderNumber - 1].name,
        task_id: taskId,
        done: checklist_item
          ? checklist_item.done
          : checkList[orderNumber - 1].done,
      };

      const createDone = yield call(
        fetchData.post,
        `/api/v1/tasks/${taskId}/checklists`,
        JSON.stringify(body)
      );
      const checklistForTask = yield call(
        fetchData.get,
        `/api/v1/tasks/${taskId}/checklists`
      );

      if (createDone && successfulCreatedTasks.createdTasks) {
        if (createDone.task_id === successfulCreatedTasks?.createdTasks[0]) {
          //   let checkItems = successfulCreatedTasks['checkItems'];
          //   checkItems.splice(0, 1);

          //   yield put(
          //     successfulCreatedNewTasks({
          //       createdTasks: successfulCreatedTasks['createdTasks'],
          //       executorsIds: successfulCreatedTasks['executorsIds'],
          //       checkItems: checkItems,
          //     })
          //   );

          const task_for_showing = yield call(
            fetchData.get,
            `/api/v1/tasks/${createDone.task_id}`
          );
          yield put(selectTask(task_for_showing));
          // CreateNotif('Задача успешно создана', 'success');
        }
      }

      yield put(rewriteWholeTaskList(checklistForTask));
      break;

    case "delete":
      const deleteResponse = yield call(
        fetchData.delete,
        `/api/v1/tasks/${taskId}/checklists/${checkList[orderNumber - 1].id}`
      );
      if (deleteResponse?.success === true) {
        const checklistForTask = yield call(
          fetchData.get,
          `/api/v1/tasks/${taskId}/checklists`
        );
        yield put(rewriteWholeTaskList(checklistForTask));
      } else {
        break;
      }
      break;

    case "patch":
      const patchBody = {
        name: checkList[orderNumber - 1].name,
        task_id: taskId,
        done: checkList[orderNumber - 1].done,
      };
      // @ts-ignore
      const patchResponse = yield call(
        fetchData.patch,
        `/api/v1/tasks/${taskId}/checklists/${checkList[orderNumber - 1].id}`,
        JSON.stringify(patchBody)
      );
      break;
  }
}

function* deligateApply({ users_ids }: delegateConfirmAction) {
  const { taskInfoPlate } = store.getState();
  let { tasks } = store.getState().tasksPage;
  const taskId = taskInfoPlate.selectedTask?.id;

  let task = yield call(
    fetchData.post,
    `/api/v1/tasks/${taskId}/delegate`,
    JSON.stringify({ user_ids: users_ids })
  );

  if (task?.id) {
    let new_data_task = yield call(fetchData.get, `/api/v1/tasks/${taskId}`);

    yield put(selectTask(new_data_task)); // обновляю новыми данными задачу в taskInfoPlate

    let index = tasks.findIndex((item) => item.id === taskId);
    tasks[index] = new_data_task;
    yield put(changeTaskList(tasks)); // обновляю новыми данными задачу в tasksPage

    CreateNotif("Задача делегирована выбранным пользователям", "success");
    yield put(setIsSendingDelegate(false));
  } else {
    CreateNotif("Задача не делегирована");
    yield put(setIsSendingDelegate(false));
  }

  // if (task) yield put(successfulDelegatedTo([...taskInfoPlate.successfulDelegatedTo, task.id]))
}

function* CreateChangeTemplRepeatTask({
  obj,
}: sendReqToChangeTemplRepTaskAction) {
  const { taskInfoPlate, commonInfo } = store.getState();

  let body = {
    name: taskInfoPlate.selectedTask?.name
      ? taskInfoPlate.selectedTask?.name
      : taskInfoPlate.name,
    status_id:
      (taskInfoPlate.selectedTask?.executor_id
        ? taskInfoPlate.selectedTask.executor_id
        : taskInfoPlate.executor_id) === commonInfo.currentUserInfo?.id
        ? 10
        : 3,
    description: taskInfoPlate.selectedTask?.description
      ? taskInfoPlate.selectedTask?.description
      : taskInfoPlate.description,
    project_id: taskInfoPlate.selectedTask?.project_id
      ? taskInfoPlate.selectedTask?.project_id
      : (taskInfoPlate.project_id as number),
    project_section_id: taskInfoPlate.selectedTask?.project_section_id
      ? taskInfoPlate.selectedTask.project_section_id
      : (taskInfoPlate.project_section_id as number),
    priority_id: taskInfoPlate.selectedTask?.priority_id
      ? taskInfoPlate.selectedTask.priority_id
      : taskInfoPlate.priority_id,
    executor_id: taskInfoPlate.selectedTask?.executor_id
      ? taskInfoPlate.selectedTask.executor_id
      : (taskInfoPlate.executor_id as number), // под вопросом правильность этих данных
    provide_to: taskInfoPlate.selectedTask?.provide_to
      ? taskInfoPlate.selectedTask.provide_to
      : taskInfoPlate.provide_to,
    begin: taskInfoPlate.selectedTask?.begin
      ? taskInfoPlate.selectedTask.begin
      : taskInfoPlate.begin.replace(/\//g, "-"),
    end: taskInfoPlate.selectedTask?.end
      ? taskInfoPlate.selectedTask.end
      : taskInfoPlate.end.replace(/\//g, "-"),
    task_load: taskInfoPlate.selectedTask?.task_load
      ? taskInfoPlate.selectedTask.task_load
      : taskInfoPlate.task_load,
    work_load: taskInfoPlate.selectedTask?.work_load
      ? taskInfoPlate.selectedTask.work_load
      : taskInfoPlate.work_load,
    prev_id: taskInfoPlate.selectedTask?.prev_id
      ? taskInfoPlate.selectedTask.prev_id
      : taskInfoPlate.prev_id,
    next_id: taskInfoPlate.selectedTask?.next_id
      ? taskInfoPlate.selectedTask.next_id
      : taskInfoPlate.next_id,
    parent_id: taskInfoPlate.selectedTask?.parent_id
      ? taskInfoPlate.selectedTask.parent_id
      : taskInfoPlate.parent_id,
    //child_tasks: taskInfoPlate.child_tasks,
    workflow_id: taskInfoPlate.selectedTask?.workflow_id
      ? taskInfoPlate.workflow_id
      : taskInfoPlate.workflow_id,
    id_bp: taskInfoPlate.id_bp,
    private: taskInfoPlate.selectedTask?.private
      ? taskInfoPlate.private
      : taskInfoPlate.private,
  };

  if (taskInfoPlate.selectedTask?.cyclic_task_id) {
    ///// изменение
    if (obj["period"] === "") {
      // удалить
      const response = yield call(
        fetchData.delete,
        "/api/v1/cyclic-tasks/" + taskInfoPlate.selectedTask?.cyclic_task_id
      );
      if (response) {
        CreateNotif("Шаблон успешно удален", "success");
        yield put(
          updateTask(taskInfoPlate.selectedTask?.id, { cyclic_task_id: 0 })
        );
      }
    } else {
      // обновить
      const response = yield call(
        fetchData.patch,
        "/api/v1/cyclic-tasks/" + taskInfoPlate.selectedTask?.cyclic_task_id,
        JSON.stringify(obj)
      );
      if (response?.id) {
        CreateNotif("Шаблон успешно изменен", "success");
        yield put(setParameterTrigger());
        // setStartCyclick(response.period, response.params, response.interval)
        // setFinishCyclick(response.ending_condition, response.end_count, response.end_date)
      }
    }
  } else {
    ////// создание
    const { end_date } = { ...taskInfoPlate.repeat };

    let bodyCyclicTask = {
      ...body,
      ...taskInfoPlate.repeat,
    };

    // пришедшие параметры вписываю в тело отсылаемых изменений
    if (Object.keys(obj).length) {
      Object.keys(obj).forEach((key) => {
        bodyCyclicTask[key] = obj[key];
      });
    }

    if (end_date) {
      let val = bodyCyclicTask?.end_date?.split(" ")[0] as string;
      bodyCyclicTask.end_date = val;
    }

    if (!bodyCyclicTask["params"].length) {
      delete bodyCyclicTask["params"];
    }

    const response = yield call(
      fetchData.post,
      "/api/v1/cyclic-tasks",
      JSON.stringify(bodyCyclicTask)
    );

    if (response?.id) {
      CreateNotif("Шаблон успешно создан", "success");

      if (taskInfoPlate.selectedTask) {
        yield put(
          updateTask(taskInfoPlate.selectedTask?.id, {
            cyclic_task_id: response?.id,
          })
        );
      }

      // обновить текущую выбранную задачу чтобы скрыть кнопку Применить
      let body_added = {
        ...body,
        id: taskInfoPlate.selectedTask?.id as number,
        author_id: taskInfoPlate.selectedTask?.author_id as number,
        cyclic_task_id: response.id,
      };
      yield put(selectTask(body_added));
    }
  }
}

export function* watchCreateTask() {
  yield takeEvery(types.CREATE_TASK, createTask);
}

export function* watchRecalculateLoad() {
  yield takeEvery(types.SET_RECALCULATE, recalculateLoad);
}

export function* watchGetComments() {
  // @ts-ignore
  yield takeEvery(types.GET_COMMENTS_LIST, getComments);
}

export function* watchDeleteComment() {
  yield takeLatest(types.DELETE_COMMENT, deleteComment);
}

export function* watchUploadCommentFile() {
  yield takeEvery(types.UPLOAD_FILE, uploadFile);
}

export function* watchDeleteCommentFile() {
  yield takeEvery(types.DELETE_FILE, deleteFile);
}

export function* watchSendComment() {
  yield takeEvery(types.SEND_COMMENT, sendComment);
}

export function* watchFetchProjectSections() {
  yield takeLatest(types.FETCH_PROJECT_SECTIONS, fetchProjectSections);
}

export function* watchDispatchCheckboxItem() {
  // @ts-ignore
  yield takeEvery(types.DISPATCH_CHECKBOX_ITEM, dispatchCheckboxItem);
}

export function* watchDeligateConfirm() {
  yield takeEvery(types.DELEGATE_CONFIRM_ACTION, deligateApply);
}

export function* watchChangeTemplRepeatTask() {
  yield takeEvery(
    types.SEND_REQ_CHANGE_TEMPL_REP_TASK,
    CreateChangeTemplRepeatTask
  );
}
