import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import palette from '../../../../common/components/palette';
import { fetchData } from '../../../../utils/fetchData';
import { selectTask } from '../../../TaskInfoPlate/actions';
import { fetchVisualizationList } from '../../actions';
import { getDifferenceDate } from './getDifferenceDate';

const nameDay = {
  1: 'пн',
  2: 'вт',
  3: 'ср',
  4: 'чт',
  5: 'пт',
};
const nameMonth = {
  1: 'января',
  2: 'февраля',
  3: 'марта',
  4: 'апреля',
  5: 'мая',
  6: 'июня',
  7: 'июля',
  8: 'августа',
  9: 'сентября',
  10: 'октября',
  11: 'ноября',
  12: 'декабря',
};

const nowDate = () => {
  return {
    day: new Date().getDay(),
    data: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };
};

//Получаю последний день месяца
const endDayInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

const WrapperVizualization = styled.div`
  position: relative;
  overflow-y: hidden;
  height: 6.375 rem;
`;

const TimeBlock = styled.div`
  display: flex;
  height: 2.875rem;
  justify-content: start;
`;
const Time = styled.div`
  align-items: center;
  min-width: 7.5rem;
  height: 100%;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;
const TasksBlock = styled.div`
  display: flex;
  justify-content: start;
  height: 3.5rem;
`;
const TaskBlock = styled.div`
  min-width: 7.5rem;
  height: 100%;
  align-items: center;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #e0e0e0;
`;

const Tasks = styled.div`
  position: absolute;
  display: flex;
  top: calc(52%);
  height: 40px;
`;
const TaskStyle = styled.div`
  color: ${palette.visualColor};
  white-space: nowrap;
  padding: 0 6px;
  overflow: hidden;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 1px;
  width: ${({ width }) => width + 'px'};
  height: 40px;
  background: ${({ warning }) =>
    warning ? palette.visualBgRed : palette.visualBgBlue};
  /* background: #f3fafe; */
  // border: 1px solid #1baaf0;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

// type Date = {
//   day: number;
//   data: number;
//   month: number;
//   year: number;
//   stringNowDate: string;
// };

type PandingVisualType = {
  task_load: number;
  id: number;
  prevVacantTime?: number;
  vacantTimeDay?: number;
  title: string;
  data: number;
  warning: boolean;
};

type Props = {
  showVisualization: boolean;
  selectedUserId: any;
  visualizationData: any;
  tasks: any;
};

const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};

const Visualization: React.FC<Props> = ({
  showVisualization,
  selectedUserId,
  visualizationData,
  tasks,
}) => {
  const [workTime, setWorkTime] = useState<number>(9);

  const dispatch = useDispatch();

  const handlerFetchTask = (event) => {
    const idTask = event.target.dataset.id;
    fetchData
      .get(`/api/v1/tasks/${idTask}`)
      .then((task) => dispatch(selectTask(task)))
      .catch((error) =>
        console.error('Не найдена задача по следующей причине: ' + error)
      );
  };

  const minVisualDay = +document.documentElement.clientWidth.toFixed() / 120;
  const { end: endTaskDate, task_load: workTimeTask } =
    visualizationData.length && visualizationData[visualizationData.length - 1];
  let allWorkLoad = 0;
  visualizationData.forEach((el) => {
    allWorkLoad = allWorkLoad + el.task_load / workTime;
  });

  // let calendar;
  // if (visualizationData.length) {
  //   allWorkLoad > minVisualDay
  //     ? (calendar = allWorkLoad)
  //     : (calendar = minVisualDay);
  // }

  const calendar = visualizationData.length
    ? getDifferenceDate(
        nowDate().data,
        nowDate().month,
        nowDate().year,
        getDate(endTaskDate).taskDate
      ) > minVisualDay
      ? getDifferenceDate(
          nowDate().data,
          nowDate().month,
          nowDate().year,
          getDate(endTaskDate).taskDate
        )
      : minVisualDay + workTimeTask / workTime
    : minVisualDay;

  useEffect(() => {
    fetchData
      .get(`/api/v1/users/${selectedUserId}/schedule`)
      .then((data) => data?.schedule.weekDays[0].time[0])
      .then(
        (time) =>
          time && setWorkTime(time.end.split(':')[0] - time.begin.split(':')[0])
      );
  }, [selectedUserId]);

  const pandingVisual = () => {
    let result: Array<PandingVisualType> = [];
    let vacantTime: number = 0;
    let loadDay: number = 0;
    let vacantTimeDay: number = 0;
    let { day, data, month, year } = nowDate();

    let maxDayinMonth: number = endDayInMonth(year, month);
    //Проверка на то что сегодня суббота
    if (day === 6) {
      day = 1;
      if (data === maxDayinMonth - 1) {
        data = 1;
        if (month === 12) {
          month = 1;
          year++;
        } else {
          month++;
        }
      } else if (data === maxDayinMonth) {
        data = 2;
        if (month === 12) {
          month = 1;
          year++;
        } else {
          month++;
        }
      } else {
        data = data + 2;
      }
    }
    //Проверка на то что сегодня воскресенье
    if (day === 0) {
      day = 1;
      if (data === maxDayinMonth) {
        data = 1;
        if (month === 12) {
          month = 1;
          year++;
        } else {
          month++;
        }
      } else {
        data++;
      }
    }

    //Если для визуализации 1 задача
    if (visualizationData.length === 1) {
      let { dayBegin, monthBegin, yearBegin } = getDate(
        visualizationData[0].begin
      );
      //Если одна задача и она просрочена или начинается сегодня
      if (dayBegin <= data && monthBegin <= month && yearBegin <= year) {
        result.push({
          task_load: visualizationData[0].task_load,
          warning: visualizationData[0]?.warning,
          id: visualizationData[0].id,
          prevVacantTime: 0,
          title: visualizationData[0].name,
          data: data,
        });
        //Если одна задача и дата начала в будущем
      } else {
        vacantTime = 0;
        let counterDay = 0;
        if (month === 12 && monthBegin < 12 && yearBegin > year) {
          counterDay = maxDayinMonth - data + dayBegin;
        } else if (month < monthBegin && year === yearBegin) {
          //TODO Доработать проверку
          counterDay = maxDayinMonth - data + dayBegin;
        } else {
          counterDay = dayBegin - data;
        }
        for (let i = 0; i < counterDay; i++) {
          if (data === maxDayinMonth) {
            if (month === 12) {
              month = 1;
              year++;
            } else {
              month++;
            }
          }
          if (day === 6) {
            day = 0;
            data++;
          } else if (day === 0) {
            day = 1;
            data++;
          } else {
            day++;
            data++;
            vacantTime = vacantTime + 120;
          }
        }
        result.push({
          task_load: visualizationData[0].task_load,
          warning: visualizationData[0]?.warning,
          id: visualizationData[0].id,
          prevVacantTime: vacantTime,
          title: visualizationData[0].name,
          data: data,
        });
      }
    }
    //Если для визуализации более 1 задачи
    else if (visualizationData.length) {
      for (let i = 0; i < visualizationData.length; i++) {
        let timeNextTask = {};
        if (i !== visualizationData.length - 1) {
          timeNextTask = getDate(visualizationData[i + 1].begin);
        }
        let timeThisTask = getDate(visualizationData[i].begin);

        let { dayBegin, monthBegin, yearBegin } = timeThisTask;
        //Условие проверяет на просрочки
        if (
          (dayBegin <= data && monthBegin < month && yearBegin === year) ||
          (dayBegin < data && monthBegin <= month && yearBegin === year) ||
          (monthBegin < month && yearBegin === year) ||
          (dayBegin >= data && monthBegin >= month && yearBegin < year)
        ) {
          if (i !== visualizationData.length - 1) {
            nextDay(visualizationData[i].task_load, timeThisTask, timeNextTask);
          }

          result.push({
            task_load: visualizationData[i].task_load,
            warning: visualizationData[i]?.warning,
            id: visualizationData[i].id,
            vacantTimeDay,
            title: visualizationData[i].name,
            data: data,
          });
        } else if (
          //Условие проверяет  на сегоднешний день
          dayBegin === data &&
          monthBegin === month &&
          yearBegin === year
        ) {
          if (i !== visualizationData.length - 1) {
            nextDay(visualizationData[i].task_load, timeThisTask, timeNextTask);
          }
          result.push({
            task_load: visualizationData[i].task_load,
            warning: visualizationData[i]?.warning,
            id: visualizationData[i].id,
            vacantTimeDay,
            title: visualizationData[i].name,
            data: data,
          });
        } else if (
          //Условие проверяет на будущие задачи
          (dayBegin > data && monthBegin >= month && yearBegin >= year) ||
          (dayBegin < data && monthBegin < month && yearBegin > year)
        ) {
          vacantTimeDay = 0;
          nextDay(visualizationData[i].task_load, timeThisTask, timeNextTask);

          result.push({
            task_load: visualizationData[i].task_load,
            warning: visualizationData[i]?.warning,
            id: visualizationData[i].id,
            prevVacantTime: vacantTime,
            vacantTimeDay,
            title: visualizationData[i].name,
            data: data,
          });
          vacantTime = 0;
        }
      }
    }
    //Проход по дням для Tasks (загрузка, тайминг текущей задачи, тайминг следующей задачи)
    function nextDay(taskLoad, timeThisTask, timeNextTask): void {
      let timeTask = +(taskLoad / workTime);
      if (loadDay + taskLoad > workTime) {
        loadDay = (loadDay + taskLoad) % workTime;
      } else {
        loadDay = loadDay + taskLoad;
      }
      let { taskDate, dayBegin, monthBegin, yearBegin } = timeThisTask;
      if (taskDate === timeNextTask.taskDate) {
        vacantTimeDay = 0;
      } else {
        if (
          // Проверка что задча  сегодняшняя или будущая
          vacantTimeDay === 0 ||
          (data <= dayBegin && month <= monthBegin && year <= yearBegin)
        ) {
          vacantTimeDay = (Math.ceil(timeTask) - taskLoad / workTime) * 120;
        } else {
          vacantTimeDay =
            vacantTimeDay - (Math.ceil(timeTask) - taskLoad / workTime) * 120;
        }
      }
      let counterDate: number = 0;
      let differenceDate = 0;

      //Первое условие проверяет на будущие задачи
      if (
        (data < dayBegin && month <= monthBegin && year <= yearBegin) ||
        (dayBegin < data && monthBegin < month && yearBegin > year)
      ) {
        counterDate = 0;
        differenceDate = getDifferenceDate(data, month, year, taskDate);

        for (let i = 0; i <= differenceDate; i++) {
          if (i === differenceDate) {
            if (day === 0 || day === 6) {
              if (day === 0) {
                data === maxDayinMonth ? (data = 1) : data++;
                day++;
              } else if (day === 6) {
                data === maxDayinMonth - 1 ? (data = 1) : (data = data + 2);
                day = 1;
              }
            }
            vacantTime = 120 * counterDate;
            if (taskDate !== timeNextTask.taskDate) {
              if (day + Math.floor(timeTask) > 5) {
                for (let i = 0; i < timeTask - 1; i++) {
                  if (day < 5) {
                    day++;
                    data++;
                  } else if (day === 5) {
                    if (data === maxDayinMonth - 2) {
                      data = 1;
                    } else if (data === maxDayinMonth - 1) {
                      data = 2;
                    } else {
                      data = data + 3;
                    }
                    day = 1;
                  }
                }
              } else {
                day = day + Math.floor(timeTask);
                if (data + Math.floor(timeTask) > maxDayinMonth) {
                  data = data + Math.floor(timeTask) - maxDayinMonth;
                  if (month === 12) {
                    month = 1;
                    year++;
                  } else {
                    month++;
                  }
                } else {
                  data = data + Math.floor(timeTask);
                }
              }
            }
          } else if (day > 5 || day === 0) {
            //Вынести увеличение data и сделать проверку на конец месяца
            if (day === 6) {
              day = 0;
              data++;
            } else if (day === 0) {
              day = 1;
              data++;
            }
          } else {
            counterDate++;
            data++;
            day++;
          }
        }
        //Выполняется при просрочке и задаче на сегоднешний день
      } else {
        // проверка на выходной или день больше чем в текущем месяце
        if (day + timeTask >= 6 || data + timeTask > maxDayinMonth) {
          for (let i = 0; i < Math.floor(timeTask); i++) {
            if (day < 5) {
              day++;
              data++;
            } else if (day === 5) {
              if (data === maxDayinMonth - 2) {
                data = 1;
              } else if (data === maxDayinMonth) {
                data = 3;
              } else {
                data = data + 3;
              }
              day = 1;
            }
          }
        } else {
          if (taskLoad >= workTime) {
            day = day + Math.floor(taskLoad / workTime);
            data = data + Math.floor(taskLoad / workTime);
          }
        }
      }
      maxDayinMonth = endDayInMonth(year, month);
      if (
        (timeNextTask.dayBegin <= data &&
          timeNextTask.monthBegin <= month &&
          timeNextTask.yearBegin <= year) ||
        (timeNextTask.dayBegin > data &&
          timeNextTask.monthBegin < 12 &&
          timeNextTask.yearBegin < year) ||
        timeNextTask.yearBegin < year
      ) {
        vacantTimeDay = 0;
      } else if (taskLoad % workTime !== 0) {
        day = day + 1;
        data = data + 1;
      }
    }

    return result;
  };

  //Создание массива на основании которого происходит отрисовка календаря
  function pandingDay() {
    const dates: any[] = [];
    let { day, data, month, year } = nowDate();
    let maxDayinMonth = endDayInMonth(year, month);

    if (day === 6) {
      day = 1;
      if (data === maxDayinMonth - 1) {
        data = 1;
        if (month === 12) {
          month = 1;
        } else {
          month++;
        }
      } else if (data === maxDayinMonth) {
        data = 2;
        if (month === 12) {
          month = 1;
        } else {
          month++;
        }
      } else {
        data = data + 2;
      }
    }

    if (day === 0) {
      day = 1;
      if (data === maxDayinMonth) {
        data = 1;
        if (month === 12) {
          month = 1;
        } else {
          month++;
        }
      } else {
        data++;
      }
    }

    for (let i = 0; i < calendar; i++) {
      if (i === 0) {
        dates.push({ day: nameDay[day], data, month });
        maxDayinMonth = endDayInMonth(year, month);
        nextDay();
      } else {
        dates.push({ day: nameDay[day], data, month });
        maxDayinMonth = endDayInMonth(year, month);
        nextDay();
      }
    }

    function nextDay() {
      if (data === maxDayinMonth && day < 5) {
        if (month === 12) {
          day++;
          data = 1;
          month = 1;
          year++;
        } else {
          day++;
          data = 1;
          month++;
        }
      } else if (day === 5 && data === maxDayinMonth) {
        if (month === 12) {
          day = 1;
          data = 3;
          month = 1;
          year++;
        } else {
          day = 1;
          data = 3;
          month++;
        }
      } else if (
        day === 5 &&
        (data === maxDayinMonth - 2 || data === maxDayinMonth - 1)
      ) {
        if (data === maxDayinMonth - 2) {
          day = 1;
          data = 1;
          if (month === 12) {
            month = 1;
          } else month++;
        } else if (data === maxDayinMonth - 1) {
          day = 1;
          data = 2;
          if (month === 12) {
            month = 1;
          } else month++;
        }
      } else if (day === 5) {
        day = 1;
        data = data + 3;
      } else {
        data++;
        day++;
      }
    }

    return dates;
  }

  function getDate(string: string) {
    let date = string.split(' ')[0].split('-');
    let taskDate = [...date].reverse().join('/');
    return {
      taskDate,
      dayBegin: +date[0],
      monthBegin: +date[1],
      yearBegin: +date[2],
    };
  }
  const getTime = (string) => {
    let time = string.split(' ')[0].split(':');
    return {
      hour: time[0],
      minuts: time[1],
    };
  };

  const pandingDays = pandingDay();
  useEffect(() => {
    dispatch(fetchVisualizationList(selectedUserId));
  }, [selectedUserId, showVisualization, tasks]);

  return (
    <WrapperVizualization>
      <TimeBlock>
        {pandingDays.map((item) => {
          return (
            <Time key={nanoid()}>
              <div>
                <div style={{ color: '#212121' }}>
                  {item.data} {nameMonth[item.month]}
                </div>
                <div style={{ color: '#707070' }}>{item.day}</div>
              </div>
            </Time>
          );
        })}
      </TimeBlock>
      <TasksBlock>
        {pandingDays.map((item) => {
          return <TaskBlock key={nanoid()} />;
        })}
        <Tasks>
          {pandingVisual().map(
            ({
              task_load,
              id,
              title,
              prevVacantTime,
              vacantTimeDay,
              warning,
            }) => {
              let width = (task_load / workTime) * 120 - 1;

              return (
                <TaskStyle
                  onClick={handlerFetchTask}
                  key={nanoid()}
                  width={width}
                  warning={warning}
                  data-id={id}
                  style={{
                    marginLeft: prevVacantTime,
                    marginRight: vacantTimeDay ? vacantTimeDay + 1 : 1,
                  }}
                >
                  {title}
                </TaskStyle>
              );
            }
          )}
        </Tasks>
      </TasksBlock>
    </WrapperVizualization>
  );
};

export default Visualization;
