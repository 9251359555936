import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import BpSampleItem from "../bp-sample-item/BpSampleItem.jsx";
import SortBtn from "../ui/sort-btn/SortBtn.jsx";
import { StatusContext } from "../../context/status.js";

const BpSamplesList = () => {
  const {
    filter,
    setFilter,
    filterMethod,
    createBpStatus,
    createBpSampleStatus,
    apiBp,
    setBpList,
    openMenuTasks,
    openMenuBp,
    createTaskStatus,
    createTaskSampleFormStatus,
    setCreateTaskStatus,
    setCreateTaskSampleFormStatus,
    setCreateBpSampleStatus,
    tasks,
    bpListChange,
    setBpListChange,
    statusBp,
    setStatusBp,
    statusBpId,
    setStatusBpId,
    statusTaskId,
    setStatusTaskId,
    change,
    setChange,
    setOpenMenuTasks,
    setOpenMenuBp,
    idCall,
    company_id,
    bearer,
  } = useContext(StatusContext);

  const token = document.cookie.replace(
    /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  const bpSamplesList = [
    { id: 1, name: "Типовой бизнес-процесс «Договоры»", action: "действие" },
    {
      id: 2,
      name: "Типовой бизнес-процесс «Прием на работу»",
      action: "действие",
    },
    { id: 3, name: "Типовой бизнес-процесс «Увольнение»", action: "действие" },
  ];

  useEffect(() => {
    axios
      .get(`${apiBp}/bp?orderFilter[${filter}]=${filterMethod}`, {
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": token,
        },
      })
      .then((response) => {
        if (response.data && response.data.data) {
          // console.log("response.data.data", response.data.data);
          setBpList(response.data.data.sort((a, b) => b.id - a.id));
        } else {
          setBpList(null);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [
    setBpList,
    filter,
    filterMethod,
    createTaskStatus,
    createTaskSampleFormStatus,
    setCreateTaskStatus,
    setCreateTaskSampleFormStatus,
    createBpSampleStatus,
    setCreateBpSampleStatus,
    tasks,
    bpListChange,
    setBpListChange,
    statusBp,
    setStatusBp,
    statusBpId,
    setStatusBpId,
    statusTaskId,
    setStatusTaskId,
    change,
    setChange,
    openMenuTasks,
    setOpenMenuTasks,
    openMenuBp,
    setOpenMenuBp,
    idCall,
  ]);

  const sortFunc = (e) => {
    setFilter(e.dataset.sort);
  };

  return (
    <div className="business__main-content__list-block">
      <div
        className={
          createBpStatus || createBpSampleStatus || openMenuTasks || openMenuBp
            ? "business__main-content__list-block__title business__main-content__list-block__title-active"
            : "business__main-content__list-block__title"
        }
      >
        <div>
          <button
            className="p-grey sort"
            data-sort="name"
            onClick={(e) => sortFunc(e.target)}
          >
            Наименование
            {filter === "name" ? <SortBtn /> : <></>}
          </button>
        </div>
        <div>
          <button
            className="p-grey sort"
            data-sort="action"
            onClick={(e) => sortFunc(e.target)}
          >
            Действие
            {filter === "action" ? <SortBtn /> : <></>}
          </button>
        </div>
        <div style={{ maxWidth: "50px" }}></div>
      </div>
      <div className="business__main-content__list-block__container">
        <div>
          {bpSamplesList.map((bpSampleItem) => (
            <BpSampleItem el={bpSampleItem} key={bpSampleItem.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BpSamplesList;
