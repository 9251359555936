import { DayOff, User } from "../../common/types";
import { Action } from "redux";

export enum types {
  SET_USER_NAME = "@@NEW_USER/SET_USER_NAME",
  SET_USER_EMAIL = "@@NEW_USER/SET_USER_EMAIL",
  CLEAR_USER = "@@NEW_USER/CLEAR_USER",
  SET_USER_PATRONYMIC = "@@NEW_USER/SET_USER_PATRONYMIC",
  SET_USER_SURNAME = "@@NEW_USER/SET_USER_SURNAME",
  SET_USER_ROLE = "@@NEW_USER/SET_USER_ROLE",
  SET_USER_POSITION = "@@NEW_USER/SET_USER_POSITION",
  SET_USER_DEPARTMENT = "@@NEW_USER/SET_USER_DEPARTMENT",
  SET_USER_BIRTHDAY = "@@NEW_USER/SET_USER_BIRTHDAY",
  SET_USER_SCHEDULE = "@@NEW_USER/SET_USER_SCHEDULE",
  SET_USER_WORK_BEGIN = "@@NEW_USER/SET_USER_WORK_BEGIN",
  SET_USER_WORK_END = "@@NEW_USER/SET_USER_WORK_END",
  SET_USER_DAYS_OFF = "@@NEW_USER/SET_USER_DAYS_OFF",
  SET_USER_PASSWORD = "@@NEW_USER/SET_USER_PASSWORD",
  CREATE_USER = "@@NEW_USER/CREATE_USER",
  SET_IS_SENDING = "@@NEW_USER/SET_IS_SENDING",
  OPEN_USER = "@@NEW_USER/OPEN_USER",
  SET_USER_IMAGE = "@@NEW_USER/SET_USER_IMAGE",
  SET_PASS_CHANGE_MODE = "@@NEW_USER/SET_PASS_CHANGE_MODE",
  PASS_RESET = "@@NEW_USER/PASS_RESET",
  CHANGE_PASS = "@@NEW_USER/CHANGE_PASS",
  CHANGE_INDICATOR = "@@NEW_USER/CHANGE_INDICATOR",
  DELETE_USER = "@@NEW_USER/DELETE_USER",
}

export interface setUserNameAction extends Action<types.SET_USER_NAME> {
  name: string;
}

export interface setUserPasswordAction extends Action<types.SET_USER_PASSWORD> {
  password: string;
}

export interface setUserEmailAction extends Action<types.SET_USER_EMAIL> {
  email: string;
}

export interface clearUserAction extends Action<types.CLEAR_USER> {}

export interface setUserPatronymicAction
  extends Action<types.SET_USER_PATRONYMIC> {
  patronymic: string | undefined;
}

export interface setUserSurnameAction extends Action<types.SET_USER_SURNAME> {
  surname: string;
}

export interface setUserRoleAction extends Action<types.SET_USER_ROLE> {
  roles: number[];
}

export interface setUserPositionAction extends Action<types.SET_USER_POSITION> {
  positions: number[];
}

export interface setUserDepartmentAction
  extends Action<types.SET_USER_DEPARTMENT> {
  departments: number[];
}

export interface setUserBirthDayAction extends Action<types.SET_USER_BIRTHDAY> {
  birthday: string;
}

export interface setUserScheduleAction extends Action<types.SET_USER_SCHEDULE> {
  schedule: number[];
}

export interface setUserWorkBeginAction
  extends Action<types.SET_USER_WORK_BEGIN> {
  time: string;
}

export interface setUserWorkEndAction extends Action<types.SET_USER_WORK_END> {
  time: string;
}

export interface setUserDaysOffAction extends Action<types.SET_USER_DAYS_OFF> {
  daysOff: DayOff[];
}

export interface createUserAciton extends Action<types.CREATE_USER> {
  update: boolean;
  changeDataUser: any;
  notificationOptions: any;
  notificationSuccess: any;
}

export interface setIsSendingAction extends Action<types.SET_IS_SENDING> {
  isSending: boolean;
}

export interface openUserAction extends Action<types.OPEN_USER> {
  user: User;
}

export interface setUserImageAction extends Action<types.SET_USER_IMAGE> {
  image: File | null;
}

export interface setPassChangeModeAction
  extends Action<types.SET_PASS_CHANGE_MODE> {
  mode: boolean;
}

export interface setPassReset extends Action<types.PASS_RESET> {
  mode: boolean;
}

export interface changeIndicatorAction extends Action<types.CHANGE_INDICATOR> {}

export interface changePass extends Action<types.CHANGE_PASS> {
  oldPassword: string;
  password: string;
}

export interface deleteUserAction extends Action<types.DELETE_USER> {
  userId: number;
}

export type NewUserAction =
  | setUserNameAction
  | setUserEmailAction
  | clearUserAction
  | setUserPatronymicAction
  | setUserSurnameAction
  | setUserRoleAction
  | setUserPositionAction
  | setUserDepartmentAction
  | setUserBirthDayAction
  | setUserScheduleAction
  | setUserWorkBeginAction
  | setUserWorkEndAction
  | setUserDaysOffAction
  | setUserPasswordAction
  | createUserAciton
  | setIsSendingAction
  | openUserAction
  | setUserImageAction
  | setPassChangeModeAction
  | changeIndicatorAction
  | setPassReset
  | deleteUserAction;
