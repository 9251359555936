import React, { RefObject } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { RegisterOptions } from 'react-hook-form';
import styled from 'styled-components';

import TableRow from './TableRow';
import TableCell from './TableCell';
import AvatarFact from './AvatarFact';
import Deadline from './Deadline';
import Project from './Project';
import WriteOff from './WriteOff';
import Selection from './Selection';
import TaskDescription from './TaskDescription';

import { ChangeTask } from '../../../../common/types';

const Paragraph = styled.p`
	color: #A8A8A8;
`;

const EmptyBlock = styled.span`
  opacity: 0;
`

type Props = {
	task: ChangeTask;
	register: (Ref: RefObject<any>, RegisterOptions?: RegisterOptions) => void;
};

const SortableItem = SortableElement(({ task, register }: Props) => {
	const {
		id,
		description,
		author_id,
		name,
		end,
		project_id,
		project_section_id,
		load,
		checked,
		accumulate_load,
		left_load,
		used_load,
	} = task;
	return (
		<TableRow>
			<TableCell>
				<Paragraph>{id}</Paragraph>
			</TableCell>
			<TableCell>
				<AvatarFact author_id={author_id} />
			</TableCell>
			<TableCell>
				<TaskDescription name={name} description={description} />
			</TableCell>
			<TableCell><Deadline end={end} /> {' '}<EmptyBlock>a</EmptyBlock>{' '}</TableCell>
			<TableCell>
				<Project project_id={project_id} project_section_id={project_section_id} />
			</TableCell>
			<TableCell isCenter>
				{used_load}
			</TableCell>
			<TableCell>
				<WriteOff
					register={register}
					task_load={load}
					writeOff={accumulate_load}
					id={id}
				/>
			</TableCell>
			<TableCell isCenter>
				{left_load}
			</TableCell>
			<TableCell>{load}</TableCell>
			<TableCell>
				<Selection
					checked={checked}
					id={id}
					register={register}
				/>
			</TableCell>
		</TableRow>
	)
});

export default SortableItem;
