import {fetchData} from './fetchData';

export const fetchUpdateData = (timestamp, blockUpdate, args) => {
    if (!timestamp) document.location.reload();
    switch (blockUpdate) {
        case 'tasks':
            return fetchData.get(
                `/api/v1/update-data?timestamp=${timestamp}&with=notifications,users,projects,${args[0]}`
            );
        case 'projects':
            return fetchData.get(
                `/api/v1/update-data?timestamp=${timestamp}&with=notifications,projects,task_projects{@${args[0]}}`
            );
        case 'users':
            return fetchData.get(
                `/api/v1/update-data?timestamp=${timestamp}&with=notifications,users{@${args[0]}}`
            );
        case 'checklistAndComments':
            return fetchData.get(
                `/api/v1/update-data?timestamp=${timestamp}&with=tasks_checklists{@${args[0]}},tasks_comments{@${args[0]}}`
            );

        default:
            break;
    }
};