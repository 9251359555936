import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { State } from '../../../rootReducer';
import { columnsOfTables } from '../../shared_data';

type Props = {
  id: number;
};

const WrapperIdCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: ${columnsOfTables[0].style?.minWidth};
  padding-right: 4px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: ${({ active }) => (active ? '#02405E' : '#a8a8a8')};
`;
const IdCell: React.FC<Props> = ({ id }) => {
  const selectTask = useSelector(
    (state: State) => state.taskInfoPlate.selectedTask
  );

  return <WrapperIdCell active={id === selectTask?.id}>{id}</WrapperIdCell>;
};

export default IdCell;
