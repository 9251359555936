import React, { useEffect, useMemo, useRef, useState } from "react";
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn";
import {
  Task,
  SelectData,
  Item,
  Section,
  // Schedule,
  File as FileType,
  Meeting,
  User,
  Project,
} from "../../../common/types";
import { connect, useDispatch, useSelector } from "react-redux";
import { State } from "../../../rootReducer";
import { updateTask } from "../../../common/actions";
// import TaskWorkLoad from './TaskWorkLoad';
import { TextareaAutosize } from "@material-ui/core";
import {
  createNewMeeting,
  editMeeting,
  meetingPlateHidingSignal,
  setMeetingDefaultValues,
  setNewParametersToReducer,
} from "../actions";
import CheckListModule from "../../../common/components/CheckList/CheckListModule";
import Button from "../../../common/components/newComponent/Buttons/Button";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import styled from "styled-components";
import {
  getProjectsSelectList,
  getSectionSelectList,
  getUsersSelectList,
} from "../../TaskInfoPlate/selectors";
import MultySelect from "../../../common/components/Selects/MultySelect";
import TaskDateTime from "../../../common/components/DatePickers/DateTimePiker";
import { initialState } from "../reducer";
import { nanoid } from "nanoid";
import breadCrumbArrow from "../../Payment/Components/breadCrumbArrow";
import ResolutionsRightPlate from "./ResolutionsRightPlate";

type Props = {
  selectedMeetingId: null | number;
  // selectedTask: Task | null;
  projectsSelect: SelectData;

  setNewParametersToReducer: (data: object) => void;
  setMeetingDefaultValues: () => void;

  usersSelect: {
    author: Item;
    executor: Item;
    list: Item[];
  };
  // uploadFile: (file: File) => void;
  // deleteFile: (id: number) => void;
  sectionSelect: SelectData;
  sections: Section[];
  // schedule: Schedule | null;
  checkList: object[];
  priorities: any[];
  currentUser: any;
  // updateTask: (id: number, params: any, withNotif?: boolean) => void;
  // dispatchCheckboxItem: (
  //   orderNumber: number,
  //   text: string,
  //   checkboxState: boolean,
  //   actionType: string
  // ) => void;
  // changeReducerCheckboxItem: (
  //   orderNumber: number,
  //   text: string,
  //   checkboxState: boolean,
  //   actionType: string
  // ) => void;
  // setTaskDescription: (val: string) => void;

  executor_id: number | null;
  taskInfoActiveTab: string;
  description: string;

  projectId: number | null;
  commonUsers: User[];
  chairman_id: number;
  secretary_id: number;
  author_id: number;
  participants: number[];
  begin: string;
  now_date: string;
  name: string;
  isSendingData: boolean;
  createNewMeeting: () => void;
  meetings: Meeting[];
  status: string;
  editMeeting: () => void;
  changedChecklistItems: object[];
  toDeleteChecklistItems: number[];
  toCreateChecklistItems: object[];
};

const InfoTab: React.FC<Props> = ({
  // dispatchCheckboxItem,
  checkList,
  taskInfoActiveTab,
  // changeReducerCheckboxItem,
  selectedMeetingId,
  projectsSelect,
  description,

  setMeetingDefaultValues,
  setNewParametersToReducer,
  projectId,
  commonUsers,
  chairman_id,
  secretary_id,
  author_id,
  participants,
  begin,
  name,
  now_date,
  isSendingData,
  createNewMeeting,
  meetings,
  status,
  editMeeting,
  changedChecklistItems,
  toDeleteChecklistItems,
  toCreateChecklistItems,
}) => {
  // const dispatch = useDispatch();
  // const [selectedItems, setSelectedItems] = useState<Item[]>([]); // Сотрудники, которые выбраны в чипсах
  // const [cyclicTaskInfo, setCyclicTaskInfo] = useState<any>(null);
  // const [applyBtnRepeatBlock, setApplyBtnRepeatBlock] = useState<boolean>(false);

  // let btnAddFile = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //     // setTaskDescription(selectedTask?.description as string);
  //     setApplyBtnRepeatBlock(false);
  // }, [selectedMeetingId]);
  // useEffect(() => {
  //     // setTaskDescription(selectedTask?.description as string);
  //     setNewParametersToReducer({author_id: })
  // }, []);
  // useEffect(() => {
  //   // нужно, чтобы в редюсере было значение первого пользователя в чипсине, пока нет selectedTask
  //   if (selectedItems.length) {
  //     if (!executor_id) {
  //       setExecutorId(selectedItems[0].value as number);
  //       setTimeout(() => recalculateTaskLoad(), 500);
  //     }
  //   } else setExecutorId(null);
  // }, [selectedItems]);
  // useEffect(() => {
  //     if (selectedMeetingId) {
  //         if (cyclicTaskInfo) {
  //             //////// проверка поля params, потому что это массив, и при простом сравнении после изменения, он дает неверный результат
  //             let params_not_equals = false;
  //             if (
  //                 Object.keys(repeat.params).length !==
  //                 Object.keys(cyclicTaskInfo.params).length
  //             ) {
  //                 params_not_equals = true;
  //             } else {
  //                 Object.keys(repeat.params).forEach((each) => {
  //                     let match = false;
  //                     Object.keys(cyclicTaskInfo.params).forEach((key) => {
  //                         if (each === key) match = true;
  //                     });
  //                     if (!match) params_not_equals = true;
  //                 });
  //             }
  //             ////////
  //             // проверка, был ли изменен хоть 1 параметр в блоке с повторениями
  //             if (
  //                 repeat.end_count !== cyclicTaskInfo.end_count ||
  //                 repeat.end_date !== cyclicTaskInfo.end_date ||
  //                 repeat.ending_condition !== cyclicTaskInfo.ending_condition ||
  //                 repeat.interval !== cyclicTaskInfo.interval ||
  //                 repeat.period !== cyclicTaskInfo.period ||
  //                 params_not_equals
  //             ) {
  //                 setApplyBtnRepeatBlock(true);
  //             } else {
  //                 setApplyBtnRepeatBlock(false);
  //             }
  //         } else {
  //             if (repeat.period !== '') {
  //                 // задача создана, она еще не циклическая, но какой то параметр уже установлен
  //                 setApplyBtnRepeatBlock(true);
  //             }
  //             // else {
  //             //   setApplyBtnRepeatBlock(true);
  //             // }
  //         }
  //     }
  // }, [repeat]);

  let found_proj = projectsSelect.list.find((item) => item.value === projectId);
  let selected_chairman = commonUsers.find((user) => user.id === chairman_id);
  let selected_secretary = commonUsers.find((user) => user.id === secretary_id);
  let selected_author = commonUsers.find((user) => user.id === author_id);

  const selected_meeting = useMemo(() => {
    return meetings.find((meet) => meet.id === selectedMeetingId);
  }, [selectedMeetingId, meetings]);

  let project_from_selected_meet = projectsSelect.list.find(
    (item) => item.value === selected_meeting?.projects[0]
  );
  let chairman_from_selected_meet = commonUsers.find(
    (item) => item.id === selected_meeting?.chairman_id
  );
  let secretary_from_selected_meet = commonUsers.find(
    (item) => item.id === selected_meeting?.secretary_id
  );
  let author_from_selected_meet = commonUsers.find(
    (item) => item.id === selected_meeting?.author_id
  );

  let participants_from_selected_meet = selected_meeting?.participants.map(
    (participant_id) => {
      let user = commonUsers.find((user) => user.id === participant_id);
      if (user) {
        return {
          text: user.name + " " + user.surname,
          value: user.id,
        };
      } else {
        return {
          text: " ",
          value: participant_id,
        };
      }
    }
  );

  const author_selected_items = useMemo(() => {
    if (selectedMeetingId) {
      // и значение в редюсере не отличается от дефолтного
      if (initialState.author_id === author_id) {
        // берем значение выделенного мита
        return author_from_selected_meet
          ? [
              {
                text:
                  author_from_selected_meet.name +
                  " " +
                  author_from_selected_meet.surname,
                value: author_from_selected_meet.id,
              },
            ]
          : [];
      } else {
        // берем значение из редюсера
        return selected_author
          ? [
              {
                text: selected_author.name + " " + selected_author.surname,
                value: selected_author.id,
              },
            ]
          : [];
      }
    } else {
      // берем значение из редюсера
      return selected_author
        ? [
            {
              text: selected_author.name + " " + selected_author.surname,
              value: selected_author.id,
            },
          ]
        : [];
    }
  }, [author_id, author_from_selected_meet]);

  const chairman_selected_items = useMemo(() => {
    if (selectedMeetingId) {
      // и значение в редюсере не отличается от дефолтного
      if (initialState.chairman_id === chairman_id) {
        // берем значение выделенного мита
        return chairman_from_selected_meet
          ? [
              {
                text:
                  chairman_from_selected_meet.name +
                  " " +
                  chairman_from_selected_meet.surname,
                value: chairman_from_selected_meet.id,
              },
            ]
          : [];
      } else {
        // берем значение из редюсера
        return selected_chairman
          ? [
              {
                text: selected_chairman.name + " " + selected_chairman.surname,
                value: selected_chairman.id,
              },
            ]
          : [];
      }
    } else {
      // берем значение из редюсера
      return selected_chairman
        ? [
            {
              text: selected_chairman.name + " " + selected_chairman.surname,
              value: selected_chairman.id,
            },
          ]
        : [];
    }
  }, [chairman_id, chairman_from_selected_meet]);

  const secretary_selected_items = useMemo(() => {
    if (selectedMeetingId) {
      // и значение в редюсере не отличается от дефолтного
      if (initialState.secretary_id === secretary_id) {
        // берем значение выделенного мита
        return secretary_from_selected_meet
          ? [
              {
                text:
                  secretary_from_selected_meet.name +
                  " " +
                  secretary_from_selected_meet.surname,
                value: secretary_from_selected_meet.id,
              },
            ]
          : [];
      } else {
        // берем значение из редюсера
        return selected_secretary
          ? [
              {
                text:
                  selected_secretary.name + " " + selected_secretary.surname,
                value: selected_secretary.id,
              },
            ]
          : [];
      }
    } else {
      // берем значение из редюсера
      return selected_secretary
        ? [
            {
              text: selected_secretary.name + " " + selected_secretary.surname,
              value: selected_secretary.id,
            },
          ]
        : [];
    }
  }, [secretary_id, secretary_from_selected_meet]);

  const participants_selected_items = useMemo(() => {
    let participants_items = participants.map((userId, number) => {
      let uuser = commonUsers.find((user) => user.id === userId);
      if (uuser)
        return { text: uuser.name + " " + uuser.surname, value: uuser.id };
      else return { text: "", value: number };
    });

    if (selectedMeetingId) {
      // и значение в редюсере не отличается от дефолтного
      if (initialState.participants === participants) {
        // берем значение выделенного мита
        return participants_from_selected_meet
          ? participants_from_selected_meet
          : [];
      } else {
        // берем значение из редюсера
        return participants_items;
      }
    } else {
      // берем значение из редюсера
      return participants_items;
    }
  }, [participants, participants_from_selected_meet]);

  const begin_value = useMemo(() => {
    if (selectedMeetingId) {
      // и значение в редюсере не отличается от дефолтного
      if (initialState.begin === begin) {
        // берем значение выделенного мита
        return selected_meeting ? selected_meeting.begin : now_date;
      } else {
        // берем значение из редюсера
        return begin;
      }
    } else {
      // берем значение из редюсера
      return begin;
    }
  }, [begin, selected_meeting]);

  // console.log(isSendingData, initialState.name !== name, initialState.status !== status, initialState.description !== description, initialState.projectId !== projectId, initialState.chairman_id !== chairman_id, initialState.secretary_id !== secretary_id, initialState.author_id !== author_id, initialState.begin !== begin, initialState.secretary_id !== secretary_id, initialState.participants !== participants)
  let save_button_enabled =
    isSendingData ||
    initialState.name !== name ||
    initialState.status !== status ||
    initialState.description !== description ||
    initialState.projectId !== projectId ||
    initialState.chairman_id !== chairman_id ||
    initialState.secretary_id !== secretary_id ||
    initialState.author_id !== author_id ||
    initialState.begin !== begin ||
    initialState.secretary_id !== secretary_id ||
    initialState.participants !== participants ||
    initialState.changedChecklistItems !== changedChecklistItems ||
    initialState.toDeleteChecklistItems !== toDeleteChecklistItems ||
    initialState.toCreateChecklistItems !== toCreateChecklistItems;

  return (
    <div>
      <div className={"full_width_components"}>
        <div className={"description"}>
          <TextareaAutosize
            aria-label="description_textarea_show"
            placeholder="Описание"
            className={"input_div collapsed"}
            rowsMin={1}
            onFocus={(e) => {
              if (e.currentTarget.classList.length) {
                for (let i = 0; i < e.currentTarget.classList.length; i++) {
                  if (e.currentTarget.classList[i] === "collapsed") {
                    e.currentTarget.classList.remove("collapsed");
                  }
                }
              }
            }}
            onChange={(e) => {
              setNewParametersToReducer({ description: e.target.value });
            }}
            style={{ minHeight: "40px" }}
            value={
              selectedMeetingId && !description.length
                ? selected_meeting?.description === null
                  ? ""
                  : selected_meeting?.description
                : description
            }
          />
        </div>
      </div>

      <div className="info_tab">
        <NameWithInput title="Проект" iconName="folder">
          <SelectWithSecondaryBtn
            disabled={false}
            list={projectsSelect.list.sort((a, b) =>
              a.text > b.text ? 1 : -1
            )}
            selected={
              selectedMeetingId
                ? initialState.projectId === projectId
                  ? (project_from_selected_meet as Item)
                  : (found_proj as Item)
                : (found_proj as Item)
            }
            selectHandler={(value, target) => {
              setNewParametersToReducer({ projectId: value });
            }}
          />
        </NameWithInput>

        <NameWithInput title="Автор" iconName="people">
          <MultySelect
            itemsList={commonUsers.map((user) => {
              return {
                text: user.name + " " + user.surname,
                value: user.id,
              };
            })}
            radio={true}
            selectedItems={author_selected_items}
            addHandler={(value) => {
              setNewParametersToReducer({ author_id: value });
            }}
            removeHandler={() => {
              setNewParametersToReducer({ author_id: -1 });
            }}
          />
        </NameWithInput>

        <NameWithInput title="Председатель" iconName="people">
          <MultySelect
            itemsList={commonUsers.map((user) => {
              return {
                text: user.name + " " + user.surname,
                value: user.id,
              };
            })}
            radio={true}
            selectedItems={chairman_selected_items}
            addHandler={(value) => {
              setNewParametersToReducer({ chairman_id: value });
            }}
            removeHandler={() => {
              setNewParametersToReducer({ chairman_id: -1 });
            }}
          />
        </NameWithInput>

        <NameWithInput title="Секретарь" iconName="people">
          <MultySelect
            itemsList={commonUsers.map((user) => {
              return {
                text: user.name + " " + user.surname,
                value: user.id,
              };
            })}
            radio={true}
            selectedItems={secretary_selected_items}
            addHandler={(value) => {
              setNewParametersToReducer({ secretary_id: value });
            }}
            removeHandler={() => {
              setNewParametersToReducer({ secretary_id: 0 });
            }}
          />
        </NameWithInput>

        <NameWithInput title="Участники" iconName="people">
          <MultySelect
            itemsList={commonUsers.map((user) => ({
              text: user.name + " " + user.surname,
              value: user.id,
            }))}
            selectedItems={participants_selected_items as Item[]}
            addHandler={(value) => {
              setNewParametersToReducer({
                participants: [...participants, value],
              });
            }}
            removeHandler={(val) => {
              let participants_selected_items_copy = [
                ...participants_selected_items,
              ] as Item[];
              let usersId = participants_selected_items_copy.map((item) => {
                return item.value;
              });

              let newParts = usersId.filter((item) => item !== val);
              // console.log(newParts, participants_selected_items_copy, usersId)
              setNewParametersToReducer({ participants: newParts });
            }}
          />
        </NameWithInput>

        <NameWithInput title="Начало" iconName="calendar-dark">
          <div style={{ width: "232px" }}>
            <TaskDateTime
              acceptHandler={(val) => setNewParametersToReducer({ begin: val })}
              date={begin_value}
            />
          </div>
        </NameWithInput>

        {/*repeat task*/}
        <NameWithInput title="Уведомление" iconName="bell">
          <div style={{ width: "94px" }}>
            <SelectWithSecondaryBtn
              list={[{ text: "1 час до", value: 1 }]}
              selected={{ text: "1 час до", value: 1 }}
              selectHandler={(value, target) => {
                // let params = {project_id: value};
                // let section = sections.find((sec) => sec.project_id === value);
                // if (section) params['project_section_id'] = section.id;
                // if (selectedMeetingId) {
                // if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                // сравнение одного с несколькими
                // эта задача вообще циклическая или сама по себе ?
                // значит циклическая
                // больше не нужно запоминать выбор в текущей сессии работы с задачей
                // if (flagForActionCyclycTask === '') {
                // setCyclicTaskToChange(params); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                // } else if (flagForActionCyclycTask === 'task') {
                //     updateTask(selectedTask.id, params);
                // } else {
                //     updateTask(selectedTask.id, params);
                //     sendReqToChangeTemplRepTask(params);
                // }
                // } else {
                //   updateTask(selectedTask.id, params);
                // }
                // }
                // setNewParametersToReducer({projectId: value})
              }}
            />
          </div>
        </NameWithInput>

        <CheckListModule
          nameForChecklist={"Повестка"}
          action={(orderNumber, nameItem, checkboxValue, actionType) => {
            // console.log(
            //     'order ', orderNumber,
            //     'name ', nameItem,
            //     'value ', checkboxValue,
            //     'type ', actionType)

            let copyTasklist = [...checkList];

            switch (actionType) {
              case "patch":
                let changedItems: {}[] = [];
                let order_number;
                if (typeof orderNumber === "number")
                  order_number = orderNumber - 1;
                else order_number = parseInt(orderNumber.substr(0, 1)) - 1;

                if (
                  copyTasklist[order_number]["name"] !== nameItem ||
                  copyTasklist[order_number]["name"] !== (checkboxValue ? 1 : 0)
                ) {
                  copyTasklist[order_number] = {
                    id: copyTasklist[order_number]["id"],
                    name: nameItem,
                    meeting_id: copyTasklist[order_number]["meeting_id"],
                    done: checkboxValue ? 1 : 0,
                  };

                  changedItems.push({
                    id: copyTasklist[order_number]["id"],
                    name: nameItem,
                    meeting_id: copyTasklist[order_number]["meeting_id"],
                    done: checkboxValue ? 1 : 0,
                  });
                }

                let copy_changed_tasklist_items = [...changedChecklistItems];
                if (
                  copy_changed_tasklist_items.find(
                    (item) => item["id"] === copyTasklist[order_number]["id"]
                  )
                ) {
                  let index = copy_changed_tasklist_items.findIndex(
                    (item) => item["id"] === copyTasklist[order_number]["id"]
                  );

                  copy_changed_tasklist_items[index] = {
                    id: copyTasklist[order_number]["id"],
                    name: nameItem,
                    meeting_id: copyTasklist[order_number]["meeting_id"],
                    done: checkboxValue ? 1 : 0,
                  };

                  // найден айдишник, который уже сеществует
                  setNewParametersToReducer({
                    changedChecklistItems: [...copy_changed_tasklist_items],
                  });
                } else {
                  // новый элемент, еще не редактировался
                  setNewParametersToReducer({
                    changedChecklistItems: [
                      ...copy_changed_tasklist_items,
                      ...changedItems,
                    ],
                  });
                }

                setNewParametersToReducer({ checkList: copyTasklist });
                break;

              case "delete":
                let toDeleteList = [...toDeleteChecklistItems];
                let found_item = copyTasklist.find(
                  (item) => item["name"] === nameItem
                );
                if (found_item) {
                  setNewParametersToReducer({
                    toDeleteChecklistItems: [...toDeleteList, found_item["id"]],
                  });
                }

                copyTasklist = copyTasklist.filter(
                  (item) => item["name"] !== nameItem
                );
                setNewParametersToReducer({ checkList: [...copyTasklist] });
                break;

              case "create":
                let toCreateList = [...toCreateChecklistItems];
                setNewParametersToReducer({
                  toCreateChecklistItems: [
                    ...toCreateList,
                    {
                      name: nameItem,
                      done: checkboxValue ? 1 : 0,
                    },
                  ],
                  checkList: [
                    ...copyTasklist,
                    {
                      done: checkboxValue,
                      name: nameItem,
                    },
                  ],
                });

                // let found_item = copyTasklist.find(item => (item['name'] === nameItem))
                // if (found_item) {
                //     setNewParametersToReducer({toDeleteChecklistItems: [...toDeleteList, found_item['id']]})
                // }

                // copyTasklist = copyTasklist.filter(item => item['name'] !== nameItem)
                // setNewParametersToReducer({checkList: [...copyTasklist]})
                break;
            }
          }}
          checkList={checkList}
          disabled={false}
        />

        {/*{repeat.period && (*/}
        {/*    <NameWithInput title="Закончить повтор" iconName="repeat">*/}
        {/*<EndRepeatTask*/}
        {/*  disabledComponent={*/}
        {/*    (currentUser?.id !== selectedTask?.author_id &&*/}
        {/*      selectedTask !== null) ||*/}
        {/*    (cyclicTaskInfo !== null &&*/}
        {/*      currentUser?.id !== cyclicTaskInfo?.author_id)*/}
        {/*  }*/}
        {/*/>*/}
        {/*    </NameWithInput>*/}
        {/*)}*/}

        {/*{applyBtnRepeatBlock && (*/}
        {/*    <NameWithInput title="" iconName="repeat">*/}
        {/*        <Button*/}
        {/*            style={{marginLeft: 144}}*/}
        {/*            title={'Применить'}*/}
        {/*            onClick={() => {*/}
        {/*                // sendReqToChangeTemplRepTask({...repeat});*/}
        {/*            }}*/}
        {/*        />*/}
        {/*    </NameWithInput>*/}
        {/*)}*/}
        {/*repeat task*/}

        {/*{!selectedMeetingId && (*/}
        {/*    <NameWithInput title="Прикрепить файл" iconName="clip">*/}
        {/*        <div>*/}
        {/*            <input*/}
        {/*                onChange={(e) => {*/}
        {/*                    let files = e.currentTarget.files;*/}
        {/*                    if (!files) return;*/}
        {/*                    // for (let i = 0; i < files?.length; i++) uploadFile(files[i]);*/}
        {/*                }}*/}
        {/*                type="file"*/}
        {/*                id="comment_files"*/}
        {/*                style={{display: 'none'}}*/}
        {/*                multiple={true}*/}
        {/*            />*/}

        {/*            <label htmlFor="comment_files" className={'info_attach_file'}>*/}
        {/*                Выбрать*/}
        {/*            </label>*/}

        {/*            {commentFiles.length > 0 && (*/}
        {/*                <div className={'attachments'} style={{width: '156px'}}>*/}
        {/*                    {commentFiles.map((elem) => (*/}
        {/*                        <FileElem*/}
        {/*                            url={elem.url}*/}
        {/*                            key={elem.id}*/}
        {/*                            id={elem.id}*/}
        {/*                            name={elem.name}*/}
        {/*                            // deleteHandler={deleteFile}*/}
        {/*                            deleteHandler={() => {*/}
        {/*                            }}*/}
        {/*                        />*/}
        {/*                    ))}*/}
        {/*                </div>*/}
        {/*            )}*/}
        {/*        </div>*/}
        {/*    </NameWithInput>*/}
        {/*)}*/}

        {/*<NameWithInput title="Загрузка" iconName="percent">*/}
        {/*  <TaskWorkLoad disabled={disabledComponent} />*/}
        {/*</NameWithInput>*/}

        {/*<NameWithInput title="Зависимости" iconName="arrow-right">*/}
        {/*  <Dependencies />*/}
        {/*</NameWithInput>*/}

        {/*{!selectedMeetingId && (*/}

        <ResolutionsRightPlate />

        <div className={"taskInfoPlateBottomRowButtons"}>
          <Button
            title="Отмена"
            design="secondary"
            onClick={() => {
              meetingPlateHidingSignal(false);
              setTimeout(() => {
                setNewParametersToReducer({ showRightPlate: false });
                setMeetingDefaultValues();
              }, 800);

              // setDefaultValues();
              setMeetingDefaultValues();
              // dispatch(setShownTaskInfoPlate(false));
              // if (!isSendingDelegate) {
              //     setIsSendingDelegate(true)
              //     let users_ids = selectedItems.map(item => (item.value))
              //     delegateConfirm(users_ids as number[])
              // }
            }}
          />
          <Button
            title={"Сохранить"}
            style={{ marginLeft: "8px" }}
            isSending={isSendingData}
            disabled={!save_button_enabled}
            onClick={() => {
              if (!isSendingData) {
                setNewParametersToReducer({ isSendingData: true });
                if (selectedMeetingId) editMeeting();
                else createNewMeeting();

                // isSendingMeetingData(true);

                // successfulCreatedNewTasks({
                //     createdTasks: [],
                //     executorsIds: selectedItems.map((item) => item.value),
                //     checkItems: [...checkList],
                // });
                // selectedItems.forEach((item) =>
                //     createTask(item.value as number)
                // );
              }
            }}
          />
        </div>

        {/*)}*/}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    // selectedTask: getSelectedTask(state),
    meetings: state.meetingsPage.meetings,

    projectId: state.meetingsPage.projectId,
    chairman_id: state.meetingsPage.chairman_id,
    secretary_id: state.meetingsPage.secretary_id,
    author_id: state.meetingsPage.author_id,
    participants: state.meetingsPage.participants,
    begin: state.meetingsPage.begin,
    name: state.meetingsPage.name,
    status: state.meetingsPage.status,
    isSendingData: state.meetingsPage.isSendingData,
    toDeleteChecklistItems: state.meetingsPage.toDeleteChecklistItems,
    toCreateChecklistItems: state.meetingsPage.toCreateChecklistItems,

    now_date: state.taskInfoPlate.begin,

    currentUser: state.commonInfo.currentUserInfo,
    commonUsers: state.commonInfo.users,
    executor_id: state.taskInfoPlate.executor_id,
    taskInfoActiveTab: state.taskInfoPlate.taskInfoActiveTab,
    projectsSelect: getProjectsSelectList(state),
    usersSelect: getUsersSelectList(state),
    sectionSelect: getSectionSelectList(state),
    sections: state.commonInfo.sections,
    // schedule: state.taskInfoPlate.executorSchedule,
    checkList: state.meetingsPage.checkList,
    changedChecklistItems: state.meetingsPage.changedChecklistItems,

    description: state.meetingsPage.description,
    // project_id: state.taskInfoPlate.project_id,
    // repeat: state.taskInfoPlate.repeat,
    // rememberObjectCyclicTaskToChange: state.taskInfoPlate.rememberObjectCyclicTaskToChange,
    // flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
    priorities: state.commonInfo.priorities,
    selectedMeetingId: state.meetingsPage.selectedMeetingId,
  };
};

const mapDispatchToProps = {
  editMeeting,
  setNewParametersToReducer,
  setMeetingDefaultValues,
  createNewMeeting,
  meetingPlateHidingSignal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoTab);
