import React from "react";

const BsFilter52 = ({ props }) => {
  return (
    <svg
      {...props}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <rect
          x="0.5"
          y="0.5"
          width="35"
          height="35"
          rx="3.5"
          fill="#436EA0"
          fill-opacity="0.1"
          stroke="#436EA0"
        />
        <path
          d="M25.0417 16.895L14.9183 10.7083C14.8331 10.6562 14.7354 10.6277 14.6355 10.6258C14.5356 10.624 14.437 10.6487 14.3499 10.6976C14.2627 10.7465 14.1901 10.8177 14.1396 10.9039C14.0891 10.9902 14.0625 11.0883 14.0625 11.1882V23.5616C14.0625 23.6616 14.0891 23.7597 14.1396 23.8459C14.1901 23.9321 14.2627 24.0034 14.3499 24.0522C14.437 24.1011 14.5356 24.1259 14.6355 24.124C14.7354 24.1222 14.8331 24.0937 14.9183 24.0416L25.0417 17.8549C25.1239 17.8047 25.1918 17.7341 25.239 17.6501C25.2861 17.566 25.3109 17.4713 25.3109 17.3749C25.3109 17.2786 25.2861 17.1838 25.239 17.0998C25.1918 17.0157 25.1239 16.9452 25.0417 16.895Z"
          stroke="#436EA0"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default BsFilter52;
