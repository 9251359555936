import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import RadioButtons from "../templates/RadioButtons";
import NameOfStep from "../templates/NameOfStep";
import "../BpResultForm.scss";

const PreparationAndApprovalFinalPaymentForUTDismissal = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.preparationAndApprovalFinalPaymentForUT?.extra
      ? settingsOfFields.preparationAndApprovalFinalPaymentForUT.extra
      : {}
  );

  const url = `${apiBp}/task/${taskId}`;
  // console.log(taskId);
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          {
            ...settingsOfFields.preparationAndApprovalFinalPaymentForUT,
            extra: data,
          },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="checkOfExecutiveDirector">
      <div>
        <NameOfStep text={"Подготовить окончательный расчет"} />
        <RadioButtons
          data={data}
          setData={setData}
          name={"f6"}
          positiveText={"Согласовано"}
          negativeText={"Не согласовано"}
        />
      </div>
      <div>
        <div>
          <NameOfStep text={"Согласование"} />
          <RadioButtons
            data={data}
            setData={setData}
            name={"f6_1"}
            positiveText={"Согласовано"}
            negativeText={"Не согласовано"}
          />
        </div>
        {/* <div>
          <NameOfStep text={"Согласование"} />
          <RadioButtons
            data={data}
            setData={setData}
            name={"f6_2"}
            positiveText={"Согласовано"}
            negativeText={"Не согласовано"}
          />
        </div> */}
        <div>
          <NameOfStep text={"Сумма к выплате"} />
          <TextAreaOrInputElement
            name={"f6_3"}
            isInput={true}
            type={"number"}
            data={data}
            setData={setData}
          />
        </div>
        <div>
          <NameOfStep text={"Номер заявки в 1С"} />
          <TextAreaOrInputElement
            name={"f6_4"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
          />
        </div>
        <div>
          <NameOfStep text={"Согласовать"} />
          <RadioButtons
            data={data}
            setData={setData}
            name={"f6_5"}
            positiveText={"Согласовано"}
            negativeText={"Не согласовано"}
          />
        </div>
      </div>
    </form>
  );
};

export default PreparationAndApprovalFinalPaymentForUTDismissal;
