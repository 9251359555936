import {Reducer} from "redux";

import {CalendarActions, types} from "./action_types";

const initialState = {
	date: {
		begin: (new Date().getTime() - (4 * 7 * 24 * 60 * 60 * 1000)).toString(),
		end: (new Date().getTime() + (4 * 7 * 24 * 60 * 60 * 1000)).toString(),
	},
	currentDate: (new Date().getTime()).toString(),
}

export type CalendarState = typeof initialState;

const reducer: Reducer<CalendarState, CalendarActions> = (
		state = initialState,
		action
) => {
	switch (action.type) {
		case types.SET_DATE_CALENDAR:
			return {
				...state,
				date: action.date
			};

		case types.SET_CURRENT_DATE:
			return {
				...state,
				currentDate: action.date
			};

		default:
			return state;
	}
};

export default reducer;
