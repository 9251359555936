import React, {Dispatch, FC, SetStateAction, useState} from 'react';

import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';
import Input from '../../../common/components/Inputs/Input';

import { changeDataUserType } from '../index';

type Props = {
	title: string;
	setChangeDataUser: Dispatch<SetStateAction<changeDataUserType>>;
	changeDataUser: changeDataUserType;
	placeholder: string;
	type: 'hours' | 'days';
}

const DaysProcessing: FC<Props> = ({
		title,
		changeDataUser,
		setChangeDataUser,
		placeholder,
		type
}) => {

	const setter = (value: string) => {
		setChangeDataUser((prev) => {
			return {
				...prev,
				processing: {
					...prev.processing,
					[type]: value,
				}
			};
		});
	};

	const handleChange = (value: string) => {
		if (type === 'hours') {
			if (+value <= 3) {
				setter(value);
			}
		} else {
			setter(value);
		}
	};

	const check = changeDataUser['processing'];

	return (
		<NameWithInput title={title}>
			<Input
				inputType='text'
				placeholder={placeholder}
				value={check ? changeDataUser['processing'][type] : ''}
				title={title}
				changeHandler={handleChange}
			/>
		</NameWithInput>
	);
};

export default DaysProcessing;