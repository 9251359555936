// Окно предупреждения о новых сообщениях (тут прорисовывается "Колокольчик" - кнопочка для прочтения сообщений
// и "точка" - уведомление о новых сообщениях)

import React, { useEffect, useCallback, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { State } from "../rootReducer";
import { setFilterWindowShow } from "../pages/Tasks/actions";
// import {setShowColumnsProjectsCustom, setShowProjectsCols} from "../pages/Projects/actions"
// import ColumnsCheckboxItem from "./components/ColumnsCustomItem/ColumnsCheckboxItem";
// import {columnsOfTables} from "./shared_data"
import { clearNotifications, load_nextpage_notifications } from "./actions";

// import TaskStatus from "./components/TaskStatus/TaskStatus"
// import {Notification} from "./types"

import { mark_notification_as_read } from "./actions";
import {
  selectTask,
  setShownTaskInfoPlate,
} from "../pages/TaskInfoPlate/actions";
import { Task } from "./types";
import { loadSpecificTask } from "../pages/Tasks/actions";
import styled from "styled-components";
import { fetchData } from "../utils/fetchData";
import BuildNotificationRow from "./components/BuildNotificationRow";
import Icon from "./components/Icon";
// type TaskNotification = {
//   id: number;
//   name: string;
// };
type Props = {
  // showNotifWindow: (val: boolean) => void;
  remote_notifications: object;
  showNotificationWindow: boolean;
  setShowNotificationWindow: any;
  notifications: any;
  mark_notification_as_read: (
    id_notification: string,
    id_current_user: number,
    readonly?: boolean
  ) => void;
  users: any;
  end: boolean;
  tasks: Task[];
  setShownTaskInfoPlate: (val: boolean) => void;
  selectTask: (val: Task) => void;
  load_nextpage_notifications: (val: string) => void;
  loadSpecificTask: (val: number) => void;
  clearNotifications: () => void;
};

const WrapperNotificationBlock = styled.div`
  position: absolute;
  right: 0px; /****************/
  top: 10px; /***************/
  display: ${({ showNotificationWindow }) =>
    showNotificationWindow ? "block" : "none"};
  width: 352px;
  z-index: 1000;
  background-color: white;
  min-height: 100px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  border-radius: 12px;
`;

// Положение точки уведомдения о сообщениях можно задать в:
// ${({ newNotification }) =>    newNotification &&  `    &::after {}
const NotificationBlock = styled.div`
  line-height: 40px;
  height: 40px;
  width: 40px;
  margin: 0 25px;
  padding: 0 11px;
  cursor: pointer;
  /* position: relative;  */ /*************************************/
  ${({ newNotification }) =>
    newNotification &&
    `
    &::after {
      content: '';
      position: absolute;
      top: 10px;
      right: 105px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #ff6666;
      
   }
`}
`;

const HeaderNotificationStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  cursor: default;
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  div {
    align-items: center;
    display: flex;
    color: #1baaf0;
    cursor: pointer;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
  }
`;
const ContentStyle = styled.div`
  height: 460px; /*****************/
  overflow-y: scroll;
  overflow-x: hidden;
`;

const NotificationWindow: React.FC<Props> = ({
  loadSpecificTask,
  tasks,

  setShowNotificationWindow,
  users,
  end,
  remote_notifications,
  showNotificationWindow,
  notifications,
  mark_notification_as_read,
  load_nextpage_notifications,
  clearNotifications,
}) => {
  // self-close for window
  let plate = useRef<HTMLDivElement>(null);
  const [dataName, setDataName] = useState({});
  const dispatch = useDispatch();
  const outsideClickHandler = useCallback(
    function (e): any {
      //e.stopPropagation();
      if (
        (e.target as Node) &&
        plate.current &&
        e?.composedPath().includes(plate.current) !== undefined &&
        !e?.composedPath().includes(plate.current)
      ) {
        setShowNotificationWindow(false);
      }
    },
    [setFilterWindowShow]
  );
  useEffect(() => {
    document.addEventListener("click", outsideClickHandler);
    return () => document.removeEventListener("click", outsideClickHandler);
  }, [outsideClickHandler]);
  // self-close for window

  let allow = false;
  const scrollHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      if (scrollHeight - 1 <= scrollTop + clientHeight) {
        window.requestAnimationFrame(function () {
          if (!end) {
            load_nextpage_notifications("ok");
            allow = false;
          }
        });
        allow = true;
      }
    },
    [end]
  );

  // const taskClickAction = (task_id) => {
  //   let found_task,
  //     specTask = true;

  //   Object.keys(tasks).forEach((item) => {
  //     if (tasks[item].id === task_id) found_task = tasks[item];
  //   });

  //   if (found_task) {
  //     // найдена в уже приехавших тасках
  //     setTimeout(() => {
  //       if (store.getState().tasksPage.taskAddMode) return;
  //       selectTask(found_task);
  //       setShownTaskInfoPlate(true);
  //     }, 500);
  //   } else {
  //     if (specTask) {
  //       setTimeout(() => loadSpecificTask(task_id), 0);
  //       specTask = false;
  //     }
  //   }
  // };

  const handlerFetchTask = (event) => {
    const idTask = event.target.dataset.taskid;

    idTask &&
      fetchData
        .get(`/api/v1/tasks/${idTask}`)
        .then((task) => dispatch(selectTask(task)))
        .catch((error) =>
          console.error("Не найдена задача по следующей причине: " + error)
        );
  };

  let arrayTasksId: number[] = [];
  let uniqIdTasks, stringTasksId;

  const fetchNameTasks = useCallback(() => {
    remote_notifications["unread"].length &&
      remote_notifications["unread"].map((notification) => {
        notification?.data?.task_id > 0 &&
          arrayTasksId.push(notification.data.task_id);
      });
    remote_notifications["read"].length &&
      remote_notifications["read"].map((notification) => {
        notification?.data?.task_id > 0 &&
          arrayTasksId.push(notification.data.task_id);
      });
    uniqIdTasks = Array.from(new Set(arrayTasksId));
    for (let i = 0; i < uniqIdTasks.length; i++) {
      if (Object.keys(dataName).includes("" + uniqIdTasks[i])) {
        uniqIdTasks.splice(i, 1);
        i = -1;
      }
    }

    if (uniqIdTasks) {
      if (uniqIdTasks.length === 1) {
        fetchData
          .get(`/api/v1/tasks?task_id=${uniqIdTasks[0]}`)
          .then((data) => {
            setDataName((prev) => {
              return {
                ...prev,
                [uniqIdTasks[0]]:
                  data[0]?.name || "Имя задачи неизвестно или она удалена",
              };
            });
          })
          .catch((e) => console.warn(e.message));
      } else if (uniqIdTasks.length <= 20 && uniqIdTasks.length > 0) {
        stringTasksId = uniqIdTasks.join(",");
        fetchData
          .get(`/api/v1/tasks?task_id=${stringTasksId}`)
          .then((data) => {
            data.map((task) => {
              setDataName((prev) => {
                return {
                  ...prev,
                  [task?.id]:
                    task?.name || "Имя задачи неизвестно или она удалена",
                };
              });
            });
          })
          .catch((e) => console.warn(e.message));
      } else {
        let copyuniqIdTasks = [...uniqIdTasks];

        for (let i = 0; i < uniqIdTasks.length / 20; i++) {
          let stringTasksId = copyuniqIdTasks.splice(0, 20).join(",");
          fetchData
            .get(`/api/v1/tasks?task_id=${stringTasksId}`)
            .then((data) => {
              data.map((task) => {
                setDataName((prev) => {
                  return {
                    ...prev,
                    [task?.id]:
                      task?.name || "Имя задачи неизвестно или она удалена",
                  };
                });
              });
            })
            .catch((e) => console.warn(e.message));
        }
      }
    }
  }, [remote_notifications["read"], remote_notifications["unread"]]);
  useEffect(() => {
    fetchNameTasks();
  }, [remote_notifications]);

  return (
    <NotificationBlock
      newNotification={Boolean(notifications.length)}
      ref={plate}
      onClick={() => {
        if (showNotificationWindow === false) {
          setShowNotificationWindow(true);
        } else {
          setShowNotificationWindow(false);
        }
      }}
    >
      <Icon name="notification" title="Уведомления" alt="Уведомления" />
      <WrapperNotificationBlock showNotificationWindow={showNotificationWindow}>
        <HeaderNotificationStyle>
          <span
            style={{
              marginLeft: "20px",
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            Уведомления
          </span>
          <div
            onClick={() => {
              clearNotifications();
            }}
          >
            <span
              style={{
                marginRight: "20px",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Закрыть
            </span>
          </div>
        </HeaderNotificationStyle>

        <ContentStyle className={"content"} onScroll={scrollHandler}>
          {remote_notifications["unread"].map((item, id_key) => {
            let avatar_url = "";
            let user_name = "";
            users.forEach((users_item) => {
              if (users_item.id === item.notifiable_id) {
                if (users_item.image) avatar_url = users_item.image.url;
                user_name = users_item.name + " " + users_item.surname;
              }
            });

            return (
              <BuildNotificationRow
                key={id_key}
                id_key={id_key}
                avatar_url={avatar_url}
                user_name={user_name}
                task_name={dataName[item?.data?.task_id] || ""}
                users={users}
                item={item}
                unread_mark={true}
                readClick={(id_notif, id_current_user) =>
                  mark_notification_as_read(id_notif, id_current_user)
                }
                textAction={(task_id, id_notif, id_current_user, event) => {
                  // taskClickAction(task_id);
                  handlerFetchTask(event);
                  mark_notification_as_read(id_notif, id_current_user, true);
                }}
              />
            );
          })}
          {remote_notifications["read"].map((item, id_key) => {
            let avatar_url = "";
            let user_name = "";
            users.forEach((users_item) => {
              if (users_item.id === item.notifiable_id) {
                if (users_item.image) avatar_url = users_item.image.url;
                user_name = users_item.name + " " + users_item.surname;
              }
            });
            if (!item.data.text) return null;

            return (
              <BuildNotificationRow
                key={id_key}
                id_key={id_key}
                avatar_url={avatar_url}
                user_name={user_name}
                task_name={dataName[item?.data?.task_id] || ""}
                users={users}
                item={item}
                unread_mark={false}
                readClick={(id_notif, id_current_user) =>
                  mark_notification_as_read(id_notif, id_current_user)
                }
                textAction={(task_id, id_notif, id_current_user, event) => {
                  // taskClickAction(task_id);
                  handlerFetchTask(event);
                  mark_notification_as_read(id_notif, id_current_user, true);
                }}
              />
            );
          })}
        </ContentStyle>
      </WrapperNotificationBlock>
      {/* )} */}
    </NotificationBlock>
  );
};

const mapStateToProps = (state: State) => {
  return {
    tasks: state.tasksPage.tasks,
    end: state.commonInfo.remote_notifications.end,
    remote_notifications: state.commonInfo.remote_notifications,
    users: state.commonInfo.users,
  };
};

const mapDispatchToProps = {
  // showNotifWindow,
  mark_notification_as_read,
  load_nextpage_notifications,
  selectTask,
  setShownTaskInfoPlate,
  loadSpecificTask,
  clearNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationWindow);