import React, { useState, useEffect } from "react";
import Toggler from "../../../common/components/Togglers/Toggler";
import Checkbox from "../../../common/components/CheckboxItem/Checkbox";
import styled from "styled-components";

import { useHistory } from "react-router";
import { nanoid } from "nanoid";
import { fetchData } from "../../../utils/fetchData";
import { useSelector } from "react-redux";
import { State } from "../../../rootReducer";

type Notifications = {
  1: number[];
  2: number[];
  3: number[];
};
const StyleNotificationWrap = styled.div`
  margin: 16px;
`;

const StyleToggle = styled.div`
  margin-bottom: 24px;
`;

const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 16px;
`;

const StyleCheckbox = styled.div`
  display: flex;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
`;

const StyleCheckboxTitle = styled.div`
  margin-left: 8px;
  font-size: 14px;
`;

const StyleBlockHint = styled.div`
  margin-bottom: 8px;
  color: #707070;
  font-size: 13px;
  padding: 8px;
`;

const options = [
  { name: "Приложение", value: 3 },
  { name: "Email", value: 1 },
  { name: "Telegram", value: 2 },
];
const notifications = [
  { name: "Задача изменена", value: 2 },
  { name: "У задачи поменялся статус", value: 3 },
  { name: "Задача отменена", value: 4 },
  { name: "Добавлен комментарий", value: 5 },
  { name: "У задачи близится дедлайн", value: 6 },
];

const Notifications = () => {
  const history = useHistory();
  const [optionsNotifications, setOptionsNotifications] =
    useState<Notifications>({
      1: [],
      2: [],
      3: [],
    });
  const [activeChannel, setActiveChannel] = useState(3);
  const currentUser = useSelector(
    (state: State) => state.commonInfo.current_user
  );
  const taskId = useSelector(
    (state: State) => state.taskInfoPlate.selectedTask?.id
  );
  useEffect(() => {
    fetchData
      .get(`/api/v1/users/${currentUser}/notifications?task_id=${taskId}`)
      .then((data) => {
        let notifications: Notifications = {
          1: [],
          2: [],
          3: [],
        };
        data.map((el) => {
          if (el.pivot.channel === 1) {
            notifications[1].push(el.pivot.notification_type_id);
          } else if (el.pivot.channel === 2) {
            notifications[2].push(el.pivot.notification_type_id);
          } else if (el.pivot.channel === 3) {
            notifications[3].push(el.pivot.notification_type_id);
          }
        });
        setOptionsNotifications(notifications);
      })
      .catch((error) => console.warn(error));
  }, [taskId]);
  const handlerDetachSubscriptions = () => {
    if (optionsNotifications[activeChannel].length >= notifications.length) {
      optionsNotifications[activeChannel].forEach((type) => {
        fetchData
          .delete(
            `/api/v1/users/${currentUser}/notification-types/${type}`,
            JSON.stringify({
              task_id: taskId,
              channel: activeChannel,
            })
          )
          .then(({ success }) => {
            if (success) {
              setOptionsNotifications((prev) => {
                return {
                  ...prev,
                  [activeChannel]: [...prev[activeChannel]].filter(
                    (el) => el !== type
                  ),
                };
              });
            }
          })
          .catch((error) => console.warn(error));
      });
    } else {
      notifications.forEach((type) => {
        fetchData
          .post(
            `/api/v1/users/${currentUser}/notification-types`,
            JSON.stringify({
              notification_type_id: type.value,
              task_id: taskId,
              channel: activeChannel,
            })
          )
          .then(({ success }) => {
            if (success) {
              setOptionsNotifications((prev) => {
                return {
                  ...prev,
                  [activeChannel]: [...prev[activeChannel], type.value],
                };
              });
            }
          })
          .catch((error) => console.warn(error));
      });
    }
  };
  const handlerChackSubscriptions = (value) => {
    let body = JSON.stringify({
      notification_type_id: value,
      task_id: taskId,
      channel: activeChannel,
    });

    if (optionsNotifications[activeChannel].includes(value)) {
      fetchData
        .delete(
          `/api/v1/users/${currentUser}/notification-types/${activeChannel}`,
          body
        )
        .then(({ success }) => {
          if (success) {
            setOptionsNotifications({
              ...optionsNotifications,
              [activeChannel]: [...optionsNotifications[activeChannel]].filter(
                (el) => el !== value
              ),
            });
          }
        })
        .then((error) => console.warn(error));
      // optionsNotifications[activeChannel].
    } else {
      fetchData
        .post(`/api/v1/users/${currentUser}/notification-types`, body)
        .then(({ success }) => {
          if (success) {
            setOptionsNotifications({
              ...optionsNotifications,
              [activeChannel]: [...optionsNotifications[activeChannel], value],
            });
          }
        })
        .catch((error) => console.warn(error));
    }
  };
  return (
    <>
      <StyleNotificationWrap>
        <StyleToggle>
          <Toggler
            activeValue={activeChannel}
            selectHandler={(channel) => {
              setActiveChannel(+channel);
            }}
            elemsList={options}
            styles={{ display: "inline-flex" }}
          />
        </StyleToggle>
        <WrapperTitle>
          {options.filter((el) => el.value === activeChannel && el)[0].name}
          <Checkbox
            checked={optionsNotifications[activeChannel].length === 5}
            onClick={(e) => e.stopPropagation()}
            onChange={handlerDetachSubscriptions}
            disabled={false}
          />
        </WrapperTitle>

        {activeChannel === 2 && (
          <StyleBlockHint>
            <>
              Подключите телеграм-бота, для удобного использования системы.{" "}
              <br />
              Нажмите start. И отправьте сообщение: <br />
              subscribe_to_notifications:{} <br />
              Ссылка на бота:
              <a
                target="_blank"
                href="https://t.me/easy_task_work_bot"
                rel="noopener noreferrer"
              >
                t.me/easy_task_work_bot
              </a>
            </>
          </StyleBlockHint>
        )}
        <div>
          {notifications.map((el) => {
            return (
              <StyleCheckbox key={nanoid()} onClick={() => {}}>
                <Checkbox
                  checked={optionsNotifications[activeChannel].includes(
                    el.value
                  )}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => {
                    handlerChackSubscriptions(el.value);
                  }}
                  disabled={false}
                />
                <StyleCheckboxTitle>{el.name}</StyleCheckboxTitle>
              </StyleCheckbox>
            );
          })}
        </div>
      </StyleNotificationWrap>
    </>
  );
};

export default Notifications;
