import React, {useEffect, useState} from 'react';
import ruLocale from 'date-fns/locale/ru';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DateTimePicker,KeyboardDatePicker} from '@material-ui/pickers';
import DateMaster from '../../../utils/DateMaster';
import {CreateNotif} from '../../../utils/createNotification';
// import { RootRef } from '@material-ui/core';
import styled from 'styled-components';
import palette from '../palette';
import {connect} from 'react-redux';
import {State} from '../../../rootReducer';
import {updateTask} from '../../actions';
import {
    recalculateTaskLoad,
    setTaskEnd,
} from '../../../pages/TaskInfoPlate/actions';

type OpenTo = 'date' | 'year' | 'month' | 'hours' | 'minutes' | undefined;


type Props = {
    date: Date | string,
    minDate?: Date,
    maxDate?: Date,
    acceptHandler: (isoDate: string) => void,
    placeholder?: string,
    styles?: Object
}

const WrapperDataTime = styled.div`
  height: 40px;
  display: flex;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  color: #212121;
  ${({disabled}) =>
    disabled &&
    `
    background: #f5f5f5;
    pointer-events: none;
    color: ${palette.textBlack};
  `};
`;
const DataBlock = styled.div`
  width: 152px;
  border: 1px solid ${palette.ultraBlueLighter};
  border-radius: 6px;
`;
const TimeBlock = styled.div`
  width: 72px;
  margin-left: 8px;
  border: 1px solid ${palette.ultraBlueLighter};
  border-radius: 6px;
`;
const DateTimeComponent = ({
                               date,
                               setOpen,
                               setOpenTo,
                               disabled,
                               hideTimeBlock,
                           }) => {
    const dateMaster = new DateMaster(date);
    return (
        <WrapperDataTime
            onClick={() => !disabled && setOpen(true)}
            disabled={disabled}
        >
            <DataBlock onClick={() => setOpenTo('date')} className="date">
                {dateMaster.getDate('name')}
            </DataBlock>
            {!hideTimeBlock && (
                <TimeBlock onClick={() => setOpenTo('hours')} className="time">
                    {/*{dateMaster.time}*/}
                    {dateMaster.time}
                </TimeBlock>
            )}
        </WrapperDataTime>
    );
};

const CustomDatePicker: React.FC<Props> = ({date, placeholder, acceptHandler, minDate, maxDate, styles}) => {


    const [isOpen, setOpen] = useState<boolean>(false);
    const [openTo, setOpenTo] = useState<OpenTo>('date');

    const [resultDate, setResultDate] = useState('');
    const [dateInput,setDateInput] = useState(new Date());

    
    let openDate;


    // эта галиматья нужна для того, чтобы поменять местами день и месяц. Потому что DateTimePicker принимает аргументы именно в таком порядке.
    if (typeof date === 'string') {
        const day = date.substr(0, 2);
        const month = date.substr(3, 2);
        const tail = date.substr(5, date.length);
        openDate =  month + '-' +day+ "-" + tail;
    } else {
        console.log("date",date);
        let result = '';
        result +=
        (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) + '-';    
      result +=
                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
                '-';      
                 
        result += date.getFullYear() + ' ';
        openDate = result;
    }

    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
      console.log(date)
      setSelectedDate(date)
    setSelectedDate(date);
    setOpenTo(date)
  };

  useEffect(()=>{
    setSelectedDate(openDate)
  },[])

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>


<KeyboardDatePicker
         minDate={minDate ? minDate : new Date('1900-01-01')}
         maxDate={maxDate ? maxDate : new Date('3000-01-01')}
          allowKeyboardControl
           format="dd-MM-yyyy"
          value={openDate}
          onChange=
            {(date) => {
                if (date) {

                    setDateInput(date);

    
                    // в разных браузерах получение нужного формата времени ToLocaleString дает разный формат времени, поэтому делаю ее вручную
                    let result = '';


                  result +=
                            (date.getMonth() + 1 < 10
                                ? '0' + (date.getMonth() + 1)
                                : date.getMonth() + 1) + '-';    
                  result +=
                                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
                                '-';                                  
                    result += date.getFullYear() + ' ';


                     if (result) setResultDate(result);
                
                }
            }}

            onBlur={ ()=>{

                if (minDate && minDate.valueOf() > dateInput.valueOf()) {
                    CreateNotif(
                        'День рождения не может быть меньше минимальной даты'
                    );
                    return;
                }
                if (maxDate && maxDate.valueOf() < dateInput.valueOf()) {
                    CreateNotif(
                        'День рождения не может быть позже максимальной даты'
                    );
                    return;
                }
                console.log("resultDate",resultDate);
                if (resultDate && resultDate.slice(0,3)==="NaN") {
                    CreateNotif(
                        'Ошибка в дате. Введите, пожалуйста, правильную дату!',"warning"
                    );                
                } else {
                    if  (resultDate)   acceptHandler(resultDate);     
                }
                
            }                
            }

            onAccept={(date) => {
                if (date) {
                    if (minDate && minDate.valueOf() > date.valueOf()) {
                        CreateNotif(
                            'День рождения не может быть меньше минимальной даты'
                        );
                        return;
                    }
                    if (maxDate && maxDate.valueOf() < date.valueOf()) {
                        CreateNotif(
                            'День рождения не может быть позже максимальной даты'
                        );
                        return;
                    }

                    // в разных браузерах получение нужного формата времени ToLocaleString дает разный формат времени, поэтому делаю ее вручную
                    let result = '';



                   result +=
                            (date.getMonth() + 1 < 10
                                ? '0' + (date.getMonth() + 1)
                                : date.getMonth() + 1) + '-';    
                    result +=
                                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
                                '-';                         
                    result += date.getFullYear() + ' ';

                   if (result) acceptHandler(result);
                }
            }}
       
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}

        />
     
  

        </MuiPickersUtilsProvider>
    );
};

const mapStateToProps = (state: State) => {
    return {
        // stop: state.taskInfoPlate.repeat.stop
    };
};

const mapDispatchToProps = {
    updateTask,
    setTaskEnd,
    recalculateTaskLoad,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(CustomDatePicker);
