import { Dispatch, SetStateAction } from 'react';
import { SchedulerStatic } from 'dhtmlx-scheduler';
import moment from 'moment';
import { setResetCalendar, setTaskName } from '../TaskInfoPlate/actions';
import { createTask } from './utils';
import { setDateCalendarAction } from './actions';

type Colors = 'orange' | 'green' | 'blue' | 'yellow' | 'red';

const toMonthShort = (month: string) => {
  switch (month) {
    case 'Янв':
      return '01';
    case 'Фев':
      return '02';
    case 'Maр':
      return '03';
    case 'Aпр':
      return '04';
    case 'Maй':
      return '05';
    case 'Июн':
      return '06';
    case 'Июл':
      return '07';
    case 'Aвг':
      return '08';
    case 'Сен':
      return '09';
    case 'Окт':
      return '10';
    case 'Ноя':
      return '11';
    case 'Дек':
      return '12';
  }
};

export const initConfig = (
  scheduler: SchedulerStatic,
  setChangeDate: Dispatch<SetStateAction<number>>
) => {
  scheduler.clearAll();
  scheduler.skin = 'material';
  scheduler.xy.nav_height = 50;
  scheduler.config.details_on_create = true;
  scheduler.config.details_on_dblclick = true;
  scheduler.config.collision_limit = 1;

  scheduler.config.dblclick_create = false;

  scheduler.config.header = {
    rows: [
      {
        cols: [
          {
            view: 'day',
            click: () => setChangeDate((prev) => prev + 1),
          },
          {
            view: 'week',
            click: () => setChangeDate((prev) => prev + 1),
          },
          {
            view: 'month',
            click: () => setChangeDate((prev) => prev + 1),
          },
          'spacer',
          {
            view: 'prev',
            click: () => setChangeDate((prev) => prev + 1),
          },
          'date',
          {
            view: 'next',
            click: () => setChangeDate((prev) => prev + 1),
          },
          'spacer',
          {
            html: 'Календарь',
          },
          // {
          // 	html:"Повестка",
          // },
        ],
      },
    ],
  };
};
export const toSchedulerHeaderColor = (color: Colors) => {
  switch (color) {
    case 'orange':
      return '#FFB84D';
    case 'blue':
      return '#4EC8F0';
    case 'green':
      return '#7CBD7F';
    case 'red':
      return '#F87C73';
    case 'yellow':
      return '#FFE34D';
    default:
      return '#4EC8F0';
  }
};

const toSchedulerColor = (
  color: Colors,
  scheduler: SchedulerStatic,
  id: number
) => {
  switch (color) {
    case 'orange':
      scheduler.getEvent(id).color = '#FFB84D';
      break;
    case 'blue':
      scheduler.getEvent(id).color = '#4EC8F0';
      break;
    case 'green':
      scheduler.getEvent(id).color = '#7CBD7F';
      break;
    case 'red':
      scheduler.getEvent(id).color = '#F87C73';
      break;
    case 'yellow':
      scheduler.getEvent(id).color = '#FFE34D';
      break;
  }
};

export const initSchedulerEvents = (
  scheduler: SchedulerStatic,
  dispatch: any,
  setChangeDate: Dispatch<SetStateAction<number>>
) => {
  const logSchedulerEvent = (action, event, id) => {
  };

  scheduler.attachEvent('onEventAdded', (id, ev) => {
    const date = moment(ev.start_date).format('dddd');
    if (date === 'Saturday' || date === 'Sunday') {
      scheduler.deleteEvent(id);
    } else {
      if (ev.eventType === 'task') {
        createTask(dispatch, ev);
      }
      const event_id = scheduler.getState().lightbox_id;
      toSchedulerColor(ev.color, scheduler, id);
      logSchedulerEvent('create', ev, id);
      scheduler.deleteEvent(event_id);
      setTimeout(() => {
        setChangeDate((prev) => prev + 1);
      }, 0);
    }
  });

  scheduler.attachEvent('onEventChanged', (id, ev) => {
    toSchedulerColor(ev.color, scheduler, id);
    dispatch(setResetCalendar());
    logSchedulerEvent('update', ev, id);
  });

  scheduler.attachEvent('onEventDeleted', (id, ev) => {
    dispatch(setResetCalendar());
    logSchedulerEvent('delete', ev, id);
  });
};
