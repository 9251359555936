// Создание нового проекта

import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ProjectSections from "./components/ProjectSections";
import Files from "./components/Files";
import { setProjectAddMode } from "../Projects/actions";
import {
  clearProject,
  setProjectName,
  setProjectDescription,
  setProjectPriority,
  createProject,
  updateProject,
  deleteProject,
} from "./actions";
import NameWithInput from "../../common/components/newComponent/Inputs/NameWithInput";
import CuratorsSelect from "./components/CuratorsSelect";
import CpeSelect from "./components/CpeSelect";
// import PriorityToggler from '../../common/components/Togglers/PriorityToggler';
import { State } from "../../rootReducer";
import { getCanSend } from "./selectors";
import Button from "../../common/components/newComponent/Buttons/Button";
import ChiefSelect from "./components/ChiefSelect";
import LeaderSelect from "./components/LeaderSelect";
import Icon from "../../common/components/Icon";
import { User } from "../../common/types";
// import { deleteProject } from "../TaskInfoPlate/actions";
type Props = {
  name: string;
  description: string;
  priority_id: number;
  canSend: boolean;
  isSending: boolean;
  projectId: number;
  author_id: number;
  // cpe_list: [];
  currentUserInfo: null | User;
  setProjectAddMode: (mode: boolean) => void;
  setProjectName: (value: string) => void;
  setProjectDescription: (value: string) => void;
  setProjectPriority: (priority: number) => void;
  clearProject: () => void;
  createProject: () => void;
  updateProject: () => void;
  deleteProject: (id: number) => void;
};

const WrapperNewProject = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 1;
  flex-direction: column;
`;
const ModalBlockNewProject = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);
  margin-top: 20px 0;
  transition: transform 0.3s ease;
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(0vh)" : "translateY(-100vh)"};
  max-width: 584px;
  width: 100%;
  padding: 1.5rem 1rem;
  max-height: 100vh;
  overflow-y: auto;
`;

const NewProjectHeader = styled.div`
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #212121;
  }
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(0vh)" : "translateY(-100vh)"};
  & > div {
    flex: 1;
  }
`;

const ProjectAddPannel: React.FC<Props> = ({
  name,
  description,
  // priority_id,
  canSend,
  isSending,
  projectId,
  // cpe_list,
  author_id,
  currentUserInfo,
  setProjectAddMode,
  setProjectName,
  setProjectDescription,
  //setProjectPriority,
  clearProject,
  createProject,
  updateProject,
  deleteProject,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(setIsVisible, 0, true);
  }, [setIsVisible]);

  const closeHandler = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
      setProjectAddMode(false);
      clearProject();
    }, 300);
  }, [clearProject, setProjectAddMode]);

  const saveHandler = useCallback(() => {
    if (projectId) {
      // Обновление проекта
      updateProject();
      // updateProject();  дополнительный запрос данных с сервера (первый раз сервер не присылает информацию о добавленных файлах)
    } else {
      createProject();
    }
  }, [createProject, projectId, updateProject]);

  return (
    <WrapperNewProject>
      <ModalBlockNewProject isVisible={isVisible}>
        <NewProjectHeader>
          {/* Выводим предупреждение о важных полях, помеченных звездочкой- их надо заполнить, иначе не получится сохранить проект */}
          <span>
            {projectId
              ? "Редактирование (* - обязательные для заполнения поля) "
              : "Новый проект (* - обязательные для заполнения поля)"}
          </span>
          {/* <PriorityToggler
            priority={priority_id}
            selectHandler={setProjectPriority}
          /> */}
        </NewProjectHeader>
        <NameWithInput
          title="Проект"
          type="textarea"
          inputValue={name}
          inputChangeHandler={setProjectName}
          necessarily
        />

        <NameWithInput
          title="Описание"
          type="textarea"
          inputValue={description}
          inputChangeHandler={setProjectDescription}
          // necessarily
        />

        {/* Надо вывести предупреждение если нет пользователей в роли кураторов*/}
        <NameWithInput
          title="Куратор"
          iconName="people"
          inputName={"CuratorsSelect"}
          necessarily
        >
          <CuratorsSelect />
        </NameWithInput>
        {/* Надо вывести предупреждение если нет пользователей в роли руководителей проектов*/}
        <NameWithInput
          title="Рук. проекта"
          iconName="people"
          inputName={"CpeSelect"}
          necessarily
        >
          <CpeSelect />
        </NameWithInput>

        <NameWithInput
          title="Нач. отдела"
          iconName="people"
          inputName={"ChiefSelect"}
        >
          <ChiefSelect />
        </NameWithInput>

        <NameWithInput
          title="Рук. группы"
          iconName="people"
          inputName={"LeaderSelect"}
        >
          <LeaderSelect />
        </NameWithInput>

        <NameWithInput title="Договор" iconName="clip">
          <Files />
        </NameWithInput>

        <NameWithInput title="Раздел " iconName="folder">
          <ProjectSections />
        </NameWithInput>

        {/* <NameWithInput title="Шаблон" /> */}

        <WrapperButtons isVisible={isVisible}>
          {/* <div>
            {!!projectId &&
              currentUserInfo &&
              currentUserInfo.id &&
              (currentUserInfo.id === author_id ||
                currentUserInfo?.roles.includes(1) ||
                currentUserInfo?.roles.includes(2)) && (
                <Icon
                  title="Удалить проект"
                  name="delete"
                  width="24px"
                  height="24px"
                  hoverColor="blue"
                  onClick={() => {
                    deleteProject(projectId);
                  }}
                />
              )}
          </div> */}

          <Button
            onClick={closeHandler}
            design="secondary"
            title="Отмена"
            style={{ marginRight: ".5rem" }}
          />
          <Button
            onClick={saveHandler}
            title={"Сохранить"}
            disabled={!canSend}
            isSending={isSending}
          />
        </WrapperButtons>
      </ModalBlockNewProject>
    </WrapperNewProject>
  );
};

const mapStateToProps = (state: State) => {
  return {
    projectId: state.newProject.id,
    name: state.newProject.name,
    description: state.newProject.description,
    priority_id: state.newProject.priority_id,
    author_id: state.newProject.author_id,
    canSend: getCanSend(state),
    isSending: state.newProject.isSending,

    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setProjectAddMode,
  setProjectName,
  setProjectDescription,
  clearProject,
  setProjectPriority,
  createProject,
  updateProject,
  deleteProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddPannel);
