// Создание БП
import React, { useContext, useEffect, useRef, useState } from "react";
import { StatusContext } from "../../context/status";
import axios from "axios";
import { CreateNotif } from "../../../../../utils/createNotification";
import { cookieMaster } from "../../../../../utils/CookieMaster";
import CloseIcon from "@material-ui/icons/Close";
import "./CreateBp.scss";

let templates;

const CreateBp = () => {
  const {
    company_id,
    bpListChange,
    setBpListChange,
    apiBp,
    bearer,
    setCreateBpStatus,
    createBpForm,
    setCreateBpForm,
    setCreateTaskStatus,
    createTaskForm,
    setCreateTaskForm,
    createBpStatus,
    createBpSampleStatus,
    setCreateBpSampleStatus,
    createBpSampleForm,
    setCreateBpSampleForm,
    createBpSampleFormDate,
    createBpSampleFormOptions,
    setCreateTaskSampleFormStatus,
    idSample,
    setIdSample,
    sampleArr,
    setSampleArr,
    setStatusCreateTask,
    tasksArr,
    setTasksArr,
    setNowBp,
    userId,
    users,
    idSample1,
    idSample2,
    idSample3,
    Mount,
    projects,
    projectSections,
    setProjectSections,
    loadTemplateBp,
    setLoadTemplateBp,
  } = useContext(StatusContext);

  let begin2 = new Date(Date.now());
  let end2 = new Date(Date.now() + 10 * 24 * 60 * 60 * 1000);
  let monthBegin2 =
    begin2.getMonth() + 1 < 10
      ? "0" + (begin2.getMonth() + 1)
      : begin2.getMonth() + 1;
  let dayBegin2 =
    begin2.getDate() < 10 ? "0" + begin2.getDate() : begin2.getDate();
  let monthEnd2 =
    end2.getMonth() + 1 < 10
      ? "0" + (end2.getMonth() + 1)
      : end2.getMonth() + 1;
  let dayEnd2 = end2.getDate() < 10 ? "0" + end2.getDate() : end2.getDate();

  let dateBegin2 = begin2.getFullYear() + "-" + monthBegin2 + "-" + dayBegin2;

  let deadline2 = end2.getFullYear() + "-" + monthEnd2 + "-" + dayEnd2;

  //console.log("sampleArr",sampleArr)

  const [accessNext, setAccessNext] = useState("blue-btn blue-btn__disabled");

  const [file, setFile] = useState({});

  const [depId, setDepId] = useState();
  const [headOfDep, setHeadOfDep] = useState();
  const [nameOfTemplate, setNameOfTemplate] = useState();

  const closeRef = useRef(null);
  const templateNameRef = useRef(null);

  const submitFile = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    axios
      .post(`${apiBp}/loadFile`, formData, {
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": document.cookie.replace(
            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
          ),
        },
      })

      .then((res) => {
        console.log(res.data);
        setCreateBpForm({ ...createBpForm, file_id: res.data.id });
        setCreateBpSampleForm({
          ...createBpSampleForm,
          businessProcess: {
            ...createBpSampleForm.businessProcess,
            file_id: res.data.id,
          },
        });
        setFile({ name: res.data.original_name });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const nextCreateTasks = () => {
    if (
      createBpForm.name !== null &&
      createBpForm.project_id !== null &&
      createBpForm.project_section_id !== null
    ) {
      setCreateBpStatus(false);
      setCreateTaskStatus(true);
    }
  };

  useEffect(() => {
    if (
      createBpForm.name !== null &&
      createBpForm.project_id !== null &&
      createBpForm.project_section_id !== null
    ) {
      setAccessNext("blue-btn");
    } else {
      setAccessNext("blue-btn blue-btn__disabled");
    }
  }, [createBpForm]);

  // useEffect(() => {
  //   if (
  //     templateNameRef &&
  //     templateNameRef.current &&
  //     templateNameRef.current.value
  //   ) {
  //     if (+templateNameRef.current.value === 1) {
  //       console.log(111);
  //       setNameOfTemplate('Типовой бизнес-процесс: "Договор"');
  //       setCreateBpSampleForm({
  //         ...createBpSampleForm,
  //         businessProcess: {
  //           ...createBpSampleForm.businessProcess,
  //           name: 'Типовой бизнес-процесс: "Договор"',
  //         },
  //       });
  //     } else if (+templateNameRef.current.value === 2) {
  //       console.log(222);
  //       setNameOfTemplate('Типовой бизнес-процесс: "Прием на работу"');
  //       setCreateBpSampleForm({
  //         ...createBpSampleForm,
  //         businessProcess: {
  //           ...createBpSampleForm.businessProcess,
  //           name: 'Типовой бизнес-процесс: "Прием на работу"',
  //         },
  //       });
  //     } else if (+templateNameRef.current.value === 3) {
  //       console.log(333);

  //       setNameOfTemplate('Типовой бизнес-процесс: "Увольнение"');
  //       setCreateBpSampleForm({
  //         ...createBpSampleForm,
  //         businessProcess: {
  //           ...createBpSampleForm.businessProcess,
  //           name: 'Типовой бизнес-процесс: "Увольнение"',
  //         },
  //       });
  //     }
  //   }
  // }, [templateNameRef, templateNameRef?.current?.value]);
  // console.log(nameOfTemplate, +templateNameRef?.current?.value === 1);

  //
  //
  // useEffect(() => {
  //   if (!!nextLinkProjects) {
  //     axios
  //       .get(nextLinkProjects, {
  //         headers: {
  //           Authorization: bearer,
  //           "company-id": company_id,
  //         },
  //       })
  //       .then((res) => {
  //         setProjects([...projects, ...res.data.data]);
  //         setNextLinkProjects(res.data.links.next);
  //       });
  //   }
  // }, [nextLinkProjects]);

  const nextBpSample = () => {
    if (!createBpSampleStatus) {
      nextCreateTasks();
    } else {
      setCreateBpStatus(false);
      setCreateBpSampleStatus(false);
      setCreateTaskStatus(true);
      setCreateTaskSampleFormStatus(true);
      setStatusCreateTask(true);
    }
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}/users/${userId}`, {
        headers: {
          Authorization: bearer,
          "company-id": company_id,
        },
      })
      .then((res) => {
        // setUsers([...users, ...res.data.data]);
        setDepId(res.data.data.departments);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (depId) {
      axios
        .get(`${process.env.REACT_APP_PUBLIC_API}/departments/${depId}`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            // Информация берется из Cookies, вместо localStorage
            "secret-token": document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
            Accept: "application/json",
            "Content-Type": "application/json",
            // "company-id": company_id,
          },
        })
        .then((res) => {
          // console.log("res", res);
          let result;

          if (res && res.data && res.data.data) {
            result = res.data.data.head_id;
          }

          // console.log("result", result);

          if (result) {
            setHeadOfDep(result);
            // console.log("result", result.head_id);
          } else {
            setHeadOfDep(userId);
          }
        })
        .catch((e) => {
          setHeadOfDep(userId);
          //  console.log("ошибка сравнения ", e);
        });
    }
  }, [depId]);

  // функция поиска исполнителя по наименованию должности. второй параметр не обязателен, третий параметр - нужно без этого параметра
  const findExecutor = (
    valueForSearch,
    secondValue = null,
    withoutValue = null
  ) => {
    if (!withoutValue) {
      const executor = users.find((user) => {
        let userNameArr = user.name.toLowerCase().split(" ");
        if (
          userNameArr.includes(valueForSearch.toLowerCase()) ||
          (secondValue &&
            userNameArr.includes(
              `${valueForSearch.toLowerCase()} ${secondValue.toLowerCase()} `
            ))
        ) {
          return user;
        }
      });
      // console.log(executor);
      return executor.id;
    } else {
      const executor = users.filter((user) => {
        let userNameArr = user.name.toLowerCase().split(" ");

        // console.log(userNameArr);
        if (
          userNameArr.includes(valueForSearch.toLowerCase()) &&
          userNameArr.includes(secondValue.toLowerCase()) &&
          !userNameArr.includes(withoutValue.toLowerCase())
        ) {
          return true;
        }
      });
      console.log(executor);
      return executor[0].id;
    }
  };

  /***  Создаем задачи для бизнес-процесса   */
  const saveBpSample2 = () => {
    let begin = new Date(Date.now());
    let deadline = new Date(Date.now() + 10 * 24 * 60 * 60 * 1000);
    //***************** */

    // console.log("createBpSampleForm", createBpSampleForm);

    if (
      createBpSampleForm.businessProcess.name &&
      createBpSampleForm.businessProcess.project_id &&
      createBpForm.project_section_id
    ) {
      // Если выбрано создание задачи по типовому шаблону "Договор"
      //************************************************************************* */
      // if (createBpSampleForm.type == idSample1) {
      //   console.log("createBpSampleForm", createBpSampleForm);

      //   // Информация о типовой задаче (включает информацию о шаблоне договора)

      //   templates = JSON.stringify({
      //     company_id: company_id,
      //     name: createBpSampleForm.businessProcess.name
      //       ? createBpSampleForm.businessProcess.name
      //       : null,
      //     initiator_id: createBpSampleForm.businessProcess.initiator_id
      //       ? createBpSampleForm.businessProcess.initiator_id
      //       : 1,
      //     project_id: createBpSampleForm.businessProcess.project_id
      //       ? createBpSampleForm.businessProcess.project_id
      //       : null,
      //     project_section_id: createBpSampleForm.businessProcess
      //       .project_section_id
      //       ? createBpSampleForm.businessProcess.project_section_id
      //       : null,
      //     deadline: new Date(Date.now() + 131.5 * 60 * 60 * 1000),
      //     template: {
      //       name: "treaty",
      //       data: {
      //         name: "Договор",
      //         tasks: {
      //           items: {
      //             init: {
      //               //нумерация полей соответствует документу "Виды_полей_по_бизнес-процесса_Договора_от_10_06_22"
      //               name: "Инициация договора",
      //               description: "Сбор данных договора",
      //               executor_id: userId,
      //               extra: {
      //                 a1: null,
      //                 a1_1: null,
      //                 a1_2: null,
      //                 a1_2_1: null,
      //                 a1_3: null,
      //                 a1_4: null,
      //                 a1_5: null,
      //                 a1_6: null,
      //                 a1_7: null,
      //                 a1_8: null,
      //                 a1_8_1: null,
      //                 a1_9: null,
      //                 a1_9_1: null,
      //                 a1_10: null,
      //                 a1_11: null,
      //                 a1_12: null,
      //                 a1_12_1: null,
      //                 a1_13: null,
      //                 a1_14: null,
      //                 a1_15: null,
      //                 a1_16: null,
      //                 a1_17: null,
      //                 a1_18: null,
      //                 a1_18_1: null,
      //                 a1_18_2: null,
      //                 a1_18_3: null,
      //                 a1_19: null,
      //                 a1_19_1: null,
      //                 a1_20: null,
      //                 a1_21: null,
      //                 a1_22: null,
      //                 a1_23: null,
      //               },
      //               begin: begin,
      //               end: new Date(Date.now() + 1 * 60 * 60 * 1000),
      //             },
      //             checkOfHeadOfDepartment: {
      //               name: "Проверка руководителем подразделения",
      //               description: "Проверка актуальности",
      //               executor_id: headOfDep,
      //               extra: {
      //                 b2: null,
      //                 b2_1: null,
      //                 b2_2: null,
      //                 b2_2_1: null,
      //                 b2_3: null,
      //                 b2_3_1: null,
      //               },
      //               begin: new Date(Date.now() + 1 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 10 * 60 * 60 * 1000),
      //             },
      //             checkOfJurist: {
      //               name: "Проверка юристом",
      //               description:
      //                 "Нужно проверить юридическую валидность договора",
      //               executor_id: findExecutor("Юрист"),
      //               extra: {
      //                 c3: null, //файл спарк
      //                 c31_1: null, //не применимо
      //                 c31_2: null, //Текстовое поле если не применимо
      //                 c3_1_1: null, //Выбор нескольких вариантов из списка
      //                 c3_1_2: null, //текстовое поле
      //                 c3_2: null, //да/ (нет Договор возвращается на инициатора.)
      //                 c3_2_1: null, // yes прикрепить файл
      //                 c3_3: null, //да/нет
      //                 c3_3_1: null, // yes текст
      //                 c3_3_2: null, // yes файл
      //                 c3_4: null, //Имеются/Отсутствуют
      //                 c3_4_1: null, //если имеются
      //               },
      //               begin: new Date(Date.now() + 10 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 19 * 60 * 60 * 1000),
      //             },
      //             checkOfChiefAccountant: {
      //               name: "Проверка главным бухгалтером",
      //               description: "Проверка главным бухгалтером",
      //               executor_id: findExecutor("Главный", "бухгалтер"),
      //               extra: {
      //                 d4: null,
      //                 d4_1: null,
      //                 d4_2: null,
      //                 d4_3: null,
      //                 d4_4: null,
      //                 d4_5: null,
      //                 d4_6: null,
      //                 d4_7: null,
      //                 d4_8: null,
      //                 d4_9: null,
      //                 d4_10: null,
      //                 d4_11: null,
      //                 d4_12: null,
      //                 d4_13: null,
      //                 d4_14: null,
      //               },
      //               begin: new Date(Date.now() + 19 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 28 * 60 * 60 * 1000),
      //             },
      //             checkOfExecutiveDirector: {
      //               name: "Проверка исполнительным директором",
      //               description: "Проверка исполнительным директором",
      //               executor_id: findExecutor("Исполнительный", "директор"),
      //               extra: {
      //                 e5: null,
      //                 e5_1: null,
      //                 e5_2: null,
      //                 e5_3: null,
      //                 e5_4: null,
      //                 e5_5: null,
      //                 e5_5_1: null,
      //               },
      //               begin: new Date(Date.now() + 28 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 37 * 60 * 60 * 1000),
      //             },
      //             checkOfFinanceDirector: {
      //               name: "Проверка финансовым директором",
      //               description: "Проверка финансовым директором",
      //               executor_id: findExecutor("Финансовый", "директор"),
      //               extra: {
      //                 f6: null,
      //                 f6_1: null,
      //                 f6_2: null,
      //                 f6_3: null,
      //               },
      //               begin: new Date(Date.now() + 37 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 46 * 60 * 60 * 1000),
      //             },
      //             checkFinalOfJurist: {
      //               name: "Финальная проверка юристом",
      //               description: "Финальная проверка юристом",
      //               executor_id: findExecutor("Юрист"),
      //               extra: {
      //                 g7_1_1: null,
      //                 g7_1_2: null,
      //                 g7_1_3: null,
      //                 g7_2_1: null,
      //                 g7_2_2: null,
      //                 g7_2_3: null,
      //               },
      //               begin: new Date(Date.now() + 46 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 55 * 60 * 60 * 1000),
      //             },
      //             signingOfTreaty: {
      //               name: "Подписание договора",
      //               description: "Подписание договора",
      //               executor_id: findExecutor("Секретарь"),
      //               extra: {
      //                 h8: null,
      //                 h8_1: null,
      //                 h8_1_1: null,
      //                 h8_1_2: null,
      //                 h8_2: null,
      //                 h8_2_1: null,
      //                 h8_3: null,
      //                 h8_4: null,
      //                 h8_5: null,
      //                 h8_5_1: null,
      //                 h8_6: null,
      //                 h8_6_1: null,
      //                 h8_6_2: null,
      //                 h8_7: null,
      //                 h8_7_1: null,
      //                 h8_8: null,
      //                 h8_8_1: null,
      //                 h8_9: null,
      //                 h8_9_1: null,
      //               },
      //               begin: new Date(Date.now() + 55 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 118 * 24 * 60 * 60 * 1000),
      //             },
      //             enteringDataInto1CUT: {
      //               name: "Внесение данных в 1С УТ",
      //               description: "Внесение данных в 1С УТ",
      //               executor_id: findExecutor("Программист", "1С", "Старший"),
      //               extra: {
      //                 i9: null,
      //                 i9_1: null,
      //               },
      //               begin: new Date(Date.now() + 118 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 122.5 * 60 * 60 * 1000),
      //             },
      //             finalRegistrationByJurist: {
      //               name: "Финальная регистрация юристом",
      //               description: "Финальная регистрация юристом",
      //               executor_id: findExecutor("Юрист"),
      //               extra: {
      //                 j10: null,
      //                 j10_1: null,
      //               },
      //               begin: new Date(Date.now() + 122.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 127 * 60 * 60 * 1000),
      //             },
      //             finalFinalStage: {
      //               name: "Внесение сведений в реестр договоров, сканирование, сохранение на диске",
      //               description:
      //                 "Внесение сведений в реестр договоров, сканирование, сохранение на диске",
      //               executor_id: findExecutor("Руководитель", "Юр"),
      //               extra: {
      //                 k11: null,
      //                 k11__1: null,
      //               },
      //               begin: new Date(Date.now() + 127 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 131.5 * 60 * 60 * 1000),
      //             },
      //           },
      //         },
      //       },
      //     },
      //   });

      //   let config = {
      //     method: "post",
      //     url: `${apiBp}/bp-template-options`,
      //     headers: {
      //       Authorization: bearer,
      //       "company-id": company_id,
      //       "secret-token": localStorage.getItem("access_token_jwt"),
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //     },
      //     data: templates,
      //   };

      //   axios(config)
      //     .then((res) => {
      //       // Скрываем форму и очищаем данные
      //       setCreateTaskSampleFormStatus(false);
      //       setCreateBpStatus(false);
      //       setCreateBpSampleStatus(false);
      //       setNowBp({});
      //       setTasksArr([]);
      //       setCreateBpSampleForm({
      //         type: 0,
      //         businessProcess: {
      //           name: null,
      //           deadline: null,
      //           project_id: null,
      //           tasks: 1,
      //           initiator_id: document.cookie.replace(
      //             /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
      //             "$1"
      //           ),
      //         },
      //         options: [],
      //       });

      //       setBpListChange((prev) => !prev);

      //       CreateNotif(
      //         `Бизнес-процесс "${createBpSampleForm.businessProcess.name}" создан по шаблону договора`,
      //         "success"
      //       );

      //       console.log("Бизнес-процесс: ", res.data);
      //     })
      //     .catch((err) => {
      //       CreateNotif(
      //         "Ошибка в посылке данных бизнес-процесса на сервер",
      //         "error"
      //       );
      //       console.log("Ошибка при создании бизнес-процесса: ", err);
      //     });

      //   //************************************************************************* */

      //   // Если выбрано создание задачи по типовому шаблону "Прием на работу"
      //   //************************************************************************* */
      // } else if (createBpSampleForm.type === idSample2) {
      //   console.log("createBpSampleForm", createBpSampleForm);

      //   // Информация о типовой задаче (включает информацию о шаблоне "Прием на работу")

      //   templates = JSON.stringify({
      //     company_id: company_id,
      //     name: createBpSampleForm.businessProcess.name
      //       ? createBpSampleForm.businessProcess.name
      //       : null,
      //     initiator_id: createBpSampleForm.businessProcess.initiator_id
      //       ? createBpSampleForm.businessProcess.initiator_id
      //       : 1,
      //     project_id: createBpSampleForm.businessProcess.project_id
      //       ? createBpSampleForm.businessProcess.project_id
      //       : null,
      //     project_section_id: createBpSampleForm.businessProcess
      //       .project_section_id
      //       ? createBpSampleForm.businessProcess.project_section_id
      //       : null,
      //     deadline: new Date(Date.now() + 725.5 * 60 * 60 * 1000),
      //     template: {
      //       name: "work",
      //       data: {
      //         name: "Прием на работу",
      //         tasks: {
      //           items: {
      //             initial: {
      //               //нумерация полей соответствует документу "Виды_полей_по_бизнес-процесса_Договора_от_10_06_22"
      //               name: "Инициирование",
      //               description: "Сбор данных сотрудника",
      //               executor_id: headOfDep,
      //               extra: {
      //                 a1: null,
      //                 a1_1: null,
      //                 a1_2: null,
      //                 a1_3: null,
      //                 a1_4: null,
      //                 a1_5: null,
      //                 a1_6: null,
      //                 a1_7: null,
      //                 a1_7_1: null,
      //                 a1_8: null,
      //               },
      //               begin: begin,
      //               end: new Date(Date.now() + 1 * 60 * 60 * 1000),
      //             },
      //             checkSecurity: {
      //               name: "Проверка кандидата СБ",
      //               description: "Формирование отчета",
      //               executor_id: findExecutor("Исполнительный", "директор"),
      //               extra: {
      //                 b2: null, // прикрепление файла
      //                 b2_1: null, // Выпадающий список Отчет положительный Отчет отрицательный
      //                 b2_2: null,
      //               },
      //               begin: new Date(Date.now() + 1 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 19 * 60 * 60 * 1000),
      //             },
      //             acceptByHeadOfDep: {
      //               name: "Подтверждение/не подтверждение о приеме сотрудника Руководителем",
      //               description:
      //                 "Принятие решения о приеме сотрудника на работу",
      //               executor_id: findExecutor("Генеральный", "менеджер"),
      //               extra: {
      //                 c3: null, //Выпадающий список Подтверждаю Не подтверждаю
      //                 c3_1: null, //Выпадающий список Принимаю Не согласен
      //               },
      //               begin: new Date(Date.now() + 19 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 23.5 * 60 * 60 * 1000),
      //             },
      //             agreementByDeputyGenManager: {
      //               name: "Согласование ЗГМ",
      //               description: "Согласование Зам.Генерального Менеджера",
      //               executor_id: findExecutor("Генерального"),
      //               extra: {
      //                 d4: null, //Выпадающий список Принимаю Не согласен
      //                 d4_1: null, //Автозаполнение Если ЗГМ кликает «принимаю», то организация назначается из исходных данных по БП Договора Инициирование. Формат взаимоотношений, и задача переходит на следующий этап.
      //                 d4_1_1: null, //текстовое поле без ограничения символов
      //                 d4_2: null, //текстовое поле без ограничения символов
      //               },
      //               begin: new Date(Date.now() + 23.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 41.5 * 60 * 60 * 1000),
      //             },
      //             forkedStage_SMZ_IP: {
      //               //Задача попадает на Бухгалтера по зп и кадрам, если на этапе 4 выбрано и подтверждено со стороны ЗГМ, что формат взаимоотношений СМЗ/ИП/вне штата.
      //               name: "Этап согласования при приёме по СМЗ/ИП/вне штата",
      //               description:
      //                 "Этап согласования при приёме по СМЗ/ИП/вне штата",
      //               executor_id: findExecutor("Главный", "бухгалтер"),
      //               extra: {
      //                 e5: null, //Выпадающий список Подтверждаю Не подтверждаю
      //                 e5_1: null, // Да Нет
      //                 e5_2: null, // Да Нет
      //                 e5_3: null, // Выпадающий список СМЗ ИП Трудоустроенный сотрудник
      //                 e5_4: null, // Да Нет
      //                 e5_5: null, // Да Нет
      //                 e5_6: null, // Да Нет
      //               },
      //               begin: new Date(Date.now() + 41.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 46 * 60 * 60 * 1000),
      //             },
      //             forkedStage_GPH_TD: {
      //               // Если на предыдущих этапах подтверждено, что ГПХ-ТД, то задача уходит на Бух зп и кадры
      //               name: "Этап согласования при приёме по ГПХ-ТД",
      //               description: "Этап согласования при приёме по ГПХ-ТД",
      //               executor_id: findExecutor("Главный", "бухгалтер"),
      //               extra: {
      //                 f6: null,
      //                 f6_1: null,
      //                 f6_2: null,
      //               },
      //               begin: new Date(Date.now() + 46 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 50.5 * 60 * 60 * 1000),
      //             },
      //             signing: {
      //               name: "Подписание и прикрепление договора",
      //               description: "Подписание и прикрепление договора",
      //               executor_id: userId,
      //               extra: {
      //                 g7: null, // Прикрепление файла
      //                 g7_1: null,
      //               },
      //               begin: new Date(Date.now() + 50.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 68.5 * 60 * 60 * 1000),
      //             },
      //             completion_3monthTimer: {
      //               name: "Завершение «таймера на 3 месяца»",
      //               description: "Завершение «таймера на 3 месяца»",
      //               executor_id: userId,
      //               extra: {
      //                 h8: null, // Прикрепление файла
      //                 h8_1: null, // Прикрепление файла
      //               },
      //               begin: new Date(Date.now() + 68.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 635.5 * 60 * 60 * 1000),
      //             },
      //             acceptByDeputyGenManager: {
      //               name: "Подтверждение Зам. Генерального Менеджера",
      //               description: "Подтверждение Зам. Генерального Менеджера",
      //               executor_id: findExecutor("Генерального"),
      //               extra: {
      //                 i9: null,
      //                 i9_1: null,
      //               },
      //               begin: new Date(Date.now() + 635.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 698.5 * 60 * 60 * 1000),
      //             },
      //             employeeRegistration: {
      //               name: "Оформление сотрудника",
      //               description: "Оформление сотрудника",
      //               executor_id: findExecutor("Главный", "бухгалтер"),
      //               extra: {
      //                 j10: null,
      //                 j10_1: null,
      //               },
      //               begin: new Date(Date.now() + 698.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 716.5 * 60 * 60 * 1000),
      //             },
      //             processTerminating: {
      //               name: "Завершение процесса",
      //               description: "Завершение процесса",
      //               executor_id: userId,
      //               extra: {
      //                 k11: null,
      //                 k11_1: null,
      //               },
      //               begin: new Date(Date.now() + 716.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 725.5 * 60 * 60 * 1000),
      //             },
      //           },
      //         },
      //       },
      //     },
      //   });

      //   let config = {
      //     method: "post",
      //     url: `${apiBp}/bp-template-options`,
      //     headers: {
      //       Authorization: bearer,
      //       "company-id": company_id,
      //       "secret-token": localStorage.getItem("access_token_jwt"),
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //     },
      //     data: templates,
      //   };

      //   axios(config)
      //     .then((res) => {
      //       // Скрываем форму и очищаем данные
      //       setCreateTaskSampleFormStatus(false);
      //       setCreateBpStatus(false);
      //       setCreateBpSampleStatus(false);
      //       setNowBp({});
      //       setTasksArr([]);
      //       setCreateBpSampleForm({
      //         type: 0,
      //         businessProcess: {
      //           name: null,
      //           deadline: null,
      //           project_id: null,
      //           tasks: 1,
      //           initiator_id: document.cookie.replace(
      //             /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
      //             "$1"
      //           ),
      //         },
      //         options: [],
      //       });

      //       setBpListChange((prev) => !prev);

      //       CreateNotif(
      //         `Бизнес-процесс "${createBpSampleForm.businessProcess.name}" создан по шаблону Прием на работу`,
      //         "success"
      //       );

      //       console.log("Бизнес-процесс: ", res.data);
      //     })
      //     .catch((err) => {
      //       CreateNotif(
      //         "Ошибка в посылке данных бизнес-процесса на сервер",
      //         "error"
      //       );
      //       console.log("Ошибка при создании бизнес-процесса: ", err);
      //     });
      //   //************************************************************************* */

      //   //  Если выбрано создание задачи по типовому шаблону "Увольнение"
      //   //************************************************************************* */
      // } else if (createBpSampleForm.type == idSample3) {
      //   console.log("createBpSampleForm", createBpSampleForm);

      //   // Информация о типовой задаче (включает информацию о шаблоне договора)

      //   templates = JSON.stringify({
      //     company_id: company_id,
      //     name: createBpSampleForm.businessProcess.name
      //       ? createBpSampleForm.businessProcess.name
      //       : null,
      //     initiator_id: createBpSampleForm.businessProcess.initiator_id
      //       ? createBpSampleForm.businessProcess.initiator_id
      //       : 1,
      //     project_id: createBpSampleForm.businessProcess.project_id
      //       ? createBpSampleForm.businessProcess.project_id
      //       : null,
      //     project_section_id: createBpSampleForm.businessProcess
      //       .project_section_id
      //       ? createBpSampleForm.businessProcess.project_section_id
      //       : null,
      //     deadline: new Date(Date.now() + 46 * 60 * 60 * 1000),
      //     template: {
      //       name: "dismissal",
      //       data: {
      //         name: "Увольнение",
      //         tasks: {
      //           items: {
      //             initDismissal: {
      //               //нумерация полей соответствует документу "Виды_полей_по_бизнес-процесса_Договора_от_10_06_22"
      //               name: "Инициирование увольнения",
      //               description: "Сбор данных для увольнения",
      //               executor_id: headOfDep,
      //               extra: {
      //                 a1: null, //Инициирование увольнения выпадающий список     (Сотрудник)
      //                 a1_1: null, //Текстовое поле без ограничения числа символов  (Работодатель)
      //                 a1_2: null, //Выпадающий список    (Формат взаимоотношений)
      //                 a1_3: null, //Выпадающий список    (Основание для увольнения)
      //                 a1_4: null, //Прикрепление файлов  (Заявление на увольнение)
      //                 a1_5: null, //друж / не друж       (Соглашение сторон )
      //                 a1_6: null, //друж / не друж       (Сокращение)
      //                 a1_7: null, //Прикрепление файлов  (Статья)
      //                 a1_8: null, //Прикрепление файлов  (Соглашение о расторжении договора ГПХ)
      //                 a1_9: null, //Прикрепление файлов  (Соглашение о расторжении ДОУ)
      //                 a1_10: null, //Прикрепление файлов (Внутренний обходной лист, подписанный..)
      //                 a1_11: null, //Прикрепление файлов (дата последнего рабочего дня)
      //                 a1_12: null, //Прикрепление файлов (время последнего рабочего дня)
      //                 a1_13: null, //Текстовое поле без ограничения числа символов  (Комментарии)
      //               },
      //               begin: begin,
      //               end: new Date(Date.now() + 1 * 60 * 60 * 1000),
      //             },

      //             initialProcessingOfDocuments: {
      //               name: "Первичное оформление документов",
      //               description: "Первичное оформление документов",
      //               executor_id: findExecutor("Главный", "бухгалтер"),
      //               extra: {
      //                 b2: null, // Заявление на увольнение    (Основание для увольнения)
      //                 b2_1: null, // Кнопка                   (Подтверждение ознакомления)
      //                 b2_2: null, // Прикрепление файлов      (Подписание)
      //                 b2_3: null, // Прикрепление файлов
      //                 b2_4: null, // Прикрепление файлов
      //                 b2_5: null, // Текстовое поле           (Комментарий)
      //                 b2_6: null, // timer                    (Установка таймера)
      //               },
      //               begin: new Date(Date.now() + 1 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 5.5 * 60 * 60 * 1000),
      //             },
      //             signingOfTreatyDismissal: {
      //               name: "Подписание документов",
      //               description: "Подписание документов",
      //               executor_id: findExecutor("Главный", "бухгалтер"),
      //               extra: {
      //                 c3: null, // Прикрепление файлов
      //                 c3_1: null, // Текстовое поле     (Номер задачи)
      //                 c3_2: null, // соглас . не соглас (Согласование)
      //               },
      //               begin: new Date(Date.now() + 5.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 14.5 * 60 * 60 * 1000),
      //             },
      //             confirmationOfPaymentOfAccountingCalculation: {
      //               name: "Подтверждение оплаты бухгалтерского расчета",
      //               description: "Подтверждение оплаты бухгалтерского расчета",
      //               executor_id: findExecutor(
      //                 "специалист",
      //                 "казначейства",
      //                 "старший"
      //               ),
      //               extra: {
      //                 d4: null, // Кнопка (Подтверждение выплаты)
      //               },
      //               begin: new Date(Date.now() + 14.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 19 * 60 * 60 * 1000),
      //             },
      //             employeeAccountActions: {
      //               name: "Действия с учетной записью сотрудника ",
      //               description: "Действия с учетной записью сотрудника ",
      //               executor_id: findExecutor("Исполнительный", "директор"),
      //               extra: {
      //                 e5_1: null, // Выпадающий список + текстовое поле (Действие)
      //                 e5_1_1: null, //
      //                 e5_2: null, // Кнопка (Выполнено)
      //               },
      //               begin: new Date(Date.now() + 19 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 23.5 * 60 * 60 * 1000),
      //             },
      //             preparationAndApprovalFinalPaymentForUT: {
      //               name: "Подготовка и согласование итоговой выплаты по УТ",
      //               description:
      //                 "Подготовка и согласование итоговой выплаты по УТ",
      //               executor_id: findExecutor("экономист", "расчетам"),
      //               extra: {
      //                 f6: null, // Кнопка   (расчет подготовлен)
      //                 f6_1: null, // Булево (согл / не согл)
      //                 f6_2: null, // Булево (согл / не согл)
      //                 f6_3: null, // input number (Число)
      //                 f6_4: null, // Текстовое поле (Номер заявки в 1С)
      //                 f6_5: null, // Булево (согл / не согл)
      //               },
      //               begin: new Date(Date.now() + 23.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 32.5 * 60 * 60 * 1000),
      //             },
      //             finalSettlementWithEmployee: {
      //               name: "Итоговый расчет с сотрудником",
      //               description: "Итоговый расчет с сотрудником",
      //               executor_id: findExecutor(
      //                 "внутреннего",
      //                 "контроля",
      //                 "руководитель"
      //               ),
      //               extra: {
      //                 g7: null, // input number (число)
      //                 g7_1: null, // Прикрепление файлов
      //               },
      //               begin: new Date(Date.now() + 32.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 41.5 * 60 * 60 * 1000),
      //             },
      //             completionOfBP: {
      //               name: "Завершение бизнес-процесса",
      //               description: "Завершение бизнес-процесса",
      //               executor_id: userId,
      //               extra: {
      //                 h8: null, // Чекбокс (Подтверждение закрытия)
      //               },
      //               begin: new Date(Date.now() + 41.5 * 60 * 60 * 1000),
      //               end: new Date(Date.now() + 46 * 60 * 60 * 1000),
      //             },
      //           },
      //         },
      //       },
      //     },
      //   });

      //   let config = {
      //     method: "post",
      //     url: `${apiBp}/bp-template-options`,
      //     headers: {
      //       Authorization: bearer,
      //       "company-id": company_id,
      //       "secret-token": localStorage.getItem("access_token_jwt"),
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //     },
      //     data: templates,
      //   };

      //   axios(config)
      //     .then((res) => {
      //       // Скрываем форму и очищаем данные
      //       setCreateTaskSampleFormStatus(false);
      //       setCreateBpStatus(false);
      //       setCreateBpSampleStatus(false);
      //       setNowBp({});
      //       setTasksArr([]);
      //       setCreateBpSampleForm({
      //         type: 0,
      //         businessProcess: {
      //           name: null,
      //           deadline: null,
      //           project_id: null,
      //           tasks: 1,
      //           initiator_id: document.cookie.replace(
      //             /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
      //             "$1"
      //           ),
      //         },
      //         options: [],
      //       });

      //       setBpListChange((prev) => !prev);

      //       CreateNotif(
      //         `Бизнес-процесс "${createBpSampleForm.businessProcess.name}" создан по шаблону Увольнение`,
      //         "success"
      //       );

      //       console.log("Бизнес-процесс: ", res.data);
      //     })
      //     .catch((err) => {
      //       CreateNotif(
      //         "Ошибка в посылке данных бизнес-процесса на сервер",
      //         "error"
      //       );
      //       console.log("Ошибка при создании бизнес-процесса: ", err);
      //     });
      // } else {
      //  Создание БП про пользовательскому шаблону

      // console.log("createBpSampleForm", createBpSampleForm);

      templates = JSON.stringify({
        company_id: company_id,
        name: createBpSampleForm.businessProcess.name
          ? createBpSampleForm.businessProcess.name
          : null,
        initiator_id: userId,
        project_id: createBpSampleForm.businessProcess.project_id
          ? createBpSampleForm.businessProcess.project_id
          : null,
        project_section_id: createBpSampleForm.businessProcess
          .project_section_id
          ? createBpSampleForm.businessProcess.project_section_id
          : null,
        deadline: createBpSampleForm.businessProcess.deadline
          ? createBpSampleForm.businessProcess.deadline
          : new Date(Date.now()),
      });

      let config = {
        method: "post",
        url: `${apiBp}/bp/from-template/${createBpSampleForm.type}`,
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": localStorage.getItem("access_token_jwt"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: templates,
      };

      axios(config)
        .then((res) => {
          // Скрываем форму и очищаем данные
          setCreateTaskSampleFormStatus(false);
          setCreateBpStatus(false);
          setCreateBpSampleStatus(false);
          setNowBp({});
          setTasksArr([]);
          setCreateBpSampleForm({
            type: 0,
            businessProcess: {
              name: null,
              deadline: null,
              project_id: null,
              tasks: 1,
              initiator_id: document.cookie.replace(
                /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
              ),
            },
            options: [],
          });

          setBpListChange((prev) => !prev);

          CreateNotif(
            `Бизнес-процесс "${createBpSampleForm.businessProcess.name}" создан по шаблону`,
            "success"
          );

          console.log("Бизнес-процесс: ", res.data);
        })
        .catch((err) => {
          CreateNotif(
            "Ошибка в посылке данных типовой задачи на сервер",
            "error"
          );
          console.log("Ошибка при создании типовой задачи: ", err);
        });

      /*
        console.log("createBpSampleForm.type", createBpSampleForm.type);
        CreateNotif(
          "Бизнес-процесс по данному шаблону еще в процессе разработки!",
          "warning"
        );*/
    } else {
      CreateNotif("Заполните, пожалуйста, все необходимые поля!", "error");
    }
  };
  //************************************************************************* */

  const saveBpSample = () => {
    let begin = new Date(Date.now());
    let end = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000);
    let monthBegin =
      begin.getMonth() + 1 < 10
        ? "0" + (begin.getMonth() + 1)
        : begin.getMonth() + 1;
    let dayBegin =
      begin.getDate() < 10 ? "0" + begin.getDate() : begin.getDate();
    let monthEnd =
      end.getMonth() + 1 < 10 ? "0" + (end.getMonth() + 1) : end.getMonth() + 1;
    let dayEnd = end.getDate() < 10 ? "0" + end.getDate() : end.getDate();

    let dateBegin = begin.getFullYear() + "-" + monthBegin + "-" + dayBegin;

    let deadline = end.getFullYear() + "-" + monthEnd + "-" + dayEnd;

    console.log("deadline", deadline);
    console.log("createBpSampleForm", createBpSampleForm);

    //***************** */
    if (
      createBpSampleForm.businessProcess.name &&
      createBpSampleForm.businessProcess.project_id &&
      createBpForm.project_section_id
    ) {
      setCreateTaskSampleFormStatus(false);
      setCreateBpStatus(false);
      setCreateBpSampleStatus(false);
      setNowBp({});

      let tasksStr = "";
      for (let i in tasksArr) {
        tasksStr = tasksStr.concat(tasksArr[i]);
        if (i < tasksArr.length - 1) {
          tasksStr = tasksStr.concat("|");
        }
      }

      // Создание бизнес-процесса без шаблона
      if (
        createBpSampleForm.type === null ||
        createBpSampleForm.type === undefined ||
        createBpSampleForm.type === 0
      ) {
        //  Создание бизнес-процесса без шаблона если к нему прикреплены файлы
        if (createBpSampleForm.businessProcess.file_id) {
          fetch(
            `
          ${apiBp}/bp?name=${createBpSampleForm.businessProcess.name}
          &company_id= ${company_id}
          &initiator_id=${createBpSampleForm.businessProcess.initiator_id}
          &project_id=${createBpSampleForm.businessProcess.project_id}
          &project_section_id=${createBpForm.project_section_id}
          &file_id=${createBpSampleForm.businessProcess.file_id}
          &deadline=${
            createBpSampleForm.businessProcess.deadline
              ? createBpSampleForm.businessProcess.deadline
              : deadline
          }`,
            {
              method: "POST",
              headers: {
                Authorization: bearer,
                "company-id": company_id,
                "secret-token": document.cookie.replace(
                  /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                  "$1"
                ),
              },
            }
          )
            .then((res) => {
              CreateNotif(
                `Бизнес-процесс ${createBpSampleForm.businessProcess.name} без шаблона создан`,
                "success"
              );
              res.json();
            })
            .then((r) => {
              setBpListChange((prev) => !prev);
              setTasksArr([]);
              setCreateBpSampleForm({
                type: 0,
                businessProcess: {
                  name: null,
                  deadline: null,
                  project_id: null,
                  tasks: 1,
                  initiator_id: cookieMaster.getCookie("user_id"),
                },
                options: [],
              });
              console.log(r.businessProcess.tasks);
            })
            .catch((e) => {
              console.log(e);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          //  Создание бизнес-процесса без шаблона если у неё нет файлов
          fetch(
            `${apiBp}/bp?name=${createBpSampleForm.businessProcess.name}
          &company_id= ${company_id}  
          &initiator_id=${createBpSampleForm.businessProcess.initiator_id}
          &project_id=${createBpSampleForm.businessProcess.project_id}
          &project_section_id=${createBpForm.project_section_id}
          &deadline=${
            createBpSampleForm.businessProcess.deadline
              ? createBpSampleForm.businessProcess.deadline
              : deadline
          }`,
            {
              method: "POST",
              headers: {
                Authorization: bearer,
                "company-id": company_id,
                "secret-token": document.cookie.replace(
                  /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                  "$1"
                ),
              },
            }
          )
            .then((res) => {
              CreateNotif(
                `Бизнес-процесс "${createBpSampleForm.businessProcess.name}" без шаблона создан`,
                "success"
              );
              res.json();
            })
            .then((r) => {
              setBpListChange((prev) => !prev);
              setTasksArr([]);
              setCreateBpSampleForm({
                type: 0,
                businessProcess: {
                  name: null,
                  deadline: null,
                  project_id: null,
                  tasks: 1,
                  initiator_id: cookieMaster.getCookie("user_id"),
                },
                options: [],
              });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } else {
        fetch(
          `${apiBp}/bp?name=${createBpSampleForm.businessProcess.name}
          &company_id= ${company_id}
          &initiator_id=${createBpSampleForm.businessProcess.initiator_id}
          &project_id=${createBpSampleForm.businessProcess.project_id}
          &project_section_id=${createBpForm.project_section_id}`,
          {
            method: "POST",
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": document.cookie.replace(
                /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
              ),
            },
          }
        )
          .then((res) => {
            setBpListChange((prev) => !prev);
            setCreateBpSampleStatus(false);
            setTasksArr([]);
            setCreateBpSampleForm({
              type: 0,
              businessProcess: {
                name: null,
                deadline: null,
                project_id: null,
                tasks: 1,
                initiator_id: document.cookie.replace(
                  /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                  "$1"
                ),
              },
              options: [],
            });
            console.log(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      CreateNotif("Заполните, пожалуйста, все необходимые поля!", "error");
    }
  };

  useEffect(() => {
    let arr = [];
    for (let i in createBpSampleFormOptions) {
      if (createBpSampleFormOptions[i]?.optionId) {
        arr.push(createBpSampleFormOptions[i]);
      }
    }
    setCreateBpSampleForm({ ...createBpSampleForm, options: arr });
  }, [createBpSampleFormOptions]);

  useEffect(() => {
    if (!!createBpSampleFormDate) {
      if (
        !!createBpSampleFormDate.deadlineDate &&
        !!createBpSampleFormDate.deadlineTime
      ) {
        setCreateBpSampleForm({
          ...createBpSampleForm,
          businessProcess: {
            ...createBpSampleForm.businessProcess,
            deadline:
              createBpSampleFormDate.deadlineDate +
              " " +
              createBpSampleFormDate.deadlineTime +
              ":00",
          },
        });
      } else if (
        !!createBpSampleFormDate.deadlineDate &&
        !createBpSampleFormDate.deadlineTime
      ) {
        setCreateBpSampleForm({
          ...createBpSampleForm,
          businessProcess: {
            ...createBpSampleForm.businessProcess,
            deadline: createBpSampleFormDate.deadlineDate + " 00:00:00",
          },
        });
      }
    }
  }, [createBpSampleFormDate]);

  useEffect(() => {
    if (
      createBpSampleForm.businessProcess.name !== null &&
      createBpSampleForm.businessProcess.project_id !== null &&
      createBpSampleForm.businessProcess.project_section_id !== null
    ) {
      setAccessNext("blue-btn");
    } else {
      setAccessNext("blue-btn blue-btn__disabled");
    }
  }, [createBpSampleForm]);

  // console.log("projects            ", projects);

  useEffect(() => {
    axios
      .get(`${apiBp}/bp-template`, {
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": document.cookie.replace(
            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
          ),
        },
      })
      .then((res) => {
        setSampleArr(res.data.data);
        // console.log("res", res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [bpListChange, loadTemplateBp]);

  useEffect(() => {
    if (!!createBpForm.project_id) {
      axios
        .get(
          `${process.env.REACT_APP_PUBLIC_API}/projects/${createBpForm.project_id}`,
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              Authorization: bearer,
            },
          }
        )
        .then((res) => {
          // console.log(res.data.data);
          setProjectSections(res.data.data.sections);
        });
    }
  }, [createBpForm.project_id]);

  // console.log("projectSections       ", projectSections);

  useEffect(() => {
    if (createBpSampleForm.type === 0) {
      let bp = sampleArr.filter((el) => el.id === parseInt(idSample));
      bp = bp[0].businessProcessId;

      let tasksStr = "";
      if (bp && bp.tasks) {
        for (let i in bp.tasks) {
          tasksStr = tasksStr.concat(bp.tasks[i].id);
          if (i < bp.tasks.length - 1) {
            tasksStr = tasksStr.concat("|");
          }
        }

        setCreateBpSampleForm({
          ...createBpSampleForm,
          businessProcess: {
            name: bp.name,
            initiator_id: parseInt(
              document.cookie.replace(
                /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
              )
            ),
            project_id: parseInt(bp.project_id),
            tasks: tasksStr,
            deadline: bp.deadline,
          },
        });
      }
    }
  }, [idSample]);

  useEffect(() => {
    if (Mount.current) {
      const handleClickOutside = (e) => {
        if (closeRef.current && !e.composedPath().includes(closeRef.current)) {
          // setCreateBpStatus(false);
          setCreateBpSampleStatus(false);
          Mount.current = false;
        }
      };
      document.body.addEventListener("click", handleClickOutside);

      return () =>
        document.body.removeEventListener("click", handleClickOutside);
    }
  }, [createBpStatus]);

  if (createBpStatus === true) {
    return (
      <div className="business__drop-content" ref={closeRef}>
        <div id="business" className="businessClass">
          <div>
            <div className="business__drop-content__title p__drop-content  p__drop-content_icon">
              * - обязательные для заполнения поля
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              // className="chatMenu-header__close"
              onClick={() => {
                Mount.current = false;
                setCreateBpStatus(false);
                setCreateBpForm({
                  name: null,
                  initiator_id: parseInt(
                    document.cookie.replace(
                      /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    )
                  ),
                  project_id: null,
                  deadlineDate: null,
                  deadlineTime: null,
                  tasks: null,
                  sample: null,
                  file_id: null,
                });
                setCreateBpSampleStatus(false);
                setCreateBpSampleForm({
                  type: 0,
                  businessProcess: {
                    name: null,
                    deadline: null,
                    project_id: null,
                    tasks: 1,
                    initiator_id: document.cookie.replace(
                      /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    ),
                  },
                  options: [],
                });
                setCreateTaskSampleFormStatus(false);
              }}
            >
              Закрыть
              <i
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  width: "28px",
                  border: "2px solid #a9a8a8",
                  borderRadius: "50%",
                  height: "28px",
                  marginLeft: "7px",
                }}
              >
                <CloseIcon
                  sx={{ width: "21px", hight: "21px", padding: "1px" }}
                  htmlColor="#737373"
                />
              </i>
            </div>
            </div>
            <form id="new-bp__form">
              <div>
                <label className="p__drop-content" htmlFor="input-name">
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                    alt="Article"
                  />
                  Шаблон
                </label>

                <select
                  className="input-form"
                  ref={templateNameRef}
                  onChange={(e) => {
                    console.log("e.target.value", e.target.value);
                    if (e.target.value !== "Без шаблона") {
                      // console.log("e.target.value", e.target.value);
                      setCreateBpSampleStatus(true);
                      if (
                        +e.target.value == idSample1 ||
                        +e.target.value == idSample2 ||
                        +e.target.value == idSample3
                      ) {
                        setIdSample(e.target.value);
                        setCreateBpSampleForm({
                          ...createBpSampleForm,
                          type: +e.target.value,
                        });
                      } else {
                        // console.log("e.target.value", e.target.value);
                        setIdSample(e.target.value);
                        setCreateBpSampleForm({
                          ...createBpSampleForm,
                          type: +e.target.value,
                        });
                      }
                    } else {
                      setCreateBpSampleStatus(false);
                      setCreateBpSampleForm({
                        ...createBpSampleForm,
                        type: 0,
                      });
                    }
                  }}
                >
                  <option>Без шаблона</option>

                  {sampleArr.map((el) => (
                    <option value={el.id} key={el.id}>
                      {el.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                {/* {console.log("nameOfTemplate      ", nameOfTemplate)} */}
                <label className="p__drop-content" htmlFor="input-name">
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                    alt="Article"
                  />
                  Название*
                </label>
                <input
                  className="input-form"
                  type="text"
                  id="input-name"
                  value={
                    createBpSampleForm.businessProcess.name
                      ? createBpSampleForm.businessProcess.name
                      : nameOfTemplate
                  }
                  onChange={(e) => {
                    // console.log(e.target.value);
                    if (e.target.value.trim() === "") {
                      setCreateBpForm({ ...createBpForm, name: null });
                      setCreateBpSampleForm({
                        ...createBpSampleForm,
                        businessProcess: {
                          ...createBpSampleForm.businessProcess,
                          name: null,
                        },
                      });
                    } else {
                      setCreateBpForm({
                        ...createBpForm,
                        name: nameOfTemplate,
                      });
                      setCreateBpSampleForm({
                        ...createBpSampleForm,
                        businessProcess: {
                          ...createBpSampleForm.businessProcess,
                          name: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </div>
              <div>
                <label className="p__drop-content" htmlFor="input-project">
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                    alt="Article"
                  />
                  Проект*
                </label>
                <select
                  className="input-form"
                  value={
                    createBpSampleForm.businessProcess.project_id
                      ? createBpSampleForm.businessProcess.project_id
                      : ""
                  }
                  onChange={(e) => {
                    setCreateBpSampleForm({
                      ...createBpSampleForm,
                      businessProcess: {
                        ...createBpSampleForm.businessProcess,
                        project_id: parseInt(e.target.value),
                      },
                    });
                    setCreateBpForm({
                      ...createBpForm,
                      project_id: parseInt(e.target.value),
                    });
                    setCreateTaskForm({
                      ...createTaskForm,
                      data: {
                        ...createTaskForm.data,
                        project_id: parseInt(e.target.value),
                      },
                    });
                  }}
                >
                  <option value={null}>Выбрать</option>
                  {projects.map((el) => {
                    return (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label
                  className="p__drop-content"
                  htmlFor="input-project-section"
                >
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                    alt="Article"
                  />
                  Раздел проекта*
                </label>
                <select
                  id="input-project-section"
                  className="input-form"
                  disabled={!createBpForm.project_id ? true : false}
                  value={
                    createBpSampleForm.businessProcess.project_section_id
                      ? createBpSampleForm.businessProcess.project_section_id
                      : ""
                  }
                  onChange={(e) => {
                    console.log(e.target.value);
                    setCreateBpSampleForm({
                      ...createBpSampleForm,
                      businessProcess: {
                        ...createBpSampleForm.businessProcess,
                        project_section_id: parseInt(e.target.value),
                      },
                    });
                    setCreateBpForm({
                      ...createBpForm,
                      project_section_id: parseInt(e.target.value),
                    });
                    setCreateTaskForm({
                      ...createTaskForm,
                      data: {
                        ...createTaskForm.data,
                        project_section_id: parseInt(e.target.value),
                      },
                    });
                  }}
                >
                  <option value={null}>Выбрать</option>
                  {projectSections.map((el) => {
                    return (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/*!createBpSampleStatus ? (
                <div>
                  <label className="p__drop-content" htmlFor="input-initiator">
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/User.svg`}
                      alt="User"
                    />
                    Инициатор
                  </label>

                  <select
                    className="input-form"
                    value={
                      createBpSampleForm.businessProcess.initiator_id
                        ? createBpSampleForm.businessProcess.initiator_id
                        : document.cookie.replace(
                            /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                            "$1"
                          )
                    }
                    onChange={(e) => {
                      if (e.target.value.trim() === "") {
                        setCreateBpForm({
                          ...createBpForm,
                          initiator_id: parseInt(
                            document.cookie.replace(
                              /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                              "$1"
                            )
                          ),
                        });
                      } else {
                        setCreateBpForm({
                          ...createBpForm,
                          initiator_id: e.target.value,
                        });
                      }
                    }}
                  >
                    <option>Выбрать</option>
                  </select>
                </div>
              ) : (
                <></>
              )*/}

              {/* {createBpSampleStatus===false &&(
              <div className="input__date">
                <label className="p__drop-content">
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/CalendarBlank.svg`}
                    alt="CalendarBlank"
                  />
                  Общий срок
                </label>
                <div>
                  <input
                    className="input-form"
                    type="date"
                    id="input-date"
                    onChange={(e) => {
                      if (e.target.value.trim() === "") {
                        setCreateBpForm({
                          ...createBpForm,
                          deadlineDate: null,
                        });
                        setCreateBpSampleFormDate({
                          ...createBpSampleFormDate,
                          deadlineDate: null,
                        });
                      } else {
                        setCreateBpForm({
                          ...createBpForm,
                          deadlineDate: e.target.value,
                        });
                        setCreateBpSampleFormDate({
                          ...createBpSampleFormDate,
                          deadlineDate: e.target.value,
                        });
                      }
                    }}
                  />
                  <input
                    className="input-form"
                    type="time"
                    id="input-time"
                    onChange={(e) => {
                      if (e.target.value.trim() === "") {
                        setCreateBpForm({
                          ...createBpForm,
                          deadlineTime: null,
                        });
                        setCreateBpSampleFormDate({
                          ...createBpSampleFormDate,
                          deadlineTime: null,
                        });
                      } else {
                        setCreateBpForm({
                          ...createBpForm,
                          deadlineTime: e.target.value,
                        });
                        setCreateBpSampleFormDate({
                          ...createBpSampleFormDate,
                          deadlineTime: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              )} */}

              {/* {createBpSampleStatus===true &&( 
               <div className="input__date">
                <label className="p__drop-content">
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/CalendarBlank.svg`}
                    alt="CalendarBlank"
                  />
                  Общий срок
                </label>
                <div>
                  <input
                  title="Для типовых задач по шаблонам рассчитывается автоматически"
                    className="input-form"
                    type="date"
                    value={deadline2}
                    disabled
                  />
                  <input
                   title="Для типовых задач по шаблонам рассчитывается автоматически"
                    className="input-form"
                    type="time"
                    value={"18:00"}
                    disabled
                  />
                </div>
              </div> )} */}

              {/*
              {!createBpSampleStatus || createBpSampleForm.type === 0 ? (
                <div id="input-download">
                  <label
                    className="p__drop-content"
                    htmlFor="input-download__input"
                  >
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Vector.svg`}
                      alt="Vector"
                    />
                    Прикрепить файл
                  </label>
                  {!!file.name ? (
                    <div className="file-download">
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.png`}
                        alt=""
                      />
                      {file.name}
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        id="input-download__input"
                        onChange={(e) => submitFile(e)}
                      />
                      <label
                        className="p__drop-content"
                        htmlFor="input-download__input"
                        id="input-download__input-label"
                      >
                        Выбрать
                      </label>
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}
              */}
            </form>
            {/***       
            // Дополнительные данные переносятся из бизнес-процесса в бизнес-процесс/Задачи
            {createBpSampleForm.type === 1 ? <Treaty /> : <></>}
            {createBpSampleForm.type === 2 ? <BpResultFormWork /> : <></>}
            {createBpSampleForm.type === 3 ? <BpResultFormDismissal /> : <></>}
            ***/}
          </div>
          <div>
            {/*** Если создаем бизнес-процесс по шаблону ***/}
            {/* {console.log(createBpSampleStatus)} */}
            {/* Если создаем бизнес-процесс без шаблона */}
            {createBpSampleStatus === true ? (
              <button
                className={accessNext}
                id="bussines-next"
                onClick={() => {
                  setNameOfTemplate("");
                  saveBpSample2();
                }}
              >
                Сохранить
              </button>
            ) : (
              /* Если создаем бизнес-процесс без шаблона */
              <button
                className={accessNext}
                id="bussines-next"
                onClick={() => {
                  setNameOfTemplate("");
                  saveBpSample();
                }}
              >
                Сохранить
              </button>
            )}

            <button
              className="defualt__btn"
              id="close-btn"
              onClick={() => {
                Mount.current = false;
                setCreateBpStatus(false);
                setCreateBpForm({
                  name: null,
                  initiator_id: parseInt(
                    document.cookie.replace(
                      /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    )
                  ),
                  project_id: null,
                  deadlineDate: null,
                  deadlineTime: null,
                  tasks: null,
                  sample: null,
                  file_id: null,
                });
                setCreateBpSampleStatus(false);
                setCreateBpSampleForm({
                  type: 0,
                  businessProcess: {
                    name: null,
                    deadline: null,
                    project_id: null,
                    tasks: 1,
                    initiator_id: document.cookie.replace(
                      /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    ),
                  },
                  options: [],
                });
                setCreateTaskSampleFormStatus(false);
              }}
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    );
  } else return null;
  {
  }
};
export default CreateBp;
