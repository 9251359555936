import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react'
import styled from 'styled-components'

import { Event } from './Lightbox/Lightbox'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  justify-content: center;
`

const WrapperCheckbox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 8px;
  border-radius: 10px;
  cursor: pointer;
  min-width: 80px;
  max-width: 150px;
  width: 100%;
  border: 1px solid
    ${(props) => (props.checked ? '#1baaf0' : 'rgb(229, 232, 235)')};
  background: ${(props) =>
    props.checked ? '#1baaf0' : props.disabled ? 'lightgray' : 'white'};
  color: ${(props) => (props.checked ? 'white' : 'black')};

  &:hover {
    box-shadow: ${(props) => (props.disabled ? '' : '0 5px 10px lightgrey')};
  }
`

const HideCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
`

type Props = {
  event: Event
  setEvent: Dispatch<SetStateAction<Event>>
  isCreatedTask: boolean
}

const LightboxActions: FC<Props> = ({ event, setEvent, isCreatedTask }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEvent(e.target.name as Event)
  }

  return (
    <Wrapper>
      <WrapperCheckbox checked={event === 'task'}>
        <HideCheckbox
          type="checkbox"
          onChange={handleChange}
          name="task"
          checked={event === 'task'}
        />
        Задача
      </WrapperCheckbox>
      <WrapperCheckbox
        disabled={isCreatedTask}
        checked={event === 'conference'}
      >
        <HideCheckbox
          disabled={isCreatedTask}
          type="checkbox"
          onChange={handleChange}
          name="conference"
          checked={event === 'conference'}
        />
        Совещание
      </WrapperCheckbox>
      {/* <WrapperCheckbox disabled={isCreatedTask} checked={event === 'meeting'}>
        <HideCheckbox
          disabled={isCreatedTask}
          type="checkbox"
          onChange={handleChange}
          name="meeting"
          checked={event === 'meeting'}
        />
        Встреча
      </WrapperCheckbox> */}
    </Wrapper>
  )
}

export default LightboxActions
