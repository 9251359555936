import React from "react";

const BsFilter5 = ({ props }) => {
  return (
    <svg
      {...props}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.90625 12.5H21.0938"
        stroke="#292A34"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        transform="rotate(90)"
        transform-origin="center center"
      />
      <path
        d="M3.90625 6.25H21.0938"
        stroke="#292A34"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        transform="rotate(90)"
        transform-origin="center center"
      />
      <path
        d="M3.90625 18.75H21.0938"
        stroke="#292A34"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        transform="rotate(90)"
        transform-origin="center center"
      />
    </svg>
  );
};

export default BsFilter5;
