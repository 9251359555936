import React, {useCallback, useState, useRef, useEffect} from 'react';
import AddButton from '../../../common/components/Buttons/AddButton';
// import DisplayTypeSelect from '../../../common/components/Selects/DisplayTypeSelect'
import UserProjectSelect from '../../../common/components/Selects/UserProjectSelect';
import {Project, SelectData, Section, Task} from '../../../common/types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getProjects} from '../../../common/selectors';
import {State} from '../../../rootReducer';

import DotsButton from '../../../common/components/Buttons/DotsButton';
import {openExistingProject} from '../../NewProject/actions';
import {useHistory, useParams} from 'react-router-dom';
import {fetchData} from '../../../utils/fetchData';
import TableSearch from '../../../common/components/TableSearch/TableSearch';

import FilterButton from '../../../common/components/Buttons/filterButton';
import ViewWeekRoundedIcon from '@material-ui/icons/ViewWeekRounded';
import ProjectsFilters from '../../Projects/components/ProjectFilters';
import ColumnsWindow from '../../../common/ColumnsCustomWindow';
import Button from '../../../common/components/newComponent/Buttons/Button';
import Toggler from '../../../common/components/Togglers/Toggler';
import {
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    ShowFiltersWindow,
} from '../../../common/actions';
import FiltersWindow from '../../../common/FiltersWindow';
import Icon from '../../../common/components/Icon';
import {cookieMaster} from '../../../utils/CookieMaster';
import {urlApp} from '../../../App';
import {fetchMeetings, setNewParametersToReducer} from "../actions";

type Props = {
    projects: Project[];
    // selectData: SelectData;
    sections: Section[];
    selectedProject: Project | null;
    openExistingProject: (project: Project) => void;
    // setProjectAddMode: (mode: boolean) => void;
    // selectProject: (project: Project | null) => void;
    // setIsFetchingTasks: (isFetching: boolean) => void;
    currentUserRoles: number[] | undefined;
    tableFilter: string;
    projectId: number | null;
    appliedFiltersExist: boolean;
    showColumnsWindow: boolean;
    showFiltersWindow: boolean;
    // setShowColumnsProjectsCustom: (show: boolean) => void;
    // setTableOrTreeToggler: (val: string) => void;
    tableOrTreeToggler: string;
    ShowFiltersWindow: () => void;
    current_user: number;
    filters: any;
    FilterTemplates: (data: {}) => void;
    DefaultFilterTemplates: (data) => void;
    setFilters: (
        data_from?: string,
        date_to?: string,
        executors?: number[],
        authors?: number[],
        statuses?: number[],
        actual?: boolean
    ) => void;
    // businessProcessesModalShow: (show: boolean) => void;
    setNewParametersToReducer: (obj: object) => void,
    fetchMeetings: (tableFilter?: string) => void,
};

const ProjectActions: React.FC<Props> = ({
                                             // setShowColumnsProjectsCustom,
                                             showFiltersWindow,
                                             showColumnsWindow,
                                             projectId,
                                             projects,
                                             selectedProject,
                                             // setProjectAddMode,
                                             // selectProject,
                                             openExistingProject,
                                             // selectData,
                                             currentUserRoles,
                                             // setTableOrTreeToggler,
                                             tableOrTreeToggler,
                                             ShowFiltersWindow,
                                             filters,
                                             // businessProcessesModalShow,
                                             setNewParametersToReducer,
                                             fetchMeetings
                                         }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
    // создал изолированную копию объекта, чтобы не перезаписать пропс
    const newRolesArr = currentUserRoles?.map((item) => {
        return item;
    });
    newRolesArr?.sort();

    const [showMenu, setShow] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const {id} = useParams();
    const menu = useRef<HTMLDivElement>(null);
    const tasksOrder = useSelector(
        (state: State) => state.projectsPage.tasksOrder
    );
    const outsideClick = useCallback((e: Event) => {
        if (!menu.current?.contains(e.target as HTMLElement)) setShow(false);
    }, []);

    // useEffect(() => {
    //   document.addEventListener('click', outsideClick);
    //   return () => document.removeEventListener('click', outsideClick);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // useEffect(() => {
    //   if (history.location.pathname.includes('undefined')) {
    //     history.push(`/projects/${projects[0].id}`);
    //     // dispatch(selectProject(projects[0]));
    //   }
    //   history.push(`/projects/${selectedProject?.id}`);
    // }, [selectedProject, projects]);

    // const editClickHandler = useCallback(async () => {
    //   const sections = await fetchData.get(`/api/v1/projects/${id}/sections`);
    //   if (sections) {
    //     openExistingProject({
    //       ...selectedProject,
    //       sections,
    //     } as Project);
    //     // setProjectAddMode(true);
    //   }
    // }, [id, openExistingProject, selectedProject]);

    let filter_btn_inscription;
    // Object.keys(filters['default_templates']).forEach((item) => {
    //   if (filters['default_templates'][item].active) {
    //     // console.log(item, filters['default_templates'][item])
    //     filter_btn_inscription = item;
    //   }
    // });
    // if (filter_btn_inscription === undefined) {
    //   Object.keys(filters['templates']).forEach((item) => {
    //     if (filters['templates'][item].active) {
    //       // console.log(item, filters['templates'][item])
    //       filter_btn_inscription = item;
    //     }
    //   });
    // }
    let company_id = localStorage.getItem('company_id');
    // const uploadTasksExcel = () => {
    //   let link = `/uploads/tasks?project_id=${projectId}&order=${tasksOrder.col_name}&`;
    //   const { executors, authors, statuses, date_from, date_to } =
    //     filters.current_set;
    //   if (tasksOrder.order_direct) link = link + `orderType=desc&`;
    //   if (executors.length > 0) link = link + `executor_id=${executors}&`;
    //   if (authors.length > 0) link = link + `author_id=${authors}&`;
    //   if (statuses.length > 0) link = link + `status_id=${statuses}&`;
    //   if (date_from.length > 0) link = link + `begin_from=${date_from}&`;
    //   if (date_to.length > 0) link = link + `end_to=${date_to}&`;
    //   fetch(`${urlApp + link}`, {
    //     headers: {
    //       'company-id': `${company_id}`,
    //       Authorization: `${cookieMaster.getCookie('access_token')}`,
    //     },
    //   })
    //     .then((data) => data.blob())
    //     .then((blob) => {
    //       let tmp = document.createElement('a');
    //       let csvData = new Blob([blob], { type: 'text/xlsx' });
    //       let csvUrl = URL.createObjectURL(csvData);
    //       tmp.href = csvUrl;
    //       tmp.setAttribute('download', 'tasks_from_view.xlsx');
    //       tmp.click();
    //     });
    // };

    useEffect(() => {
        fetchMeetings()
    }, []);

    // console.log(showFiltersWindow)
    return (
        <div className="main_actions" style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '80px',
            position: 'relative',
            marginBottom: '12px',
            marginTop: '8px'
        }}>
            {(
                <div className="project_select_wrapper">
                    {/*<UserProjectSelect*/}
                    {/*  withImage={false}*/}
                    {/*  type="projects"*/}
                    {/*  itemsList={selectData.list.sort((a, b) =>*/}
                    {/*    a.text > b.text ? 1 : -1*/}
                    {/*  )}*/}
                    {/*/>*/}


                    <Button
                        design={'outline'}
                        onClick={() => ShowFiltersWindow()}
                        style={{
                            margin: '0 8px',
                            padding: '8px',
                            width: '150px',
                            minWidth: '100px',
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        <Icon name={'filter'} style={{marginRight: '4px'}}/>
                        {filter_btn_inscription
                            ? filter_btn_inscription
                            : 'Пользовательские'}
                    </Button>

                    <TableSearch
                        searchValue={searchValue}
                        setSearchValue={(arg) => {
                            setSearchValue(arg)
                            if (timer) clearTimeout(timer);
                            setTimer(
                                setTimeout(() => {
                                    fetchMeetings(arg)
                                }, 500)
                            );
                        }}
                        isOpen={false}
                    />

                </div>
            )}

            <div className="project_select_wrapper">
                <Button
                    design={'outline'}
                    style={{marginRight: '16px'}}
                    title={''}
                    onClick={() => {
                    }}
                >
                    Таблица
                </Button>

                {/*<div*/}
                {/*  onClick={() => setShowColumnsProjectsCustom(!showColumnsWindow)}*/}
                {/*  className={`columns_select${showColumnsWindow ? ' active' : ''}`}*/}
                {/*/>*/}

                {/*<TableSearch*/}
                {/*  searchValue={tableFilter}*/}
                {/*  setSearchValue={}*/}
                {/*  isOpen={!!tableFilter}*/}
                {/*/>*/}

                {newRolesArr && newRolesArr[0] < 3 && (
                    <Button
                        title="Добавить"
                        icon="plus_circle"
                        style={{padding: '.5rem', width: '100px', margin: '0 .5rem'}}
                        onClick={() => {
                            setNewParametersToReducer({showRightPlate: true})
                            setTimeout(() => setNewParametersToReducer({showMeetingRightPlateAnimation: true}), 0)
                        }}
                    />
                )}
            </div>

            {/*<DisplayTypeSelect selectedElem={{ text: 'Список', value: 'list' }} selectHandler={() => { }} />*/}

            {/*{showFiltersWindow && <ProjectsFilters/>}*/}
            {showColumnsWindow && <ColumnsWindow context={'projects'}/>}
            {showFiltersWindow && <FiltersWindow context={'projects'}/>}
        </div>
    );
};

const mapStateToProps = (state: State) => {
    return {
        appliedFiltersExist: Boolean(
            Object.keys(state.projectsPage.filters.appliedFilters).length
        ),
        projects: getProjects(state),
        // selectData: getProjectSelectData(state),
        selectedProject: state.projectsPage.selectedProject,
        sections: state.commonInfo.sections,
        // showFiltersWindow: state.projectsPage.filtersWindowShow,
        showFiltersWindow: state.commonInfo.filters.showFiltersWindow,
        currentUserRoles: state.commonInfo.currentUserInfo?.roles,
        tableFilter: state.projectsPage.tableFilter,
        projectId: state.projectsPage.selectedProject?.id || null,
        showColumnsWindow: state.projectsPage.showColumnsWindow,
        tableOrTreeToggler: state.projectsPage.tableOrTreeToggler,
        current_user: state.commonInfo.current_user,
        filters: state.commonInfo.filters,
    };
};

const mapDispatchToProps = {
    // fetchTasks,
    openExistingProject,
    ShowFiltersWindow,
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    setNewParametersToReducer,
    fetchMeetings
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectActions);
