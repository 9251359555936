import React, { useEffect, useState } from "react";
import Pagination from "rc-pagination";
import "./PaginationTasks.scss";
import styled from "styled-components";
// //import { fetchTasks } from "../../actions";
// import { changeTaskList, fetchVisualizationList } from "../../Tasks/actions";
// import { useDispatch } from "react-redux";
// import { Task } from "../../../common/types";
import { fetchData } from "../../../utils/fetchData";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { fetchTasks, changeTaskList } from "../actions";
import { urlApp } from "../../../App";
import { cookieMaster } from "../../../utils/CookieMaster";
import { State } from "../../../rootReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 60,
      marginRight: 20,
    },
    selectEmpty: {},
  })
);
const DivSelect = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 10px;
  }
`;
const WrapperPagination = styled.div`
  margin: 5px auto;
  display: flex;
  align-items: center;
`;

type Props = {
  pageNumLast?: number;
  limitedTasks?: number | null;
  pageNumber?: number | null;

  handlerClickLimitTasks?: (pageNum: number) => void;
  handlerPageClickNumber?: (pageNum: number) => void;

  tasksOrder: Object;
  isSendingCreateNewTasks: boolean;
};

const PaginationTasks: React.FC<Props> = ({
  // pageNumLast,
  // limitedTasks,
  // pageNumber,
  // handlerClickLimitTasks,
  // handlerPageClickNumber,

  isSendingCreateNewTasks,
  tasksOrder,
}) => {
  const classes = useStyles();
  const [pageNum, setPageNum] = useState(1);

  const [pageNumLast, setPageNumLast] = useState(1); // Последняя страничка в пагинации
  const [pageNumber, setPageNumber] = useState(1); // Номер страницы на которую мы нажимаем
  const [limitTasksPages, setLimitTasksPages] = useState(20); // лимит наших задач которые будут показыватся
  const dispatch = useDispatch();

  //при добавлении новой задачи чтобы он обновлял страницу и пагинацию (был баг)
  useEffect(() => {
    setPageNum(1);
  }, [isSendingCreateNewTasks]);

  const { id } = useParams();

  useEffect(() => {
    if (pageNum !== 1) fetchTasks(pageNum);
  }, [fetchTasks, pageNum]);

  useEffect(() => {
    let company_id = localStorage.getItem("company_id");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `${cookieMaster.getCookie("access_token")}`
    );
    myHeaders.append("company-id", `${company_id}`);

    fetch(`${urlApp}/api/v1/tasks?limit=${limitTasksPages}`, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => setPageNumLast(+result.meta.last_page))
      .then(() => {
        setPageNumber(1);
      })
      .catch((error) => console.log("error", error));
  }, [limitTasksPages]);

  useEffect(() => {
    setPageNum(1);
  }, [id, tasksOrder]);

  const onClickLimitTasks = (e) => {
    setLimitTasksPages(e.target.value);
  };

  const onPageClickNumber = (e) => {
    setPageNumber(e);
  };
  useEffect(() => {
    fetchData
      .get(`/api/v1/tasks?page=${pageNumber}&limit=${limitTasksPages}`)
      .then((response) => {
        // console.log("response",response)
        dispatch(changeTaskList(response));
      })
      .catch((error) => console.warn(error));
  },  [pageNumLast, pageNumber]);


useEffect(()=>{
  setPageNumber(1)
},[limitTasksPages])
  
  return (
    <WrapperPagination>
      <Pagination
      showTitle={false}
        onChange={onPageClickNumber}
        className="ant-pagination"
        defaultCurrent={1}
        total={pageNumLast * 10}
        current={pageNumber}
      />
      <DivSelect>
        <span>Показать задач</span>
        <FormControl className={classes.formControl}>
          <Select
            value={limitTasksPages}
            onChange={onClickLimitTasks}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ "aria-label": "Without label" }}
            disableUnderline
            defaultValue={20}
          >
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </DivSelect>
    </WrapperPagination>
  );
};
export default PaginationTasks;
