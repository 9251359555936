import { ChangeTask, FactsTaskPeople } from "../common/types";

export const sortFactsForTable = (data: FactsTaskPeople[]): ChangeTask[] => {
	const changeTasks: ChangeTask[] = data?.map((el) => ({
		...el.task,
		checked: false,
		used_load: el.used_load,
		accumulate_load: el.accumulate_load,
		left_load: el.left_load,
		load: el.load,
	}));

	return changeTasks || [];
};
