// тут находятся функции для таблицы/дерева

import React, { useCallback, useState, useRef, useEffect } from "react";
import AddButton from "../../../common/components/Buttons/AddButton";
// import DisplayTypeSelect from '../../../common/components/Selects/DisplayTypeSelect'
import UserProjectSelect from "../../../common/components/Selects/UserProjectSelect";
import { Project, SelectData, Section, Task } from "../../../common/types";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  setProjectAddMode,
  selectProject,
  setIsFetchingTasks,
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setShowColumnsProjectsCustom,
  setTableOrTreeToggler,
  businessProcessesModalShow,
} from "../actions";
import { getProjects } from "../../../common/selectors";
import { State } from "../../../rootReducer";
import { getProjectSelectData } from "../selectors";
import DotsButton from "../../../common/components/Buttons/DotsButton";
import { openExistingProject } from "../../NewProject/actions";
import { useHistory, useParams } from "react-router-dom";
import { fetchData } from "../../../utils/fetchData";
import TableSearch from "../../../common/components/TableSearch/TableSearch";
import {
  changeTaskListAction,
  fetchTasksAction,
  setTableFilterAction,
} from "../action_types";
import FilterButton from "../../../common/components/Buttons/filterButton";
import ViewWeekRoundedIcon from "@material-ui/icons/ViewWeekRounded";
import ProjectsFilters from "../../Projects/components/ProjectFilters";
import ColumnsWindow from "../../../common/ColumnsCustomWindow";
import Button from "../../../common/components/newComponent/Buttons/Button";
import Toggler from "../../../common/components/Togglers/Toggler";
import {
  DefaultFilterTemplates,
  FilterTemplates,
  setFilters,
  ShowFiltersWindow,
} from "../../../common/actions";
import FiltersWindow from "../../../common/FiltersWindow";
import Icon from "../../../common/components/Icon";
import { cookieMaster } from "../../../utils/CookieMaster";
import { urlApp } from "../../../App";

export let tableFilter2 = ""; //******************************************** */

//*** setChecked, checked
type Props = {
  changeProject?: boolean; //************ */
  setChangeProject?: (boolean) => void; //************** */
  setChecked: (boolean) => void;
  checked: boolean;
  projects: Project[];
  selectData: SelectData;
  sections: Section[];
  selectedProject: Project | null;
  openExistingProject: (project: Project) => void;
  setProjectAddMode: (mode: boolean) => void;
  selectProject: (project: Project | null) => void;
  setIsFetchingTasks: (isFetching: boolean) => void;
  currentUserRoles: number[] | undefined;
  tableFilter: string;
  setTableFilter: (filter: string) => setTableFilterAction;
  changeTaskList: (tasks: Task[]) => changeTaskListAction;
  fetchTasks: (projecId: number, pageNum: number) => fetchTasksAction;
  projectId: number | null;
  appliedFiltersExist: boolean;
  showColumnsWindow: boolean;
  showFiltersWindow: boolean;
  setShowColumnsProjectsCustom: (show: boolean) => void;
  setTableOrTreeToggler: (val: string) => void;
  tableOrTreeToggler: string;
  ShowFiltersWindow: () => void;
  current_user: number;
  filters: any;
  FilterTemplates: (data: {}) => void;
  DefaultFilterTemplates: (data) => void;
  setFilters: (
    data_from?: string,
    date_to?: string,
    executors?: number[],
    authors?: number[],
    statuses?: number[],
    actual?: boolean
  ) => void;
  businessProcessesModalShow: (show: boolean) => void;
};

const WrapperIconColumn = styled.div`
  margin-right: 8px;
  border: 1px solid
    ${({ showColumnsWindow }) =>
      showColumnsWindow ? "#02405e" : "transparent"};
  border-radius: 25%;
`;
const FlexBlock = styled.div`
  display: flex;
  align-items: center;

  & ::selection {
    color: #01b0e9;
  }
`;

// ***
const ProjectActions: React.FC<Props> = ({
  changeProject, //***** */
  setChangeProject, //******** */
  setChecked,
  checked,
  setShowColumnsProjectsCustom,
  appliedFiltersExist,
  showFiltersWindow,
  showColumnsWindow,
  tableFilter,
  projectId,
  setTableFilter,
  changeTaskList,
  fetchTasks,
  projects,
  selectedProject,
  sections,
  setProjectAddMode,
  selectProject,
  setIsFetchingTasks,
  openExistingProject,
  selectData,
  currentUserRoles,
  setTableOrTreeToggler,
  tableOrTreeToggler,
  ShowFiltersWindow,
  current_user,
  FilterTemplates,
  setFilters,
  filters,
  DefaultFilterTemplates,
  businessProcessesModalShow,
}) => {
  function changeCheckbox() {
    //************************************* */
    setChecked((checked) => !checked);
  }

  //********************************************************************* */
  // useEffect(()=>{
  //   console.log("checked",checked)
  // })

  const history = useHistory();
  const dispatch = useDispatch();
  const [timer, setTimer] =
    useState<ReturnType<typeof setTimeout> | null>(null);
  // создал изолированную копию объекта, чтобы не перезаписать пропс
  const newRolesArr = currentUserRoles?.map((item) => {
    return item;
  });
  newRolesArr?.sort();

  const [showMenu, setShow] = useState<boolean>(false);
  const { id } = useParams();
  const menu = useRef<HTMLDivElement>(null);
  const tasksOrder = useSelector(
    (state: State) => state.projectsPage.tasksOrder
  );

  const outsideClick = useCallback((e: Event) => {
    if (!menu.current?.contains(e.target as HTMLElement)) setShow(false);
  }, []);

  useEffect(() => {
    document.addEventListener("click", outsideClick);
    return () => document.removeEventListener("click", outsideClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (history.location.pathname.includes("undefined") && projects.length) {
      history.push(`/projects/${projects[0].id}`);
      dispatch(selectProject(projects[0]));
    }
    if (selectedProject) history.push(`/projects/${selectedProject?.id}`);
  }, [selectedProject, projects]);

  const editClickHandler = useCallback(async () => {
    const sections = await fetchData.get(`/api/v1/projects/${id}/sections`);
    if (sections) {
      openExistingProject({
        ...selectedProject,
        sections,
      } as Project);
      setProjectAddMode(true);
    }
  }, [id, openExistingProject, selectedProject, setProjectAddMode]);

  // Процедура поиска
  const searchChangeHandler = useCallback(
    (filter: string) => {
      tableFilter2 = filter; //************************************************************ */
      if (!projectId) return;
      setTableFilter(filter);
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          changeTaskList([]);
          fetchTasks(projectId, 1);
        }, 500)
      );
    },
    [changeTaskList, fetchTasks, projectId, setTableFilter, timer]
  );
  let default_filter_active = false;
  let filter_btn_inscription;
  Object.keys(filters["default_templates"]).forEach((item) => {
    if (filters["default_templates"][item].active) {
      // console.log(item, filters['default_templates'][item])
      filter_btn_inscription = item;
      default_filter_active = true;
    }
  });
  if (filter_btn_inscription === undefined) {
    Object.keys(filters["templates"]).forEach((item) => {
      if (filters["templates"][item].active) {
        // console.log(item, filters['templates'][item])
        filter_btn_inscription = item;
      }
    });
  }
  let company_id = localStorage.getItem("company_id");
  const uploadTasksExcel = () => {
    let link = `/uploads/tasks?project_id=${projectId}&order=${tasksOrder.col_name}&`;
    const { executors, authors, statuses, date_from, date_to } =
      filters.current_set;
    if (tasksOrder.order_direct) link = link + `orderType=desc&`;
    if (executors.length > 0) link = link + `executor_id=${executors}&`;
    if (authors.length > 0) link = link + `author_id=${authors}&`;
    if (statuses.length > 0) link = link + `status_id=${statuses}&`;
    if (date_from.length > 0) link = link + `begin_from=${date_from}&`;
    if (date_to.length > 0) link = link + `end_to=${date_to}&`;
    fetch(`${urlApp + link}`, {
      headers: {
        "company-id": `${company_id}`,
        Authorization: `${cookieMaster.getCookie("access_token")}`,
      },
    })
      .then((data) => data.blob())
      .then((blob) => {
        let tmp = document.createElement("a");
        let csvData = new Blob([blob], { type: "text/xlsx" });
        let csvUrl = URL.createObjectURL(csvData);
        tmp.href = csvUrl;
        tmp.setAttribute("download", "tasks_from_view.xlsx");
        tmp.click();
      });
  };

  // console.log(selectData)
  return (
    <div className="projects_actions main_actions">
      <>
        <div className="project_select_wrapper">
          <FlexBlock>
            <UserProjectSelect
              changeProject={changeProject} //**************** */
              setChangeProject={setChangeProject} //************** */
              withImage={false}
              type="projects"
              itemsList={selectData.list.sort((a, b) =>
                a.text > b.text ? 1 : -1
              )}
            />
            {selectedProject && newRolesArr && newRolesArr[0] < 3 && (
              <DotsButton
                clickHandler={() => setTimeout(setShow, 0, true)}
                active={showMenu}
              >
                {showMenu &&  (
                  <div className="project_menu">
                    <div onClick={editClickHandler} className="edit_project">
                      Редактировать
                    </div>
                  </div>
                )}
              </DotsButton>
            )}

            <Toggler
              styles={{ marginLeft: "8px" }}
              elemsList={[
                { name: "Таблица", value: "table" },
                { name: "Дерево", value: "tree" },
              ]}
              selectHandler={(active) => {
                if (tableOrTreeToggler && tableOrTreeToggler !== active) {
                  setTableOrTreeToggler(active as string);
                  localStorage.setItem("project_show_toggle", active as string);
                }
              }}
              activeValue={tableOrTreeToggler as string}
            />
          </FlexBlock>
          <FlexBlock>
            <Icon
              name="load"
              width="32px"
              height="32px"
              onClick={() => uploadTasksExcel()}
            />
            <WrapperIconColumn showColumnsWindow={showColumnsWindow}>
              <Icon
                onClick={() => setShowColumnsProjectsCustom(!showColumnsWindow)}
                name="eye"
                // className={`columns_select${showColumnsWindow ? ' active' : ''}`}
              />
            </WrapperIconColumn>

            {/* Типовые бизнес-процессы
              <Button
                design={'outline'}
                style={{ marginRight: '16px' }}
                title={''}
                onClick={() => businessProcessesModalShow(true)}
              >
               Типовые бизнес-процессы
              </Button>
               */}
            {newRolesArr && newRolesArr[0] < 3 && (
              <Button
                title="Проект"
                icon="plus_circle"
                style={{
                  padding: ".5rem",
                  width: "100px",
                }}
                onClick={() => setProjectAddMode(true)}
              />
            )}
          </FlexBlock>
        </div>

        <div className="sub_actions">
          {/* Тут идет поле поиска */}
          <div style={{ position: "absolute", top: "-57px", right: "170px" }}>
            <TableSearch
              searchValue={tableFilter}
              setSearchValue={searchChangeHandler}
              isOpen={!!tableFilter}
            />
          </div>

          {/* Исправлено. Включим пользовательский поиск и для дерева проектов тоже */}
          {/*tableOrTreeToggler !== 'tree' && ()*/}

          {
            <Button
              design={default_filter_active ? "outline" : "blue_lightest"}
              onClick={() => ShowFiltersWindow()}
              style={{
                margin: "0 8px",
                padding: "8px",
                //width: '150px',
                minWidth: "150px",
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Icon name={"filter"} style={{ marginRight: "4px" }} />
              {filter_btn_inscription
                ? filter_btn_inscription
                : "Пользовательские"}
            </Button>
          }

          {/*********************************************** */}
          {tableOrTreeToggler == "tree" && (
            <div>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                checked={checked}
                onChange={changeCheckbox}
              ></input>
              <span style={{ cursor: "pointer" }} onClick={changeCheckbox}>
                {" "}
                Скрыть выполненные/отмененные задачи
              </span>
            </div>
          )}
          {/*********************************************** */}
        </div>
      </>

      {/*<FilterButton*/}
      {/*    context={'projects'}*/}
      {/*    text="Фильтр"*/}
      {/*    active={appliedFiltersExist}*/}
      {/*    addedClass={' filter_btn'}*/}
      {/*/>*/}

      {/*<DisplayTypeSelect selectedElem={{ text: 'Список', value: 'list' }} selectHandler={() => { }} />*/}

      {/*{showFiltersWindow && <ProjectsFilters/>}*/}
      {showColumnsWindow && <ColumnsWindow context={"projects"} />}
      {showFiltersWindow && <FiltersWindow context={"projects"} />}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    appliedFiltersExist: Boolean(
      Object.keys(state.projectsPage.filters.appliedFilters).length
    ),
    projects: getProjects(state),
    selectData: getProjectSelectData(state),
    selectedProject: state.projectsPage.selectedProject,
    sections: state.commonInfo.sections,
    // showFiltersWindow: state.projectsPage.filtersWindowShow,
    showFiltersWindow: state.commonInfo.filters.showFiltersWindow,
    currentUserRoles: state.commonInfo.currentUserInfo?.roles,
    tableFilter: state.projectsPage.tableFilter,
    projectId: state.projectsPage.selectedProject?.id || null,
    showColumnsWindow: state.projectsPage.showColumnsWindow,
    tableOrTreeToggler: state.projectsPage.tableOrTreeToggler,
    current_user: state.commonInfo.current_user,
    filters: state.commonInfo.filters,
  };
};

const mapDispatchToProps = {
  setProjectAddMode,
  setTableFilter,
  setShowColumnsProjectsCustom,
  changeTaskList,
  fetchTasks,
  selectProject,
  setIsFetchingTasks,
  openExistingProject,
  setTableOrTreeToggler,
  ShowFiltersWindow,
  DefaultFilterTemplates,
  FilterTemplates,
  setFilters,
  businessProcessesModalShow,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectActions);
