/*** Регистрация ****/
import React, { useState } from "react";
import { validateName } from "../../../utils/validPatterns";
import { validateEmail } from "../../../utils/validPatterns";
import { validateSurname } from "../../../utils/validPatterns";
import CustomBlockStandart from "./CustomBlock";
import CustomBlock from "./CustomBlockRegistration";
import styled from "styled-components";
import CustomInput from "../../../common/components/newComponent/Inputs/CustomInput";
import Checkbox from "../../../common/components/CheckboxItem/Checkbox";
import Button from "../../../common/components/newComponent/Buttons/Button";
import { validatePassword } from "../../../utils/validPatterns";
import { fetchData } from "../../../utils/fetchData";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { cookieMaster } from "../../../utils/CookieMaster";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../../rootReducer";
import { urlApp } from "../../../App";
import palette from "../../../common/components/palette"; //******************************************** */
import { CreateNotif } from "../../../utils/createNotification";
import { fetchToken } from "../actions";
import Input from "../../../common/components/Inputs/InputRegister";

import { useContext } from "react";
import { useStateContext } from "../../../useStateContext";

const CounterStyle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  color: #707070;
  margin-bottom: 18px;
  text-align: center;
`;
const CheckboxBlock = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #707070;
  display: flex;
  margin: 16px 0;
  span {
    margin-left: 8px;
  }
`;
const WrapperBlockInputs = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 6px;
  position: relative;
  margin-bottom: 24px;
`;

const TextStyle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #707070;
  margin-bottom: 12px;
`;

//************************************** */
const TextStyle2 = styled(TextStyle)`
  color: #00c;
`;
//************************************* */

const TextStyle3 = styled(TextStyle)`
  font-weight: bold;
  text-align: justify;
`;
/************************************* */

const Divider = styled.div`
  width: 5px;
  height: 5px;
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    align-items: center;
    padding: 8px 0;
    width: 132px;
  }
`;

// Документы - политика конфеденциальности и оферта
const Document = styled.a`
href= ${({ href }) => href};
title= ${({ href }) => href};
`;

const P = styled.p`
  margin-top: 24px;
  margin-left: 5px;
  text-align: center;
`;

const Register = () => {
  const {
    startOnboarding_time, //  Время, через которое после входа на сайт пользователь принимает решение зарегистироваться в системе (в секундах).
    setStartOnboarding_time, // Вычисляется с момента входа на сайт до момента нажатия на кнопку "Продолжить" при регистрации.
  } = useContext(useStateContext);

  let reg = false;

  const dispatch = useDispatch();
  const [counter, setCounter] = useState(1);
  const [confirmation, setСonfirmation] = useState(true);
  const [dataUser, setDataUser] = useState({
    email: "",
    name: "",
    surname: "",
    password: "",
    password_confirmation: "",
  });
  let history = useHistory();
  // let { current_user } = useSelector((state: State) => state.commonInfo);
  let validationEmail = /^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,5}$/i; // /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;
  let validationName = /^[a-zA-Zа-яА-ЯЁё-\s]*$/;
  let validationSurname = /^[a-zA-Zа-яА-ЯЁё-\s]*$/;

  const [visible, setVisible] = useState<boolean>(false);

  const [visible2, setVisible2] = useState<boolean>(false);

  return (
    <CustomBlock style={{ textAlign: "center" }} title={"Добро пожаловать"}>
      <>
        <form autoComplete="off">
          <Input
            inputType={"text"}
            placeholder="Имя"
            withIcon={false}
            value={dataUser.name}
            title="Имя"
            changeHandler={(value) => {
              setDataUser((prev) => {
                return {
                  ...prev,
                  name: value,
                };
              });
            }}
            isValid={dataUser.name ? validateName(dataUser.name) : true}
            validText="Имя должно состоять из русских или латинских букв"
          />
        </form>

        {/* **********************************************************  */}
        <div style={{ marginTop: "10px", height: "10px", width: "100%" }}></div>

        <form autoComplete="off">
          <Input
            inputType={"text"}
            placeholder="Фамилия"
            withIcon={false}
            value={dataUser.surname}
            title="Фамилия"
            changeHandler={(value) => {
              setDataUser((prev) => {
                return {
                  ...prev,
                  surname: value,
                };
              });
            }}
            isValid={
              dataUser.surname ? validateSurname(dataUser.surname) : true
            }
            validText="Фамилия должна состоять из русских или латинских букв"
          />
        </form>

        <form autoComplete="off"></form>

        <div style={{ marginTop: "20px", height: "0px", width: "100%" }}></div>

        <Input
          title={"email"}
          inputType={"email"}
          placeholder="Email (новый)"
          isValid={dataUser.email ? validateEmail(dataUser.email) : true}
          validText="введите пожалуйста email в это поле!"
          value={dataUser.email}
          changeHandler={(value) => {
            setDataUser((prev) => {
              return {
                ...prev,
                email: value,
              };
            });
          }}
        />

        <div style={{ marginTop: "20px", height: "0px", width: "100%" }}></div>

        <form autoComplete="off">
          <Input
            title="Пароль"
            inputType={visible ? "text" : "password"}
            placeholder="Пароль от 6 до 30 символов"
            isValid={
              dataUser.password ? validatePassword(dataUser.password) : true
            }
            validText="Пароль должен состоять из 6-30 символов и включать в себя хотя бы одно число и букву в верхнем регистре"
            value={dataUser.password}
            changeHandler={(value) => {
              setDataUser((prev) => {
                return {
                  ...prev,
                  password: value,
                };
              });
            }}
          />
        </form>
        <img
          onClick={() => {
            setVisible((prev) => !prev);
          }}
          style={{
            position: "relative",
            top: "-25px",
            left: "320px",
            width: "16px",
            height: "10px",
          }}
          src={require("../../../img/password_eye.png")}
        />

        <div style={{ marginTop: "10px", height: "0px", width: "100%" }}></div>

        <form autoComplete="off">
          <Input
            title="Пароль"
            inputType={visible2 ? "text" : "password"}
            placeholder="Подтверждение пароля от 6 до 30 символов"
            isValid={
              dataUser.password_confirmation
                ? validatePassword(dataUser.password_confirmation)
                : true
            }
            validText="Подтверждение пароля должно состоять из 6-30 символов и включать в себя хотя бы одно число и букву в верхнем регистре"
            value={dataUser.password_confirmation}
            changeHandler={(value) => {
              setDataUser((prev) => {
                return {
                  ...prev,
                  password_confirmation: value,
                };
              });
            }}
          />
        </form>
        <img
          onClick={() => {
            setVisible2((prev) => !prev);
          }}
          style={{
            position: "relative",
            top: "-25px",
            left: "320px",
            width: "16px",
            height: "10px",
          }}
          src={require("../../../img/password_eye.png")}
        />

        <CheckboxBlock>
          <span style={{ fontSize: "12px" }}>
            Продолжая регистрацию, я согласен с <br />
            <Document
              target="_blank"
              href={"https://easy-task.ru/policy"}
              title={" условия, политика конфиденциальности "}
            >
              {" "}
              условиями, политикой конфиденциальности{" "}
            </Document>
            <br />и
            <Document
              target="_blank"
              href={"https://easy-task.ru/oferta"}
              title={"договор оферты"}
            >
              {" "}
              договором оферты{" "}
            </Document>
          </span>
        </CheckboxBlock>
      </>

      <>
        <Button
          big
          disabled={
            validateEmail(dataUser.email) &&
            validatePassword(dataUser.password) &&
            validatePassword(dataUser.password_confirmation) &&
            validateName(dataUser.name) &&
            validateSurname(dataUser.surname)
              ? false
              : true
          }
          title={"Готово"}
          onClick={() => {
            let endOnboarding_time: any = new Date();

            // console.log("endOnboarding_time", endOnboarding_time);

            let onboarding_time_millisecond = Number(
              endOnboarding_time - startOnboarding_time
            );

            let onboarding_time = Math.floor(
              onboarding_time_millisecond / 1000
            );

            // console.log("onboarding_time", onboarding_time);

            let dataUserSend1 = {
              email: "",
              password: "",
              password_confirmation: "",
              name: "",
              surname: "",
              onboarding_time: 0,
            };
            dataUserSend1.email = dataUser.email; // Введенный email
            dataUserSend1.password = dataUser.password; // Введенный email
            dataUserSend1.password_confirmation =
              dataUser.password_confirmation; // Введенный email
            dataUserSend1.name = dataUser.name; // Введенное имя
            dataUserSend1.surname = dataUser.surname; // Введенная фамилия
            dataUserSend1.onboarding_time = onboarding_time; // Введенное время

            fetch(urlApp + "/api/v1/registration/step1", {
              credentials: "include",
              method: "POST",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
              },
              body: JSON.stringify(dataUserSend1),
            })
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                if (data.hasOwnProperty("success")) {
                  // console.log('data', data)
                  setCounter(counter + 1);
                  //    localStorage.setItem(dataUserSend1.email, "true");
                  localStorage.setItem("regEmail", dataUserSend1.email);

                  //************************************************************************************************************* */

                  let dataUserSend2 = {
                    email: "",
                    name: "",
                    surname: "",
                    password: "",
                  };
                  dataUserSend2.email = dataUser.email; // Введенный email
                  dataUserSend2.name = dataUser.name; // Введенное имя
                  dataUserSend2.surname = dataUser.surname; // Введенная фамилия
                  dataUserSend2.password = dataUser.password; // Введенный пароль

                  localStorage.removeItem("regEmail");

                  reg = true;

                  let dataUserSend3 = { email: "", password: "" };
                  dataUserSend3.email = dataUser.email; // Введенный email
                  dataUserSend3.password = dataUser.password; // Введенный пароль

                  fetch(urlApp + "/api/v1/login", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json;charset=utf-8",
                    },
                    body: JSON.stringify(dataUserSend3),
                  })
                    .then(function (response) {
                      return response.json();
                    })
                    .then(function (data) {
                      // console.log("data***", data);

                      if ("user_id" in data) {
                        // console.log('data', data)

                        localStorage.setItem("access_token", data.access_token);
                        localStorage.setItem("company_id", data.company_id);
                        localStorage.setItem("user_id", data.user_id);
                        localStorage.setItem("firstOpen", "true");

                        let token = data.access_token;

                        dispatch(
                          fetchToken(
                            dataUserSend3.email,
                            dataUserSend3.password
                          )
                        );

                        token = cookieMaster.getCookie("access_token");

                        setTimeout(() => {
                          if (token) {
                            history.push("/");
                          }
                        }, 500);
                      } else if ("error" in data) {
                        CreateNotif("Введен неверный пароль!  ", "warning");
                        setCounter(2);
                      }
                    })
                    .catch(function (error) {
                      console.log("error", error);
                      CreateNotif(
                        "Ошибка при регистрации. Попробуйте заново!",
                        "error"
                      );
                    });

                  //************************************************************************************************************** */
                } else if ("error" in data) {
                  CreateNotif(
                    "Такой email уже зарегистрирован. Или пароль не совпадает с подтверждением пароля",
                    "warning"
                  );
                  setCounter(1);
                }
              })
              .catch(function (error) {
                console.log("error", error);
                CreateNotif(
                  "Ошибка при регистрации. Попробуйте заново!",
                  "error"
                );
                setCounter(1);
              });
          }}
        />
      </>
    </CustomBlock>
  );
};

export default Register;
