import React, { FC } from 'react';

import DateMaster from '../../../../utils/DateMaster';

type Props = {
	end: string;
};

const Deadline: FC<Props> = ({ end }) => {
	const check = new DateMaster(end);

	const deadline = check.deadlineMini;

	return <span>{deadline}</span>;
};

export default Deadline;
