import React, { useCallback, useState } from "react";
import Input from "../../../common/components/Inputs/Input";
import { validatePassword } from "../../../utils/validPatterns";
import { validateEmail } from "../../../utils/validPatterns";
import { fetchToken } from "../actions";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import CustomBlock from "./CustomBlock";
import Button from "../../../common/components/newComponent/Buttons/Button";
import { useEffect } from "react";
import { cookieMaster } from "../../../utils/CookieMaster";
import { fetchData } from "../../../utils/fetchData";
import { CreateNotif } from "../../../utils/createNotification";
import axios from "axios";

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    margin-bottom: 8px;
  }
  a {
    text-decoration: none;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    transition: color 0.2s ease;
    &:hover {
      color: #707070;
    }
  }
`;

let token;
let company_id;
let user_id;
let isAdmin;

const Login = ({ fetchToken }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let history = useHistory();

  const formSubmitHandler = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      let emailReg = localStorage.getItem("regEmail");
      if (emailReg && emailReg == email) {
        CreateNotif(
          "Вы не завершили 2 шаг регистрации email! Начните, пожалуйста, процесс регистрации заново!",
          "warning"
        );
        localStorage.removeItem("regEmail");
      }

      e.preventDefault();

      // Получение 1 токена для программы Easy Task. Функция из saga.ts
      // Полученные в результате token, company_id  записывются в куки и localstorage

      fetchToken(email, password);

      // Получение user_id
      fetch(`${process.env.REACT_APP_PUBLIC_API}/login`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          // console.log("res",res);
          // document.cookie = `access_token=${res.access_token}`;

          let configForOneUser = {
            method: "get",
            url: `${process.env.REACT_APP_PUBLIC_API}/users/${res.user_id}`,
            headers: {
              Authorization: `Bearer ${res.access_token}`,
              company_id: res.company_id,
            },
          };

          axios(configForOneUser).then(async (response) => {
            let user = response.data.data;
            isAdmin = user.isAdmin;

            document.cookie = `user_id=${res.user_id}`;
            localStorage.setItem("user_id", res.user_id);
            //  console.log("user_id localstorage",  localStorage.getItem('user_id'));
            //  console.log("user_id куки",  parseInt(cookieMaster.getCookie('user_id')));

            // document.cookie = `company_id=${res.company_id}`;

            /***/

            // Получение 2 токена- access_token_jwt
            /********************** */

            let user_id_string = localStorage.getItem("user_id");
            let user_id = user_id_string ? +user_id_string : 1;

            // url: 'https://bpway.easy-task.ru/api/v1/login_and_get_key?isAuth=true&secret_key=kN6Gd9LpZBhpMbZNzVlAOsXLkIB7cZS36McLID2W7LJOU2GiweRxpuchNoiEg8ND8PI5Pf_easyTask&userId=756&isAdmin=true',

            let config = {
              method: "post",
              url: `${process.env.REACT_APP_BACKEND}/api/v1/login_and_get_key?isAuth=true&secret_key=kN6Gd9LpZBhpMbZNzVlAOsXLkIB7cZS36McLID2W7LJOU2GiweRxpuchNoiEg8ND8PI5Pf_easyTask&userId=${user_id}&isAdmin=${isAdmin}`,
              headers: {},
            };

            await axios(config)
              .then((response) => {
                console.log("Успешно!!!");
                // console.log("response",response);
                localStorage.setItem("access_token_jwt", response.data);
                return (document.cookie = `access_token_jwt=${response.data}`);
              })
              .catch(function (error) {
                console.log(error);
              });
          });

          /*
        localStorage.setItem("access_token_jwt", 
        "eyJhbGciOiJIUzI1NiJ9.eyJzZWNyZXRfa2V5Ijoia042R2Q5THBaQmhwTWJaTnpWbEFPc1hMa0lCN2NaUzM2TWNMSUQyVzdMSk9VMkdpd2VSeHB1Y2hOb2lFZzhORDhQSTVQZl9lYXN5VGFzayIsImlzQXV0aCI6InRydWUiLCJ1c2VySWQiOiI3NTYiLCJpc0FkbWluIjoidHJ1ZSJ9.W251bGxd"
        );
        document.cookie = `access_token_jwt=${
          "eyJhbGciOiJIUzI1NiJ9.eyJzZWNyZXRfa2V5Ijoia042R2Q5THBaQmhwTWJaTnpWbEFPc1hMa0lCN2NaUzM2TWNMSUQyVzdMSk9VMkdpd2VSeHB1Y2hOb2lFZzhORDhQSTVQZl9lYXN5VGFzayIsImlzQXV0aCI6InRydWUiLCJ1c2VySWQiOiI3NTYiLCJpc0FkbWluIjoidHJ1ZSJ9.W251bGxd"
        }`;
        */
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    [email, fetchToken, password]
  );

  useEffect(() => {
    let token = cookieMaster.getCookie("access_token");

    if (token) {
      history.push("/");
    }
  }, []);

  //***************************** */

  //******************************* */

  return (
    <CustomBlock title="Войти">
      <FormStyle onSubmit={formSubmitHandler}>
        <Input
          inputType="text"
          placeholder="Email"
          title=""
          withIcon={false}
          value={email}
          isValid={email ? validateEmail(email) : true}
          changeHandler={(value) => setEmail(value.trim())}
          validText="Введите, пожалуйста, email в этом поле"
        />
        <Input
          inputType="password"
          placeholder="Пароль"
          withIcon={true}
          value={password}
          title=""
          changeHandler={(value) => setPassword(value)}
          isValid={password ? validatePassword(password) : true}
          validText="Пароль должен состоять из 6-30 символов и включать в себя хотя бы одно число и букву в верхнем регистре"
        />

        <Link to="/forgot-password">Забыли пароль?</Link>
        <Button
          disabled={
            (validateEmail(email) && validatePassword(password)) ||
            email === "" ||
            password === ""
              ? false
              : true
          }
          title="Войти"
          big
          style={{ width: "100%", marginTop: "32px" }}
        />
      </FormStyle>
    </CustomBlock>
  );
};

const mapDispatchToProps = {
  fetchToken,
};

export default connect(null, mapDispatchToProps)(Login);
