import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
} from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import ColorPicker from './ColorPicker'
import MultySelect from '../../../common/components/Selects/MultySelect'
import LightboxFiles from './LightboxFiles'

import { Colors } from './Lightbox/Lightbox'
import { Item } from '../../../common/types'
import { State } from '../../../rootReducer'

const Chips = styled.div`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  background: rgb(230, 236, 239);
  color: rgb(33, 33, 33);
  border-radius: 20px;
  height: 32px;
  box-sizing: border-box;
  padding: 0 5px;
`

type RestData = {
  name: string
  description: string
}

type Props = {
  restData: RestData
  handleChangeData: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => void
  setParticipants: Dispatch<SetStateAction<Array<number | string>>>
  files: Array<File>
  setFiles: Dispatch<SetStateAction<Array<File>>>
  participants: Array<string | number>
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  changeDate: (event: ChangeEvent<HTMLInputElement>, toggle: boolean) => void
  changeTime: (event: ChangeEvent<HTMLInputElement>, toggle: boolean) => void
  color: Colors
  setColor: Dispatch<SetStateAction<Colors>>
}

const Conference: FC<Props> = ({
  restData,
  handleChangeData,
  setParticipants,
  files,
  setFiles,
  participants,
  changeTime,
  startTime,
  changeDate,
  startDate,
  endDate,
  endTime,
  color,
  setColor,
}) => {
  const { users, currentUserInfo } = useSelector(
    (state: State) => state.commonInfo
  )

  const participants_selected_items = useMemo(() => {
    const participants_items = participants.map((userId, number) => {
      const uuser = users.find((user) => user.id === userId)

      if (uuser) {
        return {
          text: uuser.name + ' ' + uuser.surname,
          value: uuser.id,
        }
      } else {
        return {
          text: '',
          value: number,
        }
      }
    })

    return participants_items
  }, [participants])

  return (
    <>
      <div className="lightbox-line">
        <div className="form-line decoration">
          <span>Название</span>
          <div className="inputs-container decoration">
            <input
              className="input-decoration"
              type="text"
              value={restData.name}
              onChange={(event) => handleChangeData(event, 'name')}
            />
          </div>
        </div>
      </div>
      <div className="lightbox-line">
        <div className="form-line">
          <span>Инициатор</span>
          <div className="inputs-container">
            <Chips>
              {currentUserInfo?.name} {currentUserInfo?.surname}
            </Chips>
          </div>
        </div>
        <div className="form-line">
          <span>Начало</span>
          <div className="inputs-container">
            <input
              value={startTime}
              onChange={(event) => changeTime(event, false)}
              type="time"
            />
            <input
              value={startDate}
              onChange={(event) => changeDate(event, false)}
              type="date"
            />
          </div>
        </div>
        <div className="form-line">
          <span>Окончание</span>
          <div className="inputs-container">
            <input
              value={endTime}
              type="time"
              onChange={(event) => changeTime(event, true)}
            />
            <input
              value={endDate}
              onChange={(event) => changeDate(event, true)}
              type="date"
            />
          </div>
        </div>
        <div className="form-line">
          <span>Учатники</span>
          <div className="inputs-container">
            <MultySelect
              itemsList={users.map((user) => ({
                text: user.name + ' ' + user.surname,
                value: user.id,
              }))}
              selectedItems={participants_selected_items as Item[]}
              addHandler={(value) => {
                setParticipants((prevState) => [...prevState, value])
              }}
              removeHandler={(val) => {
                const participants_selected_items_copy = [
                  ...participants_selected_items,
                ] as Item[]
                const usersId = participants_selected_items_copy.map((item) => {
                  return item.value
                })

                const newParts = usersId.filter((item) => item !== val)
                setParticipants(newParts)
              }}
            />
          </div>
        </div>
      </div>
      <div className="lightbox-line">
        <div className="form-line decoration">
          <span>Описание</span>
          <div className="inputs-container decoration">
            <textarea
              className="input-decoration textarea"
              value={restData.description}
              onChange={(event) => handleChangeData(event, 'description')}
            />
          </div>
        </div>
      </div>
      <div className="lightbox-line">
        <div className="form-line">
          <span>Прикрепить файл</span>
          <div className="inputs-container">
            <LightboxFiles files={files} setFiles={setFiles} />
          </div>
        </div>
        <div className="form-line">
          <span>Выбрать цвет</span>
          <div className="inputs-container">
            <ColorPicker color={color} setColor={setColor} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Conference
