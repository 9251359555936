import {takeEvery, call, put} from 'redux-saga/effects';
import {
    types,
    fetchTasksAction,
    fetchTasksVisualizationAction,
    readNotificationByTaskAction,
    timeSheetActionsAction,
    uploadFileAction,
    deleteFileAction,
    fetchTaskDependenceAction,
} from './action_types';

import {fetchData} from '../../utils/fetchData';
import {
    setLoadingTasks,
    changeTaskList,
    changeVisualisationList,
    LocalTimeSheetRecord,
    timeSheetActions,
    timesheetCacheRecord,
    fetchVisualizationList,
    closingDay,
    closingDayHidingSignal,
} from './actions';
import {store} from '../../App';
import {
    addCommentsList,
    clearCommentField,
    selectTask,
    setCommentFiles,
    setDependencies,
    setIsSendingDelegate,
    setIsSendongComment,
    setShownTaskInfoPlate,
} from '../TaskInfoPlate/actions';
import {
    CollectAllFilters,
    sorting,
    table_filter,
} from '../../common/shared_data';
import {
    change_remote_notifications,
    changeWorkSchedule,
    updateTask,
} from '../../common/actions';
import {
    delegateConfirmAction,
    uploadCommentFileAction,
} from '../TaskInfoPlate/action_types';
import {CreateNotif} from '../../utils/createNotification';
import {cookieMaster} from '../../utils/CookieMaster';
import {Dependences, File, Item, Task} from '../../common/types';
import {
    begin,
    dateFormatUTC,
    milisecondDateFromOurFormat,
    parseStringToDate,
} from '../TaskInfoPlate/reducer';

export function* watchFetchTasks() {
    yield takeEvery(types.FETCH_TASKS, fetchTasks);
}

export function* watchFetchTasksVisualization() {
    yield takeEvery(types.FETCH_TASKS_VISUALIZATION, fetchTasksVisualization);
}

export function* watchFetchTaskDependence() {
    yield takeEvery(types.FETCH_TASK_DEPENDENCE, fetchTaskDependence);
}

export function* watchLoadSpecificTask() {
    yield takeEvery(types.LOAD_SPECIFIC_TASK, loadSpecificTask);
}

export function* watchSetStatusFilterTasks() {
    yield takeEvery(types.SET_FILTERS, apply_filters_orders);
}

export function* watchFiltersActualToggle() {
    yield takeEvery(types.SET_ALL_OR_ACTUAL, apply_filters_orders);
}

export function* watchSetTableOrderTasks() {
    yield takeEvery(types.SET_TABLE_ORDER, apply_filters_orders);
}

export function* watchReadTaskNotification() {
    yield takeEvery(types.READ_NOTIFICATION_BY_TASK, readTaskNotification);
}

export function* watcherUploadFile() {
    yield takeEvery(types.UPLOAD_FILE_TASKS, uploadFile);
}

export function* watcherDeleteFile() {
    yield takeEvery(types.DELETE_FILE, deleteFile);
}

export function* watchTimeSheetActions() {
    yield takeEvery(types.TIME_SHEET_ACTIONS, timeSheetActs);
}

export function* watchSendCommentTasks() {
    yield takeEvery(types.SEND_COMMENT, sendComment);
}

export function* watchClosingDayDispatchAll() {
    yield takeEvery(types.CLOSING_DAY_DISPATCH_ALL, closingDayDispatchAll);
}

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* closingDayDispatchAll() {
    const TimeSheetCache = store.getState().tasksPage.TimeSheetCache;
    const timeSheet = store.getState().tasksPage.timeSheet;
    const current_user = store.getState().commonInfo.current_user;

    // тасклоадсум берется из этого массива (передается измененное из "закрытие дня")
    for (let k = 0; k < TimeSheetCache.length; k++) {
        let task_load_sum = TimeSheetCache[k].task_load_sum;


        // Тут должно измениться значение в задаче    
        // console.log("task_load_sum****",task_load_sum)
        if (task_load_sum)
            yield put(
                timeSheetActions('post', TimeSheetCache[k].task_id, task_load_sum)  // посылается по очереди на сервер каждая измененная задача
            );
    }

    // статус, коммент текст и файлы отсюда
    for (let i = 0; i < timeSheet.length; i++) {
        let task_Id = timeSheet[i].task_id;
        let commentText = timeSheet[i].comment_text;

        if (timeSheet[i].comment_text && timeSheet[i].comment_text !== '')
            yield sendComment({task_Id, commentText});
        if (timeSheet[i].status_id)
            yield put(
                updateTask(timeSheet[i].task_id, {status_id: timeSheet[i].status_id})
            );
    }

    yield put(closingDayHidingSignal(true))

    yield put(LocalTimeSheetRecord('clear_whole', 0));
    yield put(timesheetCacheRecord('clear'));
    yield put(fetchVisualizationList(current_user));

    yield call(delay, 800);
    yield put(closingDay(false));
}

function* sendComment({task_Id, commentText}: any) {
    // yield put(setIsSendongComment(true));
    // const infoPlateData = taskInfoPlateData ? taskInfoPlateData : store.getState().taskInfoPlate;
    const foundItem = store
        .getState()
        .tasksPage.timeSheet.find((item) => item.task_id === task_Id);
    const author_id = store.getState().commonInfo.current_user;

    const body = {
        text: commentText,
        author_id,
        task_id: task_Id,
        parent_id: 0,
    };

    if (foundItem?.comment_files?.length) {
        body['files'] = foundItem?.comment_files.map((file) => file.id);
    }

    const comment_done = yield call(
        fetchData.post,
        `/api/v1/tasks/${task_Id}/comments`,
        JSON.stringify(body)
    );

    if (comment_done) {
        LocalTimeSheetRecord('clear_whole', task_Id);

        // yield put(addCommentsList([comment_done]));
        // yield put(clearCommentField());
    }
    // yield put(setIsSendongComment(false));
}

function* uploadFile({file}: uploadFileAction) {
    const formData = new FormData();
    // @ts-ignore
    formData.append('file', file);

    const data = yield call(fetchData.post, '/api/v1/files', formData, {
        Authorization: cookieMaster.getCookie('access_token'),
    });

    if (data) {
        let taskId = store.getState().tasksPage.taskDoneConfirmation.taskId;
        let timeSheet = store.getState().tasksPage.timeSheet;
        let found_timesheet = timeSheet.find((task) => task.task_id === taskId);

        yield put(
            LocalTimeSheetRecord(
                'add',
                taskId,
                null,
                null,
                null,
                '',
                found_timesheet && found_timesheet.comment_files
                    ? [...(found_timesheet.comment_files as File[]), data]
                    : [data]
            )
        );

        // let files = timeSheet.slice();
        // yield put(setCommentFiles([...files, data]));
    }
}

function* deleteFile({attachmentId, taskId}: deleteFileAction) {
    let data = yield call(fetchData.delete, `/api/v1/files/${attachmentId}`);
    if (data) {
        let tSheet = store.getState().tasksPage.timeSheet;

        let sheetCommentFiles = tSheet.find(
            (record) => record.task_id === taskId
        )?.comment_files;

        if (sheetCommentFiles)
            sheetCommentFiles.splice(
                sheetCommentFiles.findIndex((rec) => rec.id === attachmentId),
                1
            );

        yield put(
            LocalTimeSheetRecord(
                'add',
                taskId as number,
                null,
                null,
                null,
                '',
                sheetCommentFiles
            )
        );

        // let files = store.getState().taskInfoPlate.commentFiles.slice(),
        //     index = files.findIndex((file) => file.id === attachmentId);

        // files.splice(index, 1);
        // yield put(setCommentFiles(files));
    }
}

function* timeSheetActs({
                            actionType,
                            taskId,
                            value,
                            timesheetId,
                        }: timeSheetActionsAction) {
    switch (actionType) {
        case 'get':
            let response = yield call(
                fetchData.get,
                `/api/v1/tasks/${taskId}/timesheets`
            );
            break;
        case 'post':
            let task = yield call(
                fetchData.post,
                `/api/v1/tasks/${taskId}/timesheets`,
                JSON.stringify({
                    spent_time: value,
                    write_off_date: store.getState().taskInfoPlate.begin.split(' ')[0],
                })
            );
            break;
        case 'patch':
            let respons21e = yield call(
                fetchData.patch,
                `/api/v1/tasks/${taskId}/timesheets/${timesheetId}`,
                JSON.stringify({
                    spent_time: value,
                    write_off_date: store.getState().taskInfoPlate.begin.split(' ')[0],
                })
            );
            break;
        case 'delete':
            let respons1e = yield call(
                fetchData.delete,
                `/api/v1/tasks/${taskId}/timesheets/${timesheetId}`
            );
            break;
    }

    // let {tasks} = store.getState().tasksPage;
    // yield put(selectTask(new_data_task)); // обновляю новыми данными задачу в taskInfoPlate

    // if (task) yield put(successfulDelegatedTo([...taskInfoPlate.successfulDelegatedTo, task.id]))
}

function* readTaskNotification({id_task}: readNotificationByTaskAction) {
    const current_user_id = store.getState().commonInfo.currentUserInfo?.id;

    const {remote_notifications} = store.getState().commonInfo;
    let new_data_as_read: any = [...remote_notifications.read];
    let new_data_as_unread = [...remote_notifications.unread];
    let nitification: any[] = [];
    remote_notifications.unread.forEach((notification: any) => {
        if (notification?.data?.task_id === id_task) {
            nitification.push(notification.id);

            new_data_as_unread.forEach(
                (el: any, i) =>
                    el.data.task_id === id_task && new_data_as_unread.splice(i, 1)
            );
            new_data_as_read.unshift(notification);
        }
    });
    for (let i = 0; i < nitification.length; i++) {
        yield call(
            fetchData.patch,
            `/api/v1/users/${current_user_id}/read-notification?id=${nitification[i]}`,
            {}
        );
    }
    yield put(change_remote_notifications(new_data_as_unread, new_data_as_read));
}

function* loadSpecificTask(data) {
    const response = yield call(fetchData.get, '/api/v1/tasks/' + data.value);
    if (response) {
        yield put(selectTask(response));
        yield put(setShownTaskInfoPlate(true));
    }
}

function* apply_filters_orders() {
    const {
        selectedUserId,
        // filters,
        tasksOrder,
        tableFilter,
    } = store.getState().tasksPage;

    const {current_set} = store.getState().commonInfo.filters;

    let сopyed_obj = {
        date_from: '',
        date_to: '',
        executors: [] as Item[],
        authors: [] as Item[],
        statuses: [] as number[],
        actual: true,
    };
    Object.keys(current_set).forEach((key) => {
        let keyType = typeof current_set[key];
        if (keyType === 'object') сopyed_obj[key] = [...current_set[key]];
        else сopyed_obj[key] = current_set[key];
    });

    // проверяю, существует ли наличие статуса "просрочено"
    let outdated = false;
    let in_work_native = false;
    if (сopyed_obj.statuses.includes(14)) {
        if (сopyed_obj.statuses.includes(10)) {
            // статус "в работе" тоже есть. Тогда статус "просрочено" удаляем, и оставляем признак outdated
            let index = сopyed_obj.statuses.findIndex((it) => it === 14);
            сopyed_obj.statuses.splice(index, 1);
            in_work_native = true;
        } else {
            // статуса "в работе" нет, тогда меняем 14 статус на 10
            сopyed_obj.statuses[сopyed_obj.statuses.indexOf(14)] = 10;
        }
        outdated = true;
    }

    const response = yield call(
        fetchData.get,
        `/api/v1/tasks?page=1` +
        ActualOrAll(сopyed_obj, selectedUserId, CollectAllFilters(сopyed_obj)) +
        sorting(tasksOrder) +
        table_filter(tableFilter)
    );

    if (response) {
        if (outdated) {
            //
            if (in_work_native) {
                // не нужно фильтровать по дедлайну с 10 статусом, "в работе" тоже нужны
                yield put(changeTaskList([...response]));
            } else {
                let filtered = response.filter((respItem) => {
                    if (respItem.status_id === 10) {
                        if (
                            new Date(Date.now()) >
                            new Date(milisecondDateFromOurFormat(respItem.end))
                        )
                            return respItem;
                    }
                });
                yield put(
                    changeTaskList([
                        ...response.filter((item) => item.status_id !== 10),
                        ...filtered,
                    ])
                );
            }
        } else {
            yield put(changeTaskList([...response]));
        }
    }
}

function* fetchTaskDependence({id}: fetchTaskDependenceAction) {
    if (id) {
        let dependencies: Dependences = {
            child: [],
            parent: null,
            next: null,
            prev: null,
        };
        const task = yield call(fetchData.get, `/api/v1/tasks/${id}`);
        if (task.parent_id !== null) {
            let parent = yield call(fetchData.get, `/api/v1/tasks/${task.parent_id}`);
            dependencies.parent = parent;
        }

        if (task.next_id !== null) {
            yield fetchData
                .get(`/api/v1/tasks/${task.next_id}`)
                .then((data: Task) => (dependencies.next = data));
        }
        if (task.prev_id !== null) {
            yield fetchData
                .get(`/api/v1/tasks/${task.prev_id}`)
                .then((data: Task) => (dependencies.prev = data));
        }
        if (task?.child_tasks?.length > 0) {
            for (let i = 0; i < task?.child_tasks?.length; i++) {
                let child = yield call(
                    fetchData.get,
                    `/api/v1/tasks/${task.child_tasks[i]}`
                );
                dependencies.child.push(child);
            }
        }
        yield put(setDependencies(dependencies));
    }
}

function* fetchTasks({pageNum}: fetchTasksAction) {
    const {
        selectedUserId,
        tasks: taskList,
        tableFilter,
        isLoading,
        tasksOrder,
    } = store.getState().tasksPage;

    const {current_set} = store.getState().commonInfo.filters;

    // делаю копию объекта стэйта, чтобы оригинальный стэйт не изменился
    let Copyed_obj = {
        date_from: '',
        date_to: '',
        executors: [] as Item[],
        authors: [] as Item[],
        statuses: [] as number[],
        actual: true,
    };
    Object.keys(current_set).forEach((key) => {
        let keyType = typeof current_set[key];
        if (keyType === 'object') Copyed_obj[key] = [...current_set[key]];
        else Copyed_obj[key] = current_set[key];
    });

    // проверяю, существует ли наличие статуса "просрочено"
    let outdated = false;
    let in_work_native = false;
    if (Copyed_obj.statuses.includes(14)) {
        if (Copyed_obj.statuses.includes(10)) {
            // статус "в работе" тоже есть. Тогда статус "просрочено" удаляем, и оставляем признак outdated
            let index = Copyed_obj.statuses.findIndex((it) => it === 14);
            Copyed_obj.statuses.splice(index, 1);
            in_work_native = true;
        } else {
            // статуса "в работе" нет, тогда меняем 14 статус на 10
            Copyed_obj.statuses[Copyed_obj.statuses.indexOf(14)] = 10;
        }
        outdated = true;
    }

    if (!isLoading && pageNum === 1) yield put(setLoadingTasks(true));

    const response = yield call(
        fetchData.get,
        `/api/v1/tasks?page=${pageNum}` +
        ActualOrAll(Copyed_obj, selectedUserId, CollectAllFilters(Copyed_obj)) +
        sorting(tasksOrder) +
        table_filter(tableFilter)
    );
    if (response) {
        if (outdated) {
            if (in_work_native) {
                // не нужно фильтровать по дедлайну с 10 статусом, "в работе" тоже нужны
                yield put(changeTaskList([...taskList, ...response]));
            } else {
                let filtered = response.filter((respItem) => {
                    if (respItem.status_id === 10) {
                        if (
                            new Date(Date.now()) >
                            new Date(milisecondDateFromOurFormat(respItem.end))
                        )
                            return respItem;
                    }
                });
                yield put(
                    changeTaskList([
                        ...taskList,
                        ...filtered,
                        ...response.filter((item) => item.status_id !== 10),
                    ])
                );
            }
        } else {
            yield put(changeTaskList([...taskList, ...response]));
        }
    }

    const workSchedule = yield call(
        fetchData.get,
        `/api/v1/users/${selectedUserId}/schedule`
    );

    if (workSchedule) yield put(changeWorkSchedule(workSchedule.schedule));

    const visualisationRequest = yield call(
        fetchData.get,
        '/api/v1/tasks?executor_id=' +
        selectedUserId +
        `&order=visual&orderType=deadline`
    );
    if (visualisationRequest && pageNum === 1) {
        // yield put(clearVisualizationList());
        yield put(changeVisualisationList(visualisationRequest));
    }

    if (visualisationRequest && visualisationRequest.length > 70) {
        visualisationRequest.splice(70);
        yield put(changeVisualisationList(visualisationRequest));
    } else if (visualisationRequest) {
        yield put(changeVisualisationList(visualisationRequest));
    }
    yield put(setLoadingTasks(false));
}

function* fetchTasksVisualization({
                                      selectedUserId,
                                  }: fetchTasksVisualizationAction) {
    // yield put(clearVisualizationList());
    const visualisationRequest = yield call(
        fetchData.get,
        '/api/v1/tasks?executor_id=' +
        selectedUserId +
        `&order=visual&orderType=deadline`
    );
    if (visualisationRequest && visualisationRequest.length > 70) {
        visualisationRequest.splice(70);
        yield put(changeVisualisationList(visualisationRequest));
    } else if (visualisationRequest) {
        yield put(changeVisualisationList(visualisationRequest));
    }
}

function ActualOrAll(filters, selectedUserId, collectedFilters) {
    let prefix = '';
    // if (filters.exec_auth === 'executor') {
    //     prefix += '&executor_id=';
    // } else if (filters.exec_auth === 'author') {
    //     prefix += '&author_id=';
    // } else {
    //     prefix += '&displayType=relevant' + '&userId=';
    // }

    if (filters.actual) {
        if (filters.authors.length + filters.executors.length === 1) {

            // prefix += '&displayType=relevant';

            // if (filters.authors.length) prefix += 'author_id=' + filters.authors[0]
            // else prefix += 'executor_id=' + filters.executors[0]
        }
    }

    // if (filters.actual === true && filters.date_from === '' && filters.date_to === '' && !filters.executors.length && !filters.authors.length && !filters.statuses.length) {
    //     prefix += '&displayType=relevant&userId=' + selectedUserId
    // }
    // console.log(filters, prefix)
    return prefix + collectedFilters;
}
