import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import { nanoid } from "nanoid";
import AddFileButton from "../templates/AddFileButton";
import NameOfStep from "../templates/NameOfStep";
import "../BpResultForm.scss";

let fileTranslate;

const Signing = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.signing?.extra ? settingsOfFields.signing.extra : {}
  );
  const url = taskId ? `${apiBp}/task/${taskId}` : null;

  const [allFilesSigning1, setAllFilesSigning1] = useState([]);
  const [allFilesSigning2, setAllFilesSigning2] = useState([]);
  const [allFilesSigning3, setAllFilesSigning3] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  //Добавляет файлы
  const onSendFileSigning = (e, numberFilesSigning) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesSigning === "filesSigning1") {
        if (file && file.name) {
          setAllFilesSigning1([
            ...allFilesSigning1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesSigning1",
            },
          ]);

          console.log("allFilesSigning1", allFilesSigning1);
        }
      } else if (numberFilesSigning === "filesSigning2") {
        if (file && file.name) {
          setAllFilesSigning2([
            ...allFilesSigning2,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesSigning2",
            },
          ]);
        }
      } else if (numberFilesSigning === "filesSigning3") {
        if (file && file.name) {
          setAllFilesSigning3([
            ...allFilesSigning3,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesSigning3",
            },
          ]);
        }
      }
    }, [500]);
  };
  //Удаляет файл
  const onDeletedFileSigning = (keyId, numberFilesSigning) => {
    if (numberFilesSigning === "filesSigning1") {
      setAllFilesSigning1(allFilesSigning1.filter((file) => file.id !== keyId));
    } else if (numberFilesSigning === "filesSigning2") {
      setAllFilesSigning2(allFilesSigning2.filter((file) => file.id !== keyId));
    } else if (numberFilesSigning === "filesSigning3") {
      setAllFilesSigning3(allFilesSigning3.filter((file) => file.id !== keyId));
    }
  };

  //Смотрит на какую кнопку какого раздела с файлами было нажато
  const onClickFetchFilesSigning = (e, numberFilesSigning) => {
    e.preventDefault();
    console.log(numberFilesSigning);

    let data;
    if (numberFilesSigning === "filesSigning1") {
      data = JSON.stringify({
        files: allFilesSigning1,
      });
      console.log("Нажал на кнопку для отправки файлов filesSigning1");
    }

    if (numberFilesSigning === "filesSigning2") {
      data = JSON.stringify({
        files: allFilesSigning2,
      });
      console.log("Нажал на кнопку для отправки файлов filesSigning2");
    }

    if (numberFilesSigning === "filesSigning3") {
      data = JSON.stringify({
        files: allFilesSigning3,
      });
      console.log("Нажал на кнопку для отправки файлов filesSigning3");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesSigning === "filesSigning1") {
      setAllFilesSigning1([]);
    }

    if (numberFilesSigning === "filesSigning2") {
      setAllFilesSigning2([]);
    }

    if (numberFilesSigning === "filesSigning3") {
      setAllFilesSigning3([]);
    }
  };

  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.Signing, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);
  return (
    <div>
      <NameOfStep text={"Подписание и прикрепление договора"} />
      <div className="file-uploaded__block">
        <ul>
          {taskCurrentGlobal &&
            taskCurrentGlobal["files"] &&
            taskCurrentGlobal["files"]["list"]
              .filter((item) => item?.tag == "filesSigning1")
              .map((item2) => (
                <li>
                  <a
                    href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                    target="_blank"
                  >
                    {item2.name}
                  </a>
                </li>
              ))}
        </ul>
      </div>
      <AddFileButton
        filesList={taskCurrentGlobal}
        data={data}
        setData={setData}
        id={"g7"}
        handlerOnSendFile={onSendFileSigning}
        handlerOnDeletedFile={onDeletedFileSigning}
        handlerOnClickFetchFiles={onClickFetchFilesSigning}
        allFiles={allFilesSigning1}
        numberFiles={"filesSigning1"}
      />
    </div>
  );
};

export default Signing;
