import axios from "axios";
import { nanoid } from "nanoid";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import AddFileButton from "../templates/AddFileButton";
import CheckboxElement from "../templates/CheckboxElement";
import NameOfStep from "../templates/NameOfStep";
import RadioButtons from "../templates/RadioButtons";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import SelectTemplate from "../templates/SelectTemplate";
import "../BpResultForm.scss";
// пропс в последующем можно удалить
//

let fileTranslate;

const CheckSecurity = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [allCheckSecurity, setAllCheckSecurity] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  //Добавляет файлы
  const onSendFile = (e, numberCheckSecurity) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberCheckSecurity === "CheckSecurity") {
        if (file && file.name) {
          setAllCheckSecurity([
            ...allCheckSecurity,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "CheckSecurity",
            },
          ]);

          console.log("allCheckSecurity", allCheckSecurity);
        }
      }
    }, [500]);
  };
  //Удаляет файл
  const onDeletedFile = (keyId, numberCheckSecurity) => {
    if (numberCheckSecurity === "CheckSecurity") {
      setAllCheckSecurity(allCheckSecurity.filter((file) => file.id !== keyId));
    }
  };

  //Смотрит на какую кнопку какого раздела с файлами было нажато
  const onClickFetchCheckSecurity = (e, numberCheckSecurity) => {
    e.preventDefault();
    console.log(numberCheckSecurity);

    let data;
    if (numberCheckSecurity === "CheckSecurity") {
      data = JSON.stringify({
        files: allCheckSecurity,
      });
      console.log("Нажал на кнопку для отправки файлов CheckSecurity");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });
    setAllCheckSecurity([]);
  };

  const [data, setData] = useState(
    settingsOfFields?.checkSecurity?.extra
      ? settingsOfFields.checkSecurity.extra
      : {}
  );
  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.checkSecurity, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="checkSecurity" className="form">
      <div>
        <NameOfStep text={"Пометка к отчету"} />
        <div className="file-uploaded__block">
          <ul>
            {taskCurrentGlobal &&
              taskCurrentGlobal["files"] &&
              taskCurrentGlobal["files"]["list"]
                .filter((item) => item?.tag == "CheckSecurity")
                .map((item2) => (
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                      target="_blank"
                    >
                      {item2.name}
                    </a>
                  </li>
                ))}
          </ul>
        </div>
        <AddFileButton
          filesList={taskCurrentGlobal}
          data={data}
          setData={setData}
          id={"b2"}
          handlerOnSendFile={onSendFile}
          handlerOnDeletedFile={onDeletedFile}
          handlerOnClickFetchFiles={onClickFetchCheckSecurity}
          allFiles={allCheckSecurity}
          numberFiles={"CheckSecurity"}
        />
        <SelectTemplate
          listArr={["Отчет положительный", "Отчет отрицательный"]}
          name={"b2_1"}
          data={data}
          setData={setData}
        />
      </div>
      <div></div>
    </form>
  );
};

export default CheckSecurity;
