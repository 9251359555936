import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { StatusContext } from "../../../context/status";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import SelectTemplate from "../templates/SelectTemplate";
import AddFileButton from "../templates/AddFileButton";
import RadioButtons from "../templates/RadioButtons";
import NameOfStep from "../templates/NameOfStep";
import "../BpResultForm.scss";

let fileTranslate;
const formOfEmployees = [
  "Cотрудник1",
  "Cотрудник2",
  "Cотрудник3",
  "Cотрудник4",
  "Cотрудник5",
];
const typeOfRelationshipFormat = ["ГПХ", "ТД", "СМ3", "ИП", "вне штата"];
const typeOfGroundsForDismissal = [
  "заявление на увольнение",
  "соглашение сторон",
  "сокращение",
  "статья",
];
const BpResultFormDismissal = () => {
  const {
    users,
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  // Если settingsOfFields.initDismissal.extra отсутствует- передаем пустой объект
  const [data, setData] = useState(
    settingsOfFields?.initDismissal?.extra
      ? settingsOfFields.initDismissal.extra
      : {}
  );

  const [createFormDate, setCreateFormDate] = useState({});

  const [allFilesTreaty1, setAllFilesTreaty1] = useState([]);
  const [allFilesTreaty2, setAllFilesTreaty2] = useState([]);
  const [allFilesTreaty3, setAllFilesTreaty3] = useState([]);
  const [allFilesTreaty4, setAllFilesTreaty4] = useState([]);
  const [allFilesTreaty5, setAllFilesTreaty5] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("answer", data);
  //console.log("businessProcessCurrentGlobal",businessProcessCurrentGlobal);

  //Добавляет файлы
  const onSendFileTreaty = (e, numberFilesTreaty) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesTreaty === "filesTreaty1") {
        if (file && file.name) {
          setAllFilesTreaty1([
            ...allFilesTreaty1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesTreaty1",
            },
          ]);
        }
      } else if (numberFilesTreaty === "filesTreaty2") {
        if (file && file.name) {
          setAllFilesTreaty2([
            ...allFilesTreaty2,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesTreaty2",
            },
          ]);
        }
      } else if (numberFilesTreaty === "filesTreaty3") {
        if (file && file.name) {
          setAllFilesTreaty3([
            ...allFilesTreaty3,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesTreaty3",
            },
          ]);
        }
      } else if (numberFilesTreaty === "filesTreaty4") {
        if (file && file.name) {
          setAllFilesTreaty4([
            ...allFilesTreaty4,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesTreaty4",
            },
          ]);
        }
      } else if (numberFilesTreaty === "filesTreaty5") {
        if (file && file.name) {
          setAllFilesTreaty5([
            ...allFilesTreaty5,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesTreaty5",
            },
          ]);
        }
      }
    }, [500]);
  };
  //Удаляет файлы
  const onDeletedFileTreaty = (keyId, numberFilesTreaty) => {
    if (numberFilesTreaty === "filesTreaty1") {
      setAllFilesTreaty1(allFilesTreaty1.filter((file) => file.id !== keyId));
    } else if (numberFilesTreaty === "filesTreaty2") {
      setAllFilesTreaty2(allFilesTreaty2.filter((file) => file.id !== keyId));
    } else if (numberFilesTreaty === "filesTreaty3") {
      setAllFilesTreaty3(allFilesTreaty3.filter((file) => file.id !== keyId));
    } else if (numberFilesTreaty === "filesTreaty4") {
      setAllFilesTreaty4(allFilesTreaty4.filter((file) => file.id !== keyId));
    } else if (numberFilesTreaty === "filesTreaty5") {
      setAllFilesTreaty5(allFilesTreaty5.filter((file) => file.id !== keyId));
    }
  };

  //Смотрит на какую кнопку какого рездела с файлами было нажато
  const onClickFetchFilesTreaty = (e, numberFilesTreaty) => {
    e.preventDefault();

    let data;

    if (numberFilesTreaty === "filesTreaty1") {
      data = JSON.stringify({
        files: allFilesTreaty1,
      });
      console.log("Нажал на кнопку для отправки файлов filesTreaty1");
    }

    if (numberFilesTreaty === "filesTreaty2") {
      data = JSON.stringify({
        files: allFilesTreaty2,
      });
      console.log("Нажал на кнопку для отправки файлов filesTreaty2");
    }

    if (numberFilesTreaty === "filesTreaty3") {
      data = JSON.stringify({
        files: allFilesTreaty3,
      });
      console.log("Нажал на кнопку для отправки файлов filesTreaty3");
    }

    if (numberFilesTreaty === "filesTreaty4") {
      data = JSON.stringify({
        files: allFilesTreaty4,
      });
      console.log("Нажал на кнопку для отправки файлов filesTreaty4");
    }

    if (numberFilesTreaty === "filesTreaty5") {
      data = JSON.stringify({
        files: allFilesTreaty5,
      });
      console.log("Нажал на кнопку для отправки файлов filesTreaty5");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })

      .catch((e) => {
        console.log(e);
      });

    if (numberFilesTreaty === "filesTreaty1") {
      setAllFilesTreaty1([]);
    }

    if (numberFilesTreaty === "filesTreaty2") {
    }

    if (numberFilesTreaty === "filesTreaty3") {
      setAllFilesTreaty3([]);
    }

    if (numberFilesTreaty === "filesTreaty4") {
      setAllFilesTreaty4([]);
    }

    if (numberFilesTreaty === "filesTreaty5") {
      setAllFilesTreaty5([]);
    }
  };

  const url = taskId ? `${apiBp}/task/${taskId}` : null;

  /*
  useEffect(() => {
    setData((prevState) => {
      return { ...prevState, a1_11: createFormDate };
    });
  }, [createFormDate]);
 */

  useEffect(() => {
    console.log("use in form worked");
    if (settingsOfFields && settingsOfFields.initDismissal && taskId) {
      axios
        .patch(
          url,
          {
            ...settingsOfFields.initDismissal,
            extra: data,
          },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="init">
      <div>
        <NameOfStep text={"Сотрудник"} />
        <SelectTemplate
          listArr={users}
          name={"a1"}
          data={data}
          setData={setData}
        />
      </div>

      <div>
        <NameOfStep text={"Работодатель"} />
        <TextAreaOrInputElement
          name={"a1_1"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
          placeholder={"указывается ВГО, из которой увольняется сотрудник"}
        />
      </div>

      <div>
        <NameOfStep text={"Формат взаимоотношений"} />
        <SelectTemplate
          listArr={typeOfRelationshipFormat}
          name={"a1_2"}
          data={data}
          setData={setData}
        />
      </div>
      {data.a1_2 === "ТД" && (
        <>
          <div>
            <NameOfStep text={"Основание для увольнения"} />
            <SelectTemplate
              listArr={typeOfGroundsForDismissal}
              name={"a1_3"}
              data={data}
              setData={setData}
            />
          </div>
          {data.a1_3 === "заявление на увольнение" && (
            <div>
              <NameOfStep text={"Заявление на увольнение"} />

              <>
                <div className="file-uploaded__block">
                  <ul>
                    {taskCurrentGlobal &&
                      taskCurrentGlobal["files"] &&
                      taskCurrentGlobal["files"]["list"]
                        .filter((item) => item.tag == "filesTreaty1")
                        .map((item2) => (
                          <li>
                            <a
                              href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                              target="_blank"
                            >
                              {item2.name}
                            </a>
                          </li>
                        ))}
                  </ul>
                </div>
                <AddFileButton
                  filesList={taskCurrentGlobal}
                  data={data}
                  setData={setData}
                  id={"a1_4"}
                  handlerOnSendFile={onSendFileTreaty}
                  handlerOnDeletedFile={onDeletedFileTreaty}
                  handlerOnClickFetchFiles={onClickFetchFilesTreaty}
                  allFiles={allFilesTreaty1}
                  numberFiles={"filesTreaty1"}
                />
              </>
            </div>
          )}

          {data.a1_3 === "соглашение сторон" && (
            <div>
              <NameOfStep text={"Соглашение сторон"} />
              <RadioButtons
                data={data}
                setData={setData}
                name={"a1_5"}
                positiveText={"Дружественное"}
                negativeText={"Не дружественное"}
              />
            </div>
          )}
          {data.a1_3 === "сокращение" && (
            <div>
              <NameOfStep text={"Сокращение"} />
              <RadioButtons
                data={data}
                setData={setData}
                name={"a1_6"}
                positiveText={"Дружественное"}
                negativeText={"Не дружественное"}
              />
            </div>
          )}
          {data.a1_3 === "статья" && (
            <div>
              <NameOfStep text={"Статья"} />
              <>
                <div className="file-uploaded__block">
                  <ul>
                    {taskCurrentGlobal &&
                      taskCurrentGlobal["files"] &&
                      taskCurrentGlobal["files"]["list"]
                        .filter((item) => item.tag == "filesTreaty2")
                        .map((item2) => (
                          <li>
                            <a
                              href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                              target="_blank"
                            >
                              {item2.name}
                            </a>
                          </li>
                        ))}
                  </ul>
                </div>
                <AddFileButton
                  filesList={taskCurrentGlobal}
                  data={data}
                  setData={setData}
                  id={"a1_7"}
                  handlerOnSendFile={onSendFileTreaty}
                  handlerOnDeletedFile={onDeletedFileTreaty}
                  handlerOnClickFetchFiles={onClickFetchFilesTreaty}
                  allFiles={allFilesTreaty2}
                  numberFiles={"filesTreaty2"}
                />
              </>
            </div>
          )}
        </>
      )}
      {data.a1_2 === "ГПХ" && (
        <div>
          <NameOfStep text={"Соглашение о расторжении договора ГПХ"} />
          <>
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal &&
                  taskCurrentGlobal["files"] &&
                  taskCurrentGlobal["files"]["list"]
                    .filter((item) => item.tag == "filesTreaty3")
                    .map((item2) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                          target="_blank"
                        >
                          {item2.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
            <AddFileButton
              filesList={taskCurrentGlobal}
              data={data}
              setData={setData}
              id={"a1_8"}
              handlerOnSendFile={onSendFileTreaty}
              handlerOnDeletedFile={onDeletedFileTreaty}
              handlerOnClickFetchFiles={onClickFetchFilesTreaty}
              allFiles={allFilesTreaty3}
              numberFiles={"filesTreaty3"}
            />
          </>
        </div>
      )}
      {(data.a1_2 === "СМ3" || data.a1_2 === "ИП") && ( // не работает проверка
        <div>
          <NameOfStep text={"Соглашение о расторжении ДОУ"} />
          <>
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal &&
                  taskCurrentGlobal["files"] &&
                  taskCurrentGlobal["files"]["list"]
                    .filter((item) => item.tag == "filesTreaty4")
                    .map((item2) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                          target="_blank"
                        >
                          {item2.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>

            <AddFileButton
              filesList={taskCurrentGlobal}
              data={data}
              setData={setData}
              id={"a1_9"}
              handlerOnSendFile={onSendFileTreaty}
              handlerOnDeletedFile={onDeletedFileTreaty}
              handlerOnClickFetchFiles={onClickFetchFilesTreaty}
              allFiles={allFilesTreaty4}
              numberFiles={"filesTreaty4"}
            />
          </>
        </div>
      )}
      {data.a1_2 === "вне штата" && (
        <div>
          <NameOfStep
            text={
              "Внутренний обходной лист, подписанный руководителем, скан (поле)"
            }
          />
          <>
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal &&
                  taskCurrentGlobal["files"] &&
                  taskCurrentGlobal["files"]["list"]
                    .filter((item) => item.tag == "filesTreaty5")
                    .map((item2) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                          target="_blank"
                        >
                          {item2.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
            <AddFileButton
              filesList={taskCurrentGlobal}
              data={data}
              setData={setData}
              id={"a1_10"}
              handlerOnSendFile={onSendFileTreaty}
              handlerOnDeletedFile={onDeletedFileTreaty}
              handlerOnClickFetchFiles={onClickFetchFilesTreaty}
              allFiles={allFilesTreaty5}
              numberFiles={"filesTreaty5"}
            />
          </>
        </div>
      )}

      <div>
        <NameOfStep text={"Дата последнего рабочего дня"} />
        {/* name={"a1_11"}  */}
        <div className="input__date">
          <div>
            <TextAreaOrInputElement
              name={"a1_11"}
              isInput={true}
              type={"date"}
              data={data}
              setData={setData}
            />

            <input
              name={"a1_12"}
              value={data["a1_12"]}
              className="input-form"
              type="time"
              id={"a1_12"}
              onChange={(e) => {
                if (!!e.target.value) {
                  // console.log("e.target.value", e.target.value);
                  // console.log("e.target.name", e.target.name);
                  setData((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <NameOfStep text={"Комментарии"} />
        <TextAreaOrInputElement
          name={"a1_13"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
          placeholder={
            "Поле для свободного заполнения (например, причин увольнения, указания периода каникул при дружественном соглашении сторон.)"
          }
        />
      </div>
    </form>
  );
};

export default BpResultFormDismissal;
