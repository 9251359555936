import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  FocusEvent,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { fetchData } from '../../../../utils/fetchData'

import ProjectAutocomplete from './ProjectAutocomplete'
import SectionAutocomplete from './SectionAutocomplete'
import Dependencies from '../../../TaskInfoPlate/components/Dependencies'
import Prioritys from '../../../../common/components/Priority/Prioritys'
import TaskWorkLoad from '../../../TaskInfoPlate/components/TaskWorkLoad'
import Button from '../../../../common/components/newComponent/Buttons/Button'
import EndRepeatTask from '../../../TaskInfoPlate/components/EndRepeatTask'
import FileElem from '../../../../common/components/FileElem/FileElem'
import RepeatTask from '../../../TaskInfoPlate/components/RepeatTask'
import ExecutorSelect from '../../../TaskInfoPlate/components/ExecutorSelectWithDelegate'

import { getSelectedTask } from '../../../TaskInfoPlate/selectors'
import {
  uploadFile,
  deleteFile,
  setStartCyclick,
  setFinishCyclick,
  sendReqToChangeTemplRepTask,
  setShownTaskInfoPlate,
  changeReducerCheckboxItem,
  dispatchCheckboxItem,
} from '../../../TaskInfoPlate/actions'
import { fetchTaskDependence } from '../../../Tasks/actions'
import { updateTask } from '../../../../common/actions'

import { Colors, IRestData } from '../Lightbox/Lightbox'
import { Item } from '../../../../common/types'
import { State } from '../../../../rootReducer'

import './style.sass'
import CheckListModule from '../../../../common/components/CheckList/CheckListModule'
import ColorPicker from '../ColorPicker'

const Chips = styled.div`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background: rgb(230, 236, 239);
  color: rgb(33, 33, 33);
  border-radius: 20px;
  height: 32px;
  box-sizing: border-box;
  padding: 0 5px;
  width: 100%;
`

const Line = styled.div`
  display: flex;
  width: 100%;
`

type Props = {
  restData: IRestData
  handleChangeData: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => void
  selectedItems: Array<Item>
  setSelectedItems: Dispatch<SetStateAction<Array<Item>>>
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  changeDate: (event: ChangeEvent<HTMLInputElement>, toggle: boolean) => void
  changeTime: (event: ChangeEvent<HTMLInputElement>, toggle: boolean) => void
  color: Colors
  setColor: Dispatch<SetStateAction<Colors>>
}

export type ProgressList = {
  isChecked: boolean
  name: string
}

const weekDays: { day: number; name: string }[] = [
  { day: 1, name: 'Пн' },
  { day: 2, name: 'Вт' },
  { day: 3, name: 'Ср' },
  { day: 4, name: 'Чт' },
  { day: 5, name: 'Пт' },
  { day: 6, name: 'Сб' },
  { day: 7, name: 'Вс' },
]

// const scheduler = window.scheduler;

const Task: FC<Props> = ({
  restData,
  handleChangeData,
  selectedItems,
  setSelectedItems,
  changeTime,
  startTime,
  changeDate,
  startDate,
  endDate,
  endTime,
  color,
  setColor,
}) => {
  const dispatch = useDispatch()

  const { currentUserInfo } = useSelector((state: State) => state.commonInfo)
  const {
    isShownTaskInfoPlate,
    commentFiles,
    repeat,
    parameter_trigger,
    checkList,
  } = useSelector((state: State) => state.taskInfoPlate)
  const state = useSelector((state: State) => state)

  const [cyclicTaskInfo, setCyclicTaskInfo] = useState<any>(null)
  const [applyBtnRepeatBlock, setApplyBtnRepeatBlock] = useState<boolean>(false)

  const selectedTask = getSelectedTask(state)

  useEffect(() => {
    ;(async () => {
      if (selectedTask?.id) {
        dispatch(fetchTaskDependence(selectedTask?.id))
      }

      if (selectedTask?.cyclic_task_id) {
        let cycleInfo = await fetchData.get(
          `/api/v1/cyclic-tasks/` + selectedTask?.cyclic_task_id
        )
        if (cycleInfo) {
          if (cycleInfo.params === null) cycleInfo['params'] = []
          if (cycleInfo.end_count)
            cycleInfo['end_count'] = JSON.parse(cycleInfo.end_count)[0] // из стринга-массива вытаскиваю первое значение
          if (cycleInfo.end_date) {
            cycleInfo['end_date'] =
              cycleInfo.end_date.substr(8, 2) +
              '-' +
              cycleInfo.end_date.substr(5, 2) +
              '-' +
              cycleInfo.end_date.substr(0, 4) +
              ' 00:00:00'
          }
          if (typeof cycleInfo.params === 'string') {
            cycleInfo['params'] = JSON.parse(cycleInfo.params)
          }
          setCyclicTaskInfo(cycleInfo)

          ///////////////     генерирование полного названяия для строки состояния цикла
          let result_string = ''
          switch (cycleInfo.period) {
            case 'every day':
              result_string += 'Каждый'
              break
            case 'every week':
              result_string += 'Каждую'
              break
            case 'every month':
              result_string += 'Каждый'
              break
          }

          if (cycleInfo.interval.toString() === '1') result_string += ' '
          else result_string += ' ' + cycleInfo.interval + ' '

          switch (cycleInfo.period) {
            case 'every day':
              result_string += 'день'
              break
            case 'every week':
              result_string += 'неделю'
              break
            case 'every month':
              result_string += 'месяц'
              break
          }

          if (cycleInfo.params.length) {
            if (cycleInfo.period === 'every week') {
              result_string += ' в: '
              cycleInfo.params.forEach((item, number) => {
                let found_day = weekDays.find((day) => day.day === item)
                result_string += (number === 0 ? '' : ', ') + found_day?.name
              })
            } else if (cycleInfo.period === 'every month') {
              result_string += ' по: '
              cycleInfo.params.forEach((item, number) => {
                result_string += (number === 0 ? '' : ', ') + item
              })
            }
          }

          result_string = result_string.replace(/\s\s+/g, ' ') // удалять двойные пробелы

          ///////////////

          // запись в редюсер всего по repeat
          dispatch(
            setStartCyclick(
              cycleInfo.period,
              cycleInfo.params === null ? [] : cycleInfo.params,
              cycleInfo.interval,
              false,
              result_string
            )
          )

          dispatch(
            setFinishCyclick(
              cycleInfo.ending_condition,
              cycleInfo.end_count !== null ? cycleInfo.end_count : null,
              cycleInfo['end_date']
            )
          )
        }
      } else {
        dispatch(setStartCyclick('', [], 1, false, ''))
        dispatch(setFinishCyclick('never', null, null))
        setCyclicTaskInfo(null)
        setApplyBtnRepeatBlock(false)
      }
    })()
  }, [selectedTask?.id, selectedTask?.cyclic_task_id, parameter_trigger])

  useEffect(() => {
    if (!isShownTaskInfoPlate) setSelectedItems([])
    dispatch(setShownTaskInfoPlate(false))
  }, [isShownTaskInfoPlate])

  const disabledComponent =
    currentUserInfo?.id === selectedTask?.author_id ||
    currentUserInfo?.id === selectedTask?.executor_id ||
    !selectedTask
      ? false
      : currentUserInfo?.roles[0] !== 1

  const handleBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    if (selectedTask?.id) {
      dispatch(updateTask(selectedTask.id, { [type]: event.target.value }))
    }
  }

  const handleEditDateOnBlur = (type: 'start' | 'end') => {
    const startDateTime = moment(startDate + ' ' + startTime).format()
    const endDateTime = moment(endDate + ' ' + endTime).format()
    const start = moment(startDateTime).format('DD-MM-YYYY HH:mm:ss')
    const end = moment(endDateTime).format('DD-MM-YYYY HH:mm:ss')
    const data = {
      start,
      end,
    }
    if (selectedTask?.id) {
      // const event = scheduler.getEvent(scheduler.getState().lightbox_id);
      // const parseDate = scheduler.date.str_to_date("%Y-%m-%d");
      // event.start_date = parseDate(startDateTime);
      // event.end_date = parseDate(endDateTime);
      dispatch(updateTask(selectedTask.id, { [type]: data[type] }))
    }
  }
  return (
    <>
      <div className="lightbox-line">
        <div className="form-line decoration">
          <span>Название</span>
          <div className="inputs-container decoration">
            <input
              className="input-decoration"
              type="text"
              value={restData.name}
              onChange={(event) => handleChangeData(event, 'name')}
              onBlur={(event) => handleBlur(event, 'name')}
            />
          </div>
        </div>
      </div>
      <div className="lightbox-line">
        <div className="form-line project-decor">
          <span>Проект</span>
          <div className="inputs-container decoration">
            <ProjectAutocomplete />
          </div>
        </div>
        <div className="form-line">
          <span>Раздел</span>
          <div className="inputs-container decoration">
            <SectionAutocomplete />
          </div>
        </div>
        <div className="form-line">
          <span>Автор</span>
          <div className="inputs-container">
            <Chips>
              {currentUserInfo?.name} {currentUserInfo?.surname}
            </Chips>
          </div>
        </div>
        <div className="form-line">
          <span>Загрузка</span>
          <div className="inputs-container">
            <TaskWorkLoad disabled={disabledComponent} />
          </div>
        </div>
      </div>
      <div className="lightbox-line">
        <div className="form-line">
          <span>Начало</span>
          <div className="inputs-container">
            <input
              value={startTime}
              onChange={(event) => changeTime(event, false)}
              type="time"
              onBlur={() => handleEditDateOnBlur('start')}
            />
            <input
              value={startDate}
              onChange={(event) => changeDate(event, false)}
              type="date"
              onBlur={() => handleEditDateOnBlur('start')}
            />
          </div>
        </div>
        <div className="form-line">
          <span>Окончание</span>
          <div className="inputs-container">
            <input
              value={endTime}
              type="time"
              onChange={(event) => changeTime(event, true)}
              onBlur={() => handleEditDateOnBlur('end')}
            />
            <input
              value={endDate}
              onChange={(event) => changeDate(event, true)}
              type="date"
              onBlur={() => handleEditDateOnBlur('end')}
            />
          </div>
        </div>
        <div className="form-line">
          <span>Приотриет</span>
          <div className="inputs-container">
            <Prioritys bigSize setColor={setColor} />
          </div>
        </div>
        <div className="form-line">
          <span>Исполнитель</span>
          <div className="inputs-container">
            <ExecutorSelect
              isScheduler
              selectedItems={selectedItems}
              setSelectedItems={(arg) => {
                setSelectedItems(arg)
              }}
            />
          </div>
        </div>
      </div>
      <div className="lightbox-line">
        <div className="form-line decoration">
          <span>Описание</span>
          <div className="inputs-container decoration">
            <textarea
              className="input-decoration textarea"
              value={restData.description}
              onChange={(event) => handleChangeData(event, 'description')}
              onBlur={(event) => handleBlur(event, 'description')}
            />
          </div>
        </div>
      </div>
      <div className="lightbox-line">
        <div className="form-line">
          <span>Прикрепить файл</span>
          <div className="inputs-container">
            <Line>
              <input
                onChange={(e) => {
                  let files = e.currentTarget.files
                  if (!files) return
                  for (let i = 0; i < files?.length; i++)
                    dispatch(uploadFile(files[i]))
                }}
                type="file"
                id="comment_files"
                style={{ display: 'none' }}
                multiple={true}
              />

              <label htmlFor="comment_files" className={'info_attach_file'}>
                Выбрать
              </label>

              {commentFiles.length > 0 && (
                <div className={'attachments'} style={{ width: '156px' }}>
                  {commentFiles.map((elem) => (
                    <FileElem
                      url={elem.url}
                      key={elem.id}
                      id={elem.id}
                      name={elem.name}
                      deleteHandler={(id) => dispatch(deleteFile(id))}
                    />
                  ))}
                </div>
              )}
            </Line>
          </div>
        </div>
        <div className="form-line">
          <span>Выбрать цвет</span>
          <div className="inputs-container">
            <ColorPicker color={color} setColor={setColor} />
          </div>
        </div>
      </div>
      <div className="lightbox-line">
        <div className="form-line decoration">
          <span>зависимости</span>
          <div className="inputs-container">
            <Dependencies />
          </div>
        </div>
        <div className="form-line decoration">
          <span>Чек-лист</span>
          <div className="inputs-container decoration">
            <CheckListModule
              action={(
                orderNumber,
                nameItem,
                checkboxValue,
                actionType,
                onlyChangeReducer
              ) => {
                if (selectedTask) {
                  if (onlyChangeReducer) {
                    dispatch(
                      changeReducerCheckboxItem(
                        orderNumber,
                        nameItem,
                        checkboxValue,
                        actionType
                      )
                    )
                  } else {
                    dispatch(
                      dispatchCheckboxItem(
                        orderNumber,
                        nameItem,
                        checkboxValue,
                        actionType
                      )
                    )
                  }
                } else {
                  dispatch(
                    changeReducerCheckboxItem(
                      orderNumber,
                      nameItem,
                      checkboxValue,
                      actionType
                    )
                  )
                }
              }}
              checkList={checkList}
              disabled={
                selectedTask
                  ? currentUserInfo?.id === selectedTask?.author_id ||
                    currentUserInfo?.id === selectedTask?.executor_id
                    ? false
                    : currentUserInfo?.roles[0] !== 1
                  : false
              }
            />
          </div>
        </div>
        <div className="form-line decoration">
          <span>Повторять</span>
          <div className="inputs-container">
            <RepeatTask
              disabledComponent={
                (currentUserInfo?.id !== selectedTask?.author_id &&
                  selectedTask !== null) ||
                (cyclicTaskInfo !== null &&
                  currentUserInfo?.id !== cyclicTaskInfo?.author_id)
              }
            />
            {applyBtnRepeatBlock && (
              <Button
                style={{ marginLeft: 144 }}
                title={'Применить'}
                onClick={() => {
                  dispatch(sendReqToChangeTemplRepTask({ ...repeat }))
                }}
              />
            )}
          </div>
        </div>
        {repeat.period && (
          <div className="form-line">
            <span>Закончить повтор</span>
            <div className="inputs-container">
              <EndRepeatTask
                disabledComponent={
                  (currentUserInfo?.id !== selectedTask?.author_id &&
                    selectedTask !== null) ||
                  (cyclicTaskInfo !== null &&
                    currentUserInfo?.id !== cyclicTaskInfo?.author_id)
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Task
