import {types, ProjectsAction} from './action_types';
import {Task, Project} from '../../common/types';
import {Reducer} from 'redux';
import {columnsOfTables} from '../../common/shared_data';

let default_init_cols: string[] = [];

let localSt: any = localStorage.getItem('projectsTableCols');
if (localSt) {
    let parsed = JSON.parse(localSt);
    Object.keys(parsed).forEach((item) => {
        default_init_cols.push(parsed[item]);
    });
} else {
    columnsOfTables.forEach((item) => {
        if (item.columnId !== 'executor_id') {
            default_init_cols.push(item.columnId);
        }
    });
}

const initialState = {
    tasks: [] as Task[],
    selectedProject: null as Project | null,
    projectAddMode: false,
    filtersWindowShow: false,
    isFetchingTasks: true,
    tableFilter: '',
    filters: {
        filterParametersList: [
            {text: 'Статус', value: 1},
            // ,
            // {text: 'Автор', value: 2},
            // {text: 'Исполнитель', value: 3},
            // {text: 'Задача', value: 4},
            // {text: 'Дата', value: 5}
        ],
        // appliedFilters: {1: [2, 4]},
        appliedFilters: {},
        draft: 0,
        addNewRow: true,
        exec_auth: '',
    },
    showColumnsWindow: false,
    businessProcessesModalShow: false,
    tasksOrder: {col_name: 'end', order_direct: true},
    activeColumns: default_init_cols,
    tableOrTreeToggler: localStorage.getItem('project_show_toggle')
        ? (localStorage.getItem('project_show_toggle') as string)
        : 'table',
};

export type ProjectsState = typeof initialState;

const reducer: Reducer<ProjectsState, ProjectsAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case types.CHANGE_TASK_LIST:
            return {
                ...state,
                tasks: action.tasks,
            };
        case types.SELECT_PROJECT:
            return {
                ...state,
                selectedProject: action.project,
            };

        case types.BUSINESS_PROCESSES_MODAL_SHOW:
            return {
                ...state,
                businessProcessesModalShow: action.show,
            };

        case types.SET_PROJECT_ADD_MODE:
            return {
                ...state,
                projectAddMode: action.mode,
            };
        case types.SET_IS_FETCHING_TASKS:
            return {
                ...state,
                isFetchingTasks: action.isFetchingTasks,
            };
        case types.SET_TABLE_FILTER:
            return {
                ...state,
                tableFilter: action.filter,
            };
        case types.SET_TABLE_ORDER:
            return {
                ...state,
                tasksOrder: {
                    col_name: action.col_name,
                    order_direct: action.order_direct,
                },
            };
        case types.SET_SHOW_COLUMNS_WINDOW_PROJECTS:
            return {
                ...state,
                showColumnsWindow: action.show,
            };
        case types.SET_SHOWING_COLS_PROJECTS:
            const val = action.value;
            let new_arr: string[] = [];

            if (val === 'DEFAULT' || val === 'ALL') {
                if (val === 'DEFAULT') {
                    columnsOfTables.forEach((item) => {
                        if (
                            !(
                                item.columnId === 'executor_id' ||
                                item.columnId === 'project_name' ||
                                item.columnId === 'section_name'
                            )
                        ) {
                            new_arr.push(item.columnId);
                        }
                    });
                } else columnsOfTables.forEach((item) => new_arr.push(item.columnId));
            } else {
                // копирую стэйт в новый объект, если вставлю старый, ререндера не произойдет
                state.activeColumns.forEach((item) => new_arr.push(item));
                new_arr.includes(val)
                    ? new_arr.splice(new_arr.indexOf(val), 1)
                    : new_arr.push(val);
            }

            // localStorage.removeItem('projectsTableCols')
            localStorage.setItem('projectsTableCols', JSON.stringify({...new_arr}));

            return {
                ...state,
                activeColumns: new_arr,
            };

        case types.SET_FILTER_WIN_PROJECTS_MODE:
            return {
                ...state,
                filtersWindowShow: action.mode,
                filters: Object.assign(
                    {},
                    state.filters,
                    {
                        addNewRow: !action.mode
                            ? !Object.keys(state.filters.appliedFilters).length
                            : state.filters.addNewRow,
                    },
                    {draft: 0}
                ),
            };

        case types.SET_FILTERS_PROJECTS:
            // пока что реализовано только для целочисленных значений внутри массива
            return {
                ...state,
                filters: Object.assign(
                    {},
                    state.filters,
                    {appliedFilters: action.filterObject},
                    {draft: 0}
                ),
            };

        case types.SET_DRAFT_VALUE_PROJECTS:
            return {
                ...state,
                filters: Object.assign(
                    {},
                    state.filters,
                    {draft: action.draft},
                    {addNewRow: false}
                ),
            };

        case types.SET_ALL_OR_ACTUAL_PROJECTS:
            return {
                ...state,
                filters: Object.assign(
                    {},
                    state.filters,
                    {exec_auth: action.value['exec_auth_value']},
                    {
                        appliedFilters: action.value['erase_applied_filters']
                            ? {}
                            : state.filters.appliedFilters,
                    },
                    {addNewRow: !state.filters.draft}
                ),
            };

        case types.SET_FILTERS_ADD_NEW_ROW_PROJECTS:
            return {
                ...state,
                filters: Object.assign({}, state.filters, {addNewRow: action.value}),
            };

        case types.SET_TABLE_OR_TREE_TOGGLER:
            return {
                ...state,
                tableOrTreeToggler: action.value,
                tasks: [],
            };

        default:
            return state;
    }
};

export default reducer;
