import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { nanoid } from "nanoid";
import { StatusContext } from "../../../context/status";
import AddFileButton from "../templates/AddFileButton";
import NameOfStep from "../templates/NameOfStep";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import "../BpResultForm.scss";
import SelectTemplate from "../templates/SelectTemplate";

let fileTranslate;

const RecruitInitial = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  // Если settingsOfFields.initial.extra отсутствует- передаем пустой объект
  const [data, setData] = useState(
    settingsOfFields?.initial?.extra ? settingsOfFields.initial.extra : {}
  );

  const [recruitInital1, setRecruitInital1] = useState([]);
  const [recruitInital2, setRecruitInital2] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  //Добавляет файлы
  const onSendFileRecruitInitial = (e, numberFilesRecruitInitial) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesRecruitInitial === "filesRecruitInitial1") {
        if (file && file.name) {
          setRecruitInital1([
            ...recruitInital1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesRecruitInitial1",
            },
          ]);
        }
      } else if (numberFilesRecruitInitial === "filesRecruitInitial2") {
        if (file && file.name) {
          setRecruitInital2([
            ...recruitInital2,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesRecruitInitial2",
            },
          ]);
        }
      }
    }, [500]);
  };
  //Удаляет файлы
  const onDeletedFileRecruitInitial = (keyId, numberFilesRecruitInitial) => {
    if (numberFilesRecruitInitial === "filesRecruitInitial1") {
      setRecruitInital1(recruitInital1.filter((file) => file.id !== keyId));
    } else if (numberFilesRecruitInitial === "filesRecruitInitial2") {
      setRecruitInital2(recruitInital2.filter((file) => file.id !== keyId));
    }
  };

  //Смотрит на какую кнопку какого рездела с файлами было нажато
  const onClickFetchFilesRecruitInitial = (e, numberFilesRecruitInitial) => {
    e.preventDefault();

    let data;

    if (numberFilesRecruitInitial === "filesRecruitInitial1") {
      data = JSON.stringify({
        files: recruitInital1,
      });
      console.log("Нажал на кнопку для отправки файлов filesRecruitInitial1");
    }

    if (numberFilesRecruitInitial === "filesRecruitInitial2") {
      data = JSON.stringify({
        files: recruitInital2,
      });
      console.log("Нажал на кнопку для отправки файлов filesRecruitInitial2");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesRecruitInitial === "filesRecruitInitial1") {
      setRecruitInital1([]);
    }

    if (numberFilesRecruitInitial === "filesRecruitInitial2") {
      setRecruitInital2([]);
    }
  };

  const formOfPosition = ["Существующая позиция", "Новая позиция"];

  const relationships = ["ГПХ-ТД", "СМЗ", "ИП", "Вне штата"];

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  // console.log(taskId);
  useEffect(() => {
    console.log("use in form worked");
    if (settingsOfFields && settingsOfFields.initial && taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.initial, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="initial">
      <div>
        <NameOfStep text={"Новая/существующая позиция"} />
        <SelectTemplate
          listArr={formOfPosition}
          name={"a1"}
          data={data}
          setData={setData}
        />
      </div>

      <div>
        <NameOfStep text={"Оклад"} />
        <div className="additional-header">
          Cумма фактического вознаграждения «на руки»
        </div>
        <TextAreaOrInputElement
          name={"a1_1"}
          isInput={true}
          type={"number"}
          data={data}
          setData={setData}
          minValue="0"
        />
      </div>
      <div>
        <NameOfStep text={"Оклад"} />
        <div className="additional-header">
          Минимальный размер официальной части вознаграждения, запрос кандидата
        </div>
        <TextAreaOrInputElement
          name={"a1_2"}
          isInput={true}
          type={"number"}
          data={data}
          setData={setData}
          minValue="0"
        />
      </div>
      <div>
        <NameOfStep text={"Оклад"} />
        <div className="additional-header">
          Минимальный размер официальной части вознаграждения, по штатному
          расписанию
        </div>
        <TextAreaOrInputElement
          name={"a1_3"}
          isInput={true}
          type={"number"}
          data={data}
          setData={setData}
          minValue="0"
        />
      </div>
      <div>
        <NameOfStep text={"Формат взаимоотношений"} />
        <SelectTemplate
          listArr={relationships}
          name={"a1_4"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Формат взаимоотношений"} />
        <TextAreaOrInputElement
          name={"a1_5"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
          placeholder="Указать компанию"
        />
      </div>
      <div>
        <NameOfStep text={"Обязанности"} />
        <TextAreaOrInputElement
          name={"a1_6"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
          placeholder="Ввести описание"
        />
        <div>
          <NameOfStep text={"График работы"} />
          <TextAreaOrInputElement
            name={"a1_7"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
            placeholder="Написать график нахождения на рабочем месте "
          />
          <div className="file-uploaded__block">
            <ul>
              {taskCurrentGlobal &&
                taskCurrentGlobal["files"] &&
                taskCurrentGlobal["files"]["list"]
                  .filter((item) => item?.tag == "filesRecruitInitial1")
                  .map((item2) => (
                    <li>
                      <a
                        href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                        target="_blank"
                      >
                        {item2.name}
                      </a>
                    </li>
                  ))}
            </ul>
          </div>
          <AddFileButton
            filesList={taskCurrentGlobal}
            data={data}
            setData={setData}
            id={"a1_7_1"}
            handlerOnSendFile={onSendFileRecruitInitial}
            handlerOnDeletedFile={onDeletedFileRecruitInitial}
            handlerOnClickFetchFiles={onClickFetchFilesRecruitInitial}
            allFiles={recruitInital1}
            numberFiles={"filesRecruitInitial1"}
          />
        </div>
      </div>
      <div>
        <NameOfStep text={"Прикрепление документов"} />
        <div className="file-uploaded__block">
          <ul>
            {taskCurrentGlobal &&
              taskCurrentGlobal["files"] &&
              taskCurrentGlobal["files"]["list"]
                .filter((item) => item?.tag == "filesRecruitInitial2")
                .map((item2) => (
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                      target="_blank"
                    >
                      {item2.name}
                    </a>
                  </li>
                ))}
          </ul>
        </div>
        <AddFileButton
          filesList={taskCurrentGlobal}
          data={data}
          setData={setData}
          id={"a1_8"}
          handlerOnSendFile={onSendFileRecruitInitial}
          handlerOnDeletedFile={onDeletedFileRecruitInitial}
          handlerOnClickFetchFiles={onClickFetchFilesRecruitInitial}
          allFiles={recruitInital2}
          numberFiles={"filesRecruitInitial2"}
        />
        {data.a1_4 && data.a1_4 === "ГПХ-ТД" && (
          <ul>
            <li className="marginInput">
              ● ГПХ/ТД – паспорт 1 страница + страница с действующей
              регистрацией, СНИЛС, ИНН, скан ТК и/или форму СТДР (что есть в
              наличии), скан документа об образовании (диплом), сведения о
              семейном положении (свидетельство), сертификат вакцинации,
              заполненная анкета, реквизиты для оплаты, электронная почта,
              личный мобильный телефон;
            </li>
          </ul>
        )}
        {data.a1_4 === "СМЗ" && (
          <ul>
            <li className="marginInput">
              ● СМЗ - паспорт 1 страница + страница с действующей регистрацией,
              ИНН, справка о регистрации СМЗ, заполненная анкета, реквизиты для
              оплаты, электронная почта, личный мобильный телефон;
            </li>
          </ul>
        )}
        {data.a1_4 === "ИП" && (
          <ul>
            <li className="marginInput">
              ● ИП - паспорт 1 страница + страница с действующей регистрацией,
              ИНН, лист записи ЕГРИП, либо свидетельство о регистрации ИП,
              заполненная анкета, реквизиты для оплаты, электронная почта,
              личный мобильный телефон;
            </li>
          </ul>
        )}
      </div>
    </form>
  );
};

export default RecruitInitial;
