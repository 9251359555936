// Создание нового пользователя
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { setUserAddMode } from "../Users/actions";
import {
  clearUser,
  createUser,
  setUserEmail,
  setUserName,
  setUserSurname,
  deleteUser,
} from "./actions";
import { State } from "../../rootReducer";
import { getCanSend } from "./selectors";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import palette from "../../common/components/palette";
import Button from "../../common/components/newComponent/Buttons/Button";
import { fetchData } from "../../utils/fetchData";
import { urlApp } from "../../App";
import Modal from "../../common/components/Modal";
import NewUserInCompany from "../Login/components/NewUserInCompany";
import Notifications from "./components/Notifications";
import Toggler from "../../common/components/Togglers/Toggler";
import ProfileSection from "./components/ProfileSection";
import ScheduleSection from "./components/ScheduleSection";
import Icon from "../../common/components/Icon";

type Props = {
  canSend: boolean;
  isSending: boolean;
  userId: number;
  currentUser: number;
  deleteUser: (userId: number) => void;
  clearUser: () => void;
  createUser: (
    update: boolean,
    changeDataUser: object,
    notificationOptions,
    notificationSuccess
  ) => void;
  setUserAddMode: (mode: boolean) => void;
  changeIndicator: boolean;
};

const StyleWrapperAddUser = styled.div`
  height: 100vh;
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 76px;
  bottom: 0;
  right: 10px;
  transition: all 0.8s ease;
  margin-right: ${({ visibleModal }) => (visibleModal ? "0px" : "-600px")};
`;

const StyleSectionsWrap = styled.div`
  min-height: 70vh;
`;
const StyleBlockUsers = styled.div`
  padding: 1rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);
  display: flex;
  flex-direction: column;
  width: 584px;
  height: 100%;
  filter: ${({ modalDel }) => modalDel && "blur(10px)"};
  & > div:first-child {
    display: flex;
    justify-content: space-between;
  }
`;
const StyleHead = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${palette.textBlack};
  margin-bottom: 24px;
`;

const StyleWrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyleToggle = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

export type changeDataUserType = {
  data: {
    roles?: number[];
    name?: string;
    surname?: string;
  };
  schedule: {
    weekDays?: [];
    daysOff?: [];
  };
  processing: {
    days?: string;
    hours?: string;
  };
};

export type Verification = {
  email_verified: number;
  telegram_registered: number;
  disabled: boolean;
  telegram_code: number;
};

const ProjectAddPanel: React.FC<Props> = ({
  canSend,
  isSending,
  userId,
  clearUser,
  createUser,
  setUserAddMode,
  deleteUser,
  currentUser,
  changeIndicator,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const roles: any = useSelector(
    (state: State) => state.commonInfo.currentUserInfo?.roles
  );

  const visibleModalSelector = useSelector(
    (state: State) => state.usersPage.userAddMode
  );
  const currentUserRoles = useSelector(
    (state: State) => state.commonInfo.currentUserInfo?.roles
  );
  const idUser = useSelector((state: State) => state.newUser.id);
  const currentId = useSelector(
    (state: State) => state.commonInfo.currentUserInfo?.id
  );

  const userInfo = useSelector((state: State) => state.newUser);

  const [isAddUserInCompany, setIsAddUserInCompany] = useState(false);
  const [modalDel, setModalDel] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [activeTab, setActiveTab] = useState({
    name: "Профиль",
    value: "profile",
  });

  const [changeDataUser, setChangeDataUser] = useState<changeDataUserType>({
    data: {},
    schedule: {
      weekDays: [],
      daysOff: [],
    },
    processing: {
      hours: "2",
      days: "3",
    },
  });
  const [notificationOptions, setNotificationOptions] = useState([
    {
      id: 0,
      user_id: 0,
      role: "author",
      settings: { 1: [1, 2, 3], 2: [1], 3: [3], 4: [], 5: [], 6: [] },
    },
    {
      id: 0,
      user_id: 0,
      role: "executor",
      settings: { 1: [1, 2, 3], 2: [1], 3: [3], 4: [], 5: [], 6: [] },
    },
  ]);

  const [verification, setVerification] = useState<Verification>({
    email_verified: 1,
    telegram_registered: 0,
    disabled: false,
    telegram_code: 0,
  });

  const [notificationSuccess, setNotificationSuccess] = useState(false);

  useEffect(() => {
    if (!location.pathname.includes("/users")) {
      closeHandler();
    }
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      setVisibleModal(visibleModalSelector);
    }, 0);
  }, [visibleModalSelector]);

  useEffect(() => {
    if (firstRender) setFirstRender(!firstRender);
    else {
      //window.location.reload() ///***************************************** */
      closeHandler();
      //setVisibleModal(false); //*************************************** */
      //setUserAddMode(false);   //************************************** */
    }
  }, [changeIndicator]);

  useEffect(() => {
    if (idUser !== 0) {
      fetchData
        .get(`/api/v1/users/${idUser}`)
        .then((data) =>
          setVerification({
            ...verification,
            email_verified: data.email_verified,
            telegram_registered: data.telegram_registered,
            telegram_code: data.telegram_code || 0,
          })
        )
        .catch((error) => console.log(error));

      fetchData
        .get(`/api/v1/users/${idUser}/notification-settings`)
        .then((data) => {
          setNotificationOptions(data);
        })
        .catch((err) => console.error(err));
    }
  }, [idUser]);

  useEffect(() => {
    let path = history.location.pathname;
    let unique_link = path.split("/").find((el) => el === "unique_link");
    if (unique_link && roles[0] < 4) {
      fetchData.get(`/api/v1${history.location.pathname}`).then((data) => {
        setIsAddUserInCompany(true);
        dispatch(setUserSurname(data?.surname || ""));
        dispatch(setUserName(data?.name || ""));
        dispatch(setUserEmail(data?.email || ""));
      });
    } else if (unique_link && roles[0] > 3) {
      setUserAddMode(false);
      if (location.pathname.includes("/users")) {
        history.push("/users");
      }
    }
  }, [location.pathname, history.location.pathname]);

  const checkPermissions = useCallback(() => {
    return (
      idUser !== 0 && (currentUserRoles?.includes(1) || idUser === currentId)
    );
  }, [currentUserRoles, idUser, currentId]);

  const optionsToggle = useCallback(() => {
    return checkPermissions()
      ? [
          { name: "Профиль", value: "profile" },
          { name: "Рабочее время", value: "schedule" },
          { name: "Уведомления", value: "notifications" },
        ]
      : [{ name: "Профиль", value: "profile" }];
  }, [checkPermissions]);

  const handlerOnChangeToggle = (value) => {
    const optionsValue = optionsToggle().find((el) => el.value === value);

    if (optionsValue) {
      setActiveTab(optionsValue);
    }
  };

  const sendVerificationRequest = () => {
    setVerification({
      ...verification,
      disabled: true,
    });
    fetch(urlApp + `/email/resend/${idUser}`).catch((error) =>
      console.error(error)
    );
  };

  const closeHandler = useCallback(() => {
    setVisibleModal(false);

    setTimeout(() => {
      setUserAddMode(false);
      clearUser();
      if (location.pathname.includes("/users")) {
        history.push("/users");
      }
    }, 600);
  }, [clearUser, history, setUserAddMode, setVisibleModal, location.pathname]);

  // self-close for window
  let plate = useRef<HTMLDivElement>(null);
  const outsideClickHandler = useCallback(
    function (e: Event): any {
      let element = e.target as Node;
      if (element === plate.current) closeHandler();
    },
    [closeHandler]
  );

  const canSendDateWork = () => {
    if (
      Object.keys(changeDataUser.data).length ||
      changeDataUser.schedule.weekDays?.length ||
      changeDataUser.schedule.daysOff?.length ||
      changeDataUser.data.roles?.length ||
      notificationSuccess
    ) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    document.addEventListener("click", outsideClickHandler);
    return () => document.removeEventListener("click", outsideClickHandler);
  }, [outsideClickHandler]);

  const createUserLabel = () => {
    if (userId === 0) {
      return "Пригласить сотрудника";
    }

    if (userInfo.name && userInfo.surname) {
      return `${userInfo.surname} ${userInfo.name}`;
    }

    return "Данные сотрудника";
  };

  useEffect(() => {
    let hash = history.location.hash.slice(1).split("&");

    if (hash.length === 1) {
      if (hash[0].split("=")[1] === "schedule") {
        setActiveTab({ name: "Рабочее время", value: "schedule" });
      }
      if (hash[0].split("=")[1] === "notifications") {
        setActiveTab({ name: "Уведомления", value: "notifications" });
      }
    } else if (hash.length === 2) {
      if (hash[0].split("=")[1] === "notifications") {
        setActiveTab({ name: "Уведомления", value: "notifications" });
      }
    }
  }, []);

  console.log(currentUser, currentUserRoles);

  return isAddUserInCompany ? (
    <NewUserInCompany
      changeDataUser={changeDataUser}
      setChangeDataUser={setChangeDataUser}
      isAddUserInCompany={isAddUserInCompany}
      setIsAddUserInCompany={setIsAddUserInCompany}
    />
  ) : (
    <StyleWrapperAddUser ref={plate} visibleModal={visibleModal}>
      <StyleBlockUsers visibleModal={visibleModal} modalDel={modalDel}>
        <div>
          <Icon
            name={"cross"}
            onClick={closeHandler}
            style={{ width: "22px", height: "22px", marginBottom: "5px" }}
          />

          {/* {userId &&
          currentUserRoles &&
          currentUserRoles.length >= 1 &&
          (currentUserRoles.includes(1) ||
            currentUserRoles.includes(2) ||
            currentUserRoles.includes(5)) ? (
            <Icon
              name={"delete"}
              onClick={() => {
                deleteUser(userId);
              }}
              style={{ width: "22px", height: "22px", marginBottom: "5px" }}
            />
          ) : (
            <></>
          )} */}
        </div>
        <StyleHead>{createUserLabel()}</StyleHead>

        <StyleToggle style={{ marginTop: "-10px" }}>
          <Toggler
            elemsList={optionsToggle()}
            selectHandler={handlerOnChangeToggle}
            activeValue={activeTab.value}
          />
        </StyleToggle>
        <StyleSectionsWrap style={{ marginTop: "-5px" }}>
          {activeTab.value === "profile" && (
            <ProfileSection
              setChangeDataUser={setChangeDataUser}
              currentUser={currentUser}
              isAddUserInCompany={isAddUserInCompany}
              sendVerificationRequest={sendVerificationRequest}
              userId={userId}
              verification={verification}
              changeDataUser={changeDataUser}
            />
          )}

          {activeTab.value === "schedule" && (
            <ScheduleSection setChangeDataUser={setChangeDataUser} />
          )}

          {activeTab.value === "notifications" && (
            <Notifications
              notificationOptions={notificationOptions}
              setNotificationOptions={setNotificationOptions}
              setNotificationSuccess={setNotificationSuccess}
              verificationTelegram={verification.telegram_registered}
              telegramCode={verification.telegram_code}
              currentUser={userId === currentUser}
            />
          )}
        </StyleSectionsWrap>

        <StyleWrapperButtons>
          <div>
            {/* Кнопка не работает- пока отключена */}
            {/* (currentUserRoles?.includes(1) || currentUserRoles?.includes(2)) &&
                        userId !== 0 &&
                        userId !== currentUser && (
                            <Button
                                title="Закрыть аккаунт"
                                design="transparent"
                                onClick={() => {
                                    setModalDel(true);
                                }}
                                style={{color: '02405E'}}
                            />
                            ) */}
          </div>
          <div style={{ display: "flex", zIndex: 1 }}>
            <Button onClick={closeHandler} title="Отмена" design="secondary" />
            <Button
              onClick={() => {
                createUser(
                  !!userId,
                  changeDataUser,
                  notificationOptions,
                  notificationSuccess
                );
              }}
              title={userId === 0 ? "Пригласить" : "Готово"}
              disabled={userId === 0 ? !canSend : canSendDateWork()}
              isSending={isSending}
              style={{ marginLeft: ".5rem" }}
            />
          </div>
          {modalDel && (
            <Modal
              onClose={() => setModalDel(false)}
              title="Закрыть аккаунт"
              description="Вы хотите заблокировать аккаунт пользователя или безвозвратно удалить со всеми последствиями?"
            >
              <Button
                style={{ width: "100%" }}
                design="secondary"
                onClick={() => {
                  fetchData
                    .delete(`/api/v1/users/${idUser}`)
                    .then(() => {
                      closeHandler();
                      setModalDel(false);
                      history.push("/users");
                    })
                    .catch((e) => console.log(e));
                }}
                title="Удалить аккаунт"
              />
              <Button
                onClick={() => {
                  fetchData
                    .patch(
                      `/api/v1/users/${idUser}`,
                      JSON.stringify({ enabled: 0 })
                    )
                    .then(() => {
                      closeHandler();
                      setModalDel(false);
                      history.push("/users");
                    })
                    .catch((e) => console.log(e));
                }}
                title="Заблокировать"
                style={{ width: "100%", marginLeft: "8px" }}
              />
            </Modal>
          )}
        </StyleWrapperButtons>
      </StyleBlockUsers>
    </StyleWrapperAddUser>
  );
};

const mapStateToProps = (state: State) => {
  return {
    canSend: getCanSend(state),
    isSending: state.newUser.isSending,
    roles: state.newUser.roles,
    userId: state.newUser.id,
    currentUser: state.commonInfo.current_user,
    changeIndicator: state.newUser.changeIndicator,
  };
};

const mapDispatchToProps = {
  setUserAddMode,
  clearUser,
  createUser,
  deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddPanel);
