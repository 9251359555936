import React from 'react';
import { connect } from 'react-redux';
import { setProjectCpeList } from '../actions';
import { getCpeSelectData, getProjectCpe } from '../selectors';
import { State } from '../../../rootReducer';
import MultySelectProject from '../../../common/components/Selects/MultySelectProject';  // Для проекта создан свой компонент MultySelectProject

type Props = {
  cpeSelectData: any;
  setProjectCpeList: (cpe: number[]) => void;
  cpe_ids: any;
};

const CpeSelect: React.FC<Props> = ({
  cpeSelectData,
  cpe_ids,
  setProjectCpeList,
}) => {
  return (
    // Для проекта создан свой компонент MultySelectProject
    <MultySelectProject
      disabled={false}
      itemsList={cpeSelectData.list}
      selectedItems={cpeSelectData.selected}
      isValid={cpeSelectData.selected.length > 0}
      addHandler={(value: number) => {
        setProjectCpeList([...cpe_ids, value as number]);
      }}
      removeHandler={(value: number) => {
        setProjectCpeList(cpe_ids.filter((id) => id !== value));
      }}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    cpe_ids: getProjectCpe(state),
    cpeSelectData: getCpeSelectData(state),
  };
};

const mapDispatchToProps = {
  setProjectCpeList,
};

export default connect(mapStateToProps, mapDispatchToProps)(CpeSelect);
