import React from 'react';
import { setUserSurname } from '../actions';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import Input from '../../../common/components/Inputs/Input';
import { State } from '../../../rootReducer';
import { User } from '../../../common/types';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

type Props = {
  surname: string;
  userId: number;
  currentUserInfo: User | null;
  setUserSurname?: (surname: string) => void;
  setChangeDataUser?: any;
  rolesUser: number[];
  isAddUserInCompany?: boolean;
};
//Фамилию можно менять для новых пользователей, для себя и если у пользователя есть роль  админа

const UserPatronymic: React.FC<Props> = ({
  surname,
  userId,
  currentUserInfo,
  setUserSurname = () => {},
  setChangeDataUser,
  rolesUser,
  isAddUserInCompany,
}) => {
  return (
    <NameWithInput title="Фамилия" iconName="people" necessarily>
      <Input
        disabled={
          isAddUserInCompany ||
          !(
            userId === 0 ||
            userId === currentUserInfo?.id ||
            currentUserInfo?.roles.includes(1) ||
            currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]
          )
        }
        inputType="text"
        placeholder=""
        title=""
        value={surname}
        changeHandler={(surname) => {
          setUserSurname(surname);
          userId !== 0 &&
            setChangeDataUser((prev) => {
              return {
                ...prev,
                data: { ...prev.data, surname },
                schedule: { ...prev.schedule },
              };
            });
        }}
        // isValid={!!surname}
        // validText={'Фамилия пользователя является обязательным полем'}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    surname: state.newUser.surname,
    userId: state.newUser.id,
    rolesUser: state.newUser.roles,
    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setUserSurname: setUserSurname,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPatronymic);
