import React, {
	FC,
	useState,
	useEffect,
	Dispatch,
	SetStateAction,
} from 'react';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import TableHead from './TableHead';
import SortableItem from './SortableItem';

import { State } from '../../../../rootReducer';
import {ChangeTask, FactsTaskPeople, Task} from '../../../../common/types';
import DateMaster from "../../../../utils/DateMaster";
import {fetchData} from "../../../../utils/fetchData";
import {sortFactsForTable} from "../../../../utils/factsUtils";

const Container = styled.div`
	width: 100%;
  height: calc(100vh - 183px);
	display: flex;
	align-items: center;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
	flex: 20;
  padding-left: 40px;
  overflow-y: scroll;
`;

const Table = styled.table`
	width: 100%;
`;

const ButtonWrapper = styled.div`
	padding-top: 5px;
	width: 100%;
	display: flex;
	justify-content: center;
	flex: 1;
`;

const Button = styled.button`
	color: white;
	width: 200px;
	height: 40px;
	background: #1BAAF0;
	
	&:hover {
		opacity: 0.9;
	}
	
	&:active {
		opacity: 0.8;
	}
`;

type Props = {
	updateTask: boolean;
	setUpdateTask: Dispatch<SetStateAction<boolean>>;
}

const FactsTable: FC<Props> = ({ updateTask, setUpdateTask }) => {
	const { filters } = useSelector((state: State) => state.commonInfo);
	const [people, setPeople] = useState<ChangeTask[]>([]);
	const { register, getValues } = useForm();
	const [updatePeopleList, setUpdatePeopleList] = useState<boolean>(false);

	const SortableList = SortableContainer(({ items }: { items: ChangeTask[] }) => {
		return (
			<tbody>
				{items.map((task, index) => {
					if (task.status_id === 10 && filters.current_set.statuses.length) {
						// статус задачи "в работе", и какой то фильтр по статусу включен
						if (new Date(new DateMaster(task.end).year, new DateMaster(task.end).month, new DateMaster(task.end).day, new DateMaster(task.end).hours, new DateMaster(task.end).minutes).getTime() <= new Date(Date.now()).getTime()) {
							// эта задача просрочена
							if (filters.current_set.statuses.indexOf(14) !== -1) {
								// фильтр по статусу 14 включен
								return (
									<SortableItem
										register={register}
										key={index}
										task={task}
										index={index}
									/>
								);
							} else {
								// фильтр по статусу 14 НЕ включен
								// ПРОПУСКАЕМ
							}
						} else {
							// НЕ просрочена
							return (
								<SortableItem
									register={register}
									key={index}
									task={task}
									index={index}
								/>
							);
						}
					} else {
						return (
							<SortableItem
								register={register}
								key={index}
								task={task}
								index={index}
							/>
						);
					}
					// return (
					// 	<SortableItem
					// 		register={register}
					// 		key={index}
					// 		task={task}
					// 		index={index}
					// 	/>)
				})}
			</tbody>
		);
	});

	const onSortEnd = ({oldIndex, newIndex}) => {
		setPeople((prev) => {
			const values = getValues();
			const data = prev.map((task) => {
				const isChecked = values[task.id].checked.length > 0;
				const valueOfChange = values[task.id].tax

				return {
					...task,
					accumulate_load: valueOfChange,
					checked: isChecked,
				}
			});

			return arrayMove(data, oldIndex, newIndex);
		})
	};

	const initData = async () => {
		const tasks: FactsTaskPeople[] = await fetchData.get('/api/v1/fact');

		const data = sortFactsForTable(tasks);

		// const tasks: Task[] = await fetchData.get('/api/v1/tasks?executor_id=746&order=visual&orderType=deadline');
		//
		// const data: ChangeTask[] = tasks.map((el) => {
		// 	return {
		// 		...el,
		// 		checked: false,
		// 		used_load: el.task_load,
		// 		accumulate_load: 4,
		// 		left_load: el.task_load,
		// 		load: 4,
		// 	};
		// })

		setPeople(data);
	}

	useEffect(() => {
		initData();
	}, [updatePeopleList]);

	const handleSubmit = async () => {
		const values = getValues();
		const filteredTasks = {};

		for (const task in values) {
			const tax = values[task].tax;
			const isChecked = !!values[task].checked.length;

			if (isChecked) {
				filteredTasks[task] = Number(tax);
			}
		}

		await fetchData.post('/api/v1/fact', JSON.stringify(filteredTasks));
		setUpdatePeopleList((prev) => !prev);
	};

	return (
		<Container>
			<Wrapper>
				<Table>
					<TableHead setPeople={setPeople} people={people} />
					<SortableList items={people} onSortEnd={onSortEnd} />
				</Table>
			</Wrapper>
			<ButtonWrapper>
				<Button onClick={handleSubmit}>принять</Button>
			</ButtonWrapper>
		</Container>
	);
};

export default FactsTable;
