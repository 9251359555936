import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { StatusContext } from "../../context/status";
import TaskPriority from "../create-task-form/components/taskPriority/TaskPriority";
import BpItemStatus from "../ui/bp-item-status/BpItemStatus";
import BpItemTaskMenu from "../ui/bp-item-task-menu/BpItemTaskMenu";

const TaskItem = ({ style, el }) => {
  const {
    createBpStatus,
    setOpenMenuTasks,
    openMenuTasks,
    openMenuBp,
    createTaskStatus,
    createBpSampleStatus,
    setIdCall,
    setOpenMenuBp,
    setSelectedTaskOfBP,
    setTaskId,
    bp,
    createBpFromSample,
    setIdBp,
    setOpenMenuTask,
    setStatusOfTask,
    setExecutorIdofTask,
    users,
    taskId,
    relatedTaskId,
    setSelectedBp,
    setRedirectedByClick,
    setThisTabs,
    setShowChatWindow,
    projects,
    statusOfTask,
  } = useContext(StatusContext);
  const [classTask, setClassTask] = useState(
    "dependencies__content-list__item"
  );

  const executor = users.find((user) => user.id === el.executor_id);

  const [project, setProject] = useState();
  const [projectSection, setProjectSection] = useState({});

  useEffect(() => {
    if (projects)
      setProject((prev) =>
        projects.find((project) => project.id === el.data.project_id)
      );
  }, [projects, el, el.data, el.data.project_id]);

  useEffect(() => {
    if (project)
      setProjectSection(
        project.sections.find(
          (section) => section.id === el.data.project_section_id
        )
      );
  }, [project, el, el.data, el.data.project_section_id]);

  // Для выделения задачи после перехода к определенному БП
  useEffect(() => {
    if (relatedTaskId === el.id) setTaskId(relatedTaskId);
  }, [relatedTaskId]);

  useEffect(() => {
    if (style === "dropdown") {
      if (
        openMenuTasks ||
        openMenuBp ||
        createTaskStatus ||
        createBpSampleStatus ||
        createBpStatus
      ) {
        setClassTask(
          "dependencies__content-list__item dependencies__content-list__item-active dependencies__content-list__item-dropdown"
        );
      } else {
        setClassTask(
          "dependencies__content-list__item dependencies__content-list__item-dropdown"
        );
      }
    } else {
      if (
        openMenuTasks ||
        openMenuBp ||
        createTaskStatus ||
        createBpSampleStatus ||
        createBpStatus
      ) {
        setClassTask(
          "dependencies__content-list__item dependencies__content-list__item-active"
        );
      } else {
        setClassTask("dependencies__content-list__item");
      }
    }
  }, [
    style,
    openMenuTasks,
    openMenuBp,
    createTaskStatus,
    createBpSampleStatus,
    createBpStatus,
  ]);
  // console.log(selectedTaskOfBP);

  // При создании бизнес-процесса по шаблону- автоматическое открытие
  // 1 задачи
  useEffect(() => {
    if (createBpFromSample && bp && bp.tasks && bp.tasks[0] && bp.tasks[0].id) {
      let el1 = bp.tasks[0];
      setIdCall(el1.id);
      setOpenMenuTasks(true);
      setOpenMenuBp(true);
      setSelectedTaskOfBP(el1.name);
      setTaskId(el1.id);
      setStatusOfTask(el1.status);
      setExecutorIdofTask(el1.executor_id);
      setSelectedBp(bp.id);
    }
  }, []);

  return (
    <div
      style={el.id === taskId ? { background: "#E5F6FF" } : null}
      className={classTask}
      id={el.id}
      onClick={() => {
        setIdCall(el.id);
        setOpenMenuTasks(true);
        setOpenMenuBp(true);
        setSelectedTaskOfBP(el.name);
        setTaskId(el.id);
        setStatusOfTask(el.status);
        setExecutorIdofTask(el.executor_id);
        setShowChatWindow(false);

        // console.log(statusOfTask);
      }}
    >
      <div className="dependencies__content-list__item__btn">
        <div className="business__main-content__list-block__item__arrow">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
            alt="drop"
            style={{ opacity: 0.5 }}
          />
        </div>
        <div
          className="business__main-content__list-block__item__message business__main-content__list-block__item__message-active"
          id={"business-item-btn-" + 1}
          onClick={(e) => {
            if (
              statusOfTask === 2 ||
              statusOfTask === 4 ||
              statusOfTask === 7
            ) {
              e.stopPropagation();
              setOpenMenuBp(true);
              setThisTabs(3);
              setShowChatWindow(true);
              setRedirectedByClick(false);
              setTaskId(el.id);

              setThisTabs(3);
            }
          }}
          style={{ padding: "10px" }}
        >
          <div>
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/message.svg`}
              alt="message"
              title="Чат задачи"
            />
          </div>
        </div>
      </div>

      <div className="dependencies__content-list__item__title">
        <p className="p-black">{el.name}</p>
        <span className="p-grey">{el.description}</span>
      </div>
      <div className="dependencies__content-list__item__right">
        <BpItemStatus status={el.status} workflow={el.workflow_id} id={el.id} taskEnd={el.end} />
        <div className="dependencies__content-list__deadline p-black">
          {new Date(new Date(el.end).toJSON()).toLocaleString("ru", {
            month: "long",
            day: "numeric",
          })}
        </div>
        <div className="business__main-content__list-block__item__logo business__main-content__list-block__item__logo__task">
          {executor && executor.image ? (
            <img
            title={executor?.name + executor?.surname}
              src={`${process.env.REACT_APP_PUBLIC_URL}/files/${executor?.image.url}/download`}
              alt="logo"
            />
          ) : (
            <span title={executor?.name + executor?.surname} >{executor?.name[0] + executor?.surname[0]}</span>
          )}
        </div>
        <div className="dependencies__content-list__project">
          <p className="p-black project-section-name">
            {project?.name ? project?.name : ""}
          </p>
          <span className="p-grey project-section-name">
            {projectSection?.name ? projectSection?.name : ""}
          </span>
        </div>
        {createBpStatus ||
        createTaskStatus ||
        createBpSampleStatus ||
        openMenuTasks ||
        openMenuBp ? (
          <></>
        ) : (
          <div className="dependencies__content-list__item__right__priority">
            {el.id && (
              <TaskPriority
                priorityId={el.data.priority_id || el.priority_id}
              />
            )}
          </div>
        )}

        <div
          className="dependencies__content-list__item__right__list"
          onClick={(e) => {
            // console.log(el.id);
            e.stopPropagation();

            setIdBp(+el.id);
            // setSelectedBp(+el.id);
          }}
        >
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/List.svg`}
            alt="list"
            onClick={(e) => {
              e.stopPropagation();

              setTaskId(+el.id);
              setOpenMenuTask(true);
            }}
          />
          <BpItemTaskMenu
            id={el.id}
            status={el.status}
            el={el}
            onClick={() => setTaskId(+el.id)}
          />
        </div>
      </div>
    </div>
  );
};

export default TaskItem;
