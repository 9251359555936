import React from "react";

const BsFilter57 = ({ props }) => {
  return (
<svg 
{...props}
width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.2">
<rect x="0.5" y="0.5" width="35" height="35" rx="3.5" fill="#7B7B7B" fill-opacity="0.1" stroke="#7B7B7B"/>
<path d="M24 11.375H12V23.375H24V11.375Z" stroke="#7B7B7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>

  );
};

export default BsFilter57;
