import {Action} from "redux"
import {Schedule, Task, Section} from "../../common/types"

export enum types {
    SET_TASK_NAME = '@@NEW_TASK/SET_PROJECT_NAME',
    SET_TASK_DESCRIPTION = '@@NEW_TASK/SET_PROJECT_DESCRIPTION',
    SET_TASK_PRIORITY = '@@NEW_TASK/SET_TASK_PRIORITY',
    SET_TASK_PROJECT = '@@NEW_TASK/SET_TASK_PROJECT',
    SET_TASK_EXECUTOR = '@@NEW_TASK/SET_TASK_EXECUTOR',
    SET_TASK_SECTION = '@@NEW_TASK/SET_TASK_SECTION',
    CLEAR_TASK = '@@NEW_TASK/CLEAR_TASK',
    SET_TASK_BEGIN = '@@NEW_TASK/SET_TASK_BEGIN',
    SET_TASK_END = '@@NEW_TASK/SET_TASK_END',
    CREATE_TASK = '@@NEW_TASK/CREATE_TASK',
    SET_IS_FETCHING_TASK = '@@NEW_TASK/SET_IS_FETCHING_TASK',
    SET_TASK_WORKLOAD = '@@NEW_TASK/SET_TASK_WORKLOAD',
    SET_TASKLOAD = '@@NEW_TASK/SET_TASKLOAD',
    SET_EXECUTOR_SCHEDULE = '@@NEW_TASK/SET_EXECUTOR_SCHEDULE',
    OPEN_EXISTING_TASK = '@@NEW_TASK/OPEN_EXISTING_TASK',
    SET_PROJECT_SECTIONS = '@@NEW_TASK/SET_PROJECT_SECTIONS',
    FETCH_PROJECT_SECTIONS = '@@NEW_TASK/FETCH_PROJECT_SECTIOINS',
    SET_PREV_TASK = '@@NEW_TASK/SET_PREV_TASK',
    SET_NEXT_TASK = '@@NEW_TASK/SET_NEXT_TASK',
    SET_PARENT_TASK = '@@NEW_TASK/SET_PARENT_TASK',
    SET_CHILD_TASKS = '@@NEW_TASK/SET_CHILD_TASKS',
    DELEGATE_TASK = '@@NEW_TASK/DELEGATE_TASK',
    SET_DELEGATE_TO = '@@NEW_TASK/SET_DELEGATE_TO',
    SET_RECALCULATE = '@@NEW_TASK/SET_RECALCULATE',
    SET_LAST_CHANGED = '@@NEW_TASK/SET_LAST_CHANGED',
    SET_PROVIDE_TO = '@@NEW_TASK/SET_PROVIDE_TO',
    SUCCESSFUL_DELEGATED_NEW_TASK = '@@NEW_TASK/SUCCESSFUL_DELEGATED_NEW_TASK'
}


export interface setTaskNameAction extends Action<types.SET_TASK_NAME> {
    name: string
}

export interface setTaskDescriptionAction extends Action<types.SET_TASK_DESCRIPTION> {
    description: string
}

export interface clearTaskAction extends Action<types.CLEAR_TASK> {
}

export interface setTaskPriorityAction extends Action<types.SET_TASK_PRIORITY> {
    priority: number
}

export interface setTaskProjectAction extends Action<types.SET_TASK_PROJECT> {
    projectId: number
}

export interface setTaskExecutorAction extends Action<types.SET_TASK_EXECUTOR> {
    executor_id: number | null
}

export interface setTaskBeginAction extends Action<types.SET_TASK_BEGIN> {
    begin: string
}

export interface setTaskEndAction extends Action<types.SET_TASK_END> {
    end: string
}

export interface createTaskAction extends Action<types.CREATE_TASK> {
    executor_id?: number
    isCalendar?: boolean;
    isUpdateScheduler?: boolean
}

export interface setTaskSectionAction extends Action<types.SET_TASK_SECTION> {
    id: number
}

export interface setIsSendingTaskAction extends Action<types.SET_IS_FETCHING_TASK> {
    isSending: boolean
}

export interface setTaskWorkLoadAction extends Action<types.SET_TASK_WORKLOAD> {
    workLoad: number
}

export interface setTaskLoadAction extends Action<types.SET_TASKLOAD> {
    taskLoad: number
}

export interface setExecutorScheduleAction extends Action<types.SET_EXECUTOR_SCHEDULE> {
    schedule: Schedule
}

export interface openExistingTaskAction extends Action<types.OPEN_EXISTING_TASK> {
    task: Task
}

export interface setProjectSectionsAction extends Action<types.SET_PROJECT_SECTIONS> {
    sections: Section[]
}

export interface fetchProjectSectionsAction extends Action<types.FETCH_PROJECT_SECTIONS> {
    projectId: number
}

export interface setPrevTaskAction extends Action<types.SET_PREV_TASK> {
    id: number
}

export interface setNextTaskAction extends Action<types.SET_NEXT_TASK> {
    id: number
}

export interface setParentTaskAction extends Action<types.SET_PARENT_TASK> {
    id: number
}

export interface setChildTasksAction extends Action<types.SET_CHILD_TASKS> {
    ids: number[]
}

export interface delegateTaskAction extends Action<types.DELEGATE_TASK> {
    executor_id: number[],
    taskId: number
}

export interface setDelegateToAction extends Action<types.SET_DELEGATE_TO> {
    delegateTo: number
}

export interface setRecalculateLoadAction extends Action<types.SET_RECALCULATE> {
    arg?: object
}


export interface setLastChangedParameter extends Action<types.SET_LAST_CHANGED> {
    arg: string
}

export interface setSetProvideTo extends Action<types.SET_PROVIDE_TO> {
    val: number
}

export interface successful_create_new_task_action extends Action<types.SUCCESSFUL_DELEGATED_NEW_TASK> {
    val: number[]
}

export type NewTaskAction =
    setTaskNameAction |
    setTaskDescriptionAction |
    clearTaskAction |
    setTaskPriorityAction |
    setTaskProjectAction |
    setTaskExecutorAction |
    setTaskBeginAction |
    setTaskEndAction |
    createTaskAction |
    setTaskSectionAction |
    setIsSendingTaskAction |
    setTaskWorkLoadAction |
    setTaskLoadAction |
    setExecutorScheduleAction |
    openExistingTaskAction |
    setProjectSectionsAction |
    fetchProjectSectionsAction |
    setPrevTaskAction |
    setNextTaskAction |
    setParentTaskAction |
    setChildTasksAction |
    delegateTaskAction |
    setDelegateToAction |
    setRecalculateLoadAction |
    setLastChangedParameter |
    setSetProvideTo |
    successful_create_new_task_action
