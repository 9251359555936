import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import ExecutorCell from '../../../../common/components/TableCells/ExecutorCell';
import Avatar from "../../../../common/components/Avatar/Avatar";
import { columnsOfTables } from '../../../../common/shared_data';
import { urlApp } from '../../../../App';
import { State } from '../../../../rootReducer';

type Props = {
	author_id: number;
}

const AvatarFact: FC<Props> = ({ author_id }) => {
	const { users } = useSelector((state: State) => state.commonInfo);
	const author = users.find((user) => user.id === author_id);

	const executorName = author ? `${author.surname} ${author.name}` : 'Неизвестен';
	const url = author?.image ? urlApp + `/files/${author.image.url}/download` : null;

	return (
		<Avatar
			url={url}
			name={executorName}
			style={{ width: '32px', height: '32px' }}
			isTooltip={false}
		/>
	);
};

export default AvatarFact;
