// Тут заводится и рассчитывается дата начала задачи   


import React, { useCallback } from 'react';
import TaskDateTime from '../../../common/components/DatePickers/DateTimePikerTask';
import { connect,useSelector} from 'react-redux'; // Добавлено
import DateMaster from '../../../utils/DateMaster';
import {
  recalculateTaskLoad,
  selectTask,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setTaskBegin,
  setTaskEnd      // ***********  добавлено
} from '../actions';
import { State } from '../../../rootReducer';
import { updateTask } from '../../../common/actions';
import { useEffect } from 'react-transition-group/node_modules/@types/react';

type Props = {
  checkedPersonalTask?:boolean;
  checkedTaskBegin?:boolean;
  disabled?: boolean;
  begin: string;
  end: string;
  maxDate: string;
  delegated: boolean;
  setTaskBegin: (begin: string) => void;
  setTaskEnd: (end: string) => void;    // ***********  добавлено
  recalculateTaskLoad: ({}) => void;
  selectedTask: any;
  updateTask: (id: number, params: any, withNotif?: boolean) => void;
  flagForActionCyclycTask: string;
  setCyclicTaskToChange: (obj: {}) => void;
  sendReqToChangeTemplRepTask: (obj: {}) => void;
};

const TaskBegin: React.FC<Props> = ({
  checkedPersonalTask,
  checkedTaskBegin,
  disabled,
  begin,
  end,
  maxDate,
  delegated,
  setTaskBegin,
  setTaskEnd,   // Добавлено
  recalculateTaskLoad,
  selectedTask,
  updateTask,
  flagForActionCyclycTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
}) => {
  // const acceptHandler = useCallback(
  //     function (e): any {
  //         setTaskBegin(e)

  const toFormatUtsFullTime = (date: string) => {
    let templateFullDate: string[] = [];
    templateFullDate.push(date.split(' ')[1]);
    let templateDate = date.split(' ')[0].split('-');
    templateDate[0] = date.split(' ')[0].split('-')[1];
    templateDate[1] = date.split(' ')[0].split('-')[0];
    templateFullDate.unshift(templateDate.join('-'));
    return {
      ru: date,
      uts: templateFullDate.join(' '),
    };
  };

  // begin и end- переменные, поступают из state в props-ах в качестве параметров
  let beginTask = begin //useSelector((state: State) => state.taskInfoPlate.begin); ///*** изменено
  let endTask = end //useSelector((state: State) => state.taskInfoPlate.end); ///*** изменено

  const acceptHandler = useCallback(
    function (e): any {

      // Добавлено. Задача 544 ******************************************* */

      let beginTaskOldStr =  beginTask; // старая дата начала задачи в строковом виде  
      let beginTaskNewStr =  e;  // новая дата начала задачи в строковом виде         
      let endTaskStr =  endTask; // дата окончания задачи в строковом виде             
  
  
      let beginTaskOld = new Date(toFormatUtsFullTime(beginTaskOldStr).uts).getTime(); 
      let beginTaskNew = new Date(toFormatUtsFullTime(beginTaskNewStr).uts ).getTime();  
      let endTaskDate = new Date(toFormatUtsFullTime(endTaskStr).uts).getTime();  
      let differenceTime = endTaskDate - beginTaskOld;
 
      if (beginTaskNew > endTaskDate) {  

      let newEnd = beginTaskNew + differenceTime;  
      let dateEnd = new DateMaster(new Date(newEnd)).fullDate;
 
      endTaskStr = `${dateEnd.toLocaleString().split(',')[0].split('.').join('-')}${
            dateEnd.toLocaleString().split(',')[1]
       }`;        
        }
 
     // Тут изменяется state. Но реальный запрос на сервер пока не делается 

      setTaskEnd(endTaskStr); // Тут вызывается из state action, который изменяет дату окончания, 
      // если пользователь выбрал другую дату начала, большую, чем дата окончания (при новой задаче)
      // (новая дата окончания= новая дата начала + differenceTime (разницу между 
      // старой датой начала и старой датой окончания)
  
      setTaskBegin(e); // Тут вызывается из state action, который изменяет дату начала, 
      // если пользователь выбрал другую дату 
  

      //******************************************** */

  
      if (selectedTask) {
        let end = selectedTask.end;
        let templateBegin = new Date(
          toFormatUtsFullTime(selectedTask.begin).uts
        ).getTime();
        let templateEnd = new Date(
          toFormatUtsFullTime(selectedTask.end).uts
        ).getTime();
        let newBegin = new Date(toFormatUtsFullTime(e).uts).getTime();
        let differenceTime = templateEnd - templateBegin;

        if (newBegin > templateEnd) {
          let newEnd = newBegin + differenceTime;
          let date = new DateMaster(new Date(newEnd)).fullDate;
          end = `${date.toLocaleString().split(',')[0].split('.').join('-')}${
            date.toLocaleString().split(',')[1]
          }`;
        }
        
        // реальный запрос на сервер и пересчет данных задачи
        recalculateTaskLoad({
          task_load: selectedTask.task_load,
          begin: e,
          end,
        });
        if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
          // сравнение одного с несколькими
          // значит циклическая
          if (flagForActionCyclycTask === '') {
            setCyclicTaskToChange({ begin: e, end }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
          } else if (flagForActionCyclycTask === 'task') {
            updateTask(selectedTask!.id, { begin: e, end, private: checkedPersonalTask? true: false});
          } else {
            updateTask(selectedTask!.id, { begin: e, end, private: checkedPersonalTask? true: false });
            sendReqToChangeTemplRepTask({ begin: e, end });
          }
        } else {
          updateTask(selectedTask!.id, { begin: e, end, private: checkedPersonalTask? true: false });
        }
      }
    },
    [beginTask,endTask,selectedTask] ///*** изменено
  );

  let [date, time] = selectedTask
    ? selectedTask.end.split(' ')
    : maxDate.split(' ');
  // let maxDateObj = new Date(`${date.split('-').reverse().join('-')} ${time}`);

  // in particular cases, 'begin' date comes here in format dd/mm/yyyy hh:mm:ss
  // expected format is dd-mm-yyyy
  begin = begin.replace(/\//g, '-');

  return (
    <TaskDateTime
      disabled={disabled || delegated}
      acceptHandler={acceptHandler}
      date={checkedTaskBegin?new Date(): selectedTask ? selectedTask.begin : begin}
      // maxDate={maxDateObj}
    />
  );
};
// )
// }

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    begin: state.taskInfoPlate.begin,
    end: state.taskInfoPlate.end,
    maxDate: state.taskInfoPlate.end,
    delegated: state.taskInfoPlate.delegated,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
  };
};

const mapDispatchToProps = {
  updateTask,
  setTaskBegin,
  setTaskEnd,    // *********** добавлено
  recalculateTaskLoad,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskBegin);
