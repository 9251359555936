import React, {useState, useCallback, useEffect} from 'react';
import {Tooltip, withStyles} from '@material-ui/core';
import styled from 'styled-components';

type InputProps = {
    inputType: string,
    placeholder: string,
    withIcon?: boolean,
    value: string | undefined,
    title: string,
    isValid?: boolean,
    validText?: string,
    changeHandler: (value: string) => void,
    blurHandler?: (value: string) => void,
    disabled?: boolean,
    styleInput?: object,
    styleInputDiv?: object,
    min?: number,
    defaultValue?: number | string
}


const InputFiled = styled.input`
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type="number"] {
        -moz-appearance: textfield;
    }
`

export const InvalidTooltip = withStyles(() => ({
  tooltip: {
    width: 200,
    background: '#FF5252',
    color: '#FFFFFF',
    fontSize: 12,
  },
}))(Tooltip);

const Input: React.FC<InputProps> = ({
                                         inputType,
                                         placeholder,
                                         withIcon = false,
                                         isValid = true,
                                         validText = '',
                                         value,
                                         disabled,
                                         changeHandler,
                                         blurHandler,
                                         styleInput,
                                         styleInputDiv,
                                         min,
                                         defaultValue
                                     }) => {
    const [type, setType] = useState(inputType)
    const [showTooltip, setShow] = useState(false)
    const onChange = useCallback((e: React.ChangeEvent) => {
        const eTarget = e.currentTarget as HTMLInputElement
        changeHandler(eTarget.value)
        let found_input_div = eTarget.closest('.input_div')
        if (found_input_div) {
            if (eTarget.value.length) found_input_div.classList.add('text_exist')
            else found_input_div.classList.remove('text_exist')
        }
    }, [changeHandler])
    const onBlur = useCallback((e: React.FocusEvent) => {
        if (blurHandler) blurHandler((e.currentTarget as HTMLInputElement).value)
    }, [blurHandler])

  useEffect(() => {
    if (isValid && showTooltip) setShow(false);
  }, [isValid, showTooltip]);

    return (
        <InvalidTooltip
            title={validText}
            disableFocusListener={true}
            open={showTooltip}
            placement="top"
        >
            <div
                style={styleInputDiv}
                className={`input_div${withIcon ? ' with_icon' : ''}${!isValid ? ' invalid' : ''}${disabled ? ' disabled' : ''}`}
                onMouseEnter={() => {
                    if (!isValid) setShow(true)
                }}
                onMouseLeave={() => {
                    if (!showTooltip) return
                    setShow(false)
                }}
            >
                <InputFiled
                    defaultValue={defaultValue}
                    style={styleInput}
                    type={inputType}
                    min={min ? min : undefined}
                    autoComplete="off"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </div>
        </InvalidTooltip>
    )
}

export default Input;
