import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { StatusContext } from "../../context/status";

const SearchField = ({ searchValue, setSearchValue, clearFilter }) => {
  const {
    company_id,
    bearer,
    setTextBpFilter,
    setBpFilter,
    bpFilter,
    textBpFilter,
    setOpenTasks,
  } = useContext(StatusContext);

  const [searchMode, setMode] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [oldText, setOldText] = useState("");

  const input = useRef(null);
  const window = useRef(null);

  const headers = {
    Authorization: bearer,
    "company-id": company_id,
    "secret-token": document.cookie.replace(
      /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    ),
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const useDebounce = (val, delay) => {
    const [debounceVar, setDebounceVar] = React.useState(val);
    React.useEffect(() => {
      const handler = setTimeout(() => {
        setDebounceVar(val);
      }, delay);
      setBpFilter(false);
      return () => {
        clearTimeout(handler);
      };
    }, [val]);
    return debounceVar;
  };

  const debounce = useDebounce(searchText, 500);

  useEffect(() => {
    if (clearFilter) {
      setSearchText("");
      setMode(false);
    }
  }, [clearFilter]);

  useEffect(() => {
    if (searchMode) {
      textBpFilter
        ? setSearchValue(textBpFilter + `&filter[search-bp]=`)
        : setSearchValue(`filter[search-bp]=`);
      setOldText(textBpFilter);
      setOpenTasks(false);
    } else {
      setBpFilter(true);
    }
  }, [searchMode]);

  useEffect(() => {
    if (searchText) {
      setTextBpFilter(searchValue + searchText);
      setBpFilter(true);
    } else {
      setTextBpFilter(oldText);
      // setBpFilter(false);
    }
  }, [debounce]);

  const searchChangeHandler = (value) => {
    setSearchText(value);
  };

  return (
    <div
      ref={window}
      onClick={() => {
        setMode(true);
        setTimeout(() => input.current?.focus(), 0);
      }}
      className={`table_search${searchMode ? " in_search_mode" : ""}`}
    >
      {searchMode ? (
        <>
          <input
            ref={input}
            value={searchText}
            onChange={(e) => searchChangeHandler(e.target.value)}
          />
          <div
            onClick={(e) => {
              e.stopPropagation();
              setSearchText("");
              setMode(false);
              setBpFilter(false)
              setTextBpFilter(searchValue);
            }}
            className="close_search"
          />
        </>
      ) : (
        <div>Поиск</div>
      )}
    </div>
  );
};

export default SearchField;
