import React, { FC } from 'react';

import styled from 'styled-components';

const TR = styled.tr`
  border-bottom: 1px solid #e0e0e0;
	
	&:hover {
    background: linear-gradient(
				to right,
    		white 0%,
    		#f2f2f2 5%,
    		#f2f2f2 90%,
    		white 100%
    );
	}
`

const TableRow: FC = ({children}) => {

	return (<TR>{children}</TR>);
};

export default TableRow;
