import React, { useState, useEffect } from 'react';
// import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import {
  // setTaskExecutor,
  setExecutorSchedule,
  setDelegateTo,
  openExistingTask,
  recalculateTaskLoad,
  setProvideTo,
} from '../actions';
import { connect } from 'react-redux';
import { User, Item, Schedule, Task } from '../../../common/types';
// import {Collapse} from '@material-ui/core';
// import delegate_arrow from '../../../img/delegate_arrow.png';
import { State } from '../../../rootReducer';
import { fetchData } from '../../../utils/fetchData';
// import {store} from '../../../App';
// import CheckboxItem from '../../../common/components/CheckboxItem/CheckboxItem';
import Checkbox from '../../../common/components/CheckboxItem/Checkbox';
import MultySelect from '../../../common/components/Selects/MultySelect';
// import {CreateNotif} from '../../../utils/createNotification';
import styled from 'styled-components';
import palette from '../../../common/components/palette';

type Props = {
  projectId: number | null;
  users: User[];
  executor_id: number | null;
  delegatedId: number | null;
  taskId: number;
  delegated: boolean;
  selectedUserId: number;
  provide_to: number;
  setDelegateTo: (delegateTo: number) => void;
  setExecutorSchedule: (schedule: Schedule) => void;
  openExistingTask: (task: Task) => void;
  recalculateTaskLoad: (val?: object) => void;
  setProvideTo: (val: number) => void;
  selectedItems: Item[];
  setSelectedItems: (arg: Item[]) => void;
  successfulCreatedTask: number[];
  currentUserRoles: number[] | undefined;
};

const WrapperExecuterWithDelegate = styled.div``;

const BlockCheckbox = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  color: ${palette.darkBlue};
`;

const ExecutorSelect: React.FC<Props> = ({
  selectedUserId,
  projectId,
  users,
  executor_id,
  taskId,
  delegatedId,
  setExecutorSchedule,
  setProvideTo,
  provide_to,
  selectedItems,
  setSelectedItems,
  currentUserRoles,
}) => {
  // const [show, setShow] = useState<boolean>(false)
  const [usersIdList, setUsersIdList] = useState([]); // айдишники пользователей, доступных в этом проекте
  const [thisFirstRender, setFirstRender] = useState(true);
  const [executorData, setExecutorData] = useState(
    // выпадающий список
    {
      selected: null as Item | null,
      list: [] as Item[],
      delegatedTo: null as Item | null,
    }
  );

  if (thisFirstRender) {
    if (!usersIdList.length) {
      // если список пользователей по проекту еще не получили,
      if (!executorData.selected) {
        // и исполнитель не выбран
        users.forEach((userObj) => {
          if (userObj.id === selectedUserId) {
            // то ставим исполнителем того, задачи которого сейчас открыты.
            setSelectedItems([
              ...selectedItems,
              {
                text: `${userObj.surname} ${userObj.name}`,
                value: userObj.id,
              },
            ]);
          }
        });
        setFirstRender(false);
      }
    }
  }

  // useEffect(() => {
  // if (selectedItems.length && selectedItems.length === successfulCreatedTask.length) {
  // updateTask(selectedTask?.id as number, {provide_to: 0, child_tasks: successfulCreatedTask}, false)
  // CreateNotif('Задачи успешно созданы', "success")
  // if (selectedItems.length === 1) {
  //     // перебрасывать на таски этого конкретного пользователя и активировать эту конкретную задачу.
  // }
  // }
  // }, [successfulCreatedTask])

  useEffect(() => {
    // setUsersIdList([]);
    (async () => {
      if (!projectId) return;
      let usersIdForProject = await fetchData.get(
        `/api/v1/projects/${projectId}/users`
      );

      // в старых проектах данные хранились в виде объекта, а не массива. Для гарантии конвертирую в массив.
      usersIdForProject = Object.values(usersIdForProject);
      setUsersIdList(usersIdForProject);

      // получили юзеров по этому проекту. Если в этом массиве айдишников есть тот юзер, который сейчас стоит исполнителем - по которому открыты задачи, то его и оставляем. Если нет - значит просто стереть.
      let found;
      usersIdForProject.forEach((id) => {
        if (id === selectedUserId) found = true;
      });

      if (!found) {
        setExecutorData({
          selected: null,
          list: executorData.list,
          delegatedTo: executorData.delegatedTo,
        });
      }
    })();
  }, [projectId]);

  useEffect(() => {
    (async () => {
      if (!executor_id) return;
      const data = await fetchData.get(`/api/v1/users/${executor_id}/schedule`);
      setExecutorSchedule(data?.schedule);
    })();
  }, [executor_id]);

  useEffect(() => {
    let selected = executorData.selected,
      delegatedTo = executorData.delegatedTo,
      list: Item[] = [];

    // здесь формируется выпадающий список
    usersIdList.forEach((userId) => {
      // users - это commonUsers
      // достаю полную информацию о пользователе из commonInfo, имея только айдишники пользователей, доступных в этом проекте
      let user = users.find((user) => user.id === userId);
      if (user) {
        let item = {
          text: `${user.surname} ${user.name}`,
          value: user.id,
        };
        // if (user.id === executor_id) selected = item
        // if (user.id === delegatedId) delegatedTo = item
        list.push(item);
      }
    });
    setExecutorData({ selected, list, delegatedTo });
  }, [delegatedId, executor_id, users, usersIdList]);

  return (
    <WrapperExecuterWithDelegate>
      {/*исполнителю вообще не должна показываться эта галка*/}
      {currentUserRoles?.[0] !== 7 && (
        <BlockCheckbox>
          <Checkbox
            checked={!!provide_to}
            onChange={() => {
              provide_to === 1 ? setProvideTo(0) : setProvideTo(1);
            }}
          />
          <div style={{ marginLeft: '.5rem' }}>Разрешить делегирование</div>
        </BlockCheckbox>
      )}

      <MultySelect
        radio={!!taskId}
        disabled={usersIdList.length === 0}
        showSearchField={!selectedItems.length || !taskId}
        itemsList={executorData.list}
        selectedItems={selectedItems}
        addHandler={(arg) => {
          // добавляется элемент в массив уже показываемых в чипсах пользователей
          // if (taskId) setTaskExecutor([arg])
          // else
          setSelectedItems([
            ...selectedItems,
            executorData.list.find((item) => item.value === arg) as Item,
          ]);
        }}
        removeHandler={(arg) => {
          let coped_array = selectedItems.map((each) => ({
            value: each.value,
            text: each.text,
          }));
          coped_array.splice(
            coped_array.findIndex((item) => item.value === arg),
            1
          );
          setSelectedItems(coped_array); // соответственно удаляется
        }}
      />
      {/* </div> */}
      {/* </div> */}
    </WrapperExecuterWithDelegate>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentUserRoles: state.commonInfo.currentUserInfo?.roles,
    successfulCreatedTask: state.newTask.successfulCreatedTask,
    provide_to: state.newTask.provide_to,
    selectedUserId: state.tasksPage.selectedUserId,
    executor_id: state.newTask.executor_id,
    users: state.commonInfo.users,
    projectId: state.taskInfoPlate.project_id,
    taskId: state.newTask.id,
    delegated: state.newTask.delegated,
    delegatedId: state.newTask.delegatedTo,
  };
};

const mapDispatchToProps = {
  // setTaskExecutor,
  setExecutorSchedule,
  setDelegateTo,
  openExistingTask,
  recalculateTaskLoad,
  setProvideTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecutorSelect);
