import {types, NewUserAction} from './action_types';
import {DayOff} from '../../common/types';
import {Reducer} from 'redux';
import {File as FileType} from '../../common/types';

const initialState = {
    email: '',
    password: '',
    surname: '',
    name: '',
    patronymic: '',
    gender: 'm',
    birthday: '',
    phone: '',
    roles: [] as number[],
    positions: [] as number[],
    departments: [] as number[],
    schedule: [1, 2, 3, 4, 5],
    workBegin: '09:00:00',
    workEnd: '18:00:00',
    daysOff: [] as DayOff[],
    isSending: false,
    id: 0,
    image: null as File | FileType | null,
    changePassWindowShow: false,
    changeIndicator: false
};

export type NewUserState = typeof initialState;

const reducer: Reducer<NewUserState, NewUserAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case types.CHANGE_INDICATOR:
            return {
                ...state,
                changeIndicator: !state.changeIndicator,
            };
        case types.SET_USER_NAME:
            return {
                ...state,
                name: action.name,
            };
        case types.SET_USER_EMAIL:
            return {
                ...state,
                email: action.email,
            };
        case types.SET_USER_PASSWORD:
            return {
                ...state,
                password: action.password,
            };
        case types.SET_USER_PATRONYMIC:
            return {
                ...state,
                patronymic: action.patronymic || '',
            };
        case types.SET_USER_SURNAME:
            return {
                ...state,
                surname: action.surname,
            };
        case types.SET_USER_ROLE:
            return {
                ...state,
                roles: action.roles,
            };
        case types.SET_USER_POSITION:
            return {
                ...state,
                positions: action.positions,
            };
        case types.SET_USER_DEPARTMENT:
            return {
                ...state,
                departments: action.departments,
            };
        case types.SET_USER_BIRTHDAY:
            return {
                ...state,
                birthday: action.birthday,
            };
        case types.SET_USER_SCHEDULE:
            return {
                ...state,
                schedule: action.schedule,
            };
        case types.SET_USER_WORK_BEGIN:
            return {
                ...state,
                workBegin: action.time,
            };
        case types.SET_USER_WORK_END:
            return {
                ...state,
                workEnd: action.time,
            };
        case types.SET_USER_DAYS_OFF:
            return {
                ...state,
                daysOff: action.daysOff,
            };
        case types.SET_IS_SENDING:
            return {
                ...state,
                isSending: action.isSending,
            };
        case types.SET_USER_IMAGE:
            return {
                ...state,
                image: action.image,
            };
        case types.CLEAR_USER:
            return initialState;
        case types.OPEN_USER:
            return {
                ...state,
                ...action.user,
            };
        case types.SET_PASS_CHANGE_MODE:
            return {
                ...state,
                changePassWindowShow: action.mode,
            };

        default:
            return state;
    }
};

export default reducer;
