import React, {
	ChangeEvent,
	Dispatch,
	FC,
	SetStateAction,
	useState,
	useEffect,
} from 'react';
import styled from 'styled-components';

import TableCell from './TableCell';
import { ChangeTask } from "../../../../common/types";
import checkbox from "../../../../common/components/CheckboxItem/Checkbox";

interface SortEvent {
	oldIndex: number;
	newIndex: number;
}

const Paragraph = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	min-width: max-content;
	align-items: flex-start;
	color: #A8A8A8;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 5px;
	align-items: center;
`;

const Checkbox = styled.input`
	width: 20px;
	height: 20px;
`;

type Props = {
	setPeople: Dispatch<SetStateAction<ChangeTask[]>>;
	people: ChangeTask[];
}

const TableHead: FC<Props> = ({ setPeople, people }) => {
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setIsChecked(event.target.checked);

		setPeople((prevArr) => {
			const data = prevArr.map((task) => ({ ...task, checked: event.target.checked }));

			return data;
		});
	};

	useEffect(() => {
		if (isChecked) {
			setPeople((prevArr) => {
				const data = prevArr.map((task) => ({ ...task, checked: isChecked }));

				return data;
			});
		}
	}, [people?.length])

	return (
		<thead>
			<tr>
				<TableCell isHeader>
					<Paragraph>№</Paragraph>
				</TableCell>
				<TableCell isHeader>
					<Paragraph>Автор</Paragraph>
				</TableCell>
				<TableCell isHeader>
					<Paragraph>Задача</Paragraph>
				</TableCell>
				<TableCell isHeader>
					<Paragraph>Дедлайн</Paragraph>
				</TableCell>
				<TableCell isHeader>
					<Paragraph>Проект</Paragraph>
				</TableCell>
				<TableCell isHeader>
					<Paragraph>Списано</Paragraph>
				</TableCell>
				<TableCell isHeader>
					<Paragraph>К списанию</Paragraph>
				</TableCell>
				<TableCell isHeader>
					<Paragraph>Остаток</Paragraph>
				</TableCell>
				<TableCell isHeader>
					<Paragraph>Загрузка</Paragraph>
				</TableCell>
				<TableCell isHeader>
					<Column>
						<Paragraph>Отметить все</Paragraph>
						<Checkbox
							type='checkbox'
							onClick={handleChange}
							checked={isChecked}
							onChange={() => {}}
						/>
					</Column>
				</TableCell>
			</tr>
		</thead>
	);
};

export default TableHead;
