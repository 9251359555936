import React, {ChangeEvent, FC, RefObject, useEffect, useState} from 'react';
import styled from 'styled-components';
import { RegisterOptions } from 'react-hook-form';

const CustomInput = styled.input`
	border: 1px solid lightgray;
	width: 100px;
	height: 30px;
`;

type Props = {
	task_load: number;
	writeOff: number;
	register: (Ref: RefObject<any>, RegisterOptions?: RegisterOptions) => void;
	id: number;
};

const WriteOff: FC<Props> = ({ task_load, register, id, writeOff }) => {
	const [value, setValue] = useState<number>(0);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (+event.target.value <= task_load) {
			setValue(+event.target.value);
		}
	};

	useEffect(() => {
		setValue(writeOff);
	}, []);

	return (
		<CustomInput
			type='number'
			min={0}
			max={task_load}
			value={value}
			onChange={handleChange}
			name={`${id}.tax`}
			ref={register}
		/>
	)
};

export default WriteOff;
