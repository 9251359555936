import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { Task } from "../../../common/types";
import TaskTr from "./TaskTr";
//import { SortableContainer } from 'react-sortable-hoc';
import { State } from "../../../rootReducer";
import { connect, useDispatch } from "react-redux";
import { fetchTasks, changeTaskList } from "../actions";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import TasksTableHead from "./TasksTableHead";
import DateMaster from "../../../utils/DateMaster";
import PaginationTasks from "./PaginationTasks";
import { fetchData } from "../../../utils/fetchData";
import { urlApp } from "../../../App";
import { cookieMaster } from "../../../utils/CookieMaster";

type Props = {
  tasks: Task[];
  tableFilter: string;
  fetchTasks: (pageNum: number) => void;
  changeTaskList: (tasks: Task[]) => void;
  filters: any;
  tasksOrder: Object;
  // notification_for_tasks: any[];
  unreadNotification: any[];
  updateTask: boolean;
  setUpdateTask: (bool: boolean) => void;
  isSendingCreateNewTasks: boolean;
};

const WrapperTasksTable = styled.div`
  width: 100%;
  height: calc(95vh - 229px);
  /* margin-left: 40px; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f4f4f4;
`;
// const WrapperTasks = styled.div`
//   max-width: 1280px;
//   width: 100%;
//   padding: 0 40px;
// `;

const TasksTableContent: React.FC<Props> = ({
  tasks,
  tableFilter,
  fetchTasks,
  changeTaskList,
  filters,
  tasksOrder,
  // notification_for_tasks,
  unreadNotification,
  updateTask,
  setUpdateTask,
  isSendingCreateNewTasks,
}) => {
  const [pageNum, setPageNum] = useState(1);
  const [isScroll, setScroll] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [idOfTaskToShow, setIdOfTaskToShow] = useState<number>(0);
  const [showSidePlate, setShowSidePlate] = useState<boolean>(true);
  /* *********************************  */


  // const scrollHandler = useCallback(
  //   (e: React.MouseEvent<HTMLDivElement>) => {
  //     const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
  //     // console.log(scrollHeight, scrollTop + clientHeight + 1)
  //     if (
  //       scrollHeight <= scrollTop + clientHeight + 10 &&
  //       (tasks.length === 20 * pageNum ||
  //         updateTask ||
  //         (tasks.length >= 20 * pageNum && !updateTask))
  //     ) {
  //       setPageNum(pageNum + 1);
  //       setUpdateTask(false);
  //     }
  //   },
  //   [pageNum, tasks.length]
  // );




  useEffect(() => {
    let widthScrol = ref?.current?.offsetWidth || 0;
    let widthTable = ref?.current?.scrollWidth || 0;
    if (widthScrol > widthTable) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [ref]);

  return (
    <>
      <TasksTableHead
      //isScroll={isScroll}
      />
      <WrapperTasksTable
      //onScroll={scrollHandler} ref={ref}
      >
        {/* <WrapperTasks> */}
        {tasks.map((task: Task) => {
          if (task.status_id === 10 && filters.current_set.statuses.length) {
            // статус задачи "в работе", и какой то фильтр по статусу включен
            if (
              new Date(
                new DateMaster(task.end).year,
                new DateMaster(task.end).month,
                new DateMaster(task.end).day,
                new DateMaster(task.end).hours,
                new DateMaster(task.end).minutes
              ).getTime() <= new Date(Date.now()).getTime()
            ) {
              // эта задача просрочена
              if (filters.current_set.statuses.indexOf(14) !== -1) {
                // фильтр по статусу 14 включен
                return (
                  <TaskTr
                    idOfTaskToShow={idOfTaskToShow}
                    setIdOfTaskToShow={setIdOfTaskToShow}
                    showSidePlate={showSidePlate}
                    setShowSidePlate={setShowSidePlate}
                    key={task.id}
                    task={task}
                    markTask={
                      unreadNotification
                        .map((notif) => notif.data?.task_id)
                        .indexOf(task.id) >= 0
                    }
                  />
                );
              } else {
                // фильтр по статусу 14 НЕ включен
                // ПРОПУСКАЕМ
              }
            } else {
              // НЕ просрочена
              return (
                <TaskTr
                  idOfTaskToShow={idOfTaskToShow}
                  setIdOfTaskToShow={setIdOfTaskToShow}
                  showSidePlate={showSidePlate}
                  setShowSidePlate={setShowSidePlate}
                  key={task.id}
                  task={task}
                  markTask={
                    unreadNotification
                      .map((notif) => notif.data?.task_id)
                      .indexOf(task.id) >= 0
                  }
                />
              );
            }
          } else {
            return (
              <TaskTr
                idOfTaskToShow={idOfTaskToShow}
                setIdOfTaskToShow={setIdOfTaskToShow}
                showSidePlate={showSidePlate}
                setShowSidePlate={setShowSidePlate}
                key={task.id}
                task={task}
                markTask={
                  unreadNotification
                    .map((notif) => notif.data?.task_id)
                    .indexOf(task.id) >= 0
                }
              />
            );
          }
        })}
        {/* </WrapperTasks> */}
      </WrapperTasksTable>

      {/* меню пагинации */}

      <PaginationTasks
      // pageNumber={pageNumber}
      //   limitedTasks={limitTasksPages}
      //   pageNumLast={pageNumLast}
      //   handlerClickLimitTasks={onClickLimitTasks}
      //   handlerPageClickNumber={onPageClickNumber}
      isSendingCreateNewTasks={isSendingCreateNewTasks}
      tasksOrder={tasksOrder}
      />
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    // project_id:state.tasksPage.tasks,
    tableFilter: state.tasksPage.tableFilter.toLocaleLowerCase(),
    tasks: state.tasksPage.tasks,
    filters: state.commonInfo.filters,
    tasksOrder: state.tasksPage.tasksOrder,
    notification_for_tasks: state.tasksPage.notification_for_tasks,
    unreadNotification: state.commonInfo.remote_notifications.unread,
    isSendingCreateNewTasks: state.taskInfoPlate.isSendingCreateNewTasks,
  };
};

const mapDispatchToProps = {
  fetchTasks,
  changeTaskList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksTableContent);

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(SortableContainer(TasksTableContent));
