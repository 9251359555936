import React, { useCallback, useState } from "react";
import AddButton from "../../../common/components/Buttons/AddButton";
// import Tumbler from '../../../common/components/Buttons/Tumbler';
import { connect, useDispatch } from "react-redux";
import {
  // setShowDepGroupPlate,
  setUserAddMode,
  openDepGroupPlate,
  openDeps,
  // openGroups,
  // setDepGroupType,
  selectDepGroup,
  setTableFilter,
  setUsers,
  fetchUsers,
} from "../actions";
// import {getDepGroupPlateType} from '../selectors'
import { State } from "../../../rootReducer";
import { useHistory } from "react-router-dom";
// import UserProjectSelect from "../../../common/components/Selects/UserProjectSelect"
// import ViewWeekRoundedIcon from "@material-ui/icons/ViewWeekRounded"
// import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded"
import TableSearch from "../../../common/components/TableSearch/TableSearch";
import { User } from "../../../common/types";
import ButtonCustom from "../../../common/components/newComponent/Buttons/Button";
import { clearUser } from "../../NewUser/actions";
import styled from "styled-components";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { IconButton } from "material-ui";
import axios from "axios";
import { cookieMaster } from "../../../utils/CookieMaster";
import { urlApp } from "../../../App";
import { CreateNotif } from "../../../utils/createNotification";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { nanoid } from "nanoid";
import { useContext } from "react";
import { useStateContext } from "../../../useStateContext";

type Props = {
  tableFilter: string;
  // depGroupPlateType: string | null,
  // setShowDepGroupPlate: (show: boolean) => void,
  setUserAddMode: (mode: boolean) => void;
  openDeps: () => void;
  // openGroups: () => void,
  // setDepGroupType: (type: string | null) => void,
  selectDepGroup: (id: number | null, totalUsers: number | null) => void;
  currentUserRole: number | undefined;
  users_length: number;
  setTableFilter: (filter: string) => void;
  setUsers: (users: User[]) => void;
  fetchUsers: (pageNum: number) => void;
  // setOrder: any
};

type SendFile = {
  method: string;
  url: string;
  data: FormData;
  headers: Headers;
};

const UserActionStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 40px 24px 40px;
`;
const RightBlockStyle = styled.div`
  display: flex;
  align-items: center;
`;

const ModalContainer = styled.div`
border-radius:5px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 650px;
  min-height: 200px;
  max-height: 700px;
  overflow-y: scroll;
  padding: 27px 40px 15px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  top: 35%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  margin-top: 100px;
`;

const ModalContentDiv = styled.div`
  width: 500px;
  text-align: center;
  margin: 15px 0px;
  border: 1px solid #d0d0d0;
  box-shadow: 3px 3px 6px -4px rgb(0 0 0 / 75%);
  padding: 10px;
`;
const ModalContentH2 = styled.h2`
  text-align: center;
  margin-bottom: 13px;
  margin-top: 14px;
  font-size: 20px;
  text-decoration: underline;
`;

const UsersActions: React.FC<Props> = ({
  tableFilter,
  fetchUsers,
  setUsers,
  setTableFilter,
  users_length,
  setUserAddMode,
  openDeps,
  selectDepGroup,
  currentUserRole,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [timer, setTimer] =
    useState<ReturnType<typeof setTimeout> | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [errorFileUsers, setErrorFileUsers] = useState<any>();

  const {
    warningWindow, setWarningWindow
  } = useContext(useStateContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    fetchUsers(1);
    setErrorFileUsers({})
  };
    
  // при поиске в тулбаре, исполняется этот колбэк
  const searchChangeHandler = useCallback(
    (filter: string) => {
      setTableFilter(filter);
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          setUsers([]);
          fetchUsers(1);
        }, 500)
      );
    },
    [fetchUsers, setTableFilter, setUsers, timer]
  );

  // excel_users.xlsx
  const sendFormsExcelInput = (e) => {
   
    let company_id = localStorage.getItem("company_id");
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    let sendFileAndGetIdConfig: any = {
      method: "POST",
      url: `${urlApp}/api/v1/upload-users`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${cookieMaster.getCookie("access_token")}`,
        "company-id": `${company_id}`,
      },
    };

    axios(sendFileAndGetIdConfig)
      .then((resp) => {
        setErrorFileUsers(resp.data.errors);
        handleOpen();
      })
      .catch((e) => {
        console.log(e);
        CreateNotif("Не получилось отправить файл");
      });
  };

  return (
    <UserActionStyle
    // className="users_actions"
    >
      {/*<UserProjectSelect*/}
      {/*    type="tasks"*/}
      {/*    itemsList={dataForUserSelect.list}*/}
      {/*/>*/}

      <div className="main_title">
        <div>Штат</div>
        <div className={"countUsers"}>{users_length} сотрудников</div>
      </div>

      {/*<Tumbler*/}
      {/*    text="Группы"*/}
      {/*    active={depGroupPlateType === 'groups'}*/}
      {/*    clickOnActive={() => {*/}
      {/*        setShowDepGroupPlate(false)*/}
      {/*        selectDepGroup(null)*/}
      {/*    }}*/}
      {/*    clickOnPassive={() => {*/}
      {/*        selectDepGroup(null)*/}
      {/*        openGroups()*/}
      {/*    }}*/}
      {/*/>*/}

      <RightBlockStyle>
        <div className="sub_actions">
          {/*<ViewWeekRoundedIcon onClick={() => {*/}
          {/*    setShowColumnsCustom(!showColumnsWindow)*/}
          {/*}} className={`columns_select${showColumnsWindow ? ' active' : ''}`}/>*/}

          {/*<div className="table_search_wrapper">*/}
          {/*    <TableSearch searchValue={tableFilter} setSearchValue={searchChangeHandler} isOpen={!!tableFilter}/>*/}
          {/*</div>*/}

        <div style={warningWindow? { position: "absolute",  top: "70px", right: "170px" }:{ position: "absolute",  top: "16px", right: "170px" }}>
            <TableSearch
              searchValue={tableFilter}
              setSearchValue={searchChangeHandler}
              isOpen={!!tableFilter}
            />
  </div> 
        </div>

        {/*<Tumbler*/}
        {/*    text="Отделы"*/}
        {/*    // active={depGroupPlateType === 'deps'}*/}
        {/*    active={false}*/}
        {/*    clickOnActive={() => {*/}
        {/*        setShowDepGroupPlate(false)*/}
        {/*        selectDepGroup(null)*/}
        {/*    }}*/}
        {/*    clickOnPassive={() => {*/}
        {/*        selectDepGroup(null)*/}
        {/*        openDeps()*/}
        {/*    }}*/}
        {/*/>*/}

        <label style={{ marginRight: "11px" }} htmlFor="download-button-file">
          <a
            style={{ textDecoration: "none", color: "#00000" }}
            href={require("../../../common/components/SendExcelUsers/excel_users.xlsx")}
            target="_blanc"
          >
            <img
              width={17}
              style={{ cursor: "pointer" }}
              title="Скачать форму для группового добавления сотрудников"
              src={require("../../Bp/public/assets/download-excel-file.svg")}
              alt="download"
            />
          </a>
        </label>

        <label style={{ marginRight: "10px" }} htmlFor="send-button-file">
          <input
            onChange={sendFormsExcelInput}
            style={{ display: "none" }}
            id="send-button-file"
            multiple
            type="file"
          />

          <img
            style={{ cursor: "pointer" }}
            width={19}
            title="Загрузить заполненную форму группового добавления сотрудников"
            src={require("../../Bp/public/assets/upload-excel-file.svg")}
            alt="send"
          />
        </label>

        {/*пользователь с ролью исполнителя не может создавать новых пользователей*/}
        {currentUserRole !== 7 && (
          <ButtonCustom
            title="Добавить"
            icon="plus_circle"
            style={{
              padding: ".5rem",
              width: "100px",
              marginLeft: "1rem",
              justifyContent: "space-between",
            }}
            onClick={() => {
              history.push("/users/0");
            }}
          />
        )}
      </RightBlockStyle>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalContainer>
          <h2 style={{ fontSize: "22px", marginBottom: "5px" }}>
            Не удалось создать следующих сотрудников:
          </h2>

          {!!errorFileUsers?.["Email уже зарегистрирован в системе"] && (
            <ModalContentDiv>
              {errorFileUsers?.["Email уже зарегистрирован в системе"].map(
                (user) => (
                  <p
                    key={nanoid()}
                    style={{
                      margin: "5px 0px",
                      color: "#F44336",
                      fontSize: "20px",
                    }}
                  >
                    {user}
                  </p>
                )
              )}
              <ModalContentH2>
                Email уже зарегистрирован в системе
              </ModalContentH2>
            </ModalContentDiv>
          )}
          {!!errorFileUsers?.["Неверный формат даты рождения"] && (
            <ModalContentDiv>
              {errorFileUsers?.["Неверный формат даты рождения"].map((user) => (
                <p
                  key={nanoid()}
                  style={{
                    margin: "5px 0px",
                    color: "#F44336",
                    fontSize: "20px",
                  }}
                >
                  {user}
                </p>
              ))}
              <ModalContentH2>Неверный формат даты рождения</ModalContentH2>
            </ModalContentDiv>
          )}
          {!!errorFileUsers?.["Имя не заполнено"] && (
            <ModalContentDiv>
              {errorFileUsers?.["Имя не заполнено"].map((user) => (
                <p
                  key={nanoid()}
                  style={{
                    margin: "5px 0px",
                    color: "#F44336",
                    fontSize: "20px",
                  }}
                >
                  {user}
                </p>
              ))}
              <ModalContentH2>Имя не заполнено</ModalContentH2>
            </ModalContentDiv>
          )}
          {!!errorFileUsers?.["Фамилия не заполнена"] && (
            <ModalContentDiv>
              {errorFileUsers?.["Фамилия не заполнена"].map((user) => (
                <p
                  key={nanoid()}
                  style={{
                    margin: "5px 0px",
                    color: "#F44336",
                    fontSize: "20px",
                  }}
                >
                  {user}
                </p>
              ))}
              <ModalContentH2>Фамилия не заполнена</ModalContentH2>
            </ModalContentDiv>
          )}

          <Button
            style={{
              background: "#01B0E9",
              width: "180px",
              marginTop: "10px",
            }}
            onClick={(e) => handleClose(e)}
            variant="contained"
            color="primary"
          >
            Ок
          </Button>
          <a
            style={{
              width: "2px",
              height: "2px",
              position: "absolute",
              bottom: "1px",
              right: "1px",
              zIndex: 10000,
              cursor: "default",
            }}
            href="https://youtu.be/FTu_ndnh-wc?t=27"
            target="_black"
          ></a>
        </ModalContainer>
      </Modal>
    </UserActionStyle>
  );
};

const mapStateToProps = (state: State) => {
  return {
    users_length: state.commonInfo.users.length,
    tableFilter: state.usersPage.tableFilter,
    // depGroupPlateType: getDepGroupPlateType(state),
    currentUserRole: state.commonInfo.currentUserInfo?.roles[0],
  };
};

const mapDispatchToProps = {
  setTableFilter,
  setUsers,
  fetchUsers,
  // setShowDepGroupPlate,
  setUserAddMode,
  openDepGroupPlate,
  openDeps,
  // openGroups,
  // setDepGroupType,
  selectDepGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersActions);
