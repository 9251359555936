import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import styled from 'styled-components'

import arrow from '../../../img/down.svg'
import attach from '../../../img/attach.svg'
import checked from '../../../img/checked1.svg'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SelectLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 6px;
`

const ColorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 6px;
`

const ArrowContainer = styled.div`
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`

const ColorLine = styled.div`
  width: 100%;
  background: ${(props) => props.hex};
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  transition: margin 85ms;

  &:hover {
    box-shadow: -8px 0 ${(props) => props.shadow};
  }

  & p {
    padding-left: 6px;
  }
`

type Colors = 'orange' | 'green' | 'blue' | 'yellow' | 'red'

type Props = {
  color: Colors
  setColor: Dispatch<SetStateAction<Colors>>
}

const colors: Array<{
  key: Colors
  label: string
  hex: string
  shadow: string
}> = [
  { key: 'blue', label: 'синий', hex: '#4EC8F0', shadow: '#039fd3' },
  { key: 'green', label: 'зеленый', hex: '#7CBD7F', shadow: '#468f4a' },
  { key: 'yellow', label: 'желтый', hex: '#FFE34D', shadow: '#ebc800' },
  { key: 'orange', label: 'оранжевый', hex: '#FFB84D', shadow: '#ff9b05' },
  { key: 'red', label: 'красный', hex: '#F87C73', shadow: '#f7483c' },
]

const ColorPicker: FC<Props> = ({ color, setColor }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggleSelect = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <Wrapper>
      <SelectLine onClick={toggleSelect}>
        <img src={attach} alt="прикпепить цвет" />
        <span>Цвета</span>
        <ArrowContainer isOpen={isOpen}>
          <img src={arrow} alt="стрелка" />
        </ArrowContainer>
      </SelectLine>
      {isOpen && (
        <ColorWrapper>
          {colors.map(({ shadow, label, key, hex }) => {
            return (
              <ColorLine
                onClick={() => setColor(key)}
                hex={hex}
                shadow={shadow}
              >
                <p>{label}</p>
                {color === key && <img src={checked} alt="выбрано" />}
              </ColorLine>
            )
          })}
        </ColorWrapper>
      )}
    </Wrapper>
  )
}

export default ColorPicker
