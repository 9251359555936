// Сведения о компании
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  showAnimateCompanyWindow,
  showCompanyWindow,
} from "../../common/actions";
import Icon from "../../common/components/Icon";
import Button from "../../common/components/newComponent/Buttons/Button";
import CustomInput from "../../common/components/newComponent/Inputs/CustomInput";
import NameWithInput from "../../common/components/newComponent/Inputs/NameWithInput";
import { State } from "../../rootReducer";
import { fetchCreateCompany } from "../Login/actions";
import { useForm } from "react-hook-form";
import { setInitial, setPlateEditMode } from "./actions";
import ShowModule from "./Components/showMode";
import EditModule from "./Components/editMode";
import { fetchData } from "../../utils/fetchData";

const WrapperSlidePlate = styled.div`
  overflow: scroll;
  position: fixed;
  z-index: 1002;
  transition: right 0.8s ease;
  right: ${({ active }) => (active ? "0" : "-590px")};
  top: 76px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  width: 584px;
  height: calc(100vh - 76px);
  padding: 16px;
`;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  height: 40px;
  span {
    font-size: 18px;
    line-height: 24px;
    color: #212121;
  }
  input {
    font-size: 18px;
    line-height: 24px;
    color: #212121;
  }
`;
const WrapperButton = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 8px;
  }
`;

const TopInfoTextItem = styled.div`
  margin-left: 8px;
  line-height: 24px;
`;

type Input = {
  name: string;
  description?: string;
  address?: string;
  inn?: number;
  kpp?: number;
  ogrn?: number;
  info?: string;
};

// const TopItem = ({iconName, content}) => {
//     return (
//         <div>
//             <div style={{height: '32px', padding: '4px', display: 'flex'}}>
//                 <Icon name={iconName}/>
//                 <TopInfoTextItem>{content}</TopInfoTextItem>
//             </div>
//         </div>
//     )
// }

const CompanyBlock = ({
  plateEditMode,
  setPlateEditMode,
  companyName,
  showAnimateCompanyWindow,
  companyInfoPlate,
  commonInfo,
  setInitial,
}) => {
  // const active = useSelector(
  //     (state: State) => state.commonInfo.show_company_window
  // );
  const dispatch = useDispatch();
  // const {register, handleSubmit, getValues} = useForm();
  // const {id, name} = useSelector((state: State) => state.companyInfoPlate);
  // const [onlyRead, setOnlyRead] = useState<boolean>();

  const [firstRender, setFirstRender] = useState<boolean>(true);

  if (firstRender) {
    setFirstRender(false);
    setTimeout(() => showAnimateCompanyWindow(true), 0);
  }
  // const [Hide, setHide] = useState<boolean>(true);

  // useEffect(() => {
  //     setOnlyRead(!!id);
  // }, [id]);
  // console.log(companyInfoPlate)

  return (
    <WrapperSlidePlate active={commonInfo.show_animate_company_window}>
      <Icon
        name="cross"
        onClick={() => {
          showAnimateCompanyWindow(false);
          setTimeout(() => {
            dispatch(showCompanyWindow(false));
            setPlateEditMode(false);
            setInitial();
          }, 800);
        }}
      />

      {!plateEditMode && <ShowModule />}
      {plateEditMode && <EditModule />}
    </WrapperSlidePlate>
  );
};
const mapStateToProps = (state: State) => {
  return {
    commonInfo: state.commonInfo,
    companyInfoPlate: state.companyInfoPlate,
    plateEditMode: state.companyInfoPlate.plateEditMode,
    companyName: state.companyInfoPlate.name,
  };
};

const mapDispatchToProps = {
  setPlateEditMode,
  showAnimateCompanyWindow,
  setInitial,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBlock);
