import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { State } from '../../../../rootReducer';

const Description = styled.p`
  color: #A8A8A8;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 5px;
`;

type Props = {
	project_id: number;
	project_section_id: number;
};

const Project: FC<Props> = ({ project_id, project_section_id }) => {
	const { projects, sections } = useSelector((state: State) => state.commonInfo);

	const project = projects.find((project) => project.id === project_id);
	const section = sections.find((section) => section.id === project_section_id);

	return (
		<Wrapper>
			<p>{project?.name}</p>
			<Description>{section?.name}</Description>
		</Wrapper>
	);
};

export default Project;
