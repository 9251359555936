import {
  types,
  fetchTokenAction,
  fetchCreateCompanyAction,
  DataCompany,
} from './action_types';

export const fetchToken = (
  email: string,
  password: string
): fetchTokenAction => {
  return {
    type: types.FETCH_TOKEN,
    email,
    password,
  };
};

export const fetchCreateCompany = (
  dataCompany: DataCompany,
  employees: object[]
): fetchCreateCompanyAction => {
  return {
    type: types.FETCH_CREATE_COMPANY,
    dataCompany,
    employees,
  };
};
