import React from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import { setUserRole } from '../actions';
import { getUserRoles, getRoleSelectData } from '../selectors';
import { State } from '../../../rootReducer';
import { User, Item } from '../../../common/types';
import MultySelect from '../../../common/components/Selects/MultySelect';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

type Props = {
  roleSelectData: {
    list: Item[];
    selected: Item[];
  };
  userId: number;
  currentUserInfo: User | null;
  userRoles: number[];
  setUserRole: (role: number[]) => void;
  setChangeDataUser: any;
  title?: string;
  isAddUserInCompany?: boolean;
};

const RoleSelect: React.FC<Props> = ({
  roleSelectData,
  userId,
  currentUserInfo,
  userRoles,
  setUserRole,
  setChangeDataUser,
  title,
  isAddUserInCompany,
}) => {
  let disabledValue: boolean = false;
  let list_of_available_roles: Item[] = roleSelectData.list;

  // какие роли может назначать залогиненный пользователь, в соответствии со своей ролью
  switch (
    currentUserInfo?.roles[0] // какая у меня топовая роль
  ) {
    case 2: // director
      list_of_available_roles.splice(0, 2);
      break;
    case 3: // curator
      list_of_available_roles.splice(0, 3);
      break;
    case 4: // cpe
      list_of_available_roles.splice(0, 4);
      break;
    case 5: // head_of_department
      list_of_available_roles.splice(0, 5);
      break;
    case 6: // team_leader
      list_of_available_roles.splice(0, 6);
      break;
    case 7: // executor
      list_of_available_roles = [];
      disabledValue = true;
      break;
  }

  if (userId !== 0) {
    // это редактирование существующего пользователя
    // если моя роль ниже, либо равна роли редактируемого, то я не могу ему редактировать роль
    if (
      roleSelectData.selected[0] &&
      currentUserInfo?.roles[0] &&
      roleSelectData.selected[0].value <= currentUserInfo?.roles[0]
    ) {
      // это не мой профайл

      if (
        userId !== currentUserInfo?.id &&
        roleSelectData.selected[0].value === 1 &&
        currentUserInfo?.roles[0] === 1
      ) {
        disabledValue = false;
      } else if (userId !== currentUserInfo?.id) {
        // не могу редактировать роль
        disabledValue = true;
      }
    }
  }

  return (
    <NameWithInput title={title} iconName="bag" necessarily>
      <MultySelect
        disabled={disabledValue}
        itemsList={list_of_available_roles}
        selectedItems={roleSelectData.selected}
        isValid={roleSelectData.selected.length > 0}
        addHandler={(value: number) => {
          setUserRole([...userRoles, value]);
          setChangeDataUser((prev) => ({
            ...prev,
            data: { ...prev.data, roles: [...userRoles, value] },
            schedule: { ...prev.schedule },
          }));
        }}
        removeHandler={(value: number) => {
          setUserRole(userRoles.filter((role) => role !== value));
          userId !== 0 &&
            setChangeDataUser((prev) => ({
              ...prev,
              data: {
                ...prev.data,
                roles: userRoles.filter((role) => role !== value),
              },
              schedule: { ...prev.schedule },
            }));
        }}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    roleSelectData: getRoleSelectData(state),
    userRoles: getUserRoles(state),
    userId: state.newUser.id,
    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setUserRole: setUserRole,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleSelect);
