import {
    types,
    fetchCommonInfoAction,
    enqueueSnackbarAction,
    closeSnackbarAction,
    removeSnackbarAction,
    setUsersAction,
    setDepartmentsAction,
    setWorkgroupsAction,
    setProjectsAction,
    setSectionsAction,
    updateTaskAction,
    setVerifiedAction,
    fetchExistingUserDataAction,
    setCurrentUserInfoAction,
    setShowNotifWindow,
    setChangeRemoteNotifs,
    setMarkNotificationRead,
    setLoadNextPageNotifications,
    changeWorkScheduleAction,
    clearNotificationsAction,
    updateTimestampAction,
    updateNewNotificationAction,
    setShowCompanyWindow,
    setFiltersAction,
    filterTemplateAction,
    ShowFiltersWindowAction,
    defaultfilterTemplateAction,
    setShowAnimateCompanyWindow,
} from './action_types';
import {
    User,
    Department,
    Workgroup,
    Project,
    Section,
    Schedule,
} from './types';
// import {setChangeRemoteNotifs, setMarkNotificationRead} from "../pages/Notifications/action_types";

export const fetchCommonInfo = (): fetchCommonInfoAction => ({
    type: types.FETCH_COMMON_INFO,
});

export const changeWorkSchedule = (
    schedule: Schedule[]
): changeWorkScheduleAction => ({
    type: types.CHANGE_WORK_SCHEDULE,
    schedule,
});

export const enqueueSnackbar = (notification: any): enqueueSnackbarAction => {
    const key = notification.options && notification.options.key;
    return {
        type: types.ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || performance.now(),
        },
    };
};

export const closeSnackbar = (key: number): closeSnackbarAction => ({
    type: types.CLOSE_SNACKBAR,
    key,
});

export const removeSnackbar = (key: number): removeSnackbarAction => ({
    type: types.REMOVE_SNACKBAR,
    key,
});

export const setUsers = (users: User[]): setUsersAction => ({
    type: types.SET_USERS,
    users,
});

export const setDepartments = (
    departments: Department[]
): setDepartmentsAction => ({
    type: types.SET_DEPARTMENTS,
    departments,
});

export const setWorkgroups = (
    workgroups: Workgroup[]
): setWorkgroupsAction => ({
    type: types.SET_WORKGROUPS,
    workgroups,
});

export const setProjects = (projects: Project[]): setProjectsAction => ({
    type: types.SET_PROJECTS,
    projects,
});

export const setSections = (sections: Section[]): setSectionsAction => ({
    type: types.SET_SECTIONS,
    sections,
});

export const updateTask = (
    taskId: number,
    params: any,
    withNotif: boolean = true
): updateTaskAction => ({
    type: types.UPDATE_TASK,
    taskId,
    params,
    withNotif,
});

export const setVerified = (verified: boolean): setVerifiedAction => ({
    type: types.SET_VERIFYED,
    verified,
});

export const fetchExistingUserData = (
    id: number
): fetchExistingUserDataAction => ({
    type: types.FETCH_EXISTING_USER_DATA,
    id,
});

export const setCurrentUserInfo = (user: User): setCurrentUserInfoAction => ({
    type: types.SET_CURRENT_USER_INFO,
    user,
});

export const showNotifWindow = (val: boolean): setShowNotifWindow => ({
    type: types.SET_SHOW_NOTIF_WINDOW,
    val,
});
export const showAnimateCompanyWindow = (value: boolean): setShowAnimateCompanyWindow => ({
    type: types.SET_ANIMATE_SHOW_COMPANY_WINDOW,
    value,
});
export const showCompanyWindow = (value: boolean): setShowCompanyWindow => ({
    type: types.SET_SHOW_COMPANY_WINDOW,
    value,
});
export const mark_notification_as_read = (
    id_notification: string,
    id_current_user: number,
    readonly?: boolean
): setMarkNotificationRead => ({
    type: types.SET_NOTIFICATION_READ,
    id_notification,
    id_current_user,
    readonly,
});

export const change_remote_notifications = (
    unread: object,
    read: object,
    load_next?: string,
    end?: boolean,
    offset?: number
): setChangeRemoteNotifs => ({
    type: types.SET_REMOTE_NOTIFICATIONS,
    unread,
    read,
    load_next,
    end,
    offset,
});

export const load_nextpage_notifications = (
    context: string
): setLoadNextPageNotifications => ({
    type: types.SET_LOAD_NEXTPAGE_NOTIFICATIONS,
    context,
});

export const clearNotifications = (): clearNotificationsAction => ({
    type: types.CLEAR_NOTIFICATIONS,
});

export const updateTimestamp = (timestamp): updateTimestampAction => ({
    type: types.UPDATE_TIMESTAMP,
    timestamp,
});

export const setFilters = (date_from, date_to, executors, authors, statuses,actual): setFiltersAction => ({
    type: types.SET_FILTERS,
    date_from,
    date_to,
    executors,
    authors,
    statuses,
    actual
});

export const FilterTemplates = (data): filterTemplateAction => ({
    type: types.SET_FILTER_TEMPLATES,
    data
});

export const DefaultFilterTemplates = (data): defaultfilterTemplateAction => ({
    type: types.SET_DEFAULT_FILTER_TEMPLATES,
    data
});

export const ShowFiltersWindow = (): ShowFiltersWindowAction => ({
    type: types.SHOW_FILTERS_WINDOW,
});

export const updateNewNotification = (
    newNotifications
): updateNewNotificationAction => ({
    type: types.UPDATE_NEW_NOTOFICATION,
    newNotifications,
});
