import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {State} from '../../../rootReducer';
import {columnsOfTables} from '../../shared_data';

type Props = {
    date: string;
};

const WrapperDateCell = styled.div`
  align-items: center;
  min-width: 96px;
  padding-right: 4px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  // color: ${({active}) => (active ? '#02405E' : '#a8a8a8')};
`;
const DateCell: React.FC<Props> = ({date}) => {
    // const selectTask = useSelector(
    //   (state: State) => state.taskInfoPlate.selectedTask
    // );

    let month_digit = date.substr(3, 2)
    let month_word

    switch (month_digit) {
        case '01':
            month_word = 'Января'
            break
        case '02':
            month_word = 'Февраля'
            break
        case '03':
            month_word = 'Марта'
            break
        case '04':
            month_word = 'Апреля'
            break
        case '05':
            month_word = 'Мая'
            break
        case '06':
            month_word = 'Июня'
            break
        case '07':
            month_word = 'Июля'
            break
        case '08':
            month_word = 'Августа'
            break
        case '09':
            month_word = 'Сентября'
            break
        case '10':
            month_word = 'Октября'
            break
        case '11':
            month_word = 'Ноября'
            break
        case '12':
            month_word = 'Декабря'
            break
    }

    return <WrapperDateCell>
        <div style={{lineHeight: '18px', marginTop: '9px'}}>
            <div>{date.substr(0, 2) + ' ' + month_word}</div>
            <div style={{color: '#a8a8a8'}}>{date.substr(11, 5)}</div>
        </div>
    </WrapperDateCell>;
};

export default DateCell;
