import React from 'react';
import TaskWorkLoading from '../TaskWorkLoading';
import styled from 'styled-components';
import { columnsOfTables } from '../../shared_data';

type Props = {
  taskLoad: number;
  workLoad: number;
  checkedPersonalTask?:boolean;
};

const WrapperTaskWork = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 4px;
  padding-left: 4px;
  min-width: ${columnsOfTables[6].style?.minWidth};
  max-width: ${columnsOfTables[6].style?.minWidth};
  span {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    padding-right: 4px;
    text-overflow: ellipsis; /* Добавляем многоточие */
  }
`;

const TaskWorkLoadingCell: React.FC<Props> = ({ taskLoad, workLoad, checkedPersonalTask }) => {
  return (
    <WrapperTaskWork>
      <span>{!checkedPersonalTask ? taskLoad: ""}ч</span>
      {/* <TaskWorkLoading
        taskLoad={taskLoad}
        workLoad={parseFloat(workLoad.toFixed(2))}
      /> */}
    </WrapperTaskWork>
  );
};

export default TaskWorkLoadingCell;
