import React, { useContext, useEffect, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { CreateNotif } from "../../../../../../utils/createNotification";
import { StatusContext } from "../../../context/status";
import "../BpResultForm.scss";
//
const TextAreaOrInputElement = ({
  name,
  value,
  isInput,
  placeholder,
  type,
  data,
  setData,
  minValue,
}) => {
  const {
    isAdmin,
    setloadDataNow,
    statusOfTask,
    taskId,
    executorIdofTask,
    userId,
  } = useContext(StatusContext);

  return (
    <>
      {isInput ? (
        <div style={{ flex: 1 }}>
          <input
            name={name}
            onWheel={(e) => {
              if (type === "number") e.target.blur();
            }}
            className={
              (statusOfTask === 0 || statusOfTask === 2) &&
              (executorIdofTask === userId || isAdmin)
                ? "input-form"
                : "input-form unavailableForm" //не дает редактировать форму в зависимости от статуса
            }
            type={type}
            placeholder={placeholder}
            value={value ? value : data[name]}
            onChange={(e) => {
              if (type === "number" && e.target.value >= 0) {
                setloadDataNow((loadDataNow) => !loadDataNow);
                setData((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                });
              } else if (type !== "number") {
                setloadDataNow((loadDataNow) => !loadDataNow);
                setData((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                });
              }
            }}
            onScroll={(e) => {
              console.log(e);
            }}
            onKeyDown={(e) => {
              if (
                type === "number" &&
                +e.target.value === 0 &&
                e.key === "ArrowDown"
              )
                e.preventDefault();
            }}
          />
          {!data[name] &&
            taskId &&
            (statusOfTask === 0 || statusOfTask === 2) &&
            (executorIdofTask === userId || isAdmin) && (
              <h2
                className="error-message-in-forms-input"
                style={{ color: "red", marginLeft: "42px" }}
              >
                Заполните поле
              </h2>
            )}
        </div>
      ) : (
        <div>
          <div>
            <TextareaAutosize
              minRows={3}
              className={
                statusOfTask === 0 || statusOfTask === 2
                  ? "input-form"
                  : "input-form unavailableForm" //не дает редактировать форму в зависимости от статуса
              }
              name={name}
              value={value ? value : data[name]}
              placeholder={placeholder}
              onChange={(e) => {
                if (executorIdofTask === userId || isAdmin) {
                  setData((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                  setloadDataNow((loadDataNow) => !loadDataNow);
                } else {
                  CreateNotif("У вас нет полномочий для данного действия");
                }
              }}
            ></TextareaAutosize>
          </div>
          {!data[name] &&
            taskId &&
            (statusOfTask === 0 || statusOfTask === 2) &&
            (executorIdofTask === userId || isAdmin) && (
              <h2
                className="error-message-in-forms-input"
                style={{ color: "red", marginLeft: "42px" }}
              >
                Заполните поле
              </h2>
            )}
        </div>
      )}
    </>
  );
};

export default TextAreaOrInputElement;
