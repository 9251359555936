export const patterns = {
  email: /^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,5}$/i,
  password: /[A-ZА-ЯЁ][0-9].{6,30}/g,
  name:  /^[a-zA-Zа-яА-ЯЁё-\s]*$/,
  surname: /^[a-zA-Zа-яА-ЯЁё-\s]*$/,
};

export function validatePassword(password: string): boolean {
  if (!password) return false;
  if (!password.match(/[A-ZА-ЯЁ]/)) return false;
  if (!password.match(/[0-9]/i)) return false;
  if (password.length > 30 || password.length < 6) return false;
  return true;
}

export function validateEmail(email: string): boolean {
  if (!email) return false;
  if (!email.match(/^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,5}$/i)) return false;
  return true;
}

export function validateName(name: string): boolean {
  if (!name) return false;
  if (!name.match(/^[a-zA-Zа-яА-ЯЁё-\s]*$/)) return false;
  return true;
}

export function validateSurname(surname: string): boolean {
  if (!surname) return false;
  if (!surname.match(/^[a-zA-Zа-яА-ЯЁё-\s]*$/)) return false;
  return true;
}
