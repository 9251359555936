import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import NameOfStep from "../templates/NameOfStep";
import SelectTemplate from "../templates/SelectTemplate";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import "../BpResultForm.scss";

const AcceptByDeputyGenManager = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.acceptByDeputyGenManager?.extra
      ? settingsOfFields.acceptByDeputyGenManager.extra
      : {}
  );

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  // console.log(taskId);
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.acceptByDeputyGenManager, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);
  return (
    <form id="acceptByDeputyGenManager">
      <div>
        <NameOfStep text={"Согласование"} />
        <SelectTemplate
          listArr={["Подтверждает", "Не подтверждает"]}
          name={"i9"}
          data={data}
          setData={setData}
        />
        <div style={{ "margin-top": "17px" }}>
          <div className="additional-header">Комментарий*</div>
          <TextAreaOrInputElement
            name={"i9_1"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
          />
        </div>
      </div>
    </form>
  );
};

export default AcceptByDeputyGenManager;
