import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import Button from "../../../common/components/newComponent/Buttons/Button";
import Icon from "../../../common/components/Icon";
import {State} from "../../../rootReducer";
import {changeStep} from "../actions";


type Props = {
    // contextStep: number,
    // currentStep: number,
    // name: string,
    // changeStep: (step: number) => void,
};


const BreadCrumbsBox = styled.div`
  width: 16px;
  height: 16px;
  margin: 0 8px;
  text-align: center;
`;

const BreadCrumbsArrow: React.FC<Props>  = () => {
    return (
        <BreadCrumbsBox>
            <Icon name={'arrowRight'}/>
        </BreadCrumbsBox>
    );
};



const mapStateToProps = (state: State) => ({
    currentTariff: state.payment.currentTariff,
    step: state.payment.step,
    months: state.payment.months,
    members: state.payment.members,
});

const mapDispatchToProps = {
    // changeStep
};

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbsArrow);

