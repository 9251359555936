import { Action } from 'redux';

export enum types {
	SET_DATE_CALENDAR = "@CALENDAR/SET_DATE_CALENDAR",
	SET_CURRENT_DATE = "@CALENDAR/SET_CURRENT_DATE",
}

export interface setDateCalendar extends Action<types.SET_DATE_CALENDAR> {
	date: {
		begin: string,
		end: string,
	};
}

export interface setCurrentDate extends Action<types.SET_CURRENT_DATE> {
	date: string;
}

export type CalendarActions = setDateCalendar | setCurrentDate;
