import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import "../BpResultForm.scss";
import NameOfStep from "../templates/NameOfStep";

import SelectTemplate from "../templates/SelectTemplate";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";

const AgreementByDeputyGenManager = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.agreementByDeputyGenManager?.extra
      ? settingsOfFields.agreementByDeputyGenManager.extra
      : {}
  );

  const url = taskId ? `${apiBp}/task/${taskId}` : null;

  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.agreementByDeputyGenManager, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="agreementByDeputyGenManager">
      <div>
        <NameOfStep text={"Поле согласования"} />
        <SelectTemplate
          listArr={["Принимаю", "Не согласен"]}
          name={"d4"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Организация"} />
        <TextAreaOrInputElement
          name={"d4_1"}
          isInput={true}
          type={"text"}
          data={data}
          setData={setData}
        />
        <TextAreaOrInputElement
          name={"d4_1_1"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Комментарий"} />
        <TextAreaOrInputElement
          name={"d4_2"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
    </form>
  );
};

export default AgreementByDeputyGenManager;
