// Показ профиля компании
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
// import {showCompanyWindow} from '../../../common/actions';
import Icon from "../../../common/components/Icon";
// import Button from '../../../common/components/newComponent/Buttons/Button';
// import CustomInput from '../../../common/components/newComponent/Inputs/CustomInput';
// import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';
import { State } from "../../../rootReducer";
// import {fetchCreateCompany} from '../../Login/actions';
import { useForm } from "react-hook-form";
import { setPlateEditMode } from ".././actions";
import { fetchData } from "../../../utils/fetchData";
import { cookieMaster } from "../../../utils/CookieMaster";
import { useHistory } from "react-router-dom";
import { showCompanyWindow } from "../../../common/actions";
import { changeStep, paymentDetails } from "../../Payment/actions";
import Picture from "./picture";

const WrapperSlidePlate = styled.div`
  position: fixed;
  z-index: 1003;
  transition: right 0.8s ease;
  right: ${({ active }) => (active ? "0" : "-590px")};
  top: 76px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  width: 584px;
  height: calc(100vh - 76px);
  padding: 16px;
`;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  height: 40px;
  span {
    font-size: 18px;
    line-height: 24px;
    color: #212121;
  }
  input {
    font-size: 18px;
    line-height: 24px;
    color: #212121;
  }
`;
const WrapperButton = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 8px;
  }
`;

const TopInfoTextItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: #02405e;
  font-size: 13px;
  line-height: 16px;
`;

const TitleBlockStyle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0;
`;
const RowStyle = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
`;
const NameLineStyle = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #02405e;
  margin-left: 8px;
`;
const WrapperTextInfoStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const TextInfoStyle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #212121;
`;

type Input = {
  name: string;
  description?: string;
  address?: string;
  inn?: number;
  kpp?: number;
  ogrn?: number;
  info?: string;
};

const TopItem = ({ iconName, content }) => {
  return (
    <div>
      <div style={{ height: "32px", padding: "4px", display: "flex" }}>
        <Icon name={iconName} />
        <TopInfoTextItem>{content}</TopInfoTextItem>
      </div>
    </div>
  );
};

const ShowModule = ({ setPlateEditMode, companyInfo }) => {
  console.log("companyInfo", companyInfo);

  const active = useSelector(
    (state: State) => state.commonInfo.show_company_window
  );
  const history = useHistory();
  const dispatch = useDispatch();
  // const { register, handleSubmit, getValues } = useForm();
  const { id, name } = useSelector((state: State) => state.companyInfoPlate);
  const [onlyRead, setOnlyRead] = useState<boolean>();

  const [pricing_plans, setPricing_plans] = useState<any>();
  const [accountInfo, setAccountInfo] = useState<any>();

  useEffect(() => {
    setOnlyRead(!!id);
  }, [id]);

  useEffect(() => {
    (async () => {
      if (companyInfo.pricing_plan_id) {
        const price_plan = await fetchData.get(
          // это должен быть тариф, а не ID компании
          `/api/v1/pricing_plans/${companyInfo.pricing_plan_id}`
        );
        setPricing_plans(price_plan);
      }
      const commentListResponse = await fetchData.get(`/api/v1/account/info`, {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        Authorization: `${cookieMaster.getCookie("access_token")}`,
        "company-id": `${companyInfo.id}`,
      });
      setAccountInfo(commentListResponse);
    })();
  }, [companyInfo.id]);

  return (
    <div>
      {!companyInfo.plateEditMode && (
        <div style={{ marginTop: "16px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "32px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: "24px" }}>{companyInfo.name}</div>
              {accountInfo?.active_users > 0 && (
                <div
                  style={{
                    display: "flex",
                    lineHeight: "32px",
                    marginLeft: "8px",
                  }}
                >
                  <div style={{ padding: "0 4px" }}>
                    <Icon name={"peoples"} />
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      marginLeft: "4px",
                      color: "#02405E",
                    }}
                  >
                    {accountInfo.active_users}
                  </div>
                </div>
              )}
            </div>
            <NameLineStyle
              style={{ cursor: "pointer" }}
              onClick={() => {
                setPlateEditMode(true);
              }}
            >
              Редактировать
            </NameLineStyle>
          </div>

          <div style={{ display: "flex", marginTop: "16px" }}>
            {/* Показ картинки для компании. Если не выбрана - стандартная */}

            <Picture
              image={companyInfo.logo}
              edit={companyInfo.plateEditMode}
            />

            <div style={{ marginLeft: "64px", width: "100%" }}>
              <div style={{ height: "32px", padding: "4px", display: "flex" }}>
                <Icon name={"phone"} />
                <TopInfoTextItem>{companyInfo.phone}</TopInfoTextItem>
              </div>
              <div style={{ height: "32px", padding: "4px", display: "flex" }}>
                <Icon name={"email"} />
                <TopInfoTextItem>{companyInfo.email}</TopInfoTextItem>
              </div>
              <div style={{ height: "32px", padding: "4px", display: "flex" }}>
                <Icon name={"globe"} />
                <TopInfoTextItem>{companyInfo.site}</TopInfoTextItem>
              </div>
            </div>
          </div>

          <div
            style={{ marginTop: "16px", fontSize: "14px", lineHeight: "16px" }}
          >
            {companyInfo.description}
          </div>

          <div>
            <TitleBlockStyle>Тариф</TitleBlockStyle>
            <RowStyle>
              <NameLineStyle
                style={{ width: "160px", display: "inline-table" }}
              >
                Тарифный план
              </NameLineStyle>
              <WrapperTextInfoStyle>
                <TextInfoStyle>
                  {pricing_plans ? pricing_plans.name : ""}
                </TextInfoStyle>
                <div
                  onClick={() => {
                    dispatch(changeStep(1));
                    dispatch(showCompanyWindow(false));
                    history.push(`/payment/${id}`);
                  }}
                  style={{ color: "#1BAAF0", cursor: "pointer" }}
                >
                  Изменить тариф
                </div>
              </WrapperTextInfoStyle>
            </RowStyle>
            {pricing_plans?.id === 2 && accountInfo?.id && (
              <RowStyle>
                <NameLineStyle
                  style={{ width: "160px", display: "inline-table" }}
                >
                  На счету
                </NameLineStyle>
                <WrapperTextInfoStyle>
                  <TextInfoStyle style={{ fontWeight: 500, fontSize: "14px" }}>
                    {accountInfo?.balance} / на{" "}
                    {Math.round(
                      accountInfo?.balance / accountInfo?.active_users
                    )}{" "}
                    дней
                  </TextInfoStyle>

                  <div
                    onClick={() => {
                      dispatch(showCompanyWindow(false));

                      dispatch(
                        paymentDetails({
                          pricing_plan_id: 2,
                        })
                      );
                      history.push(`/payment/${id}`);
                      dispatch(changeStep(2));
                    }}
                    style={{ color: "#1BAAF0", cursor: "pointer" }}
                  >
                    Пополнить
                  </div>
                </WrapperTextInfoStyle>
              </RowStyle>
            )}
          </div>

          <div>
            <TitleBlockStyle>Реквизиты</TitleBlockStyle>
            <RowStyle>
              <NameLineStyle
                style={{ width: "160px", display: "inline-table" }}
              >
                Физический адрес
              </NameLineStyle>
              <WrapperTextInfoStyle>
                <TextInfoStyle>{companyInfo.phys_address}</TextInfoStyle>
              </WrapperTextInfoStyle>
            </RowStyle>
            <RowStyle>
              <NameLineStyle
                style={{ width: "160px", display: "inline-table" }}
              >
                Юр. адрес
              </NameLineStyle>
              <WrapperTextInfoStyle>
                <TextInfoStyle>{companyInfo.address}</TextInfoStyle>
              </WrapperTextInfoStyle>
            </RowStyle>
            <RowStyle>
              <NameLineStyle
                style={{ width: "160px", display: "inline-table" }}
              >
                ИНН
              </NameLineStyle>
              <WrapperTextInfoStyle>
                <TextInfoStyle>{companyInfo.inn}</TextInfoStyle>
              </WrapperTextInfoStyle>
            </RowStyle>
            <RowStyle>
              <NameLineStyle
                style={{ width: "160px", display: "inline-table" }}
              >
                КПП
              </NameLineStyle>
              <WrapperTextInfoStyle>
                <TextInfoStyle>{companyInfo.kpp}</TextInfoStyle>
              </WrapperTextInfoStyle>
            </RowStyle>
            <RowStyle>
              <NameLineStyle
                style={{ width: "160px", display: "inline-table" }}
              >
                ОГРН
              </NameLineStyle>
              <WrapperTextInfoStyle>
                <TextInfoStyle>{companyInfo.ogrn}</TextInfoStyle>
              </WrapperTextInfoStyle>
            </RowStyle>
            <RowStyle>
              <NameLineStyle
                style={{ width: "160px", display: "inline-table" }}
              >
                ОКВЭД
              </NameLineStyle>
              <WrapperTextInfoStyle>
                <TextInfoStyle>{companyInfo.okved}</TextInfoStyle>
              </WrapperTextInfoStyle>
            </RowStyle>
            <RowStyle>
              <NameLineStyle
                style={{ width: "160px", display: "inline-table" }}
              >
                БИК
              </NameLineStyle>
              <WrapperTextInfoStyle>
                <TextInfoStyle>{companyInfo.bik}</TextInfoStyle>
              </WrapperTextInfoStyle>
            </RowStyle>
            <RowStyle>
              <NameLineStyle
                style={{ width: "160px", display: "inline-table" }}
              >
                Расчетный счет
              </NameLineStyle>
              <WrapperTextInfoStyle>
                <TextInfoStyle>{companyInfo.checking_account}</TextInfoStyle>
              </WrapperTextInfoStyle>
            </RowStyle>
            <RowStyle>
              <NameLineStyle
                style={{ width: "160px", display: "inline-table" }}
              >
                Название банка
              </NameLineStyle>
              <WrapperTextInfoStyle>
                <TextInfoStyle>{companyInfo.bank_name}</TextInfoStyle>
              </WrapperTextInfoStyle>
            </RowStyle>
            <RowStyle>
              <NameLineStyle
                style={{ width: "160px", display: "inline-table" }}
              >
                Корр. счет
              </NameLineStyle>
              <WrapperTextInfoStyle>
                <TextInfoStyle>
                  {companyInfo.correspondent_account}
                </TextInfoStyle>
              </WrapperTextInfoStyle>
            </RowStyle>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state: State) => {
  return {
    companyInfo: state.companyInfoPlate,
  };
};

const mapDispatchToProps = {
  setPlateEditMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowModule);
