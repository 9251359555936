// Поле приоритет в задаче (как имеющейся, так и новой)
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { State } from '../../../rootReducer'
import { connect, useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import palette from '../palette'
import Icon from '../Icon'
import {
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setTaskPriority,
} from '../../../pages/TaskInfoPlate/actions'

import { updateTask } from '../../actions'
import { Task } from '../../types'
import { nanoid } from 'nanoid'
import { Colors } from '../../../pages/Calendar/components/Lightbox/Lightbox'
import { getActualColor } from '../../../pages/Calendar/utils'

type Props = {
  disabled?:boolean;
  id?: number;
  bigSize?: boolean;
  updateTask: (taskId: number, params: object) => void;
  selectedTask: Task | null;
  setCyclicTaskToChange: (obj: {}) => void;
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  flagForActionCyclycTask: string;
  setColor?: Dispatch<SetStateAction<Colors>>;
};

type PrioritySlugByText = string[]

const PriorityBlock = styled.div``
const SelectButtonStyle = styled.div`
  position: relative;
`
const PrioritySwitch = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 0.25rem;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${palette.white};
  border-radius: 6px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
`
const PriorityButton = styled.div`
  display: flex;
  justify-content: start;
  padding: 0.5rem 0.25rem;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  width: 108px;
  line-height: 16px;
  text-align: right;
  background: ${palette.white};
  color: ${palette.darkBlue};
  img {
    margin-right: 8px;
  }

  &:hover {
    border-color: ${palette.darkBlue};
  }
  &:disabled {
    border-color: ${palette.lightGray};
    color: ${palette.lightGray};
  }
`

const Prioritys: React.FC<Props> = ({
  disabled,
  setColor,
  updateTask,
  selectedTask,
  id,
  bigSize = true,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
  flagForActionCyclycTask,
}) => {
  // let prioritySlugByText: PrioritySlugByText = [
  //   'Низкий',
  //   'Средний',
  //   'Высокий',
  //   'Срочный',
  // ];
  const dispatch = useDispatch()
  const { priority_id, isShownTaskInfoPlate } = useSelector(
    (state: State) => state.taskInfoPlate
  )
  const priorities = useSelector((state: State) => state.commonInfo.priorities)
  const [showPriority, setShowPriority] = useState(false)

  const handlerPriority = useCallback(
    (event) => {
      event.stopPropagation()
      const priority = +event.target.dataset.priority
      if (selectedTask) {
        if (priority !== selectedTask.priority_id) {
          if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
            // сравнение одного с несколькими
            // эта задача вообще циклическая или обычная ?
            // значит циклическая
            if (flagForActionCyclycTask === '') {
              setCyclicTaskToChange({ priority_id: priority }) // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
            } else if (flagForActionCyclycTask === 'task') {
              updateTask(selectedTask.id, { priority_id: priority })
            } else {
              updateTask(selectedTask.id, { priority_id: priority })
              sendReqToChangeTemplRepTask({ priority_id: priority })
            }
          } else {
            updateTask(selectedTask.id, { priority_id: priority })
          }
        }
      } else if (priority !== priority_id) dispatch(setTaskPriority(priority))

      setShowPriority(false)
    },
    [priority_id, selectedTask]
  )

  // self-close for window
  let plate = useRef<HTMLDivElement>(null)
  const outsideClickHandler = useCallback(
    function (e: Event): any {
      //  if (showPriority) setShowPriority(false);
    },
    [setShowPriority, showPriority]
  )
  useEffect(() => {
    document.addEventListener('click', outsideClickHandler)
    return () => document.removeEventListener('click', outsideClickHandler)
  }, [outsideClickHandler])
  // self-close for window

  useEffect(() => {
    setShowPriority(false)
  }, [isShownTaskInfoPlate])

  useEffect(() => {
    if (setColor) {
      setColor(getActualColor(priority_id))
    }
  }, [priority_id])

  // let name;
  // if (selectedTask?.priority_id) {
  //   +selectedTask?.priority_id <= 4
  //     ? (name = selectedTask!.priority_id)
  //     : (name = 1);
  // } else {
  //   name = priority_id;
  // }
  const findSlugPriority = (id) => {
    return priorities.find((el) => +el.id === +id)
  }
  let priority = findSlugPriority(selectedTask?.priority_id || priority_id)
  return (
    <PriorityBlock>
      <SelectButtonStyle>
        <PriorityButton onClick={() => setShowPriority(true)}>
          {priority?.slug && <Icon name={`priority_${priority?.slug}`} />}
          {priority?.name}
        </PriorityButton>

        {showPriority  && !disabled && ( // выпадающий список
          <PrioritySwitch ref={plate}>
            {priorities.map((priority) => (
              <PriorityButton
                key={nanoid()}
                data-priority={priority.id}
                onClick={handlerPriority}
                // style={priority.length !== i ? { marginBottom: '.5rem' } : {}}
              >
                <Icon
                  name={`priority_${priority.slug}`}
                  onClick={(e) =>e.stopPropagation()}
                />
                {priority.name}
              </PriorityButton>
            ))}
          </PrioritySwitch>
        )}
      </SelectButtonStyle>
    </PriorityBlock>
  )
}

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
  }
}

const mapDispatchToProps = {
  updateTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
}

export default connect(mapStateToProps, mapDispatchToProps)(Prioritys)
