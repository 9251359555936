import React, { useCallback, ReactNode } from 'react';
import './styles.sass';

type Props = {
  text: string;
  checked: boolean;
  value: number;
  component?: ReactNode;
  addHandler: (value: number) => void;
  removeHandler: (value: number) => void;
};

const CheckboxItem: React.FC<Props> = ({
  text,
  checked,
  value,
  component,
  addHandler,
  removeHandler,
}) => {
  const clickHandler = useCallback(() => {
    if (!checked) addHandler(value);
    else removeHandler(value);
  }, [addHandler, checked, removeHandler, value]);

  return (
    <div onClick={clickHandler} className="checkbox_item">
      <div className={`checkbox_icon${checked ? ' checked' : ''}`} />
      {component}
      <div className="checkbox_text">{text}</div>
    </div>
  );
};

export default CheckboxItem;
