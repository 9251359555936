import React, { useContext, useEffect, useState } from "react";
import Pagination from "rc-pagination";
import "./PaginationBp.scss";
import styled from "styled-components";
import { fetchData } from "../../../../../../utils/fetchData";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useParams } from "react-router-dom";

// import { fetchTasks, changeTaskList } from "../actions";
import { urlApp } from "../../../../../../App";
import { cookieMaster } from "../../../../../../utils/CookieMaster";
import axios from "axios";
import { StatusContext } from "../../../context/status";

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      minWidth: 60,
      marginRight: 20,
    },
    selectEmpty: {},
  })
);
const DivSelect = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 10px;
  }
`;
const WrapperPagination = styled.div`
  margin: 5px auto;
  display: flex;
  align-items: center;
`;

const PaginationBp = ({
  

}) => {

  const {
    company_id,
    bearer,
    apiBp,
    setBpList
  } = useContext(StatusContext);


  const classes = useStyles();
  const [pageNum, setPageNum] = useState(1);

  const [pageNumLast, setPageNumLast] = useState(1); // Последняя страничка в пагинации
  const [pageNumber, setPageNumber] = useState(1); // Номер страницы на которую мы нажимаем
  const [limitTasksPages, setLimitTasksPages] = useState(15); // лимит наших задач которые будут показыватся

  let token = document.cookie.replace(
    /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  //при добавлении новой задачи чтобы он обновлял страницу и пагинацию (был баг)
  useEffect(() => {
    setPageNum(1);
  }, []);
  //дописать переменную которая будет отслеживать добавление задачи чтобы он сразу обновлял стейт с массивом БП

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${apiBp}/bp?sort=id&perPage=${limitTasksPages}&page=3`, {
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": token,
        },
      })
      .then((res) => setPageNumLast(+res.data.meta.last_page))
      .then(() => {
        setPageNumber(1);
      })
      .catch((error) => console.log("error", error));
  }, [limitTasksPages]);


  useEffect(() => {
    setPageNum(1);
  }, [id]);
  const onClickLimitTasks = (e) => {
    setLimitTasksPages(e.target.value);
  };

  const onPageClickNumber = (e) => {
    setPageNumber(e);
  };
  useEffect(() => {
    axios
      .get(
        `${apiBp}/bp?sort=id&perPage=${limitTasksPages}&page=${pageNumber}`,
        {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        }
      )
      .then((response) => {
        setBpList(response.data.data);
      })
      .catch((error) => console.warn(error));
  }, [pageNumLast, pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [limitTasksPages]);

  return (
    <WrapperPagination>
      <Pagination
      showTitle={false}
        onChange={onPageClickNumber}
        className="ant-pagination"
        defaultCurrent={1}
        total={pageNumLast * 10}
        current={pageNumber}
      />
      <DivSelect>
        <span>Показать задач</span>
        <FormControl className={classes.formControl}>
          <Select
            value={limitTasksPages}
            onChange={onClickLimitTasks}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ "aria-label": "Without label" }}
            disableUnderline
            defaultValue={20}
          >
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </DivSelect>
    </WrapperPagination>
  );
};

export default PaginationBp;
