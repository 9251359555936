import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { StatusContext } from "../../../context/status";
import AddFileButton from "../templates/AddFileButton";
import CheckboxElement from "../templates/CheckboxElement";
import NameOfStep from "../templates/NameOfStep";
import RadioButtons from "../templates/RadioButtons";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import "../BpResultForm.scss";

let fileTranslate;

const SigningOfTreaty = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,

    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  console.log(settingsOfFields);
  const [data, setData] = useState(
    settingsOfFields?.signingOfTreaty?.extra
      ? settingsOfFields.signingOfTreaty.extra
      : {}
  );
  const [allFilesSigningTreaty1, setAllFilesSigningTreaty1] = useState([]);
  const [allFilesSigningTreaty2, setAllFilesSigningTreaty2] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  console.log(allFilesSigningTreaty1);
  const url = taskId ? `${apiBp}/task/${taskId}` : null;

  //Добавляет файлы
  const onSendFileSigningTreaty = (e, numberFilesSigningTreaty) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesSigningTreaty === "filesSigningTreaty1") {
        setAllFilesSigningTreaty1([
          ...allFilesSigningTreaty1,
          {
            data: fileTranslate,
            id: nanoid(),
            name: file.name.match(/(.*)\.[^.]+$/)[1],
            ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
            tag: "filesSigningTreaty1",
          },
        ]);
      } else if (numberFilesSigningTreaty === "filesSigningTreaty2") {
        setAllFilesSigningTreaty2([
          ...allFilesSigningTreaty2,
          {
            data: fileTranslate,
            id: nanoid(),
            name: file.name.match(/(.*)\.[^.]+$/)[1],
            ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
            tag: "filesSigningTreaty2",
          },
        ]);
      }
    }, [500]);
  };

  //Удаляет файлы
  const onDeletedFileSigningTreaty = (keyId, numberFilesSigningTreaty) => {
    if (numberFilesSigningTreaty === "filesSigningTreaty1") {
      setAllFilesSigningTreaty1(
        allFilesSigningTreaty1.filter((file) => file.id !== keyId)
      );
    } else if (numberFilesSigningTreaty === "filesSigningTreaty2") {
      setAllFilesSigningTreaty2(
        allFilesSigningTreaty2.filter((file) => file.id !== keyId)
      );
    }
  };

  //Смотрит на какую кнопку какого рездела с файлами было нажато
  const onClickFetchFilesSigningTreaty = (e, numberFilesSigningTreaty) => {
    e.preventDefault();

    console.log(numberFilesSigningTreaty);

    let data;

    if (numberFilesSigningTreaty === "filesSigningTreaty1") {
      data = JSON.stringify({
        files: allFilesSigningTreaty1,
      });
      console.log("Нажал на кнопку для отправки файлов filesSigningTreaty1");
    }

    if (numberFilesSigningTreaty === "filesSigningTreaty2") {
      data = JSON.stringify({
        files: allFilesSigningTreaty2,
      });
      console.log("Нажал на кнопку для отправки файлов filesSigningTreaty2");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesSigningTreaty === "filesSigningTreaty1") {
      setAllFilesSigningTreaty1([]);
    }

    if (numberFilesSigningTreaty === "filesSigningTreaty2") {
      setAllFilesSigningTreaty2([]);
    }
  };

  // console.log(taskId);
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.signingOfTreaty, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  // console.log("     ", );

  return (
    <form id="signingOfTreaty">
      <div>
        <NameOfStep text={"Способ подписания"} />
        <RadioButtons
          data={data}
          setData={setData}
          name={"h8"}
          positiveText={"ЭДО"}
          negativeText={"Бумажный"}
        />
      </div>
      {data.h8 === "ЭДО" && (
        <div>
          <NameOfStep
            text={
              "Договор и протокол разногласий подписан с нашей стороны и со стороны контрагента. Подписанный документ прикреплен к задаче"
            }
          />
          <RadioButtons // если ЭДО
            data={data}
            setData={setData}
            name={"h8_1"}
            positiveText={"Да"}
            negativeText={"Нет"}
          />
          <TextAreaOrInputElement //если да
            name={"h8_1_1"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
          />
          {data.h8_1 === "Да" && (
            <>
              <div className="file-uploaded__block">
                <ul>
                  {taskCurrentGlobal &&
                    taskCurrentGlobal["files"] &&
                    taskCurrentGlobal["files"]["list"]
                      .filter((item) => item?.tag == "filesSigningTreaty1")
                      .map((item2) => (
                        <li>
                          <a
                            href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                            target="_blank"
                          >
                            {item2.name}
                          </a>
                        </li>
                      ))}
                </ul>
              </div>
              <AddFileButton
                filesList={taskCurrentGlobal}
                data={data}
                setData={setData} //если да
                id={"h8_1_2"}
                handlerOnSendFile={onSendFileSigningTreaty}
                handlerOnDeletedFile={onDeletedFileSigningTreaty}
                handlerOnClickFetchFiles={onClickFetchFilesSigningTreaty}
                allFiles={allFilesSigningTreaty1}
                numberFiles={"filesSigningTreaty1"}
              />
            </>
          )}
        </div>
      )}
      <div>
        <NameOfStep text={"Кто первый подписывает договор"} />
        <RadioButtons // если ЭДО
          data={data}
          setData={setData}
          name={"h8_2"}
          positiveText={"Мы"}
          negativeText={"Контрагент"}
        />
        {data.h8_2 && (
          <TextAreaOrInputElement //если да или нет
            name={"h8_2_1"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
          />
        )}
      </div>
      {data.h8_2 === "Мы" && (
        <>
          <div>
            {/* Если мы*/}
            <NameOfStep
              text={
                "Финальная версия договора и протокола разногласий распечатана (2 экз.), передана Секретарю?"
              }
            />
            <RadioButtons
              data={data}
              setData={setData}
              name={"h8_3"}
              positiveText={"Да"}
              negativeText={"Нет"}
            />
          </div>
          <div>
            <NameOfStep
              text={
                "Печати и подпись генерального директора на каждой странице договора проставлены. Оба экземпляра договора переданы через курьера по адресу, указанному контрагентом в анкете - адрес для передачи оригинала договора?"
              }
            />
            <RadioButtons
              data={data}
              setData={setData}
              name={"h8_4"}
              positiveText={"Да"} // при выборе Да - переход задачи к Инициатору
              negativeText={"Нет"}
            />
          </div>
          <div>
            <NameOfStep
              text={
                "Контроль подписания договора контрагентом проведен. Спорные вопросы (в случае наличия) с контрагентом урегулированы.  Контроль получения нашим курьером договора, подписанного контрагентом, проведен. Контроль передачи оригинала договора от курьера юристу проведен"
              }
            />
            <RadioButtons
              data={data}
              setData={setData}
              name={"h8_5"}
              positiveText={"Да"} // при выборе Да - переход задачи к Инициатору
              negativeText={"Нет"}
            />
            {data.h8_5 === "Нет" && (
              <TextAreaOrInputElement //если нет
                name={"h8_5_1"}
                isInput={false}
                type={"text"}
                data={data}
                setData={setData}
              />
            )}
          </div>
          <div>
            <NameOfStep
              text={
                "Оригинал подписанного с обеих сторон договора получен. Договор отсканирован и скан вложен в задачу"
              }
            />
            <RadioButtons
              data={data}
              setData={setData}
              name={"h8_6"}
              positiveText={"Да"}
              negativeText={"Нет"}
            />
            {data.h8_6 === "Да" && (
              <>
                <div className="file-uploaded__block">
                  <ul>
                    {taskCurrentGlobal &&
                      taskCurrentGlobal["files"] &&
                      taskCurrentGlobal["files"]["list"]
                        .filter((item) => item.tag == "filesSigningTreaty2")
                        .map((item2) => (
                          <li>
                            <a
                              href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                              target="_blank"
                            >
                              {item2.name}
                            </a>
                          </li>
                        ))}
                  </ul>
                </div>
                <AddFileButton
                  filesList={taskCurrentGlobal}
                  data={data}
                  setData={setData} //если да
                  id={"h8_6_1"}
                  handlerOnSendFile={onSendFileSigningTreaty}
                  handlerOnDeletedFile={onDeletedFileSigningTreaty}
                  handlerOnClickFetchFiles={onClickFetchFilesSigningTreaty}
                  allFiles={allFilesSigningTreaty2}
                  numberFiles={"filesSigningTreaty2"}
                />
              </>
            )}
            {data.h8_6 === "Нет" && (
              <TextAreaOrInputElement //если нет
                name={"h8_6_2"}
                isInput={true}
                type={"text"}
                data={data}
                setData={setData}
              />
            )}
          </div>
        </>
      )}
      {data.h8_2 === "Контрагент" && (
        <>
          <div>
            <NameOfStep
              text={
                "Организация подписания договора со стороны контрагента проведена. Подписанный со стороны контрагента договор передан нашему юристу"
              }
            />
            <RadioButtons
              data={data}
              setData={setData}
              name={"h8_7"}
              positiveText={"Да"}
              negativeText={"Нет"}
            />
            {data.h8_7 === "Нет" && (
              <TextAreaOrInputElement //если нет
                name={"h8_7_1"}
                isInput={false}
                type={"text"}
                data={data}
                setData={setData}
              />
            )}
          </div>
          <div>
            <NameOfStep
              text={
                "Проверка редакции подписанного договора на предмет соответствия финально утвержденной проведена. Подписанный договор соответствует финально утвержденному. Договор передан Секретарю для подписания"
              }
            />
            <RadioButtons
              data={data}
              setData={setData}
              name={"h8_8"}
              positiveText={"Да"}
              negativeText={"Нет"}
            />
            {data.h8_8 === "Нет" && (
              <TextAreaOrInputElement //если нет
                name={"h8_8_1"}
                isInput={true}
                type={"text"}
                data={data}
                setData={setData}
              />
            )}
          </div>
          <div>
            <NameOfStep
              text={
                "Подписание договора нашим директором и проставление печатей на каждой странице проведено. Один экземпляр подписанного обеими сторонами договора передан по адресу, указанному контрагентом в анкете - адрес для передачи оригинала договора; другой экземпляр подписанного обеими сторонами договора передан юристу"
              }
            />
            <RadioButtons
              data={data}
              setData={setData}
              name={"h8_9"}
              positiveText={"Да"}
              negativeText={"Нет"}
            />
            {data.h8_9 === "Нет" && (
              <TextAreaOrInputElement //если нет
                name={"h8_9_1"}
                isInput={false}
                type={"text"}
                data={data}
                setData={setData}
              />
            )}
          </div>
        </>
      )}
    </form>
  );
};

export default SigningOfTreaty;
