import React, {useEffect} from 'react';
import TaskDateTime from '../../../common/components/DatePickers/DateTimePiker';
import withTitle from '../../../utils/hoc/withTitle';
import {connect} from 'react-redux';
import {recalculateTaskLoad, setTaskBegin} from '../actions';
import {State} from '../../../rootReducer';

type Props = {
    begin: any;
    maxDate: string;
    delegated: boolean;
    setTaskBegin: (begin: string) => void;
    recalculateTaskLoad: () => void;
};

const TaskBegin: React.FC<Props> = ({
                                        begin,
                                        maxDate,
                                        delegated,
                                        setTaskBegin,
                                        recalculateTaskLoad,
                                    }) => {
    useEffect(() => {
        recalculateTaskLoad();
    }, [begin]);

    let [date, time] = maxDate.split(' ');
    let maxDateObj = new Date(`${date.split('-').reverse().join('-')} ${time}`);
    // in particular cases, 'begin' date comes here in format dd/mm/yyyy hh:mm:ss
    // expected format is dd-mm-yyyy
    begin = begin ? begin.replace(/\//g, '-'): "";

    return (
        <TaskDateTime
            disabled={delegated}
            acceptHandler={setTaskBegin}
            date={begin ? begin: ""}
            maxDate={maxDateObj}
        />
    );
};

const mapStateToProps = (state: State) => {
    return {
        begin: state.newTask.begin,
        maxDate: state.newTask.end,
        delegated: state.newTask.delegated,
    };
};

const mapDispatchToProps = {
    setTaskBegin,
    recalculateTaskLoad,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskBegin);
