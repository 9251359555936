import React, { useState, useEffect } from "react";
import { State } from "../../../rootReducer";
import { connect, useDispatch } from "react-redux";
import { updateTask } from "../../../common/actions";
import { Task, Schedule, Item, Dependences } from "../../../common/types";
import Toggler from "../../../common/components/Togglers/Toggler";
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn";
import { fetchData } from "../../../utils/fetchData";
import {
  sendReqToChangeTemplRepTask,
  setChildTasks,
  setCyclicTaskToChange,
  setNextId,
  setParentId,
  setPrevId,
  settingDependencies,
} from "../actions";
import styled from "styled-components";
import Dependence from "./Dependence";
import { nanoid } from "nanoid";
import Icon from "../../../common/components/Icon";
import Button from "../../../common/components/newComponent/Buttons/Button";
import { fetchTaskDependence } from "../../Tasks/actions";

const WrapperDependencies = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
`;
const DependenciesBlock = styled.div`
  position: relative;
  /* height: 336px; */
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 24px;
`;
const TitleStile = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  margin-bottom: 24px;
`;
const SelectStyle = styled.div`
  margin-bottom: 16px;
`;

type Props = {
  selectedTask: Task;
  schedule: Schedule | null;
  updateTask: (taskId: number, params: any, withNotif?: boolean) => void;
  prev_id: number | null;
  next_id: number | null;
  parent_id: number | null;
  child_tasks: number[];
  disabledComponent?: boolean;
  project_id: number | null;
  setPrevId: (value: number | null) => void;
  setChildTasks: (value: any[]) => void;
  setNextId: (value: number | null) => void;
  setParentId: (value: number | null) => void;
  flagForActionCyclycTask: string;
  setCyclicTaskToChange: (obj: {}) => void;
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  dependencesTask?: Dependences;
};

const Dependencies: React.FC<Props> = ({
  selectedTask,
  schedule,
  updateTask,
  disabledComponent,
  dependencesTask,
  prev_id,
  next_id,
  parent_id,
  child_tasks,
  project_id,
  setPrevId,
  setChildTasks,
  setNextId,
  setParentId,
  flagForActionCyclycTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
}) => {
  const dispatch = useDispatch();
  const [dependenciesActiveTab, setDependenciesActiveTab] =
    useState<string>("parent"); // Сотрудники, которые выбраны в чипсах
  const [tasksForProject, setTasksForProject] = useState<Item[]>([]); // это главное хранилище тасков по проекту. сюда суммируются другие локалстэйты.

  const [resultRequestTaskInfo, setResultRequestTaskInfo] = useState<Item[]>(
    []
  ); // это главное хранилище тасков по проекту. сюда суммируются другие локалстэйты.
  const [timer, setTimer] =
    useState<ReturnType<typeof setTimeout> | null>(null);
  const [resultRequestProjectInfo, setResultRequestProjectInfo] = useState<
    Item[]
  >([]); // это главное хранилище тасков по проекту. сюда суммируются другие локалстэйты.

  const [page, setPage] = useState<number>(1);
  // const [valueSearch, setValueSearch] = useState('');
  let dependenciesInput;

  const cleaning = async () => {
    setTasksForProject([]);
    setResultRequestProjectInfo([]);
    setResultRequestTaskInfo([]);
  };

  const searchData = (valueSearch) => {
    if (valueSearch === "") {
      setPage(1);
      if (selectedTask) {
        cleaning();
        requestTasksByProjectInfo(selectedTask?.project_id);
      } else if (project_id) {
        cleaning();
        requestTasksByProjectInfo(project_id);
      }
    } else {
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          project_id &&
            fetchData
              .get(
                `/api/v1/tasks?project_id=${project_id}${
                  valueSearch && "&search="
                }${valueSearch && valueSearch}`
              )
              .then((data) => {
                let array = data.map((el) => {
                  return { ...el, value: el.id, text: el.name };
                });
                setTasksForProject(array);
              });
        }, 500)
      );
    }
  };

  useEffect(() => {
    cleaning();
    setPage(1);
    // setPrevId(null);
    // setNextId(null);
    // setChildTasks([]);
    // setParentId(null);
    // очищать при переключении на другую задачу, где другой проект.
    if (page === 1) {
      if (selectedTask) {
        cleaning();
        requestTasksByProjectInfo(selectedTask?.project_id);
      } else if (project_id) {
        cleaning();
        requestTasksByProjectInfo(project_id);
      }
    }
  }, [project_id]);

  const requestTasksByProjectInfo = async (projectId) => {
    if (page === 1) {
      cleaning();
    }

    await fetchData
      .get(`/api/v1/tasks?project_id=${projectId}&page=${page}`)
      .then((data) => {
        if (page === 1) {
          cleaning();
          setResultRequestProjectInfo([
            ...data.map((item) => ({
              ...item,
              value: item.id,
              text: item.name,
            })),
          ]);
        } else {
          setResultRequestProjectInfo([
            ...resultRequestProjectInfo,
            ...data.map((item) => ({
              ...item,
              value: item.id,
              text: item.name,
            })),
          ]);
        }
      });
  };

  useEffect(() => {
    if (tasksForProject.length === 20 * (page - 1)) {
      if (selectedTask) {
        requestTasksByProjectInfo(selectedTask?.project_id);
      } else if (project_id) {
        requestTasksByProjectInfo(project_id);
      }
    }
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      setTasksForProject([]);
    }
    // этот эффект собирает данные в 1 стэйт по 2м стэйтам - resultRequestTaskInfo и resultRequestProjectInfo
    const buffer = [];

    resultRequestTaskInfo.forEach((second) => {
      let match = false;
      tasksForProject.forEach((main) => {
        if (main.value === second.value) match = true;
      });
      if (!match) {
        // @ts-ignore
        buffer.push(second);
      }
    });

    resultRequestProjectInfo.forEach((second) => {
      let match = false;
      tasksForProject.forEach((main) => {
        if (main.value === second.value) match = true;
      });
      if (!match) {
        // @ts-ignore
        buffer.push(second);
      }
    });

    setTasksForProject([...tasksForProject, ...buffer]);
  }, [resultRequestTaskInfo, resultRequestProjectInfo, page]);

  useEffect(() => {}, [project_id]);

  return (
    <WrapperDependencies className="dependencies">
      <DependenciesBlock>
        <TitleStile>Зависимость задач</TitleStile>
        <Icon
          name="cross"
          style={{ position: "absolute", top: "25px", right: "25px" }}
          onClick={() => dispatch(settingDependencies(false))}
        />
        <Toggler
          elemsList={[
            { name: "Родительская", value: "parent" },
            { name: "Дочерняя", value: "child" },
            { name: "Предыдущая", value: "prev" },
            { name: "Следующая", value: "next" },
          ]}
          selectHandler={(active) => {
            setDependenciesActiveTab(active as string);
          }}
          activeValue={dependenciesActiveTab}
          styles={{ marginBottom: "24px" }}
        />
        {["prev", "next", "parent", "child"].indexOf(dependenciesActiveTab) !==
          -1 &&
          ((dependenciesActiveTab === "prev" &&
            !(prev_id || selectedTask?.prev_id)) ||
            (dependenciesActiveTab === "next" &&
              !(next_id || selectedTask?.next_id)) ||
            (dependenciesActiveTab === "parent" &&
              !(parent_id || selectedTask?.parent_id)) ||
            dependenciesActiveTab === "child") &&
          // && !(child_tasks?.length || selectedTask?.child_tasks?.length)
          !disabledComponent && (
            <SelectStyle id="dependencies" className={"secondRow"}>
              <SelectWithSecondaryBtn
                setPage={setPage}
                placeholder="Наименование или описание"
                searchData={searchData}
                page={page}
                list={tasksForProject}
                selected={null}
                disabled={disabledComponent || !tasksForProject.length}
                selectHandler={(value) => {
                  switch (dependenciesActiveTab) {
                    case "prev":
                      if (selectedTask) {
                        if (
                          [0, null].indexOf(selectedTask.cyclic_task_id) === -1
                        ) {
                          // сравнение одного с несколькими
                          // эта задача вообще циклическая или сама по себе ?
                          // значит циклическая
                          if (flagForActionCyclycTask === "") {
                            setCyclicTaskToChange({ prev_id: value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                          } else if (flagForActionCyclycTask === "task") {
                            updateTask(selectedTask.id, { prev_id: value });
                          } else {
                            updateTask(selectedTask.id, { prev_id: value });
                            sendReqToChangeTemplRepTask({ prev_id: value });
                          }
                        } else {
                          updateTask(selectedTask.id, { prev_id: value });
                        }
                        setTimeout(() => {
                          dispatch(fetchTaskDependence(selectedTask.id));
                        }, 250);
                      } else setPrevId(value as number);

                      break;
                    case "next":
                      if (selectedTask) {
                        if (
                          [0, null].indexOf(selectedTask.cyclic_task_id) === -1
                        ) {
                          // сравнение одного с несколькими
                          // эта задача вообще циклическая или сама по себе ?
                          // значит циклическая
                          if (flagForActionCyclycTask === "") {
                            setCyclicTaskToChange({ next_id: value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                          } else if (flagForActionCyclycTask === "task") {
                            updateTask(selectedTask.id, { next_id: value });
                          } else {
                            updateTask(selectedTask.id, { next_id: value });
                            sendReqToChangeTemplRepTask({ next_id: value });
                          }
                        } else {
                          updateTask(selectedTask.id, { next_id: value });
                        }
                        setTimeout(() => {
                          dispatch(fetchTaskDependence(selectedTask.id));
                        }, 250);
                        // updateTask(selectedTask.id, { next_id: value });
                      } else setNextId(value as number);

                      break;
                    case "parent":
                      if (selectedTask) {
                        if (
                          [0, null].indexOf(selectedTask.cyclic_task_id) === -1
                        ) {
                          // сравнение одного с несколькими
                          // эта задача вообще циклическая или сама по себе ?
                          // значит циклическая
                          if (flagForActionCyclycTask === "") {
                            setCyclicTaskToChange({ parent_id: value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                          } else if (flagForActionCyclycTask === "task") {
                            updateTask(selectedTask.id, { parent_id: value });
                          } else {
                            updateTask(selectedTask.id, { parent_id: value });
                            sendReqToChangeTemplRepTask({ parent_id: value });
                          }
                        } else {
                          updateTask(selectedTask.id, { parent_id: value });
                        }
                        setTimeout(() => {
                          dispatch(fetchTaskDependence(selectedTask.id));
                        }, 250);
                      } else setParentId(value as number);

                      break;
                    case "child":
                      if (selectedTask) {
                        if (
                          [0, null].indexOf(selectedTask.cyclic_task_id) === -1
                        ) {
                          // сравнение одного с несколькими
                          // эта задача вообще циклическая или сама по себе ?
                          // значит циклическая
                          if (flagForActionCyclycTask === "") {
                            setCyclicTaskToChange({
                              child_tasks: [...child_tasks, value as number],
                            }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                          } else if (flagForActionCyclycTask === "task") {
                            updateTask(selectedTask.id, {
                              child_tasks: [...child_tasks, value as number],
                            });
                          } else {
                            updateTask(selectedTask.id, {
                              child_tasks: [...child_tasks, value as number],
                            });
                            sendReqToChangeTemplRepTask({
                              child_tasks: [...child_tasks, value as number],
                            });
                          }
                        } else {
                          updateTask(selectedTask.id, {
                            child_tasks: [...child_tasks, value as number],
                          });
                        }
                        setTimeout(() => {
                          dispatch(fetchTaskDependence(selectedTask.id));
                        }, 250);
                      } else setChildTasks([...child_tasks, value as number]);

                      break;
                    default:
                      break;
                  }
                }}
              />
            </SelectStyle>
          )}

        <div>
          {dependenciesActiveTab === "prev" &&
            tasksForProject.length > 0 &&
            (prev_id || dependencesTask?.prev) && (
              <Dependence
                task={
                  prev_id
                    ? tasksForProject.find((el) => {
                        if (el.id === prev_id) return el;
                      })
                    : dependencesTask!.prev
                }
                icon="prev-dep"
                dependence={dependenciesActiveTab}
              />
            )}

          {dependenciesActiveTab === "next" &&
          tasksForProject.length &&
          (next_id || dependencesTask?.next) ? (
            <Dependence
              task={
                next_id
                  ? tasksForProject.find((el) => {
                      if (el.id === next_id) return el;
                    })
                  : dependencesTask!.next
              }
              icon="next-dep"
              dependence={dependenciesActiveTab}
              onDelete={() => {
                if (selectedTask) {
                  if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                    // сравнение одного с несколькими
                    // эта задача вообще циклическая или сама по себе ?
                    // значит циклическая
                    if (flagForActionCyclycTask === "") {
                      setCyclicTaskToChange({ next_id: null }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                    } else if (flagForActionCyclycTask === "task") {
                      updateTask(selectedTask.id, { next_id: null });
                    } else {
                      updateTask(selectedTask.id, { next_id: null });
                      sendReqToChangeTemplRepTask({ next_id: null });
                    }
                  } else updateTask(selectedTask.id, { next_id: null });
                  dispatch(fetchTaskDependence(selectedTask.id));
                } else setNextId(null);
              }}
            />
          ) : null}
          {dependenciesActiveTab === "parent" &&
            tasksForProject.length > 0 &&
            (parent_id || dependencesTask!.parent) && (
              <Dependence
                task={
                  parent_id
                    ? tasksForProject.find((el) => {
                        if (el.id === parent_id) return el;
                      })
                    : dependencesTask!.parent
                }
                icon="parent-dep"
                dependence={dependenciesActiveTab}
                onDelete={() => {
                  if (selectedTask) {
                    if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                      // сравнение одного с несколькими
                      // эта задача вообще циклическая или сама по себе ?
                      // значит циклическая
                      if (flagForActionCyclycTask === "") {
                        setCyclicTaskToChange({ parent_id: null }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                      } else if (flagForActionCyclycTask === "task") {
                        updateTask(selectedTask.id, { parent_id: null });
                      } else {
                        updateTask(selectedTask.id, { parent_id: null });
                        sendReqToChangeTemplRepTask({ parent_id: null });
                      }
                    } else updateTask(selectedTask.id, { parent_id: null });
                    setTimeout(() => {
                      dispatch(fetchTaskDependence(selectedTask.id));
                    }, 150);
                  } else setParentId(null);
                }}
              />
            )}
          {dependenciesActiveTab === "child" &&
          dependencesTask?.child?.length &&
          tasksForProject.length > 0
            ? dependencesTask?.child?.map((task) => {
                return (
                  <Dependence
                    task={task}
                    icon="child-dep"
                    key={nanoid()}
                    dependence={dependenciesActiveTab}
                    onDelete={(taskId) => {
                      if (selectedTask?.child_tasks?.length) {
                        let found_item = selectedTask?.child_tasks?.findIndex(
                          (item) => item === taskId
                        );
                        if (found_item > -1) {
                          let copied_array = selectedTask?.child_tasks;
                          copied_array.splice(found_item, 1);
                          if (
                            [0, null].indexOf(selectedTask.cyclic_task_id) ===
                            -1
                          ) {
                            // сравнение одного с несколькими
                            // эта задача вообще циклическая или сама по себе ?
                            // значит циклическая
                            if (flagForActionCyclycTask === "") {
                              setCyclicTaskToChange({
                                child_tasks: copied_array,
                              }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                            } else if (flagForActionCyclycTask === "task") {
                              updateTask(selectedTask.id, {
                                remove_child_tasks: [taskId],
                              });
                            } else {
                              updateTask(selectedTask.id, {
                                remove_child_tasks: [taskId],
                              });
                              sendReqToChangeTemplRepTask({
                                child_tasks: copied_array,
                              });
                            }
                            setTimeout(() => {
                              dispatch(fetchTaskDependence(selectedTask.id));
                            }, 150);
                          } else {
                            updateTask(selectedTask.id, {
                              remove_child_tasks: [taskId],
                            });
                            setTimeout(() => {
                              dispatch(fetchTaskDependence(selectedTask.id));
                            }, 150);
                          }
                        }
                      }
                    }}
                  />
                );
              })
            : dependenciesActiveTab === "child" &&
              child_tasks.length &&
              tasksForProject.length > 0
            ? child_tasks.map((taskId) => {
                return (
                  <Dependence
                    task={tasksForProject.find((task) => {
                      if (task.id === taskId) return task;
                    })}
                    icon="child-dep"
                    key={nanoid()}
                    dependence={dependenciesActiveTab}
                    onDelete={(taskId) => {
                      if (selectedTask?.child_tasks?.length) {
                        let found_item = selectedTask?.child_tasks?.findIndex(
                          (item) => item === taskId
                        );
                        if (found_item > -1) {
                          let copied_array = selectedTask?.child_tasks;
                          copied_array.splice(found_item, 1);
                          if (
                            [0, null].indexOf(selectedTask.cyclic_task_id) ===
                            -1
                          ) {
                            // сравнение одного с несколькими
                            // эта задача вообще циклическая или сама по себе ?
                            // значит циклическая
                            if (flagForActionCyclycTask === "") {
                              setCyclicTaskToChange({
                                child_tasks: copied_array,
                              }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                            } else if (flagForActionCyclycTask === "task") {
                              updateTask(selectedTask.id, {
                                remove_child_tasks: [taskId],
                              });
                            } else {
                              updateTask(selectedTask.id, {
                                remove_child_tasks: [taskId],
                              });
                              sendReqToChangeTemplRepTask({
                                child_tasks: copied_array,
                              });
                            }
                            setTimeout(() => {
                              dispatch(fetchTaskDependence(selectedTask.id));
                            }, 150);
                          } else {
                            updateTask(selectedTask.id, {
                              remove_child_tasks: [taskId],
                            });
                            setTimeout(() => {
                              dispatch(fetchTaskDependence(selectedTask.id));
                            }, 150);
                          }
                        }
                      }
                    }}
                  />
                );
              })
            : null}
        </div>
        <Button
          title="Готово"
          big
          onClick={() => dispatch(settingDependencies(false))}
        />
      </DependenciesBlock>
    </WrapperDependencies>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask!,
    schedule: state.taskInfoPlate.executorSchedule,
    project_id: state.taskInfoPlate.project_id,
    child_tasks: state.taskInfoPlate.child_tasks,
    prev_id: state.taskInfoPlate.prev_id,
    next_id: state.taskInfoPlate.next_id,
    parent_id: state.taskInfoPlate.parent_id,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
    dependencesTask: state.taskInfoPlate.dependencesTask,
  };
};

const mapDispatchToProps = {
  updateTask,
  setPrevId,
  setNextId,
  setParentId,
  setChildTasks,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dependencies);
