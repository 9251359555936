import moment from "moment";
import {
	isSendingNewTask,
	setTaskBegin,
	setTaskDescription,
	setTaskEnd,
	setTaskName,
	setTaskSection,
	successfulCreatedNewTasks,
	createTask as createTaskAction,
	setResetCalendar,
	setDefaultValues,
} from '../../TaskInfoPlate/actions';
import { Colors } from "../components/Lightbox/Lightbox";

export const createTask = (dispatch: any, event: any) => {
	const start = moment(event.start_date).format('DD-MM-YYYY HH:mm:ss');
	const end = moment(event.end_date).format('DD-MM-YYYY HH:mm:ss');
	dispatch(setTaskSection(event.sectionSelect));
	dispatch(setTaskName(event.text));
	dispatch(setTaskDescription(event.description));
	dispatch(setTaskBegin(start))
	dispatch(setTaskEnd(end))
	dispatch(isSendingNewTask(true));
	dispatch(successfulCreatedNewTasks({
		createdTasks: [],
		executorsIds: event.selectedItems.map((item) => item.value) as [],
		checkItems: [...event.checkList] as [],
	}));

	if (event.selectedItems.length) {
		event.selectedItems.forEach((item) =>
			dispatch(createTaskAction(item.value as number, true))
		);
	} else {
		dispatch(createTaskAction(event.holder as number, true, true));
	}

	dispatch(setResetCalendar());
};


export const getActualColor = (priority_id: number): Colors => {
	switch (priority_id) {
		case 1:
			return 'green'
		case 2:
			return 'yellow'
		case 3:
			return 'orange'
		case 4:
			return 'red'
	}
	return 'green'
}
