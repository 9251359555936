import React from 'react';
import styled from 'styled-components';
import { columnsOfTables } from '../../shared_data';

type Props = {
  deadline: string;
  end?: string;
};
const WrapperDeadLineCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 4px;
  line-height: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  min-width: ${columnsOfTables[4].style?.minWidth};
`;
const DateStyle = styled.span`
  font-size: 14px;
  color: #212121;
`;
const DayStyle = styled.span`
  font-size: 13px;
  color: #a8a8a8;
`;

// deadline и end - одно и то же поле, просто разный формат.
// deadline - сокращенный формат 9 дек 22 
// end - полный формат 09-12-2022 00:29:44

const DeadLineCell: React.FC<Props> = ({ deadline, end }) => {

  let endFormat = deadline;

  if (end===null || end === undefined) {
    endFormat="";
  } else {
    if (end.trim().length > 1 && end.slice(6,10)=="2099") {
      endFormat="";
    }
  }

 // console.log("deadline",deadline);
 // console.log("endFormat.slice(6,10)",end && end.slice(6,10));

  return (
    <WrapperDeadLineCell>
      <DateStyle>{endFormat}</DateStyle>
      <DayStyle>{}</DayStyle>
    </WrapperDeadLineCell>
  );
};

export default DeadLineCell;
