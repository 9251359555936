import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import "../BpResultForm.scss";
import NameOfStep from "../templates/NameOfStep";
import SelectTemplate from "../templates/SelectTemplate";

const AcceptByHeadOfDep = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.acceptByHeadOfDep?.extra
      ? settingsOfFields.acceptByHeadOfDep.extra
      : {}
  );

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.acceptByHeadOfDep, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="acceptByHeadOfDep" className="form">
      <div>
        <NameOfStep text={"Подтверждение отчета СБ"} />
        <SelectTemplate
          listArr={["Подтверждаю", "Не подтверждаю"]}
          name={"c3"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Подтверждение о приеме сотрудника "} />
        <SelectTemplate
          listArr={["Принимаю", "Не согласен"]}
          name={"c3_1"}
          data={data}
          setData={setData}
        />
      </div>
    </form>
  );
};

export default AcceptByHeadOfDep;
