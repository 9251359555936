import React, { useState } from 'react';
import CustomBlock from './CustomBlock';
import styled from 'styled-components';
import CustomInput from '../../../common/components/newComponent/Inputs/CustomInput';
import Button from '../../../common/components/newComponent/Buttons/Button';
import { fetchCreateCompany } from '../actions';
import { useDispatch } from 'react-redux';
import Chips from '../../../common/components/ChipsItem';
import { nanoid } from 'nanoid';

const TextStyle = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  line-height: 16px;
  color: #707070;
  span {
    margin-bottom: 24px;
    &:first-child:hover {
      cursor: pointer;
      color: rgb(47, 44, 209);
    }
  }
`;

const WrapperInputs = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  input {
    border-radius: 6px;
    border: none;
    &:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;
type DataCompany = {
  nameCompany: string;
  employees: User[];
};
type User = {
  email: string | undefined;
  name: string | undefined;
  surname: string | undefined;
  role: number | undefined;
};

const Company = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [newUser, setNewUser] = useState<User>({
    email: '',
    name: '',
    surname: '',
    role: 7,
  });
  const [dataCompany, setDataCompany] = useState<DataCompany>({
    nameCompany: '',
    employees: [],
  });
  const changeNameCompany = (nameCompany) => {
    setDataCompany((prev) => {
      return {
        ...prev,
        nameCompany,
      };
    });
  };
  const changeEmail = (email) => {
    setNewUser((prev) => {
      return {
        ...prev,
        email,
      };
    });
  };
  const changeName = (name) => {
    setNewUser((prev) => {
      return {
        ...prev,
        name,
      };
    });
  };

  const changeSurname = (surname) => {
    setNewUser((prev) => {
      return {
        ...prev,
        surname,
      };
    });
  };
  const addUser = () => {
    if (newUser.email && newUser.name && newUser.surname) {
      setDataCompany((prev) => {
        return {
          ...prev,
          employees: [...dataCompany.employees, newUser],
        };
      });
      setNewUser({
        email: '',
        name: '',
        surname: '',
        role: 7,
      });
    }
  };
  const handlerCreateCompany = () => {
    dispatch(
      fetchCreateCompany(
        {
          name: dataCompany.nameCompany,
        },
        dataCompany.employees
      )
    );
  };

  const deleteEmployees = (email) => {
    setDataCompany((prev) => {
      return {
        ...prev,
        employees: dataCompany.employees.filter((el) => el.email !== email),
      };
    });
  };

  {/*
  return step === 1 ? (
    <CustomBlock title="Данные компании">
      <CustomInput
        value={dataCompany.nameCompany}
        placeholder="Название"
        inputChangeHandler={changeNameCompany}
      />
      <Button
        big
        disabled={!dataCompany.nameCompany}
        title="Продолжить"
        onClick={() => {
          setStep(2);
        }}
        style={{ marginTop: '24px' }}
      />
    </CustomBlock>
  ) : (
    <CustomBlock title="Нажмите кнопку 'Создать компанию' ">
    <TextStyle>
      <span onClick={() => setStep(1)}>Назад</span>
    </TextStyle>
 
    <Button
      big
      title="Создать компанию"
      onClick={handlerCreateCompany}
      style={{ marginTop: '24px' }}
    />
  </CustomBlock>
  );

  */}

  return (
    <CustomBlock title="Данные компании">
      <CustomInput
        value={dataCompany.nameCompany}
        placeholder="Название"
        inputChangeHandler={changeNameCompany}
      />
    <Button
      big
      title="Создать компанию"
      onClick={handlerCreateCompany}
      style={{ marginTop: '24px' }}
    />
    </CustomBlock>
  );  

};

export default Company;

