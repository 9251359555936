import React from 'react';
import './styles.sass';
import {urlApp} from '../../../App';
import styled from 'styled-components';

type Props = {
    id: number;
    name: string;
    url: string;
    canBeDeleted?: boolean;
    deleteHandler?: (id: number) => void;
    width?: number
};


const MainBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  
  ${({width}) => {
    if (width) {
        return 'width:' + width + 'px; &:nth-child(2) {margin-left: 8px;}'
    } else {
        return 'margin: 0 8px 8px 0'
    }
}}
  
`

const FileElem: React.FC<Props> = ({
                                       id,
                                       name,
                                       url,
                                       canBeDeleted = true,
                                       deleteHandler,
                                       width
                                   }) => {
    return (
        <MainBlock
            className={'attachment'}
            width={width}
            onClick={() => {
                window.open(urlApp + `/files/${url}/download`);
            }}>

            {/*<div*/}
            {/*  className={'attachment'}*/}
            {/*  style={style}*/}
            {/*  onClick={() => {*/}
            {/*    window.open(urlApp + `/files/${url}/download`);*/}
            {/*  }}*/}
            {/*>*/}
            <div className={'icon'}/>

            <div className={'content'} title={name}>
                <p>{name}</p>
            </div>

            {/*<div className="file_elem">*/}
            {canBeDeleted && (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        if (deleteHandler) deleteHandler(id);
                    }}
                    className="deleteAttachment"
                />
            )}
            {/*    <img src={file} alt="file"/>*/}
            {/*    <a className="file_name" href={urlApp + `/files/${url}/download`}>{name}</a>*/}
            {/*</div>*/}
            {/*</div>*/}

        </MainBlock>
    );
};

// const FileElem: React.FC<Props> = ({ id, name, url, canBeDeleted = true, deleteHandler }) => {
//     return (
//         <div className="file_elem">
//             {canBeDeleted && <div onClick={() => {
//                 if (deleteHandler) deleteHandler(id)
//             }} className="cross"/>}
//             <img src={file} alt="file" />
//             <a className="file_name" href={urlApp+`/files/${url}/download`}>{name}</a>
//         </div>
//     )
// }

export default FileElem;
