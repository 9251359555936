// Сам элемент загрузки

import React from 'react';
import './styles.sass';
import styled from 'styled-components';

export type ToggleElem = {
  name: string;
  value?: string | number;
  id?: number;
};

type Props = {
  elemsList: ToggleElem[];
  activeValue?: string | number | null;
  selectHandler: (value: string | number) => void;
  styles?: object;
};

const TogglerBox = styled.div`
  position: relative;
  display: flex;
  ${({ styles }) => styles && styles}
`;
const TogglerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ active }) => (active ? '#01B0E9' : '#FFFFFF')};

  border: 1px solid ${({ active }) => (active ? '#01B0E9' : '#C4C4C4')};

  position: relative;
  z-index: ${(props) => (props.checked ? 1 : 0)};

  height: 32px;
  width: 88px;

  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: ${({ active }) => (active ? '#FFFFFF' : '#292a34')};
  cursor: pointer;

  z-index: ${({ active }) => (active ? 1 : 0)};

  &:first-child {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    margin-right: -1px;
  }

  &:last-child {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    margin-left: -1px;
  }

  &:hover {
    border-color: ${({ active }) => (active ? '#01B0E9' : '#292a34')};
    z-index: 2;
  }
`;

const Toggler: React.FC<Props> = ({
  elemsList,
  styles,
  activeValue = null,
  selectHandler,
}) => {
  return (
    <TogglerBox styles={{ ...styles }}>
      {elemsList.map((elem) => (
        <TogglerItem
          key={elem.value || elem.id}
          active={elem.value === activeValue || elem.id === activeValue}
          onClick={() =>
            selectHandler(
              elem.value ? elem.value : (elem.id as number | string)
            )
          }
        >
          {elem.name}
        </TogglerItem>
      ))}
    </TogglerBox>
  );
};

export default Toggler;
