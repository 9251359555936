// import { type } from 'os';

// НА ГАУФ НЕ ЗАЛИВАТЬ
import React from "react";
import styled from "styled-components";

import Icon from "../Icon";
import palette from "../palette";

type Props = {
  checked?: boolean;
  onChange?: (e: Event) => void;
  disabled?: boolean;
  onClick?: (e: Event) => void;
  data_value?: number;
};

const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  margin-right: 8px;
`;
const BoxCheckboxLabel = styled.label`
  position: relative;
  display: flex;
  > input {
    display: none;
  }
`;

const CheckBoxStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  background-color: ${palette.white};
  border: 2px solid ${palette.checkBoxDark};
  border-radius: 1px;
  cursor: pointer;
  &:hover {
    border: 2px solid ${palette.darkBlue};
  }
`;

const CheckboxInput = styled.input`
  &:checked + ${CheckBoxStyled} {
    /* background-color: ${palette.blue}; */
    border-color: ${palette.checkBoxDark};

    &:hover {
      /* background-color: ${palette.darkBlue}; */
      border-color: ${palette.darkBlue};
    }
  }
  &:checked:disabled + ${CheckBoxStyled} {
    background-color: ${palette.gray};
    border-color: ${palette.gray};
  }
  &:checked:disabled:hover + ${CheckBoxStyled} {
    background-color: ${palette.gray};
    border-color: ${palette.gray};
  }

  &:disabled + ${CheckBoxStyled} {
    background-color: ${palette.gray};
    border-color: ${palette.gray};
  }
`;

const CheckboxQuestionnaire: React.FC<Props> = ({
  checked,
  onChange,
  disabled = false,
  onClick,
  data_value,
}) => {
  // console.log("----------", checked);

  return (
    <WrapperStyled>
      <BoxCheckboxLabel>
        <CheckboxInput
          data-value={data_value}
          onChange={onChange}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onClick={onClick}
        />
        <CheckBoxStyled>
          {checked && <Icon name="doneCheckBox" />}
        </CheckBoxStyled>
      </BoxCheckboxLabel>
    </WrapperStyled>
  );
};

export default CheckboxQuestionnaire;
