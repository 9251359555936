import React from 'react';
import styled from 'styled-components';
import Icon from '../Icon';

const WrapperModal = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
`;
const ModalBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 352px;
  /* height: 152px; */
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 16px;
`;

const Title = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
`;
const Description = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #212121;
  padding: 16px 0;
`;
const WrapperButton = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
`;
const Modal = ({ title, description, onClose, children }) => {
  return (
    <WrapperModal>
      <ModalBlock>
        <Icon
          style={{ position: 'absolute', top: '16px', right: '16px' }}
          name="cross"
          onClick={onClose}
        />
        <Title>{title}</Title>
        <Description>{description}</Description>
        <WrapperButton>{children && children}</WrapperButton>
      </ModalBlock>
    </WrapperModal>
  );
};

export default Modal;
