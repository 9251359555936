import { cookieMaster } from "./../../utils/CookieMaster";
import { fetchData } from "./../../utils/fetchData";
import { takeLatest, call, put } from "redux-saga/effects";
import {
  types,
  fetchTokenAction,
  fetchCreateCompanyAction,
} from "./action_types";
import { setPlateEditMode } from "../Company/actions";

export function* watchFetchToken() {
  yield takeLatest(types.FETCH_TOKEN, fetchToken);
}

export function* watchFetchCreateCompany() {
  yield takeLatest(types.FETCH_CREATE_COMPANY, fetchCreateCompany);
}

function* fetchCreateCompany({
  dataCompany,
  employees = [],
}: fetchCreateCompanyAction) {
  console.log("dataCompany", dataCompany);

  if (dataCompany?.logo instanceof File) {
    let formData = new FormData();
    formData.append("file", dataCompany?.logo);
    const image = yield call(fetchData.post, "/api/v1/files", formData, {
      Authorization: cookieMaster.getCookie("access_token"),
    });
    if (image) {
      dataCompany.logo = image.id;
    }
  }

  if (dataCompany["id"] && dataCompany["id"] !== 0) {
    if (dataCompany["logo"] instanceof Object)
      dataCompany.logo = dataCompany.logo = dataCompany?.logo?.id;
    // update
    const updated = yield call(
      fetchData.patch,
      `/api/v1/companies/${dataCompany["id"]}`,
      JSON.stringify(dataCompany)
    );
    if (updated) {
      yield put(setPlateEditMode(false));
    }

    yield window.location.reload();
  } else {
    let data = { ...dataCompany };
    delete data["plateEditMode"];

    const company = yield call(
      fetchData.post,
      "/api/v1/companies",
      JSON.stringify(data)
    );
    if (company?.id) {
      console.log("company", company);

      yield localStorage.setItem("company_id", company.id);
      if (Object.keys(employees).length) {
        yield employees.forEach((el, i) => {
          fetchData.post(`/api/v1/users`, JSON.stringify(el), {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: `${cookieMaster.getCookie("access_token")}`,
            "company-id": `${company.id}`,
          });
        });
      }
      yield window.location.replace("/");
    }
  }
}

function* fetchToken({ email, password }: fetchTokenAction) {
  let company_id;
  if (localStorage.getItem("company_id")) {
    company_id = localStorage.getItem("company_id");
  } else {
    localStorage.setItem("company_id", "");
    company_id = "";
  }
  let body = {
    // ...loginBodyParts,
    email,
    password,
  };
  let data;
  try {
    data = yield call(fetchData.post, "/api/v1/login", JSON.stringify(body), {
      "Content-Type": "application/x-www-form-urlencoded",
    });
  } catch (error) {
    console.warn(error);
  }

  if (data?.access_token) {
    document.cookie = `access_token=${`${data.token_type} ${data.access_token}`}; path=/; expires=${
      data.expires_in
    }`;
    // document.cookie = `refresh_token=${data.refresh_token}; path=/; expires=${data.expires_in}`;
    document.cookie = `user_id=${data.user_id}; path=/; expires=${data.expires_in}`;
    if (company_id === "") {
      +data.company_id > 0
        ? localStorage.setItem("company_id", data.company_id)
        : localStorage.setItem("company_id", "0");
    }

    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("company_id", data.company_id);
    localStorage.setItem("user_id", data.user_id);
    // console.log("user_id",data.user_id)

    setTimeout(() => {
      window.location.replace("/");
    }, 1000);
  }
}
