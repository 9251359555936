// Разработка Easy Meeting устаревшая. Не испрользуется
import React, {useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Project, Task} from '../../common/types';
import {State} from '../../rootReducer';
import {useParams} from 'react-router-dom';
import MeetingsActions from "./components/MeetingsActions";
import MeetingsTableHead from "./components/MeetingsTableHead";
import ProjectTaskTr from "../Projects/components/ProjectTaskTr";
import styled from 'styled-components';
import {fetchMeetings} from "./actions";
import MeetingsTableTr from "./components/MeetingsTableTr";
import {nanoid} from "nanoid";

const WrapperTasksTable = styled.div`
  height: calc(100vh - 214px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const WrapperTasks = styled.div`
  /* max-width: 1280px; */
  width: 100%;
  padding: 0px 40px;
`;


type Props = {
    projects: Project[];
    fetchTasks: (id: number, pageNum: number) => void;
    selectProject: (project: Project) => void;
    setTableOrTreeToggler: (val: string) => void;
    tableOrTreeToggler: string;
    businessProcessesModalShow: boolean;
    // fetchMeetings: (tableFilter?: string) => void,
    meetingsData: object[]
};

const Meetings: React.FC<Props> = ({
                                       meetingsData
                                   }) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const timestamp = useSelector((state: State) => state.commonInfo.timestamp);
    // const selectedProjectID = useSelector(
    //   (state: State) => state.projectsPage.selectedProject?.id
    // );
    // const selectedProject = useSelector(
    //   (state: State) => state.projectsPage.selectedProject
    // );
    // const tasksProject = useSelector((state: State) => state.projectsPage.tasks);

    // const templateProjects = [...projects];
    // const templateTasks = [...tasksProject];


    // useEffect(() => {
    //   setTableOrTreeToggler(
    //     localStorage.getItem('project_show_toggle') as string
    //   );
    // }, [localStorage.getItem('project_show_toggle')]);

    // if (
    //   [null, undefined, ''].indexOf(localStorage.getItem('project_show_toggle')) >
    //   -1
    // ) {
    //   localStorage.setItem('project_show_toggle', 'table'); // инициализация
    // }

    useEffect(() => {
        if (timestamp === '') {
            // let day =
            //   new Date().getDate() > 9
            //     ? new Date().getDate()
            //     : '0' + new Date().getDate();
            // let month =
            //   new Date().getMonth() > 9
            //     ? Number(new Date().getMonth()) + 1
            //     : '0' + (Number(new Date().getMonth()) + 1);
            // let year = new Date().getFullYear();
            //
            // let dataString = `${year}-${month}-${day} ${new Date().toLocaleTimeString()}`;
            //
            // fetchData
            //   .get(`/api/v1/update-data?timestamp=${dataString}`)
            //   .then(({ timestamp }) => dispatch(updateTimestamp(timestamp)))
            //   .catch((error) => console.warn(error));
        } else {
            // const intervalFetch = setInterval(() => {
            //   fetchUpdateData(timestamp, 'projects', [selectedProjectID])
            //     ?.then(
            //       ({
            //         timestamp,
            //         notifications,
            //         projects: updateProjects,
            //         task_projects,
            //       }) => {
            //         dispatch(updateTimestamp(timestamp));
            //
            //         if (updateProjects?.length > 0) {
            //           updateProjects.forEach((updateProject, i) => {
            //             templateProjects.find((el, index) => {
            //               if (el.id === updateProject.id) {
            //                 templateProjects.splice(index, 1, updateProject);
            //               }
            //             });
            //             if (updateProject.id === selectedProject?.id) {
            //               selectProject(updateProject);
            //             }
            //           });
            //           dispatch(setProjects(templateProjects));
            //         }
            //         if (task_projects?.length > 0) {
            //           task_projects.forEach((updateTask) => {
            //             templateTasks.map((el, index) => {
            //               if (el.id === updateTask.id && !updateTask?.delete) {
            //                 templateTasks.splice(index, 1, updateTask);
            //               } else if (el.id === updateTask.id && updateTask?.delete) {
            //                 templateTasks.splice(index, 1);
            //               } else if (
            //                 templateTasks.length === ++index &&
            //                 updateTask?.create
            //               ) {
            //                 templateTasks.unshift(updateTask);
            //               }
            //             });
            //           });
            //           // dispatch(changeTaskList(templateTasks));
            //         }
            //         if (notifications?.length > 0) {
            //           notifications.map((el) => {
            //             if (el.hasOwnProperty('create')) {
            //               dispatch(updateNewNotification([el]));
            //             }
            //           });
            //         }
            //       }
            //     )
            //     .catch((error) => console.warn(error));
            // }, timeUpdate);

            return () => {
                // clearInterval(intervalFetch);
            };
        }
    }, [timestamp]);

    // console.log(meetingsData)

    return (
        <>
            <MeetingsActions/>

            {/*{tableOrTreeToggler === 'table' && <ProjectTable />}*/}
            <MeetingsTableHead setOrder={''} setPageNum={() => {
            }} isScroll={true}/>

            <WrapperTasksTable onScroll={() => {

            }}>
                <WrapperTasks>
                    {meetingsData.length && meetingsData.map((meet) => {
                            return <MeetingsTableTr key={nanoid()} meeting={meet}/>
                        }
                    )}
                </WrapperTasks>
            </WrapperTasksTable>
        </>
    );
};
const mapStateToProps = (state: State) => {
    return {
        meetingsData: state.meetingsPage.meetings,
        // tableOrTreeToggler: state.tableOrTreeToggler,
        // businessProcessesModalShow: state.projectsPage.businessProcessesModalShow,
    };
};
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Meetings);
