import { nanoid } from 'nanoid';
import React from 'react';
import styled from 'styled-components';
import Icon from '../../../common/components/Icon';

const tarif = {
  'Неограниченное число проектов': [true, true, true],
  'Неограниченное число задач': [true, true, true],
  'Доступ к библиотеке знаний': [true, true, true],
  'Чаты по задачам': [true, true, true],
  'Мобильное приложение для IOS и Android': [true, true, true],
  'Карта загрузки исполнителей': [true, true, true],
  'Дерево задач': [true, true, true],
  'Чек-листы к задачам': [true, true, true],
  'Возможность делегирования': [true, true, true],
  'Интеграция с календарями': [true, true, true],
  Хранилище: ['1 гб', 'Без ограничений', 'Без ограничений'],
  'Обучение администратора': [false, true, true],
  'Чат с технической поддержкой': [false, true, true],
  'Умный бот': [false, true, true],
  'Обучение всех сотрудников': [false, true, true],
  'Заведение всех проектов': [false, false, true],
  'Добавление всех пользователей': [false, false, true],
  'Присвоение ролей': [false, false, true],
  'Авто-KPI': [false, false, true],
  'Расширенные возможности интеграции': [false, false, true],
  'Настройка аналитики': [false, false, true],
  'Доработка под потребности компании': [false, false, true],
  'Облако / Сервер': [false, false, true],
};
// const tarifTitle = {
//   'Сравните наши тарифные планы': ['Базовый', 'Расширенный', 'Максимум'],
// };

const TarifBlockStyled = styled.div`
  width: 872px;
`;

const RowTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  width: 872px;
  ${({ styles }) => styles && styles}
`;
const RowList = styled.div`
  display: flex;
  height: 32px;
`;
const RowName = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  width: 296px;
  color: #707070;
  ${({ styles }) => styles && styles}
`;

const WrapperItems = styled.div`
  display: flex;
  /* width: 577px;
  justify-content: space-around; */
  ${({ styles }) => styles && styles}
`;
const RowItem = styled.div`
  display: flex;
  width: 193px;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  ${({ styles }) => styles && styles}
`;

const TarifDescription = () => {
  return (
    <TarifBlockStyled>
      <RowTitle
        styles={{ fontWeight: '500', fontSize: '18px', lineHeight: '24px' }}
      >
        <RowName
          styles={{
            fontWeight: '500',
            fontSize: '18px',
            lineHeight: '24px',
            color: '#000',
          }}
        >
          Сравните наши тарифные планы
        </RowName>
        <WrapperItems>
          <RowItem
            styles={{
              color: '#1BAAF0',
              fontSize: '18px',
              lineHeight: '32px',
            }}
          >
            EASY FREE
          </RowItem>
          <RowItem
            styles={{
              color: '#27CBA6',
              fontSize: '18px',
              lineHeight: '32px',
            }}
          >
            EASY PAY
          </RowItem>
          <RowItem
            styles={{
              color: '#BB6BD9',
              fontSize: '18px',
              lineHeight: '32px',
            }}
          >
           EASY BOX
          </RowItem>
        </WrapperItems>
      </RowTitle>
      {Object.keys(tarif).map((el, i) => {
        return (
          <RowList key={nanoid()}>
            <RowName>{el}</RowName>
            <WrapperItems styles={i % 2 ? { background: '#F2F2F2' } : {}}>
              {tarif[el].map((check) => {
                if (check === false) {
                  return <RowItem key={nanoid()}>-</RowItem>;
                } else if (check === true) {
                  return (
                    <RowItem key={nanoid()}>
                      <Icon width="16px" height="13px" name="checkOk" />
                    </RowItem>
                  );
                } else {
                  return <RowItem key={nanoid()}>{`${check}`}</RowItem>;
                }
              })}
            </WrapperItems>
          </RowList>
        );
      })}
    </TarifBlockStyled>
  );
};

export default TarifDescription;
