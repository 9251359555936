import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import NameOfStep from "../templates/NameOfStep";
import RadioButtons from "../templates/RadioButtons";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import "../BpResultForm.scss";

const EnteringDataInto1CUT = () => {
  const {
    settingsOfFields,
    company_id,
    taskId,
    bearer,
    apiBp,
    selectedBp,
    thisTabs,
    closeWindowTask,

    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.enteringDataInto1CUT?.extra
      ? settingsOfFields.enteringDataInto1CUT.extra
      : {}
  );

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  // console.log(taskId);

  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.enteringDataInto1CUT, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  // console.log("     ", );

  return (
    <form id="enteringDataInto1CUT">
      <div>
        <NameOfStep text={"Номер договора"} />
        <input
          className="input-form marginInput"
          type={"text"}
          value={`Договор № ${selectedBp}`}
          disabled={true}
        />
        <NameOfStep text={"Договор и контрагент заведен в 1С УТ"} />
        <RadioButtons
          data={data}
          setData={setData}
          name={"i9"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
        {data.i9 === "Нет" && (
          <TextAreaOrInputElement
            name={"i9_1"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
          />
        )}
      </div>
    </form>
  );
};

export default EnteringDataInto1CUT;
