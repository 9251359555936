import React, { useContext } from "react";
import { StatusContext } from "../../context/status.js";
import BpItemStatus from "../ui/bp-item-status/BpItemStatus";
import BpItemMenu from "../ui/bp-item-menu/BpItemMenu.jsx";
import TasksList from "../tasks-list/TasksList.jsx";
import { useState } from "react";
import { useEffect } from "react";

const BpItem = ({ el, redirect }) => {
  const {
    createBpStatus,
    createBpSampleStatus,
    setIdBp,
    openTasks,
    setOpenTasks,
    setOpenMenuBp,
    openMenuTasks,
    openMenuBp,
    setIdCall,
    setOpenMenuTasks,
    selectedBp,
    setSelectedBp,
    bp,
    createBpFromSample,
    setOpenMenuTask,
    users,
    projects,
    setTaskId,
    setShowChatWindow,
    setThisTabs,
    setRedirectedByClick,
    selectColumns,
    businessProcessCurrentGlobal,
  } = useContext(StatusContext);

  const [project, setProject] = useState();
  const [projectSection, setProjectSection] = useState({});
  const [statusDisplay, setStatusDisplay] = useState("none");

  const openTasksMenu = (e) => {
    if (e.id === openTasks) {
      setOpenTasks("");
      setTaskId();
    } else {
      setOpenTasks(e.id);
    }
  };

  //раскрытие плитки меню в случае перехода к БП из задач через модальное окно

  useEffect(() => {
    if (redirect) {
      setOpenTasks("business-item-" + el.id);
      setSelectedBp(el.id);
      setIdCall(el.id);
      setOpenMenuTasks(false);
    } else {
      if (selectedBp) {
        setOpenTasks("business-item-" + selectedBp);
        setSelectedBp(selectedBp);
        setIdCall(selectedBp);
      }
      setOpenMenuTasks(false);
    }
  }, [redirect]);

  // console.log(openMenuTasks);
  const initiator = users.find((user) => user.id === el.initiator_id);

  // console.log(el.tasks);

  useEffect(() => {
    if (projects)
      setProject((prev) =>
        projects.find((project) => project.id === el.project_id)
      );
  }, [projects, el, el.project_id]);

  useEffect(() => {
    if (project)
      setProjectSection(
        project.sections.find((section) => section.id === el.project_section_id)
      );
  }, [project, projectSection, el, el.project_section_id]);

  // console.log(statusDisplay);
  return (
    <div
      className={
        createBpStatus || createBpSampleStatus || openMenuTasks || openMenuBp
          ? "business__main-content__list-block__item business__main-content__list-block__item-active"
          : "business__main-content__list-block__item"
      }
    >
      {/* {console.log(openTasks)} */}
      <div
        id={"business-item-" + el.id}
        onClick={(e) => {
          setIdCall(el.id);
          // setTaskId(null)
          if (el.id) setSelectedBp(el.id);

          // для закрытия меню "запуск с" в BpItemTaskMenu
          // Mount.current = false
          setOpenMenuTask(false);
          openTasksMenu(e.currentTarget);
          setTaskId();
        }}
      >
        <div className="business__main-content__list-block__item-left">
          {selectColumns.includes("initiator_id") && (
            <>
              <div className="business__main-content__list-block__item__arrow">
                <img
                  title={
                    initiator?.name + " " + initiator?.surname + " "
                    //+ initiator?.patronymic
                  }
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
                  alt="drop"
                  style={{ opacity: 0.5 }}
                />
              </div>
              <div className="item_id">{el.id}</div>
              <div className="business__main-content__list-block__item__logo">
                {initiator && initiator.image ? (
                  <img
                    title={
                      initiator?.name + " " + initiator?.surname + " "
                      //+ initiator?.patronymic
                    }
                    src={`${process.env.REACT_APP_PUBLIC_URL}/files/${initiator?.image.url}/download`}
                    alt="logo"
                  />
                ) : (
                  <span title={initiator?.name + " " + initiator?.surname}>
                    {initiator?.name[0] + initiator?.surname[0]}
                  </span>
                )}
              </div>
            </>
          )}

          <div
            className="business__main-content__list-block__item__message business__main-content__list-block__item__message-active"
            id={"business-item-btn-" + 1}
            onClick={(e) => {
              e.stopPropagation();
              setStatusDisplay("none");
              setOpenMenuBp(true);
              setShowChatWindow(true);
              setRedirectedByClick(false);
              setThisTabs(3);
              setSelectedBp(el.id);
              setTaskId();
            }}
            style={{ padding: "10px" }}
          >
            <div>
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/message.svg`}
                alt="message"
                title="Чат бизнес-процесса"
              />
            </div>
          </div>

          {selectColumns.includes("name") && (
            <div className="business__main-content__list-block__item__name">
              <p className="p-black">{el.name}</p>
            </div>
          )}
        </div>

        <div className="business__main-content__list-block__item-status">
          {selectColumns.includes("status") && (
            <BpItemStatus
              deadline={el.deadline}
              status={el.status}
              id={el.id}
              workflow={el.workflow_id}
            />
          )}

          {selectColumns.includes("deadline") && el.tasks.length > 0 ? (
            <p className="business__main-content__list-block__item__deadline p-black">
              {new Date(el.deadline).toLocaleString("ru", {
                month: "long",
                day: "numeric",
              })}
            </p>
          ) : (
            <span className="business__main-content__list-block__item__deadline p-black">
              __________
            </span>
          )}

          {selectColumns.includes("project_id") && (
            <div className="business__main-content__list-block__item__project">
              <p className="p-black project-name">
                {!openMenuBp &&
                !createBpStatus &&
                !createBpSampleStatus &&
                !openMenuTasks
                  ? project?.name
                  : ""}
              </p>
              <span className="p-grey">
                {!openMenuBp &&
                !createBpStatus &&
                !createBpSampleStatus &&
                !openMenuTasks
                  ? projectSection?.name
                  : ""}
              </span>
            </div>
          )}
        </div>
        <div
          className="dropdown-menu__bpItem"
          onClick={(e) => {
            e.stopPropagation();
            setIdBp(+el.id);
            setSelectedBp(+el.id);
            setOpenMenuTask(false);
            setStatusDisplay((prev) => (prev === "none" ? "block" : "none"));
            // setTaskId(null);
          }}
        >
          <div>
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/drop-down-menu.svg`}
              alt="menu"
              data-id={el.id}
              className="img_menu_bp_item"
            />
            <BpItemMenu
              id={el.id}
              status={el.status}
              statusDisplay={statusDisplay}
              setStatusDisplay={setStatusDisplay}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedBp(+el.id);
              }}
            />
          </div>
        </div>
      </div>

      {openTasks === "business-item-" + el.id ? (
        <TasksList
          tasks={el.tasks}
          projectName={project && project.name ? project.name : ""}
          projectSectionName={
            projectSection && projectSection.name ? projectSection.name : ""
          }
        />
      ) : (
        <></>
      )}

      {createBpFromSample && bp.id == el.id && openTasks ? (
        <TasksList
          tasks={el.tasks}
          projectName={project && project.name ? project.name : ""}
          projectSectionName={
            projectSection && projectSection.name ? projectSection.name : ""
          }
        />
      ) : (
        <></>
      )}
      {+el.completion * 100 === 100 ? (
        <div
          className="loading"
          style={{
            width: `${+el.completion * 100}%`,
            borderRadius: "0px 0px 4px 4px",
          }}
        ></div>
      ) : (
        <div
          className="loading"
          style={{
            width: `${+el.completion * 100}%`,
            borderRadius: "0px 0px 0 4px",
          }}
        ></div>
      )}
    </div>
  );
};

export default BpItem;
