import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

const Wrapper = styled.div`
	width: 100%;
  height: calc(100vh - 183px);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px;
	box-sizing: border-box;
	flex-direction: column;
	gap: 40px;
`;

const SettingsWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const SettingsLine = styled.div`
	width: 100%;
  max-width: 650px;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

const InputLine = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 30px;
`

const InputContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;

const NameSettings = styled.p`
	font-size: 22px;
	font-weight: bold;
`;

const CustomInput = styled.input`
  border: ${(props) => props.isError ? '1px solid red' : '1px solid lightgray'};
  width: 200px;
  height: 30px;
	padding: 0 10px;
	text-align: center;
`;

const Type = styled.p`
	font-weight: bold;
	font-size: 16px;
`;

const Description = styled.p`
	font-style: italic;
	font-size: 14px;
	letter-spacing: 0.7px;
`;

const ButtonsContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 150px;
`;

const Button = styled.button`
	cursor: pointer;
	color: white;
	width: 200px;
	height: 40px;
	background: #1BAAF0;
	
	&:hover {
		opacity: 0.9;
	}
	
	&:active {
		opacity: 0.8;
	}
`;

interface IFormInput {
	first: number;
	second: number;
	third: number;
	fourth: number;
}

const SettingsPage = () => {
	const [errors, setErrors] = useState<string[]>([]);
	const defaultValues = {
		first: 40,
		second: 8,
		third: 50,
		fourth: 1,
	};
	const { register, reset , getValues } = useForm<IFormInput>({
		defaultValues: defaultValues,
	});

	const handleReset = () => {
		reset(defaultValues);
	};

	const handleSubmit = () => {
		const value = getValues();
		Object.entries(value).forEach((el) => {
			if (!el[1]) {
				setErrors((prev) => {
					const isAdd = prev.indexOf(el[0]) === -1;
					if (isAdd) {
						const array = prev.concat(el[0]);

						return array;
					};

					return prev;
				});
			} else {
				setErrors((prev) => {
					const filtered = prev.filter((name) => {
						return name !== el[0];
					});

					return filtered;
				});
			}
		})

		if (!errors.length) {
			console.log('==========>отправка данных');

			// жду ендпоинт для отправки сохранненных данных
		}
	};

	return (
		<Wrapper>
			<NameSettings>Настройки параметров</NameSettings>
			<SettingsWrapper>
				<SettingsLine>
					<InputLine>
						{/*<NameSettings>Параметр А</NameSettings>*/}
						<InputContainer>
							<CustomInput
								isError={errors.indexOf('first') !== -1}
								name='first'
								type='number'
								ref={register}
							/>
							<Type>ч.</Type>
						</InputContainer>
					</InputLine>
					<Description>
						Минимальное значение трудоемкости задачи, при котором задача считается достаточно длительной для того, чтобы можно было разрешать алгоритму дробить ее на части
					</Description>
				</SettingsLine>
				<SettingsLine>
					<InputLine>
						{/*<NameSettings>Параметр В</NameSettings>*/}
						<InputContainer>
							<CustomInput
								isError={errors.indexOf('second') !== -1}
								name='second'
								type='number'
								ref={register}
							/>
							<Type>ч.</Type>
						</InputContainer>
					</InputLine>
					<Description>
						Минимальное значение трудоемкости частей задачи, образуемых в процессе дробления длительной задачи
					</Description>
				</SettingsLine>
				<SettingsLine>
					<InputLine>
						{/*<NameSettings>Параметр D</NameSettings>*/}
						<InputContainer>
							<CustomInput
								isError={errors.indexOf('third') !== -1}
								name='third'
								type='number'
								ref={register}
							/>
							<Type>%</Type>
						</InputContainer>
					</InputLine>
					<Description>
						Процент автопродления дедлайна задач с низким приоритетом
					</Description>
				</SettingsLine>
				<SettingsLine>
					<InputLine>
						{/*<NameSettings>Параметр G</NameSettings>*/}
						<InputContainer>
							<CustomInput
								isError={errors.indexOf('fourth') !== -1}
								name='fourth'
								type='number'
								ref={register({  })}
							/>
							<Type>шт.</Type>
						</InputContainer>
					</InputLine>
					<Description>
						Количество срочных задач в день
					</Description>
				</SettingsLine>
			</SettingsWrapper>
			<ButtonsContainer>
				<Button onClick={handleReset}>
					Сбросить
				</Button>
				<Button onClick={handleSubmit}>
					Сохранить
				</Button>
			</ButtonsContainer>
		</Wrapper>
	);
};

export default SettingsPage;
