import React, { useEffect, useMemo, useState } from "react";
import UsersTableHead from "./UsersTableHead";
import UsersTableContent from "./UsersTableContent";
import { State } from "../../../rootReducer";
import { connect, useDispatch, useSelector } from "react-redux"; //****************************************** */
import { getGroupCounter } from "../selectors";
import { Department, Workgroup, User } from "../../../common/types";
import SelectedDep from "./SelectedDep";
import { throttle } from "../../../utils/throttle";
import styled from "styled-components";
import { useRef } from "react";
import { useCallback } from "react";
import { fetchUsers } from "../actions";

type Props = {
  isFetching: boolean;
  plateType: string | null;
  selectedDepGroupId: number | null;
  groupsCounter: number;
  groups: Workgroup[];
  deps: Department[];
  depGroupUsers: User[];
  userList: User[];
  usersOrder: { col_name: string; order_direct: boolean };
};

const WrapperListUser = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0 0 40px;
  position: relative;
  ${({ isFetching }) =>
    isFetching &&
    `
verflow: hidden;

&::before{
  content: '';
  background: #fff;
  z-index: 3;
  width: 100%;
  height: calc(100% + 10px);
  position: absolute;
  top:-5px;
  right:0;
}
&::after{
  content: '';
  border-radius: 50%;
  border: 3px solid #1BAAF0;
  z-index: 3;
  border-bottom-color: #fff;
  border-left-color: #fff;
  animation: spin .5s linear infinite;
  transform: translateX(-50%);
  position: absolute;
  top: 250px;
  left:50%;
  width:16px;
  height:16px;
}
`}
`;

const WrapperUsersBlock = styled.div`
  max-height: calc(100vh - 185px);
  overflow-y: auto;
`;

const UsersTable: React.FC<Props> = ({
  isFetching,
  groupsCounter,
  selectedDepGroupId,
  plateType,
  deps,
  groups,
  depGroupUsers,
  userList,
  usersOrder,
}) => {
  //******************************************************************* */
  const users = useSelector((state: State) => state.commonInfo.users);

  const dispatch = useDispatch();
  const [isScroll, setScroll] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const depGroup = useMemo(() => {
    if (plateType === "deps") {
      return deps.find((dep) => dep.id === selectedDepGroupId);
    } else return groups.find((group) => group.id === selectedDepGroupId);
  }, [deps, groups, plateType, selectedDepGroupId, ref]);
  useEffect(() => {
    let widthScrol = ref?.current?.offsetWidth || 0;
    let widthTable = ref?.current?.scrollWidth || 0;
    if (widthScrol > widthTable) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [ref, isFetching]);
  const [pageNum, setPageNum] = useState(1);
  const scrollHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      if (
        scrollHeight - 5 <= scrollTop + clientHeight &&
        userList.length === 20 * pageNum
      ) {
        // throttle - чтобы вызывать функцию не чаще, чем через заданный минимальный интервал.
        let f1000 = throttle(() => setPageNum(pageNum + 1), 1500);
        f1000();
      }
    },
    [pageNum, userList.length]
  );

  useEffect(() => {
    //console.log("***")
    dispatch(fetchUsers(pageNum));
  }, [fetchUsers, pageNum, users]); ///**************************************** */

  // при смене просматриваемого отдела/группы страница гарантированно должна обнуляться
  useEffect(() => {
    setPageNum(1);
  }, [selectedDepGroupId, usersOrder]);

  return (
    <WrapperListUser
      isFetching={isFetching}
      //   className={`table users_table${isFetching ? ' in_load' : ''}`}
    >
      {selectedDepGroupId && (
        <SelectedDep name={depGroup!.name} counter={groupsCounter} />
      )}
      <UsersTableHead isScroll={isScroll} />
      <WrapperUsersBlock onScroll={scrollHandler} ref={ref}>
        {depGroupUsers.length > 0 ? (
          <UsersTableContent />
        ) : (
          <div className="main_title" style={{ padding: 24 }}>
            Сотрудники не найдены
          </div>
        )}
      </WrapperUsersBlock>
    </WrapperListUser>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isFetching: state.usersPage.isFetchUsers,
    selectedDepGroupId: state.usersPage.selectedDepGroupId,
    plateType: state.usersPage.depGroupPlateType,
    groupsCounter: getGroupCounter(state),
    groups: state.commonInfo.workgroups,
    deps: state.commonInfo.departments,
    depGroupUsers: state.usersPage.depGroupUsers,
    userList: state.usersPage.depGroupUsers,
    usersOrder: state.usersPage.usersOrder,
  };
};

export default connect(mapStateToProps)(UsersTable);
