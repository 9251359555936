import React, { FC } from 'react'
import styled from 'styled-components'
import { Types } from '../../../pages/Calendar/components/CalendarActions'
import { ITypes } from '../../../pages/Calendar'

const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  height: 32px;
  width: 100px;

  border: 1px solid ${(props) => (props.checked ? '#01B0E9' : '#c4c4c4')};
  background: ${(props) => (props.checked ? '#01B0E9' : 'white')};

  color: ${(props) => (props.checked ? 'white' : '#292a34')};
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;

  position: relative;
  z-index: ${(props) => (props.checked ? 1 : 0)};

  &:first-child {
    margin-right: -1px;

    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }

  &:last-child {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }

  &:hover {
    z-index: 2;
    border-color: ${(props) => (props.checked ? '#01B0E9' : '#292a34')};
  }
`

type Props = {
  label: string
  handleChangeType: (value: boolean, type: Types) => void
  types: ITypes
  type: Types
}

const CustomCheckbox: FC<Props> = ({
  label,
  types,
  handleChangeType,
  type,
}) => {
  return (
    <WrapperCheckbox
      checked={types[type]}
      onClick={() => handleChangeType(!types[type], type)}
    >
      {label}
    </WrapperCheckbox>
  )
}

export default CustomCheckbox
