import React, {FC} from 'react';
import styled  from 'styled-components';

const CustomTd = styled.td`
	box-sizing: border-box;
	vertical-align: ${(props) => props.isHeader ? 'top' : 'middle'};
	padding: 10px;
	text-align: ${(props) => props.isCenter ? 'center' : 'left'};
`;

type Props = {
	isCenter?: boolean;
	isHeader?: boolean;
};

const TableCell: FC<Props> = ({ children, isCenter, isHeader }) => {

	return (
		<CustomTd isCenter={isCenter} isHeader={isHeader}>
			{children}
		</CustomTd>
	);
};

export default TableCell;
