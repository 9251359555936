import React from "react";

const BsFilter57Vector_active = ({ props }) => {
  return (
    <svg
      {...props}
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="#F2F2F2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1.375H1V13.375H13V1.375Z"
        stroke="#292A34"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BsFilter57Vector_active;
