import React, { useEffect, useState } from "react";
import withTitle from "../../../utils/hoc/withTitle";
import { connect, useDispatch } from "react-redux";
import { State } from "../../../rootReducer";
import Button from "../../../common/components/newComponent/Buttons/Button";
import palette from "../../../common/components/palette";
import styled from "styled-components";
import Icon from "../../../common/components/Icon";
import { fetchData } from "../../../utils/fetchData";
import { updateTimestamp } from "../../../common/actions";
import { nanoid } from "nanoid";
import { businessProcessesModalShow } from "../actions";

type Props = {
  businessProcessesModalShow: (show: boolean) => void;
  projectID: number | undefined;
};

const NewRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ListItem = styled.div`
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
  cursor: pointer;

  ${({ color }) => {
    if (color) {
      return `color: ${palette.visualBgBlue};`;
    } else {
      return `color: ${palette.textBlack};`;
    }
  }};
`;

const BusinessProcessesModal: React.FC<Props> = ({
  businessProcessesModalShow,
  projectID,
}) => {
  const [FirstRender, setFirstRender] = useState<boolean>(true);
  const [listData, setListData] = useState<any>([]);
  const [selectedItemID, setSelectedItem] = useState<null | number>(null);

  if (FirstRender && projectID) {
    setFirstRender(false);
    fetchData
      .get(`/api/v1/business_processes?project_id=${projectID}`)
      .then((response) => {
        // console.log(response)
        setListData(response);
        // dispatch(updateTimestamp(timestamp))
      })
      .catch((error) => console.warn(error));
  }
  // console.log(listData)

  // useEffect(() => {   // интерактивно показывать изменения в поле правой плашки. Временно отключен, потому что фон все равно заблюрен.
  //     setStartCyclick(
  //         repeat.period,
  //         repeat.params,
  //         repeat.interval,
  //         repeat.show_modal_window,
  //         stringForMainPart)
  // }, [stringForMainPart])

  return (
    <div className="add_pannel_wrapper">
      <div className={`repeat_task_window`} style={{ width: "320px" }}>
        <NewRow style={{ marginBottom: "16px" }}>
          <div
            style={{ lineHeight: "24px", fontSize: "16px", cursor: "default" }}
          >
            Типовые бизнес-процессы:
          </div>
          <div>
            <Icon
              onClick={() => businessProcessesModalShow(false)}
              name={"cross"}
            />
          </div>
        </NewRow>
        <NewRow style={{ flexDirection: "column" }}>
          {listData.map((BP) => (
            <ListItem
              color={BP.id === selectedItemID}
              key={nanoid()}
              onClick={() => {
                setSelectedItem(BP.id);
              }}
            >
              {BP.name}
            </ListItem>
          ))}
        </NewRow>
        <NewRow style={{ marginTop: "16px" }}>
          <Button
            title={"Отменить"}
            style={{ width: "100%", marginRight: "4px" }}
            design={"secondary"}
            onClick={() => {
              businessProcessesModalShow(false);
            }}
          />
          <Button
            style={{ width: "100%", marginLeft: "4px" }}
            title={"Создать"}
            disabled={selectedItemID === null}
            onClick={() => {
              fetchData
                .post(`/api/v1/business_processes/${selectedItemID}/launch`, {})
                .then((response) => {
                  businessProcessesModalShow(false);
                })
                .catch((error) => console.warn(error));
            }}
          />
        </NewRow>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    projectID: state.projectsPage.selectedProject?.id,
  };
};

const mapDispatchToProps = {
  businessProcessesModalShow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessProcessesModal);
