// Контекстное меню для бизнес-процесса
import React, { useContext, useState, useEffect } from "react";
import { StatusContext } from "../../../context/status.js";
import { ClickAwayListener } from "@material-ui/core";
import "./BpItemMenu.scss";
import axios from "axios";
import { CreateNotif } from "../../../../../../utils/createNotification";
import SaveBp from "../bp-save-window/SaveBp.jsx";

const BpItemMenu = ({ id, status, setStatusDisplay, statusDisplay }) => {
  const {
    idBp,
    company_id,
    bearer,
    setIdBp,
    apiBp,
    bpList,
    setCreateTaskStatus,
    setAddTasksMenu,
    tasks,
    setTasks,
    thisBp,
    setThisBp,
    tasksList,
    setTasksList,
    start,
    setStart,
    setOpenTasks,
    setBpListChange,
    setOpenMenuTasks,
    setOpenMenuBp,
    setTaskId,
    isAdmin,
    businessProcessCurrentGlobal,
    setShowChatWindow,
    setThisTabs,
    setRedirectedByClick,
    setSelectedBp,
    openSaveBp,
    setOpenSaveBp,
    userId,
  } = useContext(StatusContext);

  const [change1, setChange1] = useState(false);


  // Включить видимость меню при открытии
  // useEffect(() => {
  //   setStatusDisplay("block");
  // }, [change1]);

  // Показывать меню только для той бизнес-процесса, на которой кликнули
  useEffect(() => {
    bpList.filter((el) => {
      if (el.id === idBp) {
        return setThisBp(el);
      }
      return false;
    });
  }, [idBp, change1]);

  // Получить задачи для данной бизнес-процесса
  useEffect(() => {
    if (!!thisBp.id) {
      let a = thisBp.tasks.map((el) => el.id);
      setTasks(a);
    }
  }, [thisBp, change1]);

  // Функция: запуск бизнес-процесса

  const startBp = () => {
    if (idBp) {
      if (tasks && tasks.length > 0) {
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/bp/${idBp}/start`,
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
            Accept: "application/json",
          },
        };

        axios(config)
          .then((res) => {
            //   console.log(res);

            setBpListChange((prev) => !prev);
            setOpenMenuTasks(false);
            setChange1((prev) => !prev);
            CreateNotif(`Бизнес-процесс: "${thisBp.name}" запущен!`, "success");
          })

          .catch(function (error) {
            CreateNotif(
              `Ошибка запуска бизнес-процесса "${thisBp.name}" `,
              "error"
            );
          });
      } else {
        CreateNotif(
          `Для запуска добавьте в бизнес-процесс "${thisBp.name}" хотя бы одну задачу! `,
          "warning"
        );
      }
    }
  };
  //**************************************************************** */

  // Функция: редактирование бизнес-процесса
  const editBp = () => {
    if (idBp) {
    }
  };
  //**************************************************************** */

  // Функция: отменить бизнес-процесса
  const cancelBp = () => {
    if (idBp) {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/bp/${idBp}/cancel`,
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": document.cookie.replace(
            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
          ),
          Accept: "application/json",
        },
      };

      axios(config)
        .then((res) => {
          // console.log(res);
          setBpListChange((prev) => !prev);
          setOpenMenuTasks(false);
          setChange1((prev) => !prev);
          CreateNotif(`Бизнес-процесс: "${thisBp.name}" отменен`, "warning");
        })

        .catch(function (error) {
          CreateNotif(
            `Ошибка отмены бизнес-процесса "${thisBp.name}" `,
            "error"
          );
        });
    }
  };
  //**************************************************************** */

  // Функция удаления бизнес-процесса
  const deleteSample = () => {
    if (idBp) {
      let config = {
        method: "delete",
        url: `${apiBp}/bp/${idBp}`,
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": localStorage.getItem("access_token_jwt"),
          Accept: "application/json",
        },
      };

      axios(config)
        .then((res) => {
          // console.log(res);

          setBpListChange((prev) => !prev);
          CreateNotif(`Бизнес-процесс: "${thisBp.name}" удален`, "error");
        })

        .catch(function (error) {
          CreateNotif(
            `Ошибка удаления бизнес-процесса "${thisBp.name}" `,
            "error"
          );
        });
    }
  };
  //********************************************************************* */

  // Функция: создать бизнес-процесса по шаблону
  const createSample = () => {
    console.log(company_id);
    if (idBp) {
      let data = JSON.stringify({
        name: `Шаблон ${thisBp.name}`,
        company_id: company_id,
      });

      let config = {
        method: "post",
        url: `${apiBp}/bp-template/from-bp/${idBp}`,
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": localStorage.getItem("access_token_jwt"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((res) => {
          //  console.log(res);

          setBpListChange((prev) => !prev);
          CreateNotif(
            `Шаблон по типовому бизнес-процессу "${thisBp.name}" создан `,
            "success"
          );
        })

        .catch(function (error) {
          CreateNotif(`Ошибка создания бизнес-процесса по шаблону `, "error");
        });
    }
  };
  //**************************************************************** */

  // Данная Бизнес-процесс активная (проверить необходимость данной функции)
  // const makeActiveTasks = () => {
  //   const getTasks = thisBp.tasks.map((item) => {
  //     const link = `${process.env.REACT_APP_PUBLIC_API}/tasks/${item.id}`;
  //     return axios.get(link, {
  //       headers: {
  //         Authorization: bearer,
  //         "company-id": company_id,
  //       },
  //     });
  //   });
  //   Promise.all(getTasks).then((results) => setTasksList(results));

  //   fetch(`${apiBp}/bp/${idBp}/makeActive`, {
  //     method: "PATCH",
  //     headers: {
  //       Authorization: bearer,
  //       "company-id": company_id,
  //       "secret-token": document.cookie.replace(
  //         /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
  //         "$1"
  //       ),
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((re) => console.log(re));
  //   setStart(true);
  // };

  // Обработка задач в типовой задаче  (проверить необходимость данной функции)
  useEffect(() => {
    if (start && tasksList.length > 0) {
      let arr = tasksList.map((el) => {
        return {
          ...el,
          data: {
            ...el.data,
            data: {
              ...el.data.data,
              status_id: 50,
            },
          },
        };
      });
      setTasksList(arr);
      setOpenTasks("business-item-" + idBp);
      setStart(false);
    }
  }, [tasksList, start]);

  // Контекстное меню
  if (idBp === id) {
    //  alert(idBp)
    return (
      <ClickAwayListener
        onClickAway={() => {
          setStatusDisplay("none");
          setIdBp("");
          setOpenSaveBp(false);
        }}
      >
        <div style={{ display: `${statusDisplay}` }}>
          <div style={{ display: `${statusDisplay}` }}></div>
          {idBp && (
            <div
              className="bp-item-menu"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ul>
                {businessProcessCurrentGlobal &&
                  !businessProcessCurrentGlobal.template_id &&
                  status === 0 && (
                    <li
                      className="hover"
                      onClick={(e) => {
                        e.stopPropagation();
                        setStatusDisplay("none");
                        setCreateTaskStatus(true);
                        setAddTasksMenu(true); // Включается меню создании задачи из createTask.jsx
                        setTaskId();
                      }}
                    >
                      Добавить задачу
                    </li>
                  )}
                {/*************** Запустить бизнес-процесса *********************************** */}
                {status === 0 ? (
                  <li
                    className="hover"
                    onClick={(e) => {
                      e.stopPropagation();
                      startBp();
                      setStatusDisplay("none");
                      setTasksList([]);
                      setTaskId();
                      //     makeActiveTasks();
                    }}
                  >
                    Запустить бизнес-процесс
                  </li>
                ) : null}
                {/************************************************** */}
                {/* ************** Редактировать бизнес-процесс
                 *********************************** */}
                {(isAdmin && status === 0 ||
                  (businessProcessCurrentGlobal.initiator_id === userId &&
                    status === 0)) ? (
                  <li
                    className="hover"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenMenuBp(true);
                      setSelectedBp(+idBp);
                      setStatusDisplay("none");
                      setTaskId();
                      // setStatusDisplay("none");
                      // setTasksList([]);
                      // setTaskId();
                      //     makeActiveTasks();
                    }}
                  >
                    Редактировать бизнес-процесс{" "}
                  </li>
                ) : null}
                {/* Отменить бизнес-процесс */}
                {status === 2 ? (
                  <li
                    className="hover"
                    onClick={(e) => {
                      e.stopPropagation();
                      cancelBp();
                      setStatusDisplay("none");
                      setTasksList([]);
                      setTaskId();
                      //    makeActiveTasks();
                    }}
                  >
                    Отменить бизнес-процесс
                  </li>
                ) : null}
                {/* *****************  */}
                {/* Удалить бизнес-процесса */}
                {(isAdmin ||
                  (businessProcessCurrentGlobal.initiator_id === userId &&
                    status === 0 || status === 5)) && (
                  <li
                    className="hover"
                    onClick={(e) => {
                      e.stopPropagation();
                      setStatusDisplay("none");
                      deleteSample();
                      setTaskId();
                      setOpenMenuBp(false);
                      setOpenMenuTasks(false);
                      // setBusinessProcessCurrentGlobal();
                    }}
                  >
                    Удалить бизнес-процесс
                  </li>
                )}
                {/* *****************  */}
                {/* Сохранить бизнес-процесса как шаблон */}
                {businessProcessCurrentGlobal &&
                  !businessProcessCurrentGlobal.template_id && (
                    <li
                      className="hover"
                      onClick={(e) => {
                        e.stopPropagation();
                        // setStatusDisplay("none");
                        // createSample();
                        // setTaskId();
                        setOpenSaveBp(!openSaveBp);
                      }}
                    >
                      Сохранить как шаблон
                    </li>
                  )}
                {openSaveBp && (
                  <SaveBp
                    setStatusDisplay={setStatusDisplay}
                    statusDisplay={statusDisplay}
                  />
                )}
                {/** ********************* */}
                {businessProcessCurrentGlobal.template_id && (
                  <li
                    className="hover"
                    onClick={(e) => {
                      e.stopPropagation();
                      setStatusDisplay("none");
                      setOpenMenuBp(true);
                      // setShowGeneralResult(true);
                      setTaskId();
                      setThisTabs(2);
                      setRedirectedByClick(false);

                      // setSelectedBp(idBp);
                      //console.log(selectedBp);
                    }}
                  >
                    Показать общий результат
                  </li>
                )}
                <li
                  className="hover"
                  onClick={(e) => {
                    e.stopPropagation();
                    setStatusDisplay("none");
                    setOpenMenuBp(true);
                    setShowChatWindow(true);
                    setThisTabs(3);
                    setTaskId();
                    setRedirectedByClick(false);
                    setSelectedBp(id);
                    //console.log(selectedBp);
                  }}
                >
                  Открыть чат БП
                </li>
              </ul>
            </div>
          )}
        </div>
      </ClickAwayListener>
    );

    // ******************
  } else return null;
  {
  }
};

export default BpItemMenu;
