import React, { useEffect, useState } from "react";
import { State } from "../../../rootReducer";
import { connect } from "react-redux";
import { getDepGroups, getDepList } from "../selectors";
import {
  fetchUsers,
  selectDepGroup,
  setAddUserToGroupDep,
  setDepGroupType,
  setUsers,
  showWindowCreateDepGroup,
} from "../actions";
import { User, Department } from "../../../common/types";
import styled from "styled-components";
import Icon from "../../../common/components/Icon";
type Props = {
  name: string;
  id?: number;
  selectedDepGroupId: number | null;
  plateType: string | null;
  type: string;
  setAddUserToGroupDep: (val: boolean) => void;
  setDepGroupType: (val: string | null) => void;
  // setDepId: (val: number | null) => void;
  selectDepGroup: (val: number | null, totalUsers: number | null) => void;
  number_of_employees: number;
  fetchUsers: (pageNum: number) => void;
  setUsers: (val: User[]) => void;
  showWindowCreateDepGroup: (val: boolean) => void;
  deps: Department[];
};

const DepListElementStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ active }) =>
    active &&
    `
    background:#F3FAFE;
    color: #1BAAF0;
   
    `}
  &:hover {
    div {
      display: none;
    }
    div:last-child {
      display: flex;
      justify-content: end;
      align-items: center;
      img {
        display: flex;
      }
    }
  }

  span {
    color: #212121;
    font-size: 14px;
    line-height: 32px;
    padding: 0 12px;
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div {
    color: #a8a8a8;
    height: 32px;
    line-height: 32px;
    margin: 0 12px;
    text-align: center;
  }
  div:last-child {
    display: none;
    img {
      display: none;
      margin-right: 4px;
    }
  }

  /* div:last-child {
    
  } */
`;

export const filterDepsByParentId = (parent_id, deps) => {
  let filteredDeps = deps.filter((dep) => dep.parent_id === parent_id);
  return filteredDeps;
};

const DepGroupListElem: React.FC<Props> = ({
  name,
  setUsers,
  fetchUsers,
  number_of_employees,
  selectDepGroup,
  setDepGroupType,
  setAddUserToGroupDep,
  id,
  selectedDepGroupId,
  type,
  plateType,
  showWindowCreateDepGroup,
  deps,
}) => {
  const [handleClick, setHandleClick] = useState(false);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <DepListElementStyle
        onClick={() => {
          if (id) {
            setDepGroupType(type);
            selectDepGroup(id, number_of_employees);
          } else {
            // У пункта Все нет айдишника
            selectDepGroup(null, null);
            setDepGroupType(null);
            setUsers([]);
          }

          fetchUsers(1);
          filterDepsByParentId(id, deps);
          setHandleClick(!handleClick);
        }}
        active={
          (selectedDepGroupId === id && plateType === type) ||
          (!id && selectedDepGroupId === null)
        }
      >
        <span>{name}</span>
        <div>{number_of_employees}</div>
        {id && (
          <div>
            <Icon
              name="setting"
              width="24px"
              height="24px"
              color="#fff"
              onClick={() => {
                selectDepGroup(id, number_of_employees);
                setAddUserToGroupDep(true);
              }}
            />

            <Icon
              name="plus_circle_blue"
              width="16px"
              height="16px"
              // style={{ padding: "12px" }}
              onClick={(e) => {
                setDepGroupType("deps");
                showWindowCreateDepGroup(true);
              }}
            />
          </div>
        )}
      </DepListElementStyle>
      {handleClick &&
        filterDepsByParentId(id, deps).map((elem) => (
          <div
            style={{
              marginLeft: "15px",
              borderLeft: "1px solid rgba(0,0,0,0.12)  ",
            }}
          >
            <div
              style={{
                marginLeft: "15px",
                width: "3px",
                borderLeft: "1px solid rgba(0,0,0,0.12)  ",
              }}
            ></div>
            <DepGroupListElem
              type={"deps"}
              key={elem.id}
              id={elem.id}
              name={elem.name}
              number_of_employees={elem.number_of_employees}
              setUsers={setUsers}
              fetchUsers={fetchUsers}
              selectDepGroup={selectDepGroup}
              setDepGroupType={setDepGroupType}
              setAddUserToGroupDep={setAddUserToGroupDep}
              selectedDepGroupId={selectedDepGroupId}
              plateType={plateType}
              showWindowCreateDepGroup={showWindowCreateDepGroup}
              deps={deps}
            />
          </div>
        ))}
    </div>
  );
};

const mapStateToProps = (state: State, props) => ({
  selectedDepGroupId: state.usersPage.selectedDepGroupId,
  plateType: state.usersPage.depGroupPlateType,
  groups: getDepGroups(state, props),
  deps: getDepList(state),
});

const mapDispatchToProps = {
  setAddUserToGroupDep,
  setUsers,
  setDepGroupType,
  // setDepId,
  selectDepGroup,
  fetchUsers,
  showWindowCreateDepGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepGroupListElem);
