import { call, takeLatest } from 'redux-saga/effects';
import { cookieMaster } from '../../utils/CookieMaster';
import { fetchData } from '../../utils/fetchData';
import { types, setPaidAction, setFreeAction } from './action_types';

export function* watchSetPaidCompany() {
  yield takeLatest(types.SET_PAID, setPaid);
}
export function* watchSetFreeCompany() {
  yield takeLatest(types.SET_FREE, setFree);
}
function* setFree({ companyId }: setFreeAction) {
  let header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Authorization: `${cookieMaster.getCookie('access_token')}`,
    'company-id': `${companyId}`,
  };
    yield call(fetchData.get, '/api/v1/account/set-free', header)
    yield window.location.reload()
}
function* setPaid({ data, pay, companyId }: setPaidAction) {
  let header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Authorization: `${cookieMaster.getCookie('access_token')}`,
    'company-id': `${companyId}`,
  };
  try {
    console.log("setPaid start"); 
    let res = yield call(
      fetchData.post,
      '/api/v1/account/set-paid',
      JSON.stringify(data),
      header
    );
    console.log("data",data);  
    if (data && res.success) {
      console.log("setPaid res.success"); 
      let linkYandex = yield call(
        fetchData.post,
        '/api/v1/payments/create',
        JSON.stringify(pay),
        header
      );
      console.log("linkYandex",linkYandex); 
      if (linkYandex[0].confirmation_url) {
        console.log("setPaid linkYandex"); 
        console.log("linkYandex[0].confirmation_url",linkYandex[0].confirmation_url);
        window.location.replace(linkYandex[0].confirmation_url);
      }

      //   yield put(paidSuccess(true));
      //   yield put(changeStep(4));
    }
    // console.log(data)
  } catch (error) {
    console.warn(error);
  }
}
