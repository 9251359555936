// Авторизация и регистрация
import React, { useEffect } from "react";
import "./styles.sass";
import Login from "./components/Login";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import EmailForm from "./components/EmailForm";
import EmailForm2 from "./components/EmailForm2";
import PasswordRecovery from "./components/PasswordRecovery";
import PasswordRecoveryFromUser from "./components/PasswordRecoveryFromUser";
import styled from "styled-components";
import Icon from "../../common/components/Icon";
import Register from "./components/Register";
import EmailConfirmed from "./components/EmailConfirmed";
import { cookieMaster } from "../../utils/CookieMaster";
import Company from "./components/Company";
import CompanyRequest from "./components/CompanyRequest";
import NewUserInCompany from "./components/NewUserInCompany";
import Faq from "../../common/components/Faq";
import { useContext } from "react";
import { useStateContext } from "../../useStateContext";
import Questionnaire from "./components/Questionnaire";

const LinksBlock = () => {
  const {
    startOnboarding_time, //  Время, через которое после входа на сайт пользователь принимает решение зарегистироваться в системе (в секундах).
    setStartOnboarding_time, // Вычисляется с момента входа на сайт до момента нажатия на кнопку "Продолжить" при регистрации.
  } = useContext(useStateContext);

  useEffect(() => {
    setStartOnboarding_time(new Date());
    console.log("startOnboardingTime", startOnboarding_time);
  }, []);

  return (
    <div style={{ width: "320px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "320px",
          marginTop: "35px",
        }}
      >
        <a
          href={require("../../common/components/Faq/doc.pdf")}
          target="_blanc"
          style={{
            color: "#06A0EB",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {" "}
          Руководство пользователя{" "}
        </a>
        <a
          href="https://easy-task.ru/pricing"
          target="_blanc"
          style={{
            marginLeft: "50px",
            color: "#06A0EB",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {" "}
          Тарифы{" "}
        </a>
      </div>
    </div>
  );
};

const TextBlock = () => {
  return (
    <div
      style={{
        width: "800px",
        fontSize: "12px",
        position: "absolute",
        bottom: "20px",
      }}
    >
      <p style={{ color: "#292A34", marginTop: "30px", textAlign: "justify" }}>
        {" "}
        Сервис EASY распространяется в виде интернет-сервиса, специальные
        действия по установке ПО на стороне пользователя не требуются.
      </p>

      <p style={{ color: "#292A34", marginTop: "10px", textAlign: "justify" }}>
        {" "}
        Правообладателем сервиса EASY является Общество с ограниченной
        ответственностью "МАСТЕРСКАЯ ЦИФРОВЫХ РЕШЕНИЙ"{" "}
      </p>

      <p style={{ color: "#292A34", marginTop: "10px", textAlign: "justify" }}>
        {" "}
        ОЭЗ Технополис Москва, РФ{" "}
      </p>
    </div>
  );
};

//min-width: 1000px;
//min-height: 550px;
//*************************************************************** */
const WrapperLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f7f7f9;
  min-width: 320px;
  width: 100%;
  height: 100vh;
  margin-top: 0px;
`;
const HeaderStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  min-height: 72px;
  width: 100vw;
  a {
    text-decoration: none;
    color: #02405e;
    border: 1px solid #e0e0e0;
    padding: 12px 16px;
    border-radius: 6px;
    &:hover {
      border-color: #02405e;
    }
  }
`;

const LoginPlate = () => {
  let history = useHistory();
  const location = useLocation();
  let token = cookieMaster.getCookie("access_token");
  let company_id = localStorage.getItem("company_id") || "";
  const isLoginPage = location.pathname.indexOf("login") > 0;
  // TODO  удалить через какое то время
  // useEffect(() => {
  //   cookieMaster.getCookie('company_id') &&
  //     cookieMaster.deleteCookie('company_id');
  // }, []);
  return (
    <WrapperLogin>
      <HeaderStyle>
        <Icon name="logo" onClick={() => history.replace("/")} />
        <Link
          onClick={() => {
            if (
              token &&
              (history.location.pathname === "/register" ||
                history.location.pathname === "/company-request" ||
                history.location.pathname === "/company") &&
              !+company_id
            ) {
              cookieMaster.deleteCookie("access_token");
              cookieMaster.deleteCookie("login");
            }
          }}
          to={
            history.location.pathname === "/register" ||
            history.location.pathname === "/company-request"
              ? "/login"
              : "/register"
          }
        >
          {(history.location.pathname === "/register" ||
            history.location.pathname === "/company-request" ||
            history.location.pathname === "/company") &&
          !+company_id &&
          token
            ? "Выйти"
            : history.location.pathname === "/register"
            ? "Войти"
            : "Новый аккаунт"}
        </Link>
      </HeaderStyle>
      <Switch>
        <Route path="/questionnaire" component={Questionnaire} />
        <Route path="/verify/" component={EmailConfirmed} />
        <Route strict path="/unique_link/" component={NewUserInCompany} />
        <Route path="/register" component={Register} />
        <Route path="/company-request" component={CompanyRequest} />
        <Route path="/company" component={Company} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={EmailForm} />
        <Route path="/password/recovery" component={PasswordRecovery} />
        <Route
          path="/password-recovery-from-user"
          component={PasswordRecoveryFromUser}
        />
        <Route path="/password-recovery-from-user2" component={EmailForm2} />

        {/* <Route path="/email" component={EmailVerify} /> */}
      </Switch>

      <div className="image" />
      {isLoginPage && <LinksBlock />}
      {/* <Faq /> */}
      <TextBlock />
    </WrapperLogin>
  );
};

export default LoginPlate;
