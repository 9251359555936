import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import NameOfStep from "../templates/NameOfStep";
import RadioButtons from "../templates/RadioButtons";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import "../BpResultForm.scss";

const FinalRegistrationByJurist = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,

    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.finalRegistrationByJurist?.extra
      ? settingsOfFields.finalRegistrationByJurist.extra
      : {}
  );

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  // console.log(taskId);

  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.finalRegistrationByJurist, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )

        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  // console.log("     ", );

  return (
    <form id="finalRegistrationByJurist">
      <div>
        <NameOfStep
          text={
            "Оригинал договора получен, внесен в реестр договоров, помещен в папку. Скан договора сохранен на диске"
          }
        />
        <RadioButtons
          data={data}
          setData={setData}
          name={"j10"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
        {data.j10 === "Нет" && (
          <TextAreaOrInputElement
            name={"j10_1"}
            isInput={true}
            type={"text"}
            data={data}
            setData={setData}
          />
        )}
      </div>
    </form>
  );
};

export default FinalRegistrationByJurist;
