// ************** Тут создаются задачи **************************
import React, { useEffect, useRef, useState } from "react";
import SelectWithSecondaryBtnProject from "../../../common/components/Selects/SelectWithSecondaryBtnProject"; //**** Добавлено
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn";
import { useLocation } from "react-router-dom";
import {
  Task,
  SelectData,
  Item,
  Section,
  Schedule,
  File as FileType,
} from "../../../common/types";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getSelectedTask,
  getUsersSelectList,
  getProjectsSelectList,
  getSectionSelectList,
} from "../selectors";
import { State } from "../../../rootReducer";
import { updateTask } from "../../../common/actions";
import TaskWorkLoad from "./TaskWorkLoad";
import { TextareaAutosize } from "@material-ui/core";
import {
  setTaskPrivate,
  changeReducerCheckboxItem,
  createTask,
  deleteFile,
  dispatchCheckboxItem,
  fetchProjectSections,
  isSendingNewTask,
  recalculateTaskLoad,
  selectTask,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setDefaultValues,
  setExecutorId,
  setFinishCyclick,
  setShownTaskInfoPlate,
  setStartCyclick,
  setTaskDescription,
  setTaskPriority,
  setTaskProject,
  setTaskSection,
  successfulCreatedNewTasks,
  updateTaskInfoPlate,
  uploadFile,
  setTaskBegin,
  setTaskEnd,
} from "../actions";
import CheckListModule from "../../../common/components/CheckList/CheckListModule";
import Button from "../../../common/components/newComponent/Buttons/Button";
import Chips from "../../../common/components/ChipsItem";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import styled from "styled-components";
import Prioritys from "../../../common/components/Priority/Prioritys";
import ExecutorSelect from "../../TaskInfoPlate/components/ExecutorSelectWithDelegate";
import Delegate from "../../TaskInfoPlate/components/Delegate";
import TaskBegin from "../../TaskInfoPlate/components/TaskBegin";
import TaskEnd from "../../TaskInfoPlate/components/TaskEnd";
import FileElem from "../../../common/components/FileElem/FileElem";
import Dependencies from "./Dependencies";
import RepeatTask from "./RepeatTask";
import EndRepeatTask from "./EndRepeatTask";
import { fetchData } from "../../../utils/fetchData";
import { fetchTaskDependence } from "../../Tasks/actions";
import { dateFormatUTC } from "../reducer";
import { useContext } from "react";
import { useStateContext } from "../../../useStateContext";
import DateMaster from "../../../utils/DateMaster";
import ProjectSectionsForTask from "../../NewProject/components/ProjectSectionsForTask";

export let status = "task"; //**********************************************************

let project: Item | null = null; //**********************************************************

let projectId: number | null = null; //**********************************************************

// let sectionSelectOR:Item|null = null//**********************************************************

let section: Section | null = null; //**********************************************************

type Props = {
  checkedPersonalTask: boolean;
  setCheckedPersonalTask: (checkedPersonalTask: boolean) => void;
  selectedTask: Task | null;
  projectsSelect: SelectData;
  id_bp: any;

  usersSelect: {
    author: Item;
    executor: Item;
    list: Item[];
  };
  uploadFile: (file: File) => void;
  deleteFile: (id: number) => void;
  sectionSelect: SelectData;
  commentFiles: FileType[];
  sections: Section[];
  schedule: Schedule | null;
  checkList: object[];
  priorities: any[];
  currentUser: any;
  updateTask: (id: number, params: any, withNotif?: boolean) => void;
  dispatchCheckboxItem: (
    orderNumber: number,
    text: string,
    checkboxState: boolean,
    actionType: string
  ) => void;
  changeReducerCheckboxItem: (
    orderNumber: number,
    text: string,
    checkboxState: boolean,
    actionType: string
  ) => void;
  setTaskDescription: (val: string) => void;
  name: string;
  executor_id: number | null;
  taskInfoActiveTab: string;
  description: string;
  project_id: number | null;
  project_section_id: number | null;
  successfulCreatedNewTasks: (value: any) => void;
  setTaskProject: (val: number) => void;
  fetchProjectSections: (val: number) => void;
  setTaskSection: (id: number) => void;
  setExecutorId: (id: number | null) => void;
  createTask: (executor_id?: number) => void;
  setDefaultValues: () => void;
  repeat: any;
  isSendingNewTask: (isSending: boolean) => void;
  isSendingCreateNewTasks: boolean;
  isShownTaskInfoPlate: boolean;
  // rememberObjectCyclicTaskToChange: string;
  setCyclicTaskToChange: (obj: {}) => void;
  // flagForActionCyclycTask: string;
  parameter_trigger: boolean;
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  setStartCyclick: (
    period?: string,
    params?: Array<number>,
    interval?: number,
    show_modal_window?: boolean,
    stringForMainPart?: string
  ) => void;
  setFinishCyclick: (
    ending_condition?: string,
    end_count?: number | null,
    end_date?: string | null
  ) => void;
};

const WrapperChipsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InfoTab: React.FC<Props> = ({
  checkedPersonalTask,
  setCheckedPersonalTask,
  dispatchCheckboxItem,
  checkList,
  taskInfoActiveTab,
  changeReducerCheckboxItem,
  selectedTask,
  projectsSelect,
  usersSelect,
  sections,
  sectionSelect,
  schedule,
  updateTask,
  project_id,
  setTaskDescription,
  setTaskProject,
  fetchProjectSections,
  setTaskSection,
  setExecutorId,
  createTask,
  setDefaultValues,
  name,
  description,
  project_section_id,
  uploadFile,
  deleteFile,
  commentFiles,
  successfulCreatedNewTasks,
  currentUser,
  executor_id,
  repeat,
  isSendingNewTask,
  isSendingCreateNewTasks,
  isShownTaskInfoPlate,
  // rememberObjectCyclicTaskToChange,
  setCyclicTaskToChange,
  // flagForActionCyclycTask,
  sendReqToChangeTemplRepTask,
  setStartCyclick,
  setFinishCyclick,
  parameter_trigger,
  priorities,
  id_bp,
}) => {
  //************************************ */
  const {
    checkedTaskBegin,
    setCheckedTaskBegin,
    checkedTaskEnd,
    setCheckedTaskEnd,
  } = useContext(useStateContext);

 useEffect(
    ()=>{
      setCheckedTaskBegin(false);
      setCheckedTaskEnd(false);
      if (selectedTask && checkedPersonalTask) {
      if  (selectedTask.begin==="01-01-1970 00:00:00") setCheckedTaskBegin(true);
      if  (selectedTask.end==="01-01-2099 00:00:00") setCheckedTaskEnd(true);
      } else {
        setCheckedPersonalTask(false);
      }
    },[selectedTask,isShownTaskInfoPlate]
  ) 

  /*
  useEffect(
    ()=>{
      if (checkedPersonalTask) {
      setCheckedTaskBegin(false);
      setCheckedTaskEnd(false)
      }
    },[setCheckedPersonalTask]
  ) 
  */
  /*
  useEffect(
    ()=>{
      if (!selectedTask){
      setCheckedPersonalTask(false);
      setCheckedTaskBegin(false);
      setCheckedTaskEnd(false)}
    },[selectedTask]
  ) */

  // const [checkedTaskBegin, setCheckedTaskBegin] = useState<boolean>(false);

  // const [checkedTaskEnd, setCheckedTaskEnd] = useState<boolean>(false);

  function changeTaskBegin() {
    //************************************* */

    let dateNow = new Date();

    let dateNowString = `${dateNow
      .toLocaleString()
      .split(",")[0]
      .split(".")
      .join("-")}${dateNow.toLocaleString().split(",")[1]}`;

    if (selectedTask) {
      // если обновление задачи

      if (checkedTaskBegin === false) {
        // поменяется на на true
        setCheckedTaskBegin(true);
        updateTask(selectedTask!.id, { begin: "01-01-1970 00:00:00",private:true });
      }

      if (checkedTaskBegin === true) {
        // поменяется на на false
        setCheckedTaskBegin(false);
        updateTask(selectedTask!.id, { begin: dateNowString, private:true });
      }
    } else {
      // если создание новой задачи

      if (checkedTaskBegin === false) {
        // поменяется на на true
        setCheckedTaskBegin(true);
        dispatch(setTaskBegin("01-01-1970 00:00:00"));
      }

      if (checkedTaskBegin === true) {
        // поменяется на на false
        setCheckedTaskBegin(false);
        dispatch(setTaskBegin(dateNowString));
      }
    }
  }

  function changeTaskEnd() {
    //************************************* */

    let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

    let tomorrowString = `${tomorrow
      .toLocaleString()
      .split(",")[0]
      .split(".")
      .join("-")}${tomorrow.toLocaleString().split(",")[1]}`;

    if (selectedTask) {
      // если обновление задачи

      if (checkedTaskEnd === false) {
        // поменяется на true
        setCheckedTaskEnd(true);
        updateTask(selectedTask!.id, { end: "01-01-2099 00:00:00",private:true });
      }

      if (checkedTaskEnd === true) {
        // поменяется на false
        setCheckedTaskEnd(false);
        updateTask(selectedTask!.id, { end: tomorrowString, private:true });
      }
    } else {
      // если создание новой задачи

      if (checkedTaskEnd === false) {
        // поменяется на на true
        setCheckedTaskEnd(true);
        dispatch(setTaskEnd("01-01-2099 00:00:00"));
      }

      if (checkedTaskEnd === true) {
        // поменяется на на false
        setCheckedTaskEnd(false);
        dispatch(setTaskEnd(tomorrowString));
      }
    }
  }

  let location = useLocation();

  useEffect(() => {
    if (projectsSelect.list.length > 0 && projectsSelect.selected === null) {
      let index: number;
      for (index = 0; index < projectsSelect.list.length; ++index) {
        if (projectsSelect.list[index].text === "Быстрые задачи") {
          //  console.log("sections",sections);
          //  console.log("sectionSelect",sectionSelect);

          //  console.log(projectsSelect.list[index].value,projectsSelect.list[index].text);

          project = projectsSelect.list[index];
          projectId = +projectsSelect.list[index].value;
          //  project_id=projectId;

          let value = projectId;
          let params = { project_id: value };
          let section0 = sections.find((sec) => sec.project_id === value);
          if (section0) {
            section = section0;
          }
          if (section) params["project_section_id"] = section.id;
          if (selectedTask) {
            //    updateTask(selectedTask.id, params);
          } else {
            setTaskProject(value as number);
            fetchProjectSections(value as number);
          }

          let value2: number | null = null;

          if (projectId) {
            if (sections && sections.length > 0) {
              let index2: number;
              for (index2 = 0; index2 < sections.length; ++index2) {
                if (+sections[index2].project_id == projectId) {
                  if (sections[index2].name === "Основной раздел") {
                    if (sections[index2].id && sections[index2].id !== 0)
                      value2 = sections[index2].id;
                    //   console.log("value2",value2);
                  }
                }
              }
            }
          }

          if (selectedTask) {
          } else setTaskSection(value2 as number);
        }
      }
      /*
let value= 162;
 
 if (selectedTask) {

} else setTaskSection(value as number);

*/

      /*
    if (projectId) {
    //  console.log("*sectionSelectOR1",sectionSelectOR)    

      if((sections) && (sections.length>0)){
        let index2:number;
     //   console.log("*sectionSelectOR2",sectionSelectOR)  
        for (index2 = 0; index2 < sections.length; ++index2) {
          if (+sections[index2].project_id == projectId){
       //     console.log("*sectionSelectOR3",sectionSelectOR)    
          if (sections[index2].name ==='Основной раздел'){
          sectionSelectOR=  {text:"",value:0};
          
          sectionSelectOR.text = sections[index2].name;
          sectionSelectOR.value =  +sections[index2].id
          if(sections[index2].id && sections[index2].id!==0) project_section_id= sections[index2].id;
       //   console.log("*sectionSelectOR4",sectionSelectOR)    
          }
          }
      }
    }





    }

*/
    }
    // console.log("project_id",project_id);
    // console.log(" project_section_id", project_section_id);
    // console.log("projectsSelect.list",projectsSelect);
  }, [schedule]);

  //},[isShownTaskInfoPlate,project_id])
  //************************************ */

  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<Item[]>([]); // Сотрудники, которые выбраны в чипсах
  const [cyclicTaskInfo, setCyclicTaskInfo] = useState<any>(null);
  const [applyBtnRepeatBlock, setApplyBtnRepeatBlock] =
    useState<boolean>(false);
  const taskInfoPlate = useSelector((state: State) => state.taskInfoPlate);

  // let btnAddFile = useRef<HTMLDivElement>(null);
  const weekDays: { day: number; name: string }[] = [
    { day: 1, name: "Пн" },
    { day: 2, name: "Вт" },
    { day: 3, name: "Ср" },
    { day: 4, name: "Чт" },
    { day: 5, name: "Пт" },
    { day: 6, name: "Сб" },
    { day: 7, name: "Вс" },
  ];

  const disabledComponent =
    currentUser?.id === selectedTask?.author_id ||
    currentUser?.id === selectedTask?.executor_id ||
    !selectedTask
      ? false
      : currentUser?.roles[0] !== 1;
  // компоненты ОТКЛЮЧЕНЫ, если: пользователь, из под которого смотришь на задачу (не автор, не исполнитель, не администратор)

  useEffect(() => {
    if (!isShownTaskInfoPlate) setSelectedItems([]);
  }, [isShownTaskInfoPlate]);

  useEffect(() => {
    setApplyBtnRepeatBlock(false);
  }, [taskInfoActiveTab]);

  useEffect(() => {
    (async () => {
      if (selectedTask?.cyclic_task_id) {
        let cycleInfo = await fetchData.get(
          `/api/v1/cyclic-tasks/` + selectedTask?.cyclic_task_id
        );
        if (cycleInfo) {
          if (cycleInfo.params === null) cycleInfo["params"] = [];
          if (cycleInfo.end_count)
            cycleInfo["end_count"] = JSON.parse(cycleInfo.end_count)[0]; // из стринга-массива вытаскиваю первое значение
          if (cycleInfo.end_date) {
            cycleInfo["end_date"] =
              cycleInfo.end_date.substr(8, 2) +
              "-" +
              cycleInfo.end_date.substr(5, 2) +
              "-" +
              cycleInfo.end_date.substr(0, 4) +
              " 00:00:00";
          }
          if (typeof cycleInfo.params === "string") {
            cycleInfo["params"] = JSON.parse(cycleInfo.params);
          }
          setCyclicTaskInfo(cycleInfo);

          ///////////////     генерирование полного названяия для строки состояния цикла
          let result_string = "";
          switch (cycleInfo.period) {
            case "every day":
              result_string += "Каждый";
              break;
            case "every week":
              result_string += "Каждую";
              break;
            case "every month":
              result_string += "Каждый";
              break;
          }

          if (cycleInfo.interval.toString() === "1") result_string += " ";
          else result_string += " " + cycleInfo.interval + " ";

          switch (cycleInfo.period) {
            case "every day":
              result_string += "день";
              break;
            case "every week":
              result_string += "неделю";
              break;
            case "every month":
              result_string += "месяц";
              break;
          }

          if (cycleInfo.params.length) {
            if (cycleInfo.period === "every week") {
              result_string += " в: ";
              cycleInfo.params.forEach((item, number) => {
                let found_day = weekDays.find((day) => day.day === item);
                result_string += (number === 0 ? "" : ", ") + found_day?.name;
              });
            } else if (cycleInfo.period === "every month") {
              result_string += " по: ";
              cycleInfo.params.forEach((item, number) => {
                result_string += (number === 0 ? "" : ", ") + item;
              });
            }
          }

          result_string = result_string.replace(/\s\s+/g, " "); // удалять двойные пробелы

          ///////////////

          // запись в редюсер всего по repeat
          setStartCyclick(
            cycleInfo.period,
            cycleInfo.params === null ? [] : cycleInfo.params,
            cycleInfo.interval,
            false,
            result_string
          );
          setFinishCyclick(
            cycleInfo.ending_condition,
            cycleInfo.end_count !== null ? cycleInfo.end_count : null,
            cycleInfo["end_date"]
          );
        }
      } else {
        setStartCyclick("", [], 1, false, "");
        setFinishCyclick("never", null, null);
        setCyclicTaskInfo(null);
        setApplyBtnRepeatBlock(false);
      }
    })();
  }, [selectedTask?.id, selectedTask?.cyclic_task_id, parameter_trigger]);

  useEffect(() => {
    // setTaskDescription(selectedTask?.description as string);
    setApplyBtnRepeatBlock(false);
  }, [selectedTask?.id]);

  useEffect(() => {
    // нужно, чтобы в редюсере было значение первого пользователя в чипсине, пока нет selectedTask
    if (selectedItems.length) {
      if (!executor_id) {
        setExecutorId(selectedItems[0].value as number);
        setTimeout(() => recalculateTaskLoad(), 500);
      }
    } else setExecutorId(null);
  }, [selectedItems]);

  useEffect(() => {
    if (selectedTask) {
      if (cyclicTaskInfo) {
        //////// проверка поля params, потому что это массив, и при простом сравнении после изменения, он дает неверный результат
        let params_not_equals = false;
        if (
          Object.keys(repeat.params).length !==
          Object.keys(cyclicTaskInfo.params).length
        ) {
          params_not_equals = true;
        } else {
          Object.keys(repeat.params).forEach((each) => {
            let match = false;
            Object.keys(cyclicTaskInfo.params).forEach((key) => {
              if (each === key) match = true;
            });
            if (!match) params_not_equals = true;
          });
        }
        ////////
        // проверка, был ли изменен хоть 1 параметр в блоке с повторениями
        if (
          repeat.end_count !== cyclicTaskInfo.end_count ||
          repeat.end_date !== cyclicTaskInfo.end_date ||
          repeat.ending_condition !== cyclicTaskInfo.ending_condition ||
          repeat.interval !== cyclicTaskInfo.interval ||
          repeat.period !== cyclicTaskInfo.period ||
          params_not_equals
        ) {
          setApplyBtnRepeatBlock(true);
        } else {
          setApplyBtnRepeatBlock(false);
        }
      } else {
        if (repeat.period !== "") {
          // задача создана, она еще не циклическая, но какой то параметр уже установлен
          setApplyBtnRepeatBlock(true);
        }
        // else {
        //   setApplyBtnRepeatBlock(true);
        // }
      }
    }
  }, [repeat]);

  // дедлайн по умолчанию- через сутки после начала задачи
  useEffect(() => {
    dispatch(
      updateTaskInfoPlate({
        ...taskInfoPlate,
        begin: dateFormatUTC(Date.now()),
        end: dateFormatUTC(Date.now() + 3600000 * 24),
      })
    );
  }, [isShownTaskInfoPlate]);

  const styleDiv = document.querySelector(".main_div");

  if (id_bp) {
    styleDiv?.setAttribute("style", "pointer-events: none");
  }

  return (
    <div className="main_div">
      <div className={"full_width_components"}>
        <div className={"description"}>
          <TextareaAutosize
            disabled={
              selectedTask && selectedTask.id_bp !== null ? true : false
            }
            aria-label="description_textarea_show"
            placeholder="Введите описание*"
            className={"input_div collapsed"}
            rowsMin={2}
            onFocus={(e) => {
              if (e.currentTarget.classList.length) {
                for (let i = 0; i < e.currentTarget.classList.length; i++) {
                  if (e.currentTarget.classList[i] === "collapsed") {
                    e.currentTarget.classList.remove("collapsed");
                  }
                }
              }
            }}
            onChange={(e) => {
              if (!disabledComponent) setTaskDescription(e.currentTarget.value);
            }}
            onBlur={(e) => {
              if (selectedTask) {
                e.currentTarget.classList.add("collapsed");
                if (!disabledComponent) {
                  if (e.target.value !== selectedTask?.description) {
                    // мгновенно обновляю значение для заколлапсированного состояния
                    if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                      // сравнение одного с несколькими
                      // эта задача вообще циклическая или сама по себе ?
                      // значит циклическая

                      // больше не нужно запоминать выбор в текущей сессии работы с задачей

                      // if (flagForActionCyclycTask === '') {
                      setCyclicTaskToChange({ description: e.target.value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                      // } else if (flagForActionCyclycTask === 'task') {
                      //     updateTask(selectedTask.id, {
                      //         description: e.target.value,
                      //     });
                      // } else {
                      //     updateTask(selectedTask.id, {
                      //         description: e.target.value,
                      //     });
                      //     sendReqToChangeTemplRepTask({
                      //         description: e.target.value,
                      //     });
                      // }
                    } else {
                      updateTask(selectedTask.id, {
                        description: e.target.value,
                      });
                    }
                  }
                }
              } else setTaskDescription(e.target.value);
            }}
            value={description}
          />
        </div>

        <CheckListModule
          action={(
            orderNumber,
            nameItem,
            checkboxValue,
            actionType,
            onlyChangeReducer
          ) => {
            if (selectedTask) {
              if (onlyChangeReducer) {
                changeReducerCheckboxItem(
                  orderNumber,
                  nameItem,
                  checkboxValue,
                  actionType
                );
              } else {
                dispatchCheckboxItem(
                  orderNumber,
                  nameItem,
                  checkboxValue,
                  actionType
                );
              }
            } else {
              changeReducerCheckboxItem(
                orderNumber,
                nameItem,
                checkboxValue,
                actionType
              );
            }
          }}
          checkList={checkList}
          disabled={selectedTask && selectedTask.id_bp !== null ? true : false}
          /*  disabled={
            selectedTask
              ? currentUser?.id === selectedTask?.author_id ||
                currentUser?.id === selectedTask?.executor_id
                ? false
                : currentUser?.roles[0] !== 1
              : false
          }*/
        />
      </div>

      <div className="info_tab">
        {/************************************************************************************************** */}
        <NameWithInput
          disabled={selectedTask && selectedTask.id_bp !== null ? true : false}
          title="Проект*"
          iconName="folder"
          // disabled={delegated}
        >
          <SelectWithSecondaryBtnProject // Для проектов- свой модуль. Данные о количестве проектов передаются в -> SelectWithSecondaryBtnProject -> SelectBtnProject, а там выводится предупреждение если нет проектов
            projectSelect={projectsSelect.list.length} // передаем - сколько проектов выбранно. Если 0- выведем предупреждение.
            disabled={
              (selectedTask && selectedTask.id_bp !== null) ||
              checkedPersonalTask
                ? true
                : false
            } //{disabledComponent}
            list={projectsSelect.list.sort((a, b) =>
              a.text > b.text ? 1 : -1
            )}
            selected={
              projectsSelect.selected === null
                ? project
                : projectsSelect.selected
            }
            selectHandler={(value, target) => {
              let params = { project_id: value };
              let section = sections.find((sec) => sec.project_id === value);
              if (section) params["project_section_id"] = section.id;
              if (selectedTask) {
                if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                  // сравнение одного с несколькими
                  // эта задача вообще циклическая или сама по себе ?
                  // значит циклическая

                  // больше не нужно запоминать выбор в текущей сессии работы с задачей

                  // if (flagForActionCyclycTask === '') {
                  setCyclicTaskToChange(params); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                  // } else if (flagForActionCyclycTask === 'task') {
                  //     updateTask(selectedTask.id, params);
                  // } else {
                  //     updateTask(selectedTask.id, params);
                  //     sendReqToChangeTemplRepTask(params);
                  // }
                } else {
                  updateTask(selectedTask.id, params);
                }
              } else {
                setTaskProject(value as number);
                fetchProjectSections(value as number);
              }
            }}
          />
        </NameWithInput>
        {/*
    selected={sectionSelect.selected===null?sectionSelectOR:sectionSelect.selected }
         ********************************************************************************************* */}
        <NameWithInput
          title="Раздел*"
          inputName={"sectionSelect"}
          iconName="folder"
        >
          <SelectWithSecondaryBtn
            list={sectionSelect.list}
            selected={sectionSelect.selected}
            disabled={
              (selectedTask && selectedTask.id_bp !== null) ||
              checkedPersonalTask
                ? true
                : false
            }
            //disabled={disabledComponent || !sectionSelect.list.length}
            selectHandler={(value) => {
              if (selectedTask) {
                // проверить шаблон и спросить про изменение
                if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                  // сравнение одного с несколькими
                  // эта задача вообще циклическая или сама по себе ?
                  // значит циклическая

                  // больше не нужно запоминать выбор в текущей сессии работы с задачей

                  // if (flagForActionCyclycTask === '') {
                  setCyclicTaskToChange({ project_section_id: value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                  // } else if (flagForActionCyclycTask === 'task') {
                  //     updateTask(selectedTask.id, {project_section_id: value});
                  // } else {
                  //     updateTask(selectedTask.id, {project_section_id: value});
                  //     sendReqToChangeTemplRepTask({project_section_id: value});
                  // }
                } else {
                  updateTask(selectedTask.id, { project_section_id: value });
                }
              } else setTaskSection(value as number);
            }}
          />
          <ProjectSectionsForTask
            sections={sectionSelect.list}
            projectId={
              projectsSelect.selected === null
                ? project?.value
                : projectsSelect.selected.value
            }
          />
        </NameWithInput>
        <NameWithInput
          title="Приоритет"
          iconName="prioritySelect"
          disabled={selectedTask && selectedTask.id_bp !== null ? true : false}
        >
          <Prioritys
            disabled={
              selectedTask && selectedTask.id_bp !== null ? true : false
            }
            bigSize
          />
        </NameWithInput>
        <NameWithInput title="Автор" iconName="people">
          <WrapperChipsBlock>
            <Chips
              image={usersSelect?.author?.urlImage}
              // text={`${author?.surname} ${author?.name}
              // ${author?.patronymic === null ? '' : author?.patronymic}`}
              text={usersSelect.author?.text}
              disabled
            />
          </WrapperChipsBlock>
        </NameWithInput>

        <NameWithInput title="Исполнитель" iconName="people">
          <ExecutorSelect
            disabled={
              (selectedTask && selectedTask.id_bp !== null) ||
              checkedPersonalTask
                ? true
                : false
            }
            checkedPersonalTask={checkedPersonalTask}
            selectedItems={selectedItems}
            setSelectedItems={(arg) => setSelectedItems(arg)}
          />
        </NameWithInput>

        {selectedTask &&
          selectedTask?.executor_id === currentUser?.id &&
          Boolean(selectedTask.provide_to) && (
            <NameWithInput title="Делегировать" iconName="arrow-right">
              <Delegate
                selectedItems={selectedItems}
                setSelectedItems={(arg) => setSelectedItems(arg)}
              />
            </NameWithInput>
          )}

        {/* компонент для заведения даты начала задачи */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <NameWithInput title="Начало" iconName="calendar-dark">
            <div style={{ width: "232px" }}>
            { !checkedTaskBegin &&
              <TaskBegin
                checkedPersonalTask={checkedPersonalTask}
                checkedTaskBegin={checkedTaskBegin}
                disabled={
                  (selectedTask && selectedTask.id_bp !== null) ||
                  checkedTaskBegin
                    ? true
                    : false
                }
              /> }
            </div>
          </NameWithInput>

          {checkedPersonalTask && (
            <div style={{ marginRight: "60px", paddingTop: "20px" }}>
              <span
                style={{ cursor: "pointer", marginRight: "10px" }}
                onClick={changeTaskBegin}
              >
                {" "}
                Без даты
              </span>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                checked={checkedTaskBegin}
                onChange={changeTaskBegin}
              ></input>
            </div>
          )}
        </div>

        {/* компонент для заведения даты окончания задачи */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <NameWithInput title="Дедлайн" iconName="calendar-dark">
            <div style={{ width: "232px" }}>
            {!checkedTaskEnd &&
              <TaskEnd
                checkedPersonalTask={checkedPersonalTask}
                checkedTaskEnd={checkedTaskEnd}
                disabled={
                  (selectedTask && selectedTask.id_bp !== null) ||
                  checkedTaskEnd
                    ? true
                    : false
                }
              />}
            </div>
          </NameWithInput>

          {checkedPersonalTask && (
            <div style={{ marginRight: "60px", paddingTop: "20px" }}>
              <span
                style={{ cursor: "pointer", marginRight: "10px" }}
                onClick={changeTaskEnd}
              >
                {" "}
                Без даты
              </span>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                checked={checkedTaskEnd}
                onChange={changeTaskEnd}
              ></input>
            </div>
          )}
        </div>

        {/*repeat task*/}
        <NameWithInput title="Повторять" iconName="repeat">
          <RepeatTask
            disabledComponent={
              (selectedTask && selectedTask.id_bp !== null ? true : false) ||
              (currentUser?.id !== selectedTask?.author_id &&
                selectedTask !== null) ||
              (cyclicTaskInfo !== null &&
                currentUser?.id !== cyclicTaskInfo?.author_id)
            }
          />
        </NameWithInput>

        {repeat.period && (
          <NameWithInput title="Закончить повтор" iconName="repeat">
            <EndRepeatTask
              disabledComponent={
                (currentUser?.id !== selectedTask?.author_id &&
                  selectedTask !== null) ||
                (cyclicTaskInfo !== null &&
                  currentUser?.id !== cyclicTaskInfo?.author_id)
              }
            />
          </NameWithInput>
        )}

        {applyBtnRepeatBlock && (
          <NameWithInput title="" iconName="repeat">
            <Button
              style={{ marginLeft: 144 }}
              title={"Применить"}
              onClick={() => {
                sendReqToChangeTemplRepTask({ ...repeat });
              }}
            />
          </NameWithInput>
        )}
        {/*repeat task*/}

        {!selectedTask && (
          <NameWithInput title="Прикрепить файл" iconName="clip">
            <div>
              <input
                onChange={(e) => {
                  let files = e.currentTarget.files;
                  if (!files) return;
                  for (let i = 0; i < files?.length; i++) uploadFile(files[i]);
                }}
                type="file"
                id="comment_files"
                style={{ display: "none" }}
                multiple={true}
              />

              <label htmlFor="comment_files" className={"info_attach_file"}>
                Выбрать
              </label>

              {commentFiles.length > 0 && (
                <div className={"attachments"} style={{ width: "156px" }}>
                  <div style={{ height: "10px" }}> </div>
                  {commentFiles.map((elem) => (
                    <FileElem
                      url={elem.url}
                      key={elem.id}
                      id={elem.id}
                      name={elem.name}
                      deleteHandler={deleteFile}
                    />
                  ))}
                </div>
              )}
            </div>
          </NameWithInput>
        )}

        {/* Тут выбирается поле "загрузка" */}
        <NameWithInput
          title="Загрузка"
          inputName={"TaskWorkLoad"}
          iconName="clock"
        >
          <TaskWorkLoad
            disabled={
              (checkedPersonalTask || (selectedTask && selectedTask?.id_bp !== null)) ||
              disabledComponent
            }
          />
        </NameWithInput>

        {/* Переход в дерево проектов  */}

        {/* ******************************************************************** */}
        {(selectedTask
          ? selectedTask.child_tasks
            ? selectedTask.child_tasks.length > 0 ||
              selectedTask.parent_id !== null
              ? true
              : false
            : false
          : false) && (
          <NameWithInput title="Дерево проектов" iconName="arrow-right">
            <Button
              style={{ color: "black", background: "#eee" }}
              title={"Перейти в дерево проектов"}
              onClick={() => {
                if (selectedTask) {
                  localStorage.setItem("taskChoice", "true");
                  localStorage.setItem("taskId", String(selectedTask.id));

                  localStorage.setItem("project_show_toggle", "tree");

                  let id = selectedTask.project_id;
                  window.location.replace(`/projects/${id}`);
                } else {
                  window.location.replace(`/projects`);
                }
              }}
            />
          </NameWithInput>
        )}

        {/* ******************************************************************** */}

        {/* ***************** */}
        {selectedTask && selectedTask.id_bp === null && (
          <NameWithInput title="Зависимости" iconName="arrow-right">
            <Dependencies />
          </NameWithInput>
        )}

        {!selectedTask && (
          <div className={"taskInfoPlateBottomRowButtons"}>
            <Button
              title="Отмена"
              design="secondary"
              onClick={() => {
                setDefaultValues();
                dispatch(setShownTaskInfoPlate(false));
                // if (!isSendingDelegate) {
                //     setIsSendingDelegate(true)
                //     let users_ids = selectedItems.map(item => (item.value))
                //     delegateConfirm(users_ids as number[])
                // }
              }}
            />

            {/********************************** */}
            {/* <Button
              title={"Сохранить идею"}
              style={{ marginLeft: "8px" }}
              isSending={status === "idea" ? isSendingCreateNewTasks : false}
              disabled={
                !name?.length ||
                !description?.length ||
                !project_id ||
                !project_section_id ||
                !selectedItems.length // исполнители
              }
              onClick={() => {
                status = "idea";
                if (!isSendingCreateNewTasks) {
                  isSendingNewTask(true);
                  successfulCreatedNewTasks({
                    createdTasks: [],
                    executorsIds: selectedItems.map((item) => item.value),
                    checkItems: [...checkList],
                  });
                  selectedItems.forEach((item) => {
                    createTask(item.value as number);
                  });
                }
              }}
            /> */}

            <Button
              title={"Сохранить задачу"}
              style={{ marginLeft: "8px" }}
              isSending={status === "task" ? isSendingCreateNewTasks : false}
              disabled={
                !name?.length ||
                !description?.length ||
                !project_id ||
                !project_section_id ||
                !selectedItems.length // исполнители
              }
              onClick={() => {
                //  dispatch(setTaskPrivate(true));
                status = "task";
                if (!isSendingCreateNewTasks) {
                  isSendingNewTask(true);
                  successfulCreatedNewTasks({
                    createdTasks: [],
                    executorsIds: selectedItems.map((item) => item.value),
                    checkItems: [...checkList],
                  });
                  selectedItems.forEach((item) => {
                    createTask(item.value as number);
                  });
                }

               // dispatch(setShownTaskInfoPlate(false));

             if (location.pathname.match("/projects")) {
                  setTimeout(() => window.location.reload(), 500);
                }
              }}
            />
            {/********************************** */}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: getSelectedTask(state),
    currentUser: state.commonInfo.currentUserInfo,
    executor_id: state.taskInfoPlate.executor_id,
    taskInfoActiveTab: state.taskInfoPlate.taskInfoActiveTab,
    projectsSelect: getProjectsSelectList(state),
    usersSelect: getUsersSelectList(state),
    sectionSelect: getSectionSelectList(state),
    sections: state.commonInfo.sections,
    schedule: state.taskInfoPlate.executorSchedule,
    checkList: state.taskInfoPlate.checkList,
    name: state.taskInfoPlate.name,
    description: state.taskInfoPlate.description,
    project_id: state.taskInfoPlate.project_id,
    project_section_id: state.taskInfoPlate.project_section_id,
    commentFiles: state.taskInfoPlate.commentFiles,
    repeat: state.taskInfoPlate.repeat,
    isSendingCreateNewTasks: state.taskInfoPlate.isSendingCreateNewTasks,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    // rememberObjectCyclicTaskToChange: state.taskInfoPlate.rememberObjectCyclicTaskToChange,
    // flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
    parameter_trigger: state.taskInfoPlate.parameter_trigger,
    priorities: state.commonInfo.priorities,
    id_bp: state.taskInfoPlate.id_bp,
  };
};

const mapDispatchToProps = {
  setTaskPrivate,
  updateTask,
  dispatchCheckboxItem,
  changeReducerCheckboxItem,
  setTaskDescription,
  setTaskProject,
  fetchProjectSections,
  setTaskSection,
  setExecutorId,
  createTask,
  setDefaultValues,
  uploadFile,
  deleteFile,
  successfulCreatedNewTasks,
  isSendingNewTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
  setStartCyclick,
  setFinishCyclick,
  // deleteCheckboxItem,
  // dispatchActionForItem
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoTab);
