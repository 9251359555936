import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { StatusContext } from "../../../context/status";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import AddFileButton from "../templates/AddFileButton";
import RadioButtons from "../templates/RadioButtons";
import NameOfStep from "../templates/NameOfStep";
import "../BpResultForm.scss";

let fileTranslate;

const SigningOfTreatyDismissal = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  console.log(settingsOfFields);
  const [data, setData] = useState(
    settingsOfFields?.signingOfTreatyDismissal?.extra
      ? settingsOfFields.signingOfTreatyDismissal.extra
      : {}
  );
  const [allFilesSigningTreatySend1, setAllFilesSigningTreatySend1] = useState(
    []
  );

  const [allFilesSigningTreaty1, setAllFilesSigningTreaty1] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  const url = taskId ? `${apiBp}/task/${taskId}` : null;

  //Добавляет файлы
  const onSendFileSigningTreaty = (e, numberFilesSigningTreaty) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesSigningTreaty === "filesSigningTreaty1") {
        setAllFilesSigningTreaty1([
          ...allFilesSigningTreaty1,
          {
            data: fileTranslate,
            id: nanoid(),
            name: file.name.match(/(.*)\.[^.]+$/)[1],
            ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
            tag: "filesSigningTreaty1",
          },
        ]);
      }
    }, [500]);
  };

  //Удаляет файлы
  const onDeletedFileSigningTreaty = (keyId, numberFilesSigningTreaty) => {
    if (numberFilesSigningTreaty === "filesSigningTreaty1") {
      setAllFilesSigningTreaty1(
        allFilesSigningTreaty1.filter((file) => file.id !== keyId)
      );
    }
  };

  //Смотрит на какую кнопку какого рездела с файлами было нажато
  const onClickFetchFilesSigningTreaty = (e, numberFilesSigningTreaty) => {
    e.preventDefault();

    console.log(numberFilesSigningTreaty);

    let data;

    if (numberFilesSigningTreaty === "filesSigningTreaty1") {
      data = JSON.stringify({
        files: allFilesSigningTreaty1,
      });
      console.log("Нажал на кнопку для отправки файлов filesSigningTreaty1");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesSigningTreaty === "filesSigningTreaty1") {
      setAllFilesSigningTreaty1([]);
    }
  };

  // console.log(taskId);
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          {
            ...settingsOfFields.signingOfTreatyDismissal,
            extra: data,
          },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);
  return (
    <>
      <div>
        <NameOfStep text={"Прикрепить документы"} />
        <div className="file-uploaded__block">
          <ul>
            {taskCurrentGlobal &&
              taskCurrentGlobal["files"] &&
              taskCurrentGlobal["files"]["list"]
                .filter((item) => item.tag == "filesSigningTreaty1")
                .map((item2) => (
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                      target="_blank"
                    >
                      {item2.name}
                    </a>
                  </li>
                ))}
          </ul>
        </div>
        <AddFileButton
          filesList={taskCurrentGlobal}
          data={data}
          setData={setData}
          id={"с3"}
          handlerOnSendFile={onSendFileSigningTreaty}
          handlerOnDeletedFile={onDeletedFileSigningTreaty}
          handlerOnClickFetchFiles={onClickFetchFilesSigningTreaty}
          allFiles={allFilesSigningTreaty1}
          numberFiles={"filesSigningTreaty1"}
        />
      </div>
      <div>
        <NameOfStep text={"Номер задачи"} />
        <TextAreaOrInputElement
          name={"c3_1"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Согласование"} />

        <RadioButtons
          data={data}
          setData={setData}
          name={"c3_2"}
          positiveText={"Согласовано"}
          negativeText={"Не согласовано"}
        />
      </div>
    </>
  );
};

export default SigningOfTreatyDismissal;
