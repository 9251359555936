import React, { useContext } from "react";
import { CreateNotif } from "../../../../../../utils/createNotification";
import { StatusContext } from "../../../context/status";
import "../BpResultForm.scss";
//
const RadioButtons = ({
  name,
  positiveText,
  negativeText,
  otherText,
  data,
  setData,
}) => {
  const {
    isAdmin,
    loadDataNow,
    setloadDataNow,
    statusOfTask,
    taskId,
    executorIdofTask,
    userId,
  } = useContext(StatusContext);
  return (
    <div
      className={
        statusOfTask === 0 || statusOfTask === 2
          ? "we-supply"
          : "we-supply unavailableForm" //не дает редактировать форму в зависимости от статуса
      }
    >
      <div>
        <input
          key={name}
          className="input-form"
          type="radio"
          id={`${name}__yes`}
          name={name}
          value={positiveText}
          checked={data[name] === positiveText}
          onChange={(e) => {
            if (executorIdofTask === userId || isAdmin) {
              setloadDataNow((loadDataNow) => !loadDataNow);
              setData((prevState) => {
                return { ...prevState, [e.target.name]: positiveText };
              });
            } else {
              CreateNotif("У вас нет полномочий для данного действия");
            }
          }}
        />
        <label htmlFor={`${name}__yes`} className="we-supply__radio"></label>
        <label htmlFor={`${name}__yes`}>{positiveText}</label>
      </div>
      <div>
        <input
          key={name}
          className="input-form"
          type="radio"
          id={`${name}__no`}
          name={name}
          value={false}
          checked={data[name] === negativeText}
          onClick={(e) => {
            if (executorIdofTask === userId || isAdmin) {
              setData((prevState) => {
                return { ...prevState, [e.target.name]: negativeText };
              });
            } else {
              CreateNotif("У вас нет полномочий для данного действия");
            }
          }}
        />
        <label htmlFor={`${name}__no`} className="we-supply__radio"></label>
        <label htmlFor={`${name}__no`}>{negativeText}</label>
      </div>
      {otherText && (
        <>
          <div>
            <input
              key={name}
              className="input-form"
              type="radio"
              id={`${name}__other3`}
              name={name}
              value={negativeText}
              checked={data[name] === otherText}
              onClick={(e) => {
                if (executorIdofTask === userId || isAdmin) {
                  setData((prevState) => {
                    return { ...prevState, [e.target.name]: otherText };
                  });
                } else {
                  CreateNotif("У вас нет полномочий для данного действия");
                }
              }}
            />
            <label
              htmlFor={`${name}__other3`}
              className="we-supply__radio"
            ></label>
            <label htmlFor={`${name}__other3`}>{otherText}</label>
          </div>
        </>
      )}
      {!data[name] &&
        taskId &&
        (statusOfTask === 0 || statusOfTask === 2) &&
        (executorIdofTask === userId || isAdmin) && (
          <h2 className="error-message-in-forms-check" style={{ color: "red" }}>
            Выберите значение
          </h2>
        )}
    </div>
  );
};

export default RadioButtons;
