import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTask } from '../../../../common/actions';
import { State } from '../../../../rootReducer';
import { fetchTaskDependence } from '../../../Tasks/actions';
import {
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setNextId,
} from '../../actions';
import Dependence from '../Dependence';

const DependenceNext = ({ nextTask }) => {
  const dispatch = useDispatch();
  const { selectedTask, flagForActionCyclycTask } = useSelector(
    (state: State) => state.taskInfoPlate
  );

  return (
    <Dependence
      task={nextTask}
      // id={nextTask.id}
      // status_id={nextTask.status_id}
      // name={nextTask.name}
      // dedline={{
      // begin: nextTask.begin,
      // end: nextTask.end,
      // }}
      icon="next-dep"
      // priority_id={nextTask.priority_id}
      dependence="next"
      // load={nextTask.task_load}
      onDelete={() => {
        if (selectedTask) {
          if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
            // сравнение одного с несколькими
            // эта задача вообще циклическая или сама по себе ?
            // значит циклическая
            if (flagForActionCyclycTask === '') {
              dispatch(setCyclicTaskToChange({ next_id: null })); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
            } else if (flagForActionCyclycTask === 'task') {
              dispatch(updateTask(selectedTask.id, { next_id: null }));
            } else {
              dispatch(updateTask(selectedTask.id, { next_id: null }));
              dispatch(sendReqToChangeTemplRepTask({ next_id: null }));
            }
          } else {
            dispatch(updateTask(selectedTask.id, { next_id: null }));
          }
          setTimeout(() => {
            dispatch(fetchTaskDependence(selectedTask!.id));
          }, 250);
        } else dispatch(setNextId(null));
      }}
    />
  );
};

export default DependenceNext;
