import {
  types,
  changeTaskListAction,
  fetchTaskDependenceAction,
  changeStepAction,
  pricePartsAction,
  paidSuccessAction,
  paymentDetailsAction,
  setPaidAction,
  setFreeAction,
} from './action_types';
import { Task } from '../../common/types';

export const changeStep = (stepNumber: number): changeStepAction => ({
  type: types.CHANGE_STEP,
  stepNumber,
});

export const changeTaskList = (tasks: Task[]): changeTaskListAction => ({
  type: types.CHANGE_TASK_LIST,
  tasks,
});

export const fetchTaskDependence = (id: number): fetchTaskDependenceAction => ({
  type: types.FETCH_TASK_DEPENDENCE,
  id,
});

export const paidSuccess = (value): paidSuccessAction => ({
  type: types.PAID_SUCCESS,
  value,
});

export const paymentDetails = (value): paymentDetailsAction => ({
  type: types.PAYMENT_DETAILS,
  value,
});

export const changePriceParts = (
  members?: number,
  months?: number
): pricePartsAction => ({
  type: types.CHANGE_PRICE_PARTS,
  members,
  months,
});

export const setPaid = (data, pay, companyId): setPaidAction => ({
  type: types.SET_PAID,
  data,
  pay,
  companyId,
});
export const setFree = (companyId): setFreeAction => ({
  type: types.SET_FREE,
  companyId,
});
