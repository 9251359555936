import { all } from "redux-saga/effects";
import { watchFetchCreateCompany, watchFetchToken } from "./pages/Login/saga";
import { watchSetFreeCompany, watchSetPaidCompany } from "./pages/Payment/saga";
import {
  watchFetchTasks,
  watchSetStatusFilterTasks,
  watchFiltersActualToggle,
  watchSetTableOrderTasks,
  watchLoadSpecificTask,
  watchFetchTasksVisualization,
  watchReadTaskNotification,
  watcherUploadFile,
  watcherDeleteFile,
  watchSendCommentTasks,
  watchClosingDayDispatchAll,
  watchTimeSheetActions,
  watchFetchTaskDependence,
} from "./pages/Tasks/saga";
import {
  watchFetchProjectTasks,
  watchSetStatusFilterProjects,
  watchSetTableOrderProjects,
  watchFiltersActualToggleProjects,
} from "./pages/Projects/saga";
import {
  watchFetchCommonInfo,
  watchUpdateTask,
  watchFetchExistingUser,
  watchNotificationsRead,
  watchLoadNextPage,
  watchClearNotifications,
} from "./common/saga";
import {
  watchCreateProject,
  watchUploadFile,
  watchDeleteFile,
  watchRemoveProjectSection,
  watchChangeSectionName,
  watchUpdateProject,
  watchAddProjectSection,
  watchDeleteProject,
} from "./pages/NewProject/saga";
import {
  watchCreateUser,
  watchPasswordChange,
  watchPasswordReset,
  watchDeleteUser,
} from "./pages/NewUser/saga";
import {
  watchOpenDeps,
  watchCreateDep,
  watchFetchUsers,
  watchGetDepGroupUsers,
  watchAddUserToDepartment,
  watchAddUserToGroup,
  watchRemoveUserFromDepartment,
  watchRemoveUserFromGroup,
  watchCreateGroup,
  watchSetTableOrderUsers,
  watchUpdateDepGroupInfo,
  watch_delete_dep_group,
  watchGetNextPageAmongAll,
} from "./pages/Users/saga";
import {
  watchFetchSections,
  watchDelegateTask,
  watchSetRecalculateLoad,
} from "./pages/NewTask/saga";
import {
  watchDeleteComment,
  watchUploadCommentFile,
  watchDeleteCommentFile,
  watchSendComment,
  watchFetchProjectSections,
  watchGetComments,
  watchRecalculateLoad,
  watchDispatchCheckboxItem,
  watchDeligateConfirm,
  watchCreateTask,
  watchChangeTemplRepeatTask,
} from "./pages/TaskInfoPlate/saga";
import {
  watchCreateChecklistItems,
  watchCreateResolutionItem,
  watchCreatMeeting,
  watchEditMeeting,
  watchFetchMeetings,
  watchPatchResolutionItem,
} from "./pages/Meetings/saga";

export function* rootSaga() {
  yield all([
    watchPatchResolutionItem(),
    watchCreateResolutionItem(),
    watchFetchMeetings(),
    watchEditMeeting(),
    watchCreatMeeting(),
    watchCreateTask(),
    watchFetchToken(),
    watchFetchCreateCompany(),
    watchFetchTasks(),
    watchUpdateDepGroupInfo(),
    watchSetStatusFilterTasks(),
    watchLoadSpecificTask(),
    watchFetchProjectTasks(),
    watchSetStatusFilterProjects(),
    watchSetTableOrderProjects(),
    watchFiltersActualToggleProjects(),
    watchFetchCommonInfo(),
    watchCreateProject(),
    watchDeleteProject(),
    watchUploadFile(),
    watchDeleteFile(),
    watchCreateUser(),
    watchDeleteUser(),
    // watchOpenGroups(),
    watchOpenDeps(),
    watchCreateDep(),
    watchSetRecalculateLoad(),
    watchFetchUsers(),
    watchUpdateTask(),
    watchDeleteComment(),
    watchUploadCommentFile(),
    watchDeleteCommentFile(),
    watchSendComment(),
    watchGetDepGroupUsers(),
    watchFetchExistingUser(),
    watchAddUserToDepartment(),
    watchAddUserToGroup(),
    watchRemoveUserFromDepartment(),
    watchRemoveUserFromGroup(),
    watchCreateGroup(),
    watchRemoveProjectSection(),
    watchChangeSectionName(),
    watchUpdateProject(),
    watchAddProjectSection(),
    watchFetchSections(),
    watchFetchProjectSections(),
    watchDelegateTask(),
    watchPasswordChange(),
    watchFiltersActualToggle(),
    watchPasswordReset(),
    watchSetTableOrderTasks(),
    watchSetTableOrderUsers(),
    watchGetComments(),
    watchNotificationsRead(),
    watchLoadNextPage(),
    watchRecalculateLoad(),
    watchDispatchCheckboxItem(),
    watch_delete_dep_group(),
    watchGetNextPageAmongAll(),
    watchDeligateConfirm(),
    watchFetchTasksVisualization(),
    watchFetchTaskDependence(),
    watchChangeTemplRepeatTask(),
    watchClearNotifications(),
    watchReadTaskNotification(),
    watchTimeSheetActions(),
    watchClosingDayDispatchAll(),
    watcherDeleteFile(),
    watcherUploadFile(),
    watchSendCommentTasks(),
    watchSetFreeCompany(),
    watchSetPaidCompany(),
    watchCreateChecklistItems(),
    watchDeleteUser,
  ]);
}
