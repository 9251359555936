import React, {
	ChangeEvent,
	FC,
	RefObject,
	useState,
	useEffect,
} from 'react';
import { RegisterOptions } from 'react-hook-form';
import styled from "styled-components";

const CustomCheckbox = styled.input`
	width: 20px;
	height: 20px;
`;

type Props = {
	checked: boolean;
	id: number;
	register: (Ref: RefObject<any>, RegisterOptions?: RegisterOptions) => void;
};

const Selection: FC<Props> = ({ register, id, checked }) => {
	const [isChecked, setIsChecked] = useState<boolean>(false);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setIsChecked(event.target.checked);
	};

	useEffect(() => {
		setIsChecked(checked);
	}, []);

	return (
		<CustomCheckbox
			name={`${id}.checked`}
			ref={register}
			type='checkbox'
			onClick={handleChange}
			checked={isChecked}
			onChange={() => {}}
		/>
	);
};

export default Selection;