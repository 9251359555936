// Тут разрабатывается компонент "Календарь"
import React, { FC, useState, useEffect } from 'react';
import { SchedulerStatic } from 'dhtmlx-scheduler';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { fetchData } from '../../utils/fetchData';

import CalendarActions from './components/CalendarActions';
import Scheduler from './components/Scheduler';
import Lightbox from './components/Lightbox/Lightbox';

import {
  fetchTasks,
  changeTaskList,
  setSelectedUserId,
} from '../Tasks/actions';

import { selectTask } from '../TaskInfoPlate/actions';

import { State } from '../../rootReducer';
import { Task, TestTask } from '../../common/types';

import './style.sass';
import { cookieMaster } from '../../utils/CookieMaster';

declare global {
  interface Window {
    scheduler: SchedulerStatic;
  }
}

export interface IEvent {
  start_date: string;
  end_date: string;
  text: string;
  id: number;
  holder?: string;
}

export interface IRenderCalendarDates {
  view: string;
  currentDate: Date;
}

export interface ITypes {
  task: boolean;
  meeting: boolean;
  appointment: boolean;
}

interface ICalendar {
  events: Array<IEvent | Task>;
}

const test = [
  {
    begin: '2022-03-29T05:30:00+03:00',
    end: '2022-11-29T06:30:00+03:00',
    type: 'task',
    id: 123,
    data: {
      id: 834,
      name: 'Новое событие2',
      description: 'Описание нового события',
      begin: '29-03-2022 05:30:00',
      end: '29-11-2022 23:00:00',
      task_load: 1,
      work_load: 11.11,
      provide_to: 0,
      project_id: 83,
      project_section_id: 169,
      parent_id: null,
      next_id: 833,
      prev_id: null,
      author_id: 284,
      executor_id: 284,
      priority_id: 223,
      status_id: 10,
      status_related_user_id: null,
      workflow_id: 1,
      cyclic_task_id: 0,
      child_tasks: [],
      delegated_task: [],
      timesheets: [],
      task_load_sum: 0,
    },
  },
  {
    begin: '2022-03-31T19:36:31+03:00',
    end: '2022-11-31T23:36:31+03:00',
    type: 'task',
    id: 124,
    data: {
      id: 833,
      name: 'Разработка',
      description: 'Сделать календарь',
      begin: '31-03-2022 19:36:31',
      end: '01-11-2022 18:36:31',
      task_load: 4,
      work_load: 44.44,
      provide_to: 0,
      project_id: 83,
      project_section_id: 169,
      parent_id: null,
      next_id: 835,
      prev_id: 834,
      author_id: 284,
      executor_id: 284,
      priority_id: 224,
      status_id: 10,
      status_related_user_id: null,
      workflow_id: 1,
      cyclic_task_id: 0,
      child_tasks: [],
      delegated_task: [],
      timesheets: [],
      task_load_sum: 0,
    },
  },
  {
    begin: '2022-04-05T15:00:00+03:00',
    end: '2022-11-05T17:00:00+03:00',
    type: 'task',
    id: 125,
    data: {
      id: 835,
      name: 'Проверка создания быстрого',
      description:
        'Что нужно сделать: \n\nКак нужно сделать: \n    \nКакой должен быть результат:',
      begin: '05-04-2022 15:00:00',
      end: '05-11-2022 19:59:58',
      task_load: 2,
      work_load: 66.67,
      provide_to: 0,
      project_id: 83,
      project_section_id: 169,
      parent_id: null,
      next_id: null,
      prev_id: 833,
      author_id: 284,
      executor_id: 284,
      priority_id: 223,
      status_id: 10,
      status_related_user_id: null,
      workflow_id: 1,
      cyclic_task_id: 0,
      child_tasks: [],
      delegated_task: [],
      timesheets: [],
      task_load_sum: 0,
    },
  },
  {
    begin: '2022-04-06T09:00:57+03:00',
    end: '2022-11-06T09:55:00+03:00',
    type: 'task',
    id: 126,
    data: {
      id: 836,
      name: 'Предыдущая',
      description:
        'Что нужно сделать: \n\nКак нужно сделать: \n    \nКакой должен быть результат:',
      begin: '06-04-2022 09:00:57',
      end: '06-11-2022 09:55:00',
      task_load: 1,
      work_load: 5.5600000000000005,
      provide_to: 0,
      project_id: 83,
      project_section_id: 169,
      parent_id: null,
      next_id: 837,
      prev_id: null,
      author_id: 284,
      executor_id: 284,
      priority_id: 223,
      status_id: 10,
      status_related_user_id: null,
      workflow_id: 1,
      cyclic_task_id: 0,
      child_tasks: [],
      delegated_task: [],
      timesheets: [],
      task_load_sum: 0,
    },
  },
  {
    begin: '2022-06-07T23:06:00+03:00',
    end: '2022-11-08T00:06:00+03:00',
    type: 'task',
    id: 127,
    data: {
      id: 837,
      name: 'Следующая',
      description:
        'Что нужно сделать: \n\nКак нужно сделать: \n    \nКакой должен быть результат:',
      begin: '07-04-2022 23:06:00',
      end: '08-11-2022 23:06:00',
      task_load: 1,
      work_load: 11.11,
      provide_to: 0,
      project_id: 83,
      project_section_id: 169,
      parent_id: null,
      next_id: null,
      prev_id: 836,
      author_id: 284,
      executor_id: 284,
      priority_id: 223,
      status_id: 10,
      status_related_user_id: null,
      workflow_id: 1,
      cyclic_task_id: 0,
      child_tasks: [],
      delegated_task: [],
      timesheets: [],
      task_load_sum: 0,
    },
  },
];

const test2 = [
  {
    start_date: '07-04-2022 23:06:00',
    end_date: '08-11-2022 23:06:00',
    text: 'string',
    id: 1,
    holder: 'string',
  },
  {
    start_date: '07-04-2022 23:06:00',
    end_date: '08-11-2022 23:06:00',
    text: 'xtring',
    id: 3,
    holder: 'rtring',
  },
];

const scheduler = window.scheduler;

const Calendar: FC<ICalendar> = () => {
  const [data, setData] = useState<Array<IEvent | Task>>([]);
  const [types, setTypes] = useState<ITypes>({
    meeting: false,
    appointment: false,
    task: true,
  });
  const [changeDate, setChangeDate] = useState<number>(0);
  const [renderCalendarDates, setRenderCalendarDates] =
    useState<IRenderCalendarDates>({
      view: 'week',
      currentDate: new Date(),
    });
  const params = useParams<{ id: string }>();

  // useEffect(() => {
  // 	dispatch(changeTaskList([]));
  // }, [params.id, changeTaskList]);
  //
  // useEffect(() => {
  // 	const parseDate = scheduler.date.str_to_date("%Y-%m-%d");
  // 	dispatch(setSelectedUserId(+params.id));
  // 	dispatch(fetchTasks(pageNum));
  // 	setPageNum((prev) => prev + 1);
  // 	//
  // 	const filtred = tasks.map((el) => ({ ...el, start_date: parseDate(el.begin), end_date: parseDate(el.end), text: el.name, eventType: 'task' }));
  // 	setData(filtred);
  // 	dispatch(selectTask(null));
  // }, [params.id]);

  const getTypes = (types: ITypes): string => {
    const preTypes = Object.entries(types);
    const typesArray: string[] = [];
    preTypes.forEach((el) => {
      if (el[1]) {
        typesArray.push(el[0]);
      }
    });

    return typesArray.join(',');
  };

  const initData = async (begin: Date, end: Date, type: ITypes) => {
    try {
      const types = getTypes(type);
      // console.log('==========>types', types);
      const parseDate = scheduler.date.str_to_date('%Y-%m-%d');
      const beginDate = begin.toISOString();
      const endDate = end.toISOString();
      const query = `begin=${beginDate}&end=${endDate}&type=${types}&user=${params.id}`;
      const response: TestTask[] = await fetchData.get(
        `/api/v1/calendar?${query}`
      );

      const filter = response.map((el) => {
        return {
          ...el.data,
          taskId: el.data.id,
          start_date: el.begin,
          end_date: el.end,
          text: el.data.name,
          eventType: el.type,
          id: el.id,
        };
      });

      // const response: Task[] = await fetchData
      // 	.get('/api/v1/tasks?executor_id=746&order=visual&orderType=deadline');
      //
      // const filter = response.map((el) => {
      // 	return {
      // 		...el,
      // 		start_date: el.begin,
      // 		end_date: el.end,
      // 		text: el.name,
      // 		eventType: 'task',
      // 	};
      // })

      setData(filter);
    } catch (err) {}
  };

  useEffect(() => {
    const state = scheduler.getState();
    const currentDate = state.mode ? state.date : new Date();
    const view = state.mode || 'week';
    setRenderCalendarDates({
      view,
      currentDate,
    });
    initData(state.min_date as Date, state.max_date as Date, types);
  }, [changeDate, types]);

  return (
    <>
      <CalendarActions types={types} setTypes={setTypes} />
      <div className="scheduler-container">
        <Scheduler
          setChangeDate={setChangeDate}
          events={data}
          renderCalendarDates={renderCalendarDates}
        />
      </div>
      <Lightbox setChangeDate={setChangeDate} />
    </>
  );
};

export default Calendar;
