import React, { useContext, useEffect, useState } from "react";
import { CreateNotif } from "../../../../../../utils/createNotification";
import { StatusContext } from "../../../context/status";
import "../BpResultForm.scss";
import TextAreaOrInputElement from "./TextAreaOrInputElement";
//
const CheckboxElement = ({
  name,
  text,
  data,
  setData,
  arr,
  isNeedTextFieldsAfterEach,
}) => {
  const {
    isAdmin,
    loadDataNow,
    setloadDataNow,
    statusOfTask,
    taskId,
    executorIdofTask,
    userId,
  } = useContext(StatusContext);

  const [checkedElements, setCheckedElements] = useState(0);

  const dataValues = Object.values(data);

  useEffect(() => {
    if (arr) {
      const selectedValues = arr.filter((value) => {
        if (dataValues.includes(value)) {
          return true;
        } else {
          return false;
        }
      });
      setCheckedElements((prev) => selectedValues.length);
    }
  }, [dataValues, arr]);

  return (
    <div
      className={
        statusOfTask === 0 || statusOfTask === 2
          ? "we-supply"
          : "we-supply unavailableForm" //не дает редактировать форму в зависимости от статуса
      }
    >
      {arr ? (
        arr.map((listItem, index) => {
          return (
            <React.Fragment key={listItem}>
              <div>
                <input
                  style={{ visibility: "hidden", position: "absolute" }}
                  // className="input-form checkbox"
                  type="checkbox"
                  id={name + "__" + index}
                  name={name + "__" + index}
                  defaultChecked={!!data[name + "__" + index]}
                  onChange={(e) => {
                    if (executorIdofTask === userId || isAdmin) {
                      if (data[e.target.name] === listItem) {
                        setloadDataNow((loadDataNow) => !loadDataNow);
                        setData((prevState) => {
                          return { ...prevState, [e.target.name]: null };
                        });
                      } else {
                        setloadDataNow((loadDataNow) => !loadDataNow);
                        setData((prevState) => {
                          return { ...prevState, [e.target.name]: listItem };
                        });
                      }
                    } else {
                      CreateNotif("У вас нет полномочий для данного действия");
                    }
                  }}
                />
                <label
                  htmlFor={name + "__" + index}
                  className="we-supply__radio"
                ></label>
                <label htmlFor={name + "__" + index}>{listItem}</label>
              </div>
              {isNeedTextFieldsAfterEach && !!data[name + "__" + index] && (
                <div key={name + "__" + index + "text"}>
                  <TextAreaOrInputElement
                    name={name + "__" + index + "text"}
                    isInput={true}
                    type={"text"}
                    data={data}
                    setData={setData}
                  />
                </div>
              )}
            </React.Fragment>
          );
        })
      ) : (
        <>
          <div>
            <input
              className="input-form checkbox"
              type="checkbox"
              id={name}
              name={name}
              defaultChecked={data[name]}
              onChange={(e) => {
                if (executorIdofTask === userId || isAdmin) {
                  if (data[name] === text) {
                    setloadDataNow((loadDataNow) => !loadDataNow);
                    setData((prevState) => {
                      return { ...prevState, [e.target.name]: null };
                    });
                  } else {
                    setloadDataNow((loadDataNow) => !loadDataNow);
                    setData((prevState) => {
                      return { ...prevState, [e.target.name]: text };
                    });
                  }
                } else {
                  CreateNotif("У вас нет полномочий для данного действия");
                }
              }}
            />
            <label htmlFor={name} className="we-supply__radio"></label>
            <label htmlFor={name}>{text}</label>
          </div>
        </>
      )}
      {((text && !data[name]) || (arr && checkedElements === 0)) &&
        text !== "Не применимо" &&
        taskId &&
        (statusOfTask === 0 || statusOfTask === 2) &&
        (executorIdofTask === userId || isAdmin) && (
          <h2 className="error-message-in-forms-check" style={{ color: "red" }}>
            Выберите значение
          </h2>
        )}
    </div>
  );
};

export default CheckboxElement;
