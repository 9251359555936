import React from 'react';
import { setUserPatronymic } from '../actions';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import Input from '../../../common/components/Inputs/Input';
import { State } from '../../../rootReducer';
import { User } from '../../../common/types';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

type Props = {
  patronymic: string;
  userId: number;
  currentUserInfo: User | null;
  setUserPatronymic: (patronymic: string) => void;
  setChangeDataUser: any;
  rolesUser: number[];
};

//отчество можно менять для новых пользователей, для себя и если у пользователя есть роль  админа

const UserPatronymic: React.FC<Props> = ({
  patronymic,
  userId,
  currentUserInfo,
  rolesUser,
  setUserPatronymic,
  setChangeDataUser,
}) => {
  return (
    <NameWithInput title="Отчество" iconName="people">
      <Input
        disabled={
          !(
            userId === 0 ||
            userId === currentUserInfo?.id ||
            currentUserInfo?.roles.includes(1) ||
            currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]
          )
        }
        inputType="text"
        placeholder=""
        title=""
        value={patronymic ? patronymic : ''}
        changeHandler={(patronymic) => {
          setUserPatronymic(patronymic);
          userId !== 0 &&
            setChangeDataUser((prev) => {
              return {
                ...prev,
                data: { ...prev.data, patronymic },
                schedule: { ...prev.schedule },
              };
            });
        }}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    patronymic: state.newUser.patronymic,
    userId: state.newUser.id,
    rolesUser: state.newUser.roles,
    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setUserPatronymic: setUserPatronymic,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPatronymic);
